import {
  CaretDownFilled,
  EllipsisOutlined,
  LoadingOutlined,
  RightOutlined,
  SearchOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Badge, Breadcrumb, Dropdown, Input, Menu } from 'antd';
import Link, { navigate } from 'gatsby-link';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { callGetApi } from '../../api/axios';
import '../../assets/style/header.css';
import FmIcon from '../../assets/style/icons/fairmatic-logo.svg';
import headerMenuOptions from '../../constants/headerMenuConstants';
import { useGlobalState } from '../../contexts/AppContext';
import MixpanelContext from '../../services/tracking';
import { DriverResultLabel } from '../../utils/common';
import { Routes, roles_enum } from '../../utils/roles';
import { debounce } from '../../utils/search';
import { toast, toastTypes } from '../../utils/toast';
import { getUser } from '../../utils/validateUserToken';

function Header({ type }) {
  const mixpanel = useContext(MixpanelContext);
  const STYLE = { background: '#fff', color: 'rgb(65, 65, 65)' };
  const { state } = useGlobalState();
  const user = getUser();
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const user_role = user?.roles?.length ? roles_enum[user?.roles[0]] : '';
  const siteTitle = state?.activeItem?.name;
  const breadcrumbs = state?.breadcrumbs;

  const menu = (
    <Menu
      className="menu-dropdown"
      selectedKeys={[siteTitle]}
      theme="dark"
      style={{ borderRight: 0, background: 'transparent' }}
    >
      <Menu.Item
        style={siteTitle !== headerMenuOptions.DASHBOARD ? STYLE : {}}
        key={headerMenuOptions.DASHBOARD}
        onClick={() => navigate(user_role?.defaultRoute)}
      >
        Dashboard
      </Menu.Item>
      <Menu.Item
        style={siteTitle !== headerMenuOptions.SETTINGS ? STYLE : {}}
        key={headerMenuOptions.SETTINGS}
        onClick={() => {
          navigate('/app/settings');
          mixpanel.track('Settings');
        }}
      >
        Settings
      </Menu.Item>
      <Menu.Item
        style={STYLE}
        key={headerMenuOptions.HELP_CENTER}
        onClick={() => navigate('/helpcenter')}
      >
        Help Center
      </Menu.Item>
      <Menu.Item
        style={STYLE}
        key={headerMenuOptions.DEVELOPER}
        onClick={() => {
          window.open('https://docs.fairmatic.com/', '_blank').focus();
        }}
      >
        Developer
      </Menu.Item>
      <Menu.Item
        style={siteTitle !== headerMenuOptions.LOGOUT ? STYLE : {}}
        key={headerMenuOptions.LOGOUT}
        onClick={() => {
          mixpanel.track('Logout');
          mixpanel.people.increment('Logout');
          Cookies.remove('Authorization');
          Cookies.remove('User');
          navigate('/login');
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );
  const driverList = (
    <Menu>
      {drivers.length > 0 ? (
        <div style={{ maxHeight: '600px', overflow: 'auto' }}>
          {drivers.map((driver) => {
            const driverName = DriverResultLabel(driver);
            return (
              <Menu.Item
                key={driver.id}
                onClick={() => {
                  mixpanel.track('Selected Driver', {
                    searchKeyword: driverName,
                  });
                  navigate(
                    `/app/dashboard/driver-details?driverId=${encodeURIComponent(
                      driver.id,
                    )}`,
                  );
                }}
              >
                <div className="d-flex align-items-center py-2">
                  <div className="px-3">
                    <div className="user_avatar_wrapper">
                      <UserOutlined />
                    </div>
                  </div>
                  <div className="px-3">
                    <p className="driver_name">{driverName}</p>
                  </div>
                </div>
              </Menu.Item>
            );
          })}
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-center p-4">
          <h4>No driver found</h4>
        </div>
      )}
    </Menu>
  );

  async function searchDriver(query) {
    setLoading(true);
    try {
      const { data } = await callGetApi(
        `/v1/api/search-drivers?query=${encodeURIComponent(query)}`,
      );
      setDrivers(data);
    } catch (err) {
      const { message } = err?.response?.data || {};
      if (message) toast(toastTypes.ERROR, message);
    } finally {
      setLoading(false);
    }
  }

  const onChange = (event) => {
    debounce(() => {
      // this check required so that when the input is cleared
      // we do not need to call the search driver api
      if (event?.target.value) {
        searchDriver(event.target.value);
        mixpanel.track('Search Driver', {
          searchKeyword: event.target.value,
        });
        mixpanel.people.increment('# of Driver searches');
        return;
      }
      setDrivers([]);
    }, 800)();
  };

  const renderPageHeader = () => {
    if (!breadcrumbs.length) {
      if (siteTitle === 'Incidents' && state.unreadIncidents) {
        return (
          <h2 className="page__title">
            <Badge
              dot
              style={{ width: '10px', height: '10px', marginRight: '10px' }}
              offset={[0, 7]}
            />
            {`${state.unreadIncidents} Unread Incidents`}
          </h2>
        );
      }
      return <h2 className="page__title">{siteTitle}</h2>;
    }

    return (
      <h2 className="page__title">
        <Breadcrumb
          separator={
            <RightOutlined style={{ color: 'white', fontSize: '20px' }} />
          }
        >
          {breadcrumbs.map((bd) => (
            <Breadcrumb.Item
              key={bd.uri}
              className="driver_header"
              style={{ cursor: 'pointer' }}
              onClick={() => bd?.uri && navigate(bd?.uri)}
            >
              {bd?.text}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </h2>
    );
  };

  return (
    <div className="header">
      <div className="header_back">
        <div
          className="px-3"
          style={{
            width: '15%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Link to={user_role?.defaultRoute}>
            <FmIcon style={{ fontSize: '48px' }} className="fm_logo" />
          </Link>
        </div>
        <div className="px-3">{renderPageHeader()}</div>
        <div
          className="ml-auto px-2"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {type === 'settings' ||
          window.location.pathname === Routes.SUPPORT ? null : (
            <div className="ml-auto px-2">
              <Dropdown
                overlay={driverList}
                placement="bottomRight"
                trigger={['click']}
              >
                <div className="search_container">
                  <Input
                    size="small"
                    placeholder="Enter Driver ID, First name, Last name"
                    className="search_box"
                    prefix={<SearchOutlined className="search-icon" />}
                    onChange={onChange}
                    suffix={
                      loading ? (
                        <LoadingOutlined className="search-loading" />
                      ) : null
                    }
                  />
                </div>
              </Dropdown>
            </div>
          )}
          <div>
            <Dropdown
              overlay={menu}
              placement="bottomRight"
              trigger={['click']}
            >
              {type === 'settings' ? (
                <div style={{ display: 'flex', cursor: 'pointer' }}>
                  <p className="user_name">{user?.first_name?.toUpperCase()}</p>
                  <CaretDownFilled
                    style={{
                      color: 'white',
                      stroke: 'white',
                      fontSize: '12px !important',
                      marginLeft: '2px',
                    }}
                  />
                </div>
              ) : (
                <EllipsisOutlined
                  rotate={90}
                  className="menu-icon"
                  onClick={(e) => e.preventDefault()}
                />
              )}
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
}

Header.propTypes = {
  type: PropTypes.string,
};

Header.defaultProps = {
  type: 'dashboard',
};

export default Header;
