const INVALID_NAME_MESSAGE = 'Name cannot have @ or numbers';
const EMAIL_PHONE_ERROR_MESSAGE = 'Please enter either email or phone number';

const appStatusDocsURL =
  'https://docs.fairmatic.com/knowledge-base/fairmatic-app-status';

const deviceOwnershipMap = {
  private: 'Private Device (Personal)',
  business_dedicated: 'Business Device (Dedicated)',
  business_shared: 'Business Device (Shared)',
};

const deviceOwnershipSelectOptions = Object.keys(deviceOwnershipMap).map(
  (key) => ({
    value: key,
    label: deviceOwnershipMap[key],
  }),
);

export {
  EMAIL_PHONE_ERROR_MESSAGE,
  INVALID_NAME_MESSAGE,
  appStatusDocsURL,
  deviceOwnershipMap,
  deviceOwnershipSelectOptions,
};
