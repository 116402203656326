import { Button, Menu, Space, Typography } from 'antd';
import React from 'react';

export default function TagsEmptyMenu({ handleModalToggle, setOpen }) {
  return (
    <Menu key="edit-tags-empty-menu">
      <Space
        key="tags-error"
        style={{
          width: 250,
          textAlign: 'center',
          padding: 10,
        }}
        direction="vertical"
      >
        <Typography.Text>No tags available</Typography.Text>
        <Typography.Text type="secondary">
          Please create tags to assign
        </Typography.Text>

        <Button
          onClick={() => {
            handleModalToggle('tags');
            setOpen(false);
          }}
          style={{
            marginTop: 5,
          }}
          type="primary"
        >
          Manage Tags
        </Button>
      </Space>
    </Menu>
  );
}
