import { CloseOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import 'assets/style/dashboard/drivers.css';
import { PERFORMANCE_FILTERS } from 'constants';
import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { fetchLocationsService } from 'services';
import { getDefaultDateRange, handleDateSelection } from 'utils/dateFilter';
import DateWidget from '../../components/widgets/dateWidget';
import LocationWidget from '../../components/widgets/locationWidget';
import PeriodsFilter from '../../components/widgets/periodsFilter';
import { sort_types as SORT_TYPES } from '../../constants/sort-types';
import useUserDetails from '../../hooks/useUserDetails';
import { toast, toastTypes } from '../../utils/toast';

import PerformanceFilters from '../../components/widgets/performanceFilters';
import TagsFilter from '../../components/widgets/TagsFilter';
import { useGlobalState } from '../../contexts/AppContext';
import MixpanelContext from '../../services/tracking';
import DriversTable from './components/DriversTable';
import { ORDER_BY } from './constants';
import { getDriversListService, getStatsService } from './services';
import transformDriverList from './utils';

const limit = 50;
export default function Drivers() {
  const mixpanel = useContext(MixpanelContext);
  const { user, isPeriodsFilterEnabled, periodFilterPersistKey } =
    useUserDetails();
  const { dispatch } = useGlobalState();
  const distanceUnit = 'miles';

  const [loading, setLoading] = useState(false);
  const [dashboardStatsLoading, setDashboardStatsLoading] = useState(false);
  const [toggleFilterOnMobile, setToggleFilterOnMobile] = useState(false);
  const [totalDrivers, setTotalDrivers] = useState(0);
  const [sort, setSort] = useState({
    key: ORDER_BY.zendrive_score.key,
    order: SORT_TYPES.desc,
  });

  const [prefGroup, setPrefGroup] = useState(
    queryString.parse(window.location.search)?.perfGroup ||
      PERFORMANCE_FILTERS.all.key,
  );

  const [toggleNewDrivers, setToggleNewDrivers] = useState(false);
  const [locationList, setLocationList] = useState([
    { label: 'All locations', value: null },
  ]);
  const [selectedLocation, setSelectedLocation] = useState(
    queryString.parse(window.location.search)?.loc || null,
  );
  const [selectedPeriod, setSelectedPeriod] = useState([
    ...JSON.parse(sessionStorage.getItem(periodFilterPersistKey) || '["ALL"]'),
  ]);
  const [drivers, setDrivers] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const [currentActivePageNumber, setCurrentActivePageNumber] = useState(1);

  const [driversStats, setDriversStats] = useState({
    low_distance: 0,
    excellent: 0,
    coaching: 0,
    fair: 0,
  });
  const [dateWidgetData, setDateWidgetData] = useState(getDefaultDateRange);
  const { startDate, endDate, intervalType } = dateWidgetData;

  const updateUrlParam = (param, value) => {
    setTimeout(() => {
      const searchParams = new URLSearchParams(window.location.search);
      if (value === undefined) {
        searchParams.delete(param);
      } else {
        searchParams.set(param, value);
      }
      const newRelativePathQuery = `${
        window.location.pathname
      }?${searchParams.toString()}`;
      window.history.pushState(null, '', newRelativePathQuery);
      // window.location.search = searchParams.toString();
    }, 0);
  };

  async function fetchLocations() {
    const params = {
      apiKey: user.api_key,
      start_date: startDate,
      // end_date: endDate,
      interval: intervalType,
    };

    const {
      data,
      isError = false,
      errorMessage = '',
    } = await fetchLocationsService({
      queryParams: params,
    });
    if (isError) {
      toast(toastTypes.ERROR, errorMessage);
      return;
    }
    if (data.length > 0) {
      setLocationList(data);
    }
  }

  async function getDriversList() {
    setLoading(true);
    const params = {
      start_date: startDate,
      interval: intervalType,
      order_by: sort.key,
      order_type: sort.order,
      offset: (currentActivePageNumber - 1) * limit,
      limit,
      ...(isPeriodsFilterEnabled && {
        trip_periods: selectedPeriod,
      }),
      ...(selectedTags.length > 0 && { tags: selectedTags }),
    };
    if (selectedLocation && selectedLocation !== 'null') {
      params.location = selectedLocation;
    }
    if (prefGroup !== 'all') {
      params.perf_group = prefGroup;
    }

    const {
      data,
      isError = false,
      errorMessage = '',
    } = await getDriversListService({
      queryParams: params,
    });
    if (isError) {
      setLoading(false);
      toast(toastTypes.ERROR, errorMessage);
      return;
    }
    const driversList = transformDriverList(data.drivers, { distanceUnit });
    setTotalDrivers(data.count || 0);
    setDateWidgetData({
      ...dateWidgetData,
      endDate: data.end_date,
    });
    setDrivers(driversList);
    setLoading(false);
  }

  // GET DASHBOARD STATS API
  async function getDriverStats() {
    setDashboardStatsLoading(true);
    const params = {
      start_date: startDate,
      interval: intervalType,
      ...(isPeriodsFilterEnabled && {
        trip_periods: selectedPeriod,
      }),
      ...(selectedTags.length > 0 && { tags: selectedTags }),
    };
    if (selectedLocation) {
      params.location = selectedLocation;
    }
    const {
      isError = false,
      data,
      errorMessage = '',
    } = await getStatsService({
      queryParams: params,
    });

    if (isError) {
      console.log(isError);
      toast(toastTypes.ERROR, errorMessage);
      return;
    }
    setDriversStats(data);
    setDashboardStatsLoading(false);
  }

  const handleLocationSelection = (loc) => {
    setSelectedLocation(loc);
    updateUrlParam('loc', loc);
  };

  const handleStartDateSelection = (dt, interval) => {
    const { start, end } = handleDateSelection(dt, interval);
    const params = {
      startDate: start,
      endDate: end,
      intervalType: interval,
    };
    setDateWidgetData(params);
    localStorage.setItem('FM_DATE_FILTER', JSON.stringify(params));

    mixpanel.track('Filter a report by Datetime', {
      ...params,
    });
    mixpanel.people.increment('# of Filters by Datetime');
  };

  const updatePerfGroup = (value) => {
    setPrefGroup(value);
    updateUrlParam('perfGroup', value);

    mixpanel.track('Filter a report by Performance', {
      performanceParam: value,
    });
    mixpanel.people.increment('# of Filters by Performance');
  };

  const handlePeriodChange = (period) => {
    setSelectedPeriod(period);
    updateUrlParam('period', period);
  };

  useEffect(() => {
    dispatch({
      type: 'SET_HEADER_BREADCRUMBS',
      payload: [],
    });
    mixpanel.track('View Page', {
      pageName: 'Drivers Page',
    });
  }, []);

  useEffect(() => {
    getDriverStats();
  }, [
    toggleNewDrivers,
    startDate,
    intervalType,
    selectedLocation,
    selectedPeriod,
    selectedTags,
  ]);

  useEffect(() => {
    fetchLocations();
    getDriversList();
  }, [
    startDate,
    intervalType,
    sort,
    prefGroup,
    toggleNewDrivers,
    selectedLocation,
    selectedPeriod,
    selectedTags,
    currentActivePageNumber,
  ]);

  const handleTagChange = (tags) => {
    setSelectedTags(tags);
  };

  return (
    <div className="playground">
      <div className="d-flex align-items-start driver_component_wrapper">
        {/* LIST OF DRIVERS TABLE WRAPPEr */}
        <div className="table_wrapper">
          <div className="filters_wrapper">
            <DateWidget
              onSelectDate={(date, interval) => {
                handleStartDateSelection(date, interval);
              }}
              startDate={startDate}
              endDate={endDate}
              intervalType={intervalType}
            />
            <LocationWidget
              locations={locationList}
              onSetLocation={handleLocationSelection}
              selectedLocation={selectedLocation}
            />

            <PeriodsFilter
              selectedPeriod={selectedPeriod}
              handlePeriodChange={handlePeriodChange}
            />

            <TagsFilter handleTagChange={handleTagChange} />

            <PerformanceFilters
              prefGroup={prefGroup}
              setPrefGroup={updatePerfGroup}
              toggleNewDrivers={toggleNewDrivers}
              setToggleNewDrivers={(value) => setToggleNewDrivers(value)}
              driversStats={driversStats}
              onClose={() => setToggleFilterOnMobile(!toggleFilterOnMobile)}
              loading={dashboardStatsLoading}
            />

            <div style={{ marginLeft: 'auto' }}>
              <div className="d-flex align-items-center">
                <div
                  className="show_filters_on_mobile px-3"
                  onClick={() => setToggleFilterOnMobile(true)}
                >
                  <div className="mobile_filter_wrapper">
                    <p className="driver_name">Filters</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-4">
            <Alert
              message="Data is displayed as per browser time zone & trips can take up to
              2 hours from trip completion to be displayed here"
              type="info"
              showIcon
              className="drivers_list_info_alert"
            />
            <div className="drivers_list_card_background">
              <DriversTable
                drivers={drivers}
                loading={loading}
                className="px-4 py-4"
                setCurrentActivePageNumber={setCurrentActivePageNumber}
                currentActivePageNumber={currentActivePageNumber}
                totalDrivers={totalDrivers}
                sort={sort}
                setSort={setSort}
              />
            </div>
          </div>
        </div>
        {toggleFilterOnMobile && (
          <div className="filter_slider">
            <div className="filter_wrapper_on_mobile_back show_filters_on_mobile">
              <div className="close_icon">
                <CloseOutlined
                  onClick={() => setToggleFilterOnMobile(!toggleFilterOnMobile)}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
