import { Tag } from 'antd';
import React, { useContext, useState } from 'react';
import AppStatusContext from '../../contexts/AppStatusContext';
import { appStatusDocsURL } from './AppStatusConstants';

export default function DriverTableIssues({ tags }) {
  const [showMore, setShowMore] = useState(false);
  const handleShowMore = () => setShowMore(true);
  const handleShowLess = () => setShowMore(false);
  const { status } = useContext(AppStatusContext);
  const { all_error_codes } = status;
  if (tags.length === 0) {
    return '-';
  }

  const fetchLink = (tag) => {
    const errorCode = all_error_codes?.filter(
      (err) => err.display_name === tag,
    )[0];
    return `${appStatusDocsURL}#${errorCode?.code ? errorCode.code : ''}`;
  };

  return (
    <div className="driver_issue_tags_wrapper">
      {tags.slice(0, showMore ? tags.length : 1).map((tag) => (
        <span key={tag}>
          <Tag className="driver_issue_tag">
            <a target="_blank" rel="noreferrer" href={fetchLink(tag)}>
              {tag}
            </a>
          </Tag>
        </span>
      ))}
      {tags.length > 1 && (
        <span className="driver_issue_action">
          {showMore ? (
            <Tag color="#586CC4" onClick={handleShowLess}>
              show less
            </Tag>
          ) : (
            <Tag className="driver_issue_tag" onClick={handleShowMore}>
              + {tags.length - 1}
            </Tag>
          )}
        </span>
      )}
    </div>
  );
}
