import { Table } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import AppStatusContext from '../../contexts/AppStatusContext';
import { formatFilteredKeys, formatTableColumns } from './DriverTableUtils';

export default function DriverTable() {
  const {
    allTags,
    status,
    driversList,
    isDriversLoading,
    handleFilterChange,
    selectedRowKeys,
  } = useContext(AppStatusContext);
  const { all_error_codes } = status;
  const ref = useRef();
  const [tableHeight, setTableHeight] = useState(600);
  const { result = [] } = driversList;
  const searchableErrors = all_error_codes?.filter(
    (err) => err.is_searchable === true,
  );

  const TableColumns = formatTableColumns(searchableErrors, allTags);

  useEffect(() => {
    const node = ref.current;
    const resizeObserver = new ResizeObserver(() => {
      const { top } = node.getBoundingClientRect();
      setTableHeight(window.innerHeight - top - 155);
    });
    resizeObserver.observe(node);

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref, result]);

  const onTableChange = (_, filters) => {
    const issues = formatFilteredKeys(filters?.issues, searchableErrors);
    const activity = filters?.is_active?.[0];
    const tags = filters?.tags;
    handleFilterChange({
      issues,
      activity,
      tags,
    });
  };

  return (
    <Table
      ref={ref}
      rowKey={(record) => record.driver_id}
      selectedRowKeys={selectedRowKeys}
      columns={TableColumns}
      dataSource={result.map((key) => key)}
      pagination={false}
      onChange={onTableChange}
      loading={isDriversLoading}
      tableLayout="auto"
      scroll={{ y: tableHeight, x: 'max-content' }}
    />
  );
}
