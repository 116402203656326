import React from 'react';
import PropTypes from 'prop-types';

const AppContext = React.createContext();

function AppReducer(state, action) {
  switch (action.type) {
    case 'TOGGLE_SIDEBAR':
      return { ...state, toggleSidebarOnMobile: !state.toggleSidebarOnMobile };
    case 'SET_ACTIVE_ITEM':
      return { ...state, activeItem: action.payload };
    case 'SET_HEADER_BREADCRUMBS':
      return { ...state, breadcrumbs: action.payload };
    case 'SET_UNREAD_INCIDENTS':
      return { ...state, unreadIncidents: action.payload };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AppContextProvider({ children }) {
  const [state, dispatch] = React.useReducer(AppReducer, {
    activeItem: {},
    breadcrumbs: [],
  });
  const value = React.useMemo(() => ({ state, dispatch }), [state, dispatch]);
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

function useGlobalState() {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error('AppContext must be used within a ContextProvider');
  }
  return context;
}

AppContextProvider.propTypes = {
  children: PropTypes.node,
};

export { AppContextProvider, useGlobalState };
