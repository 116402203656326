import Highcharts from 'highcharts';
import moment from 'moment';
import { BLACK } from '../../assets/style/colors';
import { performanceGradeBreakdown } from '../../utils/performanceGradeCharts';

const setPerfBreakdownChart = (
  chartData,
  xAxisLabelFormat = 'MMM D',
  tooltipLabelFormat = 'MMM D, yyyy',
) => {
  const xAxis = chartData?.dates;

  return Highcharts.chart('pf-breakdown-chart', {
    chart: {
      height: 220,
      type: 'area',
    },
    title: {
      text: null,
      verticalAlign: 'top',
      floating: true,
      align: 'left',
    },
    yAxis: {
      title: {
        text: null,
      },
      tickLength: 0,
      tickInterval: undefined,
      min: 0,
      labels: {
        formatter: function formatter() {
          if (this.value === 0) {
            return 0;
          }
          if (this.value >= 0) {
            return this.value;
          }

          return null;
        },
        style: {
          color: BLACK,
          fontSize: '12px',
        },
      },
    },
    xAxis: {
      categories: xAxis,
      labels: {
        formatter: function formatter(v) {
          return moment(v?.value).format(xAxisLabelFormat).toUpperCase();
        },
        style: {
          color: 'black',
          fontSize: '12px',
        },
        autoRotation: false,
        y: 40,
      },
      tickLength: 10,
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
    },
    tooltip: {
      formatter: function formatter() {
        const date = moment(this.point.category).format(tooltipLabelFormat);
        const currentValue = this.point.options.y;
        const percentage =
          this.point.stackTotal !== 0
            ? parseInt((currentValue / this.point.stackTotal) * 100, 10)
            : 0;
        const adjective = this.point.series.name.toUpperCase();
        return '\n  <div class="date">'
          .concat(date, '</div>\n    <div class="score">\n      ')
          .concat(
            currentValue,
            '\n      <span class="middledot">&nbsp;&nbsp;&middot;&nbsp;&nbsp;</span>\n      (',
          )
          .concat(percentage, '%)\n    </div>\n  <div class="qualifier">')
          .concat(adjective, ' DRIVERS</div>\n  ');
      },
      backgroundColor: null,
      borderWidth: 0,
      shadow: false,
      useHTML: true,
      style: {
        padding: 0,
      },
    },
    plotOptions: {
      area: {
        animation: false,
        marker: {
          enabled: xAxis.length === 1,
          symbol: 'circle',
          radius: 6,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
        lineWidth: 2,
        stacking: 'normal',
      },
    },

    series: performanceGradeBreakdown({ data: chartData }),
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              VerticalAlignValue: 'top',
            },
          },
        },
      ],
    },
    credits: { enabled: false },
  });
};

const formatPerfBreakdownChartData = (pfData) => {
  const data = {
    excellent: [],
    good: [],
    moderate: [],
    coaching: [],
    dates: [],
  };
  pfData.forEach((d) => {
    data.dates.push(d.date);
    data.excellent.push(d.excellent);
    data.good.push(d.good);
    data.moderate.push(d.moderate);
    data.coaching.push(d.coaching_required);
  });
  return data;
};

const formatLineChartData = (
  metricData = [],
  xAxisKey = 'date',
  yAxisKey = 'count',
  converter = null,
) => {
  const dates = metricData?.map((e) => e[xAxisKey]);
  const values = metricData?.map((e) => {
    let value = e[yAxisKey];
    if (converter) {
      value = converter(value);
    }
    return value == null ? null : +value;
  });

  return { dates, values };
};

const formatScoreHistoryData = (metricData = [], xAxisKey = 'date') => {
  const dates = metricData?.map((e) => e[xAxisKey]);
  const values = metricData?.map((e) => {
    const score = e?.fm_score;
    return score === null ? null : +score;
  });

  return { dates, values };
};

const getLineChartAxisData = (metricData) => {
  const xAxis = [];
  const yAxis = [];
  metricData?.dates?.forEach((d, idx) => {
    yAxis.push(metricData.values[idx]);
    xAxis.push(d);
  });

  return { xAxis, yAxis };
};

export {
  formatLineChartData,
  formatPerfBreakdownChartData,
  formatScoreHistoryData,
  getLineChartAxisData,
  setPerfBreakdownChart,
};
