import React, { useEffect, useContext } from 'react';
import ValidatorToolCard from './ValidatorToolCard';
import MixpanelContext from '../../../services/tracking';

export default function ValidatorTool() {
  const mixpanel = useContext(MixpanelContext);

  useEffect(() => {
    mixpanel.track('View Page', {
      pageName: 'Validation tools Page',
    });
  }, [mixpanel]);

  return (
    <div className="playground">
      {/* VALIDATOR TOOL CARD  */}
      <ValidatorToolCard />
    </div>
  );
}
