import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import ConfirmationModal from 'components/shared/confirmationModal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { callPostApi } from '../../../api/axios';
import { toast, toastTypes } from '../../../utils/toast';
import { getUser } from '../../../utils/validateUserToken';
import AddManagerModal from './AddManagerModal';

const STATIC_COLUMNS = [
  {
    title: 'name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
  },
];
export default function ManagerListCard(props) {
  const user = getUser();
  const { listOfManagers, updateListOfManagers, deleteAManager } = props;
  const [editManagerModal, setEditManagerModal] = useState({
    toggle: false,
    data: {},
  });
  const [toggleDeleteManagerModal, setToggleDeleteManagerModal] = useState({
    toggle: false,
    data: {},
  });
  const [loading, setLoading] = useState(false);
  const columns = [
    ...STATIC_COLUMNS,
    {
      key: 'edit',
      render: (record) => (
        <EditOutlined
          title="Edit"
          onClick={() =>
            setEditManagerModal(() => ({
              toggle: true,
              data: record,
            }))
          }
        />
      ),
    },
    {
      key: 'delete',
      render: (record) =>
        user.email !== record.email ? (
          <DeleteOutlined
            title="Delete"
            onClick={() =>
              setToggleDeleteManagerModal(() => ({
                toggle: true,
                data: record,
              }))
            }
          />
        ) : null,
    },
  ];
  async function handleDeleteMember() {
    setLoading(true);
    try {
      const { data } = await callPostApi('/v1/api/delete-user', {
        user_id: toggleDeleteManagerModal.data.email,
      });
      setToggleDeleteManagerModal((state) => ({
        ...state,
        toggle: false,
      }));
      deleteAManager(data);
    } catch (err) {
      const { message } = err?.response?.data || {};
      if (message) toast(toastTypes.ERROR, message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="p-2">
      {/* EDIT MANAGER MODAL  */}
      {editManagerModal.toggle && (
        <AddManagerModal
          userInfo={editManagerModal.data}
          visible={editManagerModal.toggle}
          onCancel={() =>
            setEditManagerModal((state) => ({
              ...state,
              toggle: false,
            }))
          }
          onProceed={(value) => {
            setEditManagerModal((state) => ({
              ...state,
              toggle: false,
            }));
            updateListOfManagers(value);
          }}
          title="Update Member"
          footer={null}
        />
      )}
      {/* DELETE MANAGER MODAL  */}
      {toggleDeleteManagerModal.toggle && (
        <ConfirmationModal
          visible={toggleDeleteManagerModal.toggle}
          title="Delete Manager"
          footer={[
            <Button
              key="cancel"
              disabled={loading}
              onClick={() =>
                setToggleDeleteManagerModal((state) => ({
                  ...state,
                  toggle: false,
                }))
              }
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="danger"
              loading={loading}
              disabled={loading}
              onClick={() => handleDeleteMember()}
            >
              Delete
            </Button>,
          ]}
        >
          <p>Are you sure you want to delete this user</p>
        </ConfirmationModal>
      )}
      <div className="account_info_wrapper_card">
        <div className="card_header_spacing">
          <h4 className="card_header_text">
            Members [{listOfManagers.length}]
          </h4>
        </div>
        <div className="card_body_spacing" style={{ overflow: 'scroll' }}>
          {listOfManagers.length > 0 ? (
            <Table
              columns={columns}
              pagination={false}
              dataSource={listOfManagers}
            />
          ) : (
            <p>There are no members</p>
          )}
        </div>
      </div>
    </div>
  );
}

ManagerListCard.propTypes = {
  listOfManagers: PropTypes.array,
  updateListOfManagers: PropTypes.func,
  deleteAManager: PropTypes.func,
};
