import React, { useState, useEffect, useContext } from 'react';
import '../../assets/style/settings/settings.css';
import '../../assets/style/authStyles.css';
import { LoadingOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import { getUser } from '../../utils/validateUserToken';
import { Roles } from '../../utils/roles';
import MixpanelContext from '../../services/tracking';

export default function Incidents() {
  const user = getUser();
  const mixpanel = useContext(MixpanelContext);
  const isSysAdmin = user?.roles?.includes(Roles.SYS_ADMIN);
  const [loading, setLoading] = useState(true);

  const iframeUrl = `${
    process.env.REACT_APP_BASE_URL
  }/oauth/authorize?client_id=${
    process.env.OAUTH_XTRACT_CLIENT_ID
  }&scope=profile&response_type=code&redirect_uri=${
    process.env.XTRACT_IFRAME_URL
  }&token=${Cookies.get('Authorization')}`;

  const onIframeLoad = () => {
    setLoading(false);
  };

  useEffect(() => {
    mixpanel.track('View Page', {
      pageName: 'Incidents Page',
    });
  }, [mixpanel]);

  return (
    <div className="playground" style={{ padding: 0 }}>
      {isSysAdmin ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '75vh',
            fontSize: '18px',
            color: 'gray',
          }}
        >
          This functionality can only be accessed by users with either
          Administrator or Fleet Manager roles
        </div>
      ) : (
        <div>
          {loading && (
            <div
              style={{
                padding: '15px',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div>
                <LoadingOutlined style={{ fontSize: '32px' }} />
              </div>
            </div>
          )}
          <iframe
            src={iframeUrl}
            title="iframe"
            style={{ border: 'none', height: '100vh' }}
            width="100%"
            onLoad={onIframeLoad}
            allow="geolocation *"
          />
        </div>
      )}
    </div>
  );
}
