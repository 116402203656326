// @flow

import moment from 'moment';
import { isBetween } from './date';

const day = 1000 * 60 * 60 * 24;

const getUnavailableFinalDataCapsule = (startDate, endDate, capsuleColor) => {
  const unavailableDataCapsule = [];

  for (let i = 0; i < 5; i += 1) {
    const currentDataCapsule = {
      color: capsuleColor,
      end: endDate,
      start: startDate,
      y: i,
    };
    unavailableDataCapsule.push(currentDataCapsule);
  }
  return unavailableDataCapsule;
};

const getOtherUnavailableData = (data, dateRange) => {
  if (
    data &&
    data.aggregates &&
    data.aggregates.length > 0 &&
    !isBetween(
      dateRange.startDate,
      data.aggregates[0].date,
      dateRange.endDate,
      true,
    )
  ) {
    const startDate = new Date(dateRange.startDate).getTime();
    const endDate = new Date(data.aggregates[0].date).getTime();

    return getUnavailableFinalDataCapsule(
      startDate,
      endDate,
      'rgba(215, 218, 220, 0.7)',
    );
  }
  return null;
};

const yesterdayDateString = moment()
  .subtract(1, 'days')
  .startOf('day')
  .format('YYYY-MM-DD');
const beforeYesterdayDateString = moment()
  .subtract(2, 'days')
  .startOf('day')
  .format('YYYY-MM-DD');
const yesterdayDate = new Date(yesterdayDateString).getTime();
const beforeYesterdayDate = new Date(beforeYesterdayDateString).getTime();

const getCurrentAndPreviousDayData = (dateRange, data) => {
  const endDate = new Date(dateRange.endDate);
  endDate.setHours(0, 0, 0, 0);
  const endDateTS = endDate.getTime();

  const currentDateString = moment().startOf('day').format('YYYY-MM-DD');
  const dataLastDate = data.aggregates[data.aggregates.length - 1].date;

  const startDateTS = new Date(dataLastDate).getTime();

  if (
    dataLastDate !== yesterdayDateString &&
    dataLastDate !== beforeYesterdayDateString &&
    (isBetween(
      currentDateString,
      dateRange.startDate,
      dateRange.endDate,
      true,
    ) ||
      isBetween(
        yesterdayDateString,
        dateRange.startDate,
        dateRange.endDate,
        true,
      ))
  ) {
    return [
      {
        color: 'rgba(0, 0, 255, 0.7)',
        data: getUnavailableFinalDataCapsule(
          yesterdayDate,
          endDateTS + 1 * day,
          'rgba(0, 0, 255, 0.7)',
        ),
        name: 'Processing',
        type: 'gantt',
      },
    ];
  }
  if (
    isBetween(
      currentDateString,
      dateRange.startDate,
      dateRange.endDate,
      true,
    ) ||
    isBetween(yesterdayDateString, dateRange.startDate, dateRange.endDate, true)
  ) {
    return [
      {
        color: 'rgba(0, 0, 255, 0.7)',
        data: getUnavailableFinalDataCapsule(
          startDateTS + 1 * day,
          endDateTS + 1 * day,
          'rgba(0, 0, 255, 0.7)',
        ),
        name: 'Processing',
        type: 'gantt',
      },
    ];
  }
  return null;
};

const checkAndFillEmptyCells = (dateRange, index, tempData) => {
  const endDateDataCapsule = dateRange
    ? new Date(dateRange.endDate).getTime()
    : null;

  const currentDateDay = new Date(tempData[index].date);
  const currentDateTS = currentDateDay.getTime();
  const expectedNextDayDateTS = currentDateTS + day * 1;
  currentDateDay.setDate(currentDateDay.getDate() + 1);
  const expectedNextDayDateString = moment(currentDateDay).format('YYYY-MM-DD');
  const nextDateTS =
    index + 1 < tempData.length
      ? new Date(tempData[index + 1].date).getTime()
      : null;

  if (nextDateTS && expectedNextDayDateTS !== nextDateTS) {
    const unavailableDataInBetween = getUnavailableFinalDataCapsule(
      expectedNextDayDateTS,
      nextDateTS,
      'rgba(215, 218, 220, 0.7)',
    );
    return unavailableDataInBetween;
  }
  if (
    index === tempData.length - 1 &&
    currentDateTS !== beforeYesterdayDate &&
    currentDateTS !== yesterdayDate
  ) {
    if (
      dateRange &&
      isBetween(
        expectedNextDayDateString,
        dateRange.startDate,
        dateRange.endDate,
        true,
      ) &&
      isBetween(
        yesterdayDateString,
        dateRange.startDate,
        dateRange.endDate,
        true,
      )
    ) {
      const unavailableDataInBetween = getUnavailableFinalDataCapsule(
        expectedNextDayDateTS,
        yesterdayDate,
        'rgba(215, 218, 220, 0.7)',
      );
      return unavailableDataInBetween;
    }
    if (
      dateRange &&
      endDateDataCapsule &&
      isBetween(
        expectedNextDayDateString,
        dateRange.startDate,
        dateRange.endDate,
        true,
      ) &&
      expectedNextDayDateString !== dateRange.endDate
    ) {
      const unavailableDataInBetween = getUnavailableFinalDataCapsule(
        expectedNextDayDateTS,
        endDateDataCapsule + day * 1,
        'rgba(215, 218, 220, 0.7)',
      );
      return unavailableDataInBetween;
    }
  }
  return null;
};

export default function heartbeatTranslator(data, inputRange) {
  const dateRange = { ...inputRange };
  const tempData = data.aggregates;

  const onAllTimesData = [];
  const onIntermittentlyData = [];
  const offAllTimesData = [];
  let offUnavailableData = [];

  let finalData = [];
  let unavailableDataFlag = false;

  const invalidData =
    !dateRange ||
    !dateRange.startDate ||
    !dateRange.endDate ||
    (data && data.aggregates && data.aggregates.length === 0);

  const currentAndPreviousDayData = invalidData
    ? null
    : getCurrentAndPreviousDayData(dateRange, data);
  const otherUnavailableData = invalidData
    ? null
    : getOtherUnavailableData(data, dateRange);

  if (otherUnavailableData) {
    offUnavailableData = offUnavailableData.concat(otherUnavailableData);
    unavailableDataFlag = true;
  }

  for (let i = 0; i < tempData.length; i += 1) {
    const UnavailableData = checkAndFillEmptyCells(dateRange, i, tempData);
    if (UnavailableData) {
      offUnavailableData = offUnavailableData.concat(UnavailableData);
      unavailableDataFlag = true;
    }
    const currentDateTS = new Date(tempData[i].date).getTime();

    const overallObj = {
      start: currentDateTS,
      end: currentDateTS + day * 1,
      color: '',
      y: 0,
    };
    const setupObj = {
      start: currentDateTS,
      end: currentDateTS + day * 1,
      color: '',
      y: 1,
    };
    const locPermobj = {
      start: currentDateTS,
      end: currentDateTS + day * 1,
      color: '',
      y: 2,
    };
    const actPermobj = {
      start: currentDateTS,
      end: currentDateTS + day * 1,
      color: '',
      y: 3,
    };
    const locServobj = {
      start: currentDateTS,
      end: currentDateTS + day * 1,
      color: '',
      y: 4,
    };

    const tempOverallObj = overallObj;
    const tempSetupObj = setupObj;
    const tempLocPermObj = locPermobj;
    const tempActPermObj = actPermobj;
    const tempLocServObj = locServobj;

    switch (tempData[i].day_level_aggregates.overall_color_code) {
      case 'R':
        tempOverallObj.color = 'rgba(250, 42, 23, 0.7)';
        tempOverallObj.y = 0;
        offAllTimesData.push(tempOverallObj);
        break;
      case 'G':
        tempOverallObj.color = 'rgba(24, 170, 1, 0.7)';
        tempOverallObj.y = 0;
        onAllTimesData.push(tempOverallObj);
        break;
      default:
        tempOverallObj.color = 'rgba(254, 153, 2, 0.7)';
        tempOverallObj.y = 0;
        onIntermittentlyData.push(tempOverallObj);
        break;
    }

    switch (tempData[i].day_level_aggregates.setup_color_code) {
      case 'R':
        tempSetupObj.color = 'rgba(250, 42, 23, 0.7)';
        tempSetupObj.y = 1;
        offAllTimesData.push(tempSetupObj);
        break;
      case 'G':
        tempSetupObj.color = 'rgba(24, 170, 1, 0.7)';
        tempSetupObj.y = 1;
        onAllTimesData.push(tempSetupObj);
        break;
      default:
        tempSetupObj.color = 'rgba(254, 153, 2, 0.7)';
        tempSetupObj.y = 1;
        onIntermittentlyData.push(tempSetupObj);
        break;
    }

    switch (tempData[i].day_level_aggregates.location_permissions_color_code) {
      case 'R':
        tempLocPermObj.color = 'rgba(250, 42, 23, 0.7)';
        tempLocPermObj.y = 2;
        offAllTimesData.push(tempLocPermObj);
        break;
      case 'G':
        tempLocPermObj.color = 'rgba(24, 170, 1, 0.7)';
        tempLocPermObj.y = 2;
        onAllTimesData.push(tempLocPermObj);
        break;
      default:
        tempLocPermObj.color = 'rgba(254, 153, 2, 0.7)';
        tempLocPermObj.y = 2;
        onIntermittentlyData.push(tempLocPermObj);
        break;
    }

    switch (tempData[i].day_level_aggregates.activity_permissions_color_code) {
      case 'R':
        tempActPermObj.color = 'rgba(250, 42, 23, 0.7)';
        tempActPermObj.y = 4;
        offAllTimesData.push(tempActPermObj);
        break;
      case 'G':
        tempActPermObj.color = 'rgba(24, 170, 1, 0.7)';
        tempActPermObj.y = 4;
        onAllTimesData.push(tempActPermObj);
        break;
      default:
        tempActPermObj.color = 'rgba(254, 153, 2, 0.7)';
        tempActPermObj.y = 4;
        onIntermittentlyData.push(tempActPermObj);
        break;
    }

    switch (tempData[i].day_level_aggregates.location_service_color_code) {
      case 'R':
        tempLocServObj.color = 'rgba(250, 42, 23, 0.7)';
        tempLocServObj.y = 3;
        offAllTimesData.push(tempLocServObj);
        break;
      case 'G':
        tempLocServObj.color = 'rgba(24, 170, 1, 0.7)';
        tempLocServObj.y = 3;
        onAllTimesData.push(tempLocServObj);
        break;
      default:
        tempLocServObj.color = 'rgba(254, 153, 2, 0.7)';
        tempLocServObj.y = 3;
        onIntermittentlyData.push(tempLocServObj);
        break;
    }
  }

  const onData = {
    name: 'Online',
    type: 'gantt',
    color: 'rgba(24, 170, 1, 0.7)',
    data: onAllTimesData,
  };

  const offData = {
    name: 'Offline',
    type: 'gantt',
    color: 'rgba(250, 42, 23, 0.7)',
    data: offAllTimesData,
  };

  const onIntermittentData = {
    name: 'Degraded',
    type: 'gantt',
    color: 'rgba(254, 153, 2, 0.7)',
    data: onIntermittentlyData,
  };

  const unavailableData = {
    name: 'Unavailable',
    type: 'gantt',
    color: 'rgba(215, 218, 220, 0.7)',
    data: offUnavailableData,
  };

  finalData.push(onData);
  finalData.push(offData);
  finalData.push(onIntermittentData);

  if (currentAndPreviousDayData) {
    finalData = finalData.concat(currentAndPreviousDayData);
  }
  if (unavailableDataFlag) {
    finalData = finalData.concat(unavailableData);
  }

  return finalData;
}
