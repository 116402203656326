import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import MapWrapper from './MapWrapper';
import TripDetails from './TripDetails';

import { callGetApi } from '../../../../api/axios';
import { toast, toastTypes } from '../../../../utils/toast';

// Temporary fix, should move this to backend
const ALLOWED_EVENTS = {
  OVERSPEEDING: true,
  HARD_BRAKE: true,
  RAPID_ACCELERATION: true,
  PHONE_USE: true,
};
function TripDetailsContainer({ user, trip = {}, driverId }) {
  const [mapData, setMapData] = useState(null);
  const [mapLoading, setMapLoading] = useState(false);

  const getMapData = () => {
    setMapLoading(true);
    setMapData(null);

    callGetApi(`/v2/api/driver/${driverId}/trip/${trip.tripId}/trail?`)
      .then((resp) => {
        setMapData({
          ...resp.data,
          events: resp?.data?.events?.filter(
            ({ event_type_name }) => ALLOWED_EVENTS[event_type_name] === true,
          ),
        });
        setMapLoading(false);
      })
      .catch((err) => {
        const { fault } = err?.response?.data || {};
        if (fault) toast(toastTypes.ERROR, fault?.faultstring);
        setMapLoading(false);
      });
  };

  useEffect(() => {
    getMapData();
  }, []);
  return (
    <div className="trips_table_responsive trip_detailed_view">
      {/* ADD MAP BOX HERE  */}
      <div className="map_box_wrapper">
        {mapData && (
          <MapWrapper mapData={mapData} mapLoading={mapLoading} user={user} />
        )}
      </div>
      {/* DRIVER STATS  */}
      <TripDetails trip={trip} />
    </div>
  );
}
TripDetailsContainer.propTypes = {
  user: PropTypes.object.isRequired,
  trip: PropTypes.object.isRequired,
  driverId: PropTypes.string.isRequired,
};

export default TripDetailsContainer;
