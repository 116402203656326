import {
  ExclamationCircleOutlined,
  SearchOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Input, Typography } from 'antd';
import React from 'react';

export default function EditDriversTableHeader({
  searchTerm,
  handleSearch,
  editedDriversList,
}) {
  const numberOfDriversWithError = editedDriversList.filter(
    (driver) => driver.hasError,
  ).length;
  const numberOfDriversWithoutError =
    editedDriversList.length - numberOfDriversWithError;
  const DriverString = numberOfDriversWithoutError > 1 ? 'drivers' : 'driver';
  const ErrorString = numberOfDriversWithError > 1 ? 'errors' : 'error';

  return (
    <div className="edit_driver_table_header">
      <Input
        className="edit_driver_table_input"
        placeholder="Search by driver name, email, phone number"
        prefix={<SearchOutlined />}
        defaultValue={searchTerm}
        onChange={(event) => handleSearch(event.target.value)}
        allowClear
        size="medium"
      />
      <section>
        {numberOfDriversWithError > 0 && (
          <Typography.Text className="mr-3" style={{ color: '#BD4747' }}>
            <ExclamationCircleOutlined className="mr-2" />
            {numberOfDriversWithError} {ErrorString}
          </Typography.Text>
        )}

        {numberOfDriversWithoutError > 0 && (
          <Typography.Text style={{ color: '#BD8E14' }}>
            <WarningOutlined className="mr-2" />
            Unpublished changes for {numberOfDriversWithoutError} {DriverString}
          </Typography.Text>
        )}
      </section>
    </div>
  );
}
