import { Form, Input, Popover, Select, Tag } from 'antd';
import React, { useContext, useRef } from 'react';
import PhoneInput from 'react-phone-number-input/input';
import CustomPhoneInputComponent from '../../../components/shared/CustomPhoneInputComponent';
import { debounce } from '../../../utils/search';
import {
  EMAIL_PHONE_ERROR_MESSAGE,
  INVALID_NAME_MESSAGE,
  deviceOwnershipSelectOptions,
} from '../AppStatusConstants';
import { isAlphabetic } from '../DriverTableUtils';
import AddDriverContext from './AddDriverContext';
import { vaidationRules, validateTags } from './AddDriverUtils';

export default function EditableCell(props) {
  const {
    editable,
    children,
    dataIndex,
    hint,
    record,
    allTags,
    handleSave,
    VComponents,
    duplicateDrivers,
    ...restProps
  } = props;
  const form = useContext(AddDriverContext);
  let childNode = children;
  const inputRef = useRef(null);
  const fieldErrors = form.getFieldError([record?.key, dataIndex]);
  const CellComponent = VComponents ? VComponents?.body.cell : 'td';

  const fetchTouchedFields = () => {
    const isFieldTouched = form.isFieldTouched([record?.key, dataIndex]);
    const dirtyFields = record?.isTouched || [];
    const touchedFields =
      isFieldTouched && !dirtyFields.includes(dataIndex)
        ? [...dirtyFields, dataIndex]
        : [...dirtyFields];
    return touchedFields;
  };

  const save = async () => {
    let values = {};
    try {
      values = await form.validateFields([
        [record?.key, 'firstName'],
        [record?.key, 'lastName'],
        [record?.key, 'email'],
        [record?.key, 'phoneNumber'],
        [record?.key, 'deviceOwnershipType'],
        [record?.key, 'tags'],
      ]);
      form.setFieldValue({ ...record, ...values[record.key] });
    } catch (errInfo) {
      values = errInfo.values;
    }
    handleSave({
      ...record,
      ...values[record.key],
      isTouched: fetchTouchedFields(),
    });
  };

  const tagRender = (args) => {
    const { label, closable, onClose } = args;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
      >
        {label}
      </Tag>
    );
  };

  const handleDuplicates = () => {
    const errorValue = dataIndex === 'email' ? 'email' : 'phone number';
    const errorMsg = `Duplicate ${errorValue}`;
    const isDuplicate = record.duplicates?.includes(errorValue);
    const isTouched = record?.isTouched?.includes(dataIndex);
    const hasError = isDuplicate && !isTouched;

    return hasError ? Promise.reject(new Error(errorMsg)) : Promise.resolve();
  };

  const checkValue = async () => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      deviceOwnershipType,
      tags = [],
    } = form.getFieldsValue([record?.key])[record?.key] || {};
    const isFirstNameValid = isAlphabetic(firstName);
    const isLastNameValid = isAlphabetic(lastName);
    const isOwnershipTypeValid =
      deviceOwnershipType && deviceOwnershipType !== 'business_shared';

    // either email or phone number is required
    if (
      (dataIndex === 'email' || dataIndex === 'phoneNumber') &&
      !email &&
      !phoneNumber
    ) {
      return Promise.reject(new Error(EMAIL_PHONE_ERROR_MESSAGE));
    }

    if (dataIndex === 'tags' && tags.length > 0) {
      return validateTags(tags);
    }

    // if ownership type is either personal or business_owned,
    // then name should only contain alphabets
    if (
      isOwnershipTypeValid &&
      ((dataIndex === 'firstName' && !isFirstNameValid) ||
        (dataIndex === 'lastName' && !isLastNameValid))
    ) {
      return Promise.reject(new Error(INVALID_NAME_MESSAGE));
    }

    return handleDuplicates();
  };

  const renderInputComponent = () => {
    switch (dataIndex) {
      case 'phoneNumber':
        return (
          <PhoneInput
            key={dataIndex}
            ref={inputRef}
            defaultCountry="US"
            placeholder={hint}
            inputComponent={CustomPhoneInputComponent}
            title=""
          />
        );
      case 'deviceOwnershipType':
        return (
          <Select
            key={dataIndex}
            placeholder={hint}
            options={deviceOwnershipSelectOptions}
            onChange={save}
            title=""
          />
        );
      case 'tags':
        return (
          <Select
            key={dataIndex}
            mode="tags"
            placeholder={hint}
            onChange={save}
            showSearch
            options={allTags}
            tagRender={tagRender}
          />
        );
      default:
        return (
          <Input key={dataIndex} placeholder={hint} ref={inputRef} title="" />
        );
    }
  };

  if (editable) {
    const content = <div className="add_driver_popover">{fieldErrors[0]}</div>;
    childNode = (
      <div className="popover-container">
        <Popover
          content={fieldErrors.length > 0 ? content : null}
          placement="bottomLeft"
          autoAdjustOverflow
        >
          <Form.Item
            key={`${record.key}-${dataIndex}`}
            name={[record.key, dataIndex]}
            rules={[
              ...vaidationRules[dataIndex],
              {
                validator: checkValue,
              },
            ]}
            preserve={false}
            onChange={debounce(save)}
            shouldUpdate={(prevValues, curValues) =>
              prevValues[dataIndex] !== curValues[dataIndex]
            }
            help=""
          >
            {renderInputComponent()}
          </Form.Item>
        </Popover>
      </div>
    );
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <CellComponent {...restProps}>{childNode}</CellComponent>;
}
