import { ArrowDownOutlined } from '@ant-design/icons';
import React from 'react';

export default function EditDriversFooter({
  isDriversLoading,
  driversList,
  handlePagination,
}) {
  const { result = [], total_count } = driversList;

  if (!result || isDriversLoading) {
    return null;
  }

  return (
    <div className="status_table_footer">
      {total_count > result?.length && (
        <span className="loadmore" onClick={handlePagination}>
          Load More <ArrowDownOutlined />
        </span>
      )}
      <span className="showing">
        Showing {result?.length || 0}/{total_count || 0}
      </span>
    </div>
  );
}
