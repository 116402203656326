import PropTypes from 'prop-types';
import React from 'react';
import { formatDistance } from 'utils/common';
import { deviceOwnershipMap } from '../../AppStatus/AppStatusConstants';
import TagList from '../../TagsManagement/TagList';

export default function DriverInfoCard({ id, driverStats = {} }) {
  const {
    totalTrips = 'NA',
    milesDriven,
    deviceType,
    deviceVersion,
    firstDateOfTravel = 'NA',
    lastDateOfTravel = 'NA',
    fullName = 'NA',
    deviceOwnershipType,
    tags = [],
  } = driverStats;

  const type =
    deviceType ||
    (driverStats.device_info && driverStats.device_info[0]?.model);
  const version =
    deviceVersion ||
    (driverStats.device_info && driverStats.device_info[0]?.version);

  const renderDeviceInfo = () => {
    let infoText = 'NA';

    if (type && version) {
      infoText = `${type} (v${version})`;
    }

    if (deviceOwnershipType) {
      infoText += `, ${deviceOwnershipMap[deviceOwnershipType]}`;
    }

    return infoText;
  };

  function renderTagsList(tagsList) {
    return (
      <div className="d-flex align-items-center">
        <p style={{ marginRight: '16px' }} className="stats_key">
          Tags
        </p>
        <div style={{ marginLeft: 'auto', textAlign: 'right' }}>
          <TagList tags={tagsList} />
        </div>
      </div>
    );
  }

  const infoRows = [
    { label: 'ID', value: id },
    { label: 'Trips', value: totalTrips },
    {
      label: 'Distance',
      value: milesDriven ? formatDistance(milesDriven) : 'NA',
    },
    { label: 'First Date of Travel', value: firstDateOfTravel || 'NA' },
    { label: 'Last Date of Travel', value: lastDateOfTravel || 'NA' },
    {
      label: 'Device Info',
      value: renderDeviceInfo(),
    },
    { label: 'Alias', value: fullName || 'NA' },
  ];

  return (
    <div>
      {infoRows.map((row) => (
        <InfoRow key={row.label} label={row.label} value={row.value} />
      ))}
      {tags && tags.length > 0 && renderTagsList(tags)}
    </div>
  );
}

function InfoRow({ label, value }) {
  return (
    <div className="d-flex align-items-center py-1">
      <p className="stats_key">{label}</p>
      <div style={{ marginLeft: 'auto' }}>
        <p className="stats_value">{value}</p>
      </div>
    </div>
  );
}

InfoRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
};

DriverInfoCard.propTypes = {
  id: PropTypes.string,
  driverStats: PropTypes.object,
};
