function transformDriverList(drivers) {
  if (!drivers) return [];
  return drivers.map((driver) => {
    const {
      user_id: driverId,
      drive_hrs,
      distance_miles,
      info,
      focus_areas,
      fm_score,
      count_trips,
    } = driver;
    const ratings = focus_areas?.filter((area) => area.active);
    const driverName = info?.first_name
      ? `${info?.first_name} ${info?.last_name}`
      : driverId;
    return {
      id: driverId,
      name: driverName,
      email: info?.email || '-',
      trip_count: count_trips || 0,
      distance: distance_miles,
      duration: drive_hrs,
      score: fm_score,
      ratings,
      tags: info?.tags || [],
    };
  });
}
export default transformDriverList;
