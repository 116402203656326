import { InfoCircleOutlined } from '@ant-design/icons';
import { Spin, Tooltip } from 'antd';
import { formatNightDrivingDuration } from 'components/charts/utils';
import highwayImage from 'images/highway.svg';
import nightDriving from 'images/night-driving.svg';
import PropTypes from 'prop-types';
import React from 'react';
import { getUser } from 'utils/validateUserToken';

const formatMiles = function (n) {
  if (n === undefined || Number.isNaN(n)) return 'NA';
  return `${Number(n).toFixed(1)} miles`;
};
const formatPercent = function (n) {
  if (n === undefined || Number.isNaN(n)) return 'N/A';
  return `${Math.round(n)}%`;
};

export default function EnvironmentalRiskFactorCard({ stats, isLoading }) {
  if (!stats || stats === null || stats === undefined) {
    stats = {};
  }
  const user = getUser();
  const {
    highway_miles,
    night_drive_hrs_percent,
    highway_miles_percent,
    night_drive_hrs,
  } = stats;

  if (isLoading) {
    return (
      <div
        style={{ minHeight: '250px' }}
        className="d-flex align-items-center justify-content-center"
      >
        <Spin />
      </div>
    );
  }

  return (
    <>
      {/* NIGHT DRIVING STATS  */}
      <div className="d-flex align-items-center">
        <div className="px-3">
          <img src={nightDriving} alt="Night driving" />
        </div>
        <div className="px-4">
          <div className="py-2 d-flex align-items-center">
            <p className="drive_type">Night Driving</p>
            <div className="px-2" style={{ marginTop: '4px' }}>
              <Tooltip
                placement="bottom"
                title={
                  <span>
                    Night driving is defined as driving activity between 12:00
                    AM to 4:00 AM local time.
                    <br /> <br />
                    Night driving is an additional risk factor which affects
                    your Cost Per Mile.
                  </span>
                }
              >
                <InfoCircleOutlined />
              </Tooltip>
            </div>
          </div>
          <p className="drive_percent">
            {formatPercent(night_drive_hrs_percent)}
          </p>
          <div className="py-2">
            <p className="drive_type">
              {formatNightDrivingDuration(Math.round(night_drive_hrs * 3600))}
            </p>
          </div>
        </div>
      </div>
      {/* HIGHWAY DRIVING STATS  */}
      <div className="d-flex align-items-center">
        <div className="px-3">
          <img src={highwayImage} alt="Highway driving" />
        </div>
        <div className="px-4">
          <div className="py-2 d-flex align-items-center">
            <p className="drive_type">Highway Driving</p>
            <div className="px-2" style={{ marginTop: '4px' }}>
              <Tooltip
                placement="bottom"
                title={
                  <p>
                    Highway driving (only applicable for US locations) is
                    defined by evaluating the list of latitude and longitude of
                    the trip and comparing it with known highway polygons. If it
                    falls within highway polygon, then it is a highway mile.
                    <br /> <br />
                    Highway driving is an additional risk factor which affects
                    your Cost Per Mile.
                  </p>
                }
              >
                <InfoCircleOutlined />
              </Tooltip>
            </div>
          </div>
          <p className="drive_percent">
            {formatPercent(highway_miles_percent)}
          </p>
          <div className="py-2">
            {/* TODO:  NEED TO DISCUSS THIS  */}
            <p className="drive_type">{formatMiles(highway_miles)}</p>
          </div>
        </div>
      </div>
    </>
  );
}

EnvironmentalRiskFactorCard.propTypes = {
  stats: PropTypes.object,
  isLoading: PropTypes.bool,
};
