import { Card } from 'antd';
import moment from 'moment';
import numbro from 'numbro';
import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { fetchLocationsService } from 'services';
import {
  INTERVAL_TYPES,
  getDefaultDateRange,
  getLastPeriodDates,
  handleDateSelection,
} from 'utils/dateFilter';
import { callGetApi } from '../../api/axios';
import { SECONDARY_LIGHTER } from '../../assets/style/colors';
import '../../assets/style/dashboard/dashboard.css';
import useUserDetails from '../../hooks/useUserDetails';
import { toast, toastTypes } from '../../utils/toast';
import { formatLineChartData, formatScoreHistoryData } from './utils';

import SimpleLegend from '../../components/charts/SimpleLegend';
import DateWidget from '../../components/widgets/dateWidget';
import LocationWidget from '../../components/widgets/locationWidget';
import PeriodsFilter from '../../components/widgets/periodsFilter';
import MixpanelContext from '../../services/tracking';
import DailyDistanceChart from './components/DailyDistanceChart';
import DailyScoreChart from './components/DailyScoreChart';
import DriverScoreCard from './components/DriverScoreCard';
import PerformanceGroup from './components/PerformanceGroup';

import EnvironmentalRiskFactorCard from '../../components/EnvironmentalRiskFactorCard';
import PerformanceBreakdown from './components/PerformanceBreakdown/PerformanceBreakdown';
import { fetchScoreService } from './services';

import TagsFilter from '../../components/widgets/TagsFilter';
import { formatDistanceNumber } from '../../utils/common';
import ActiveDriversChart from './components/ActiveDriversChart';
import DownloadReportTrigger from './components/DownloadReportTrigger';
import DriversOverview from './components/DriversOverview';

export default function Overview() {
  const { user, isPeriodsFilterEnabled, periodFilterPersistKey } =
    useUserDetails();
  const score_types = {
    greater: 'greater',
    smaller: 'smaller',
  };

  const BoxShadow =
    '0 6px 10px 0 rgb(47 49 104 / 12%), 0 1px 6px 0 rgb(0 0 0 / 12%)';
  const distanceUnitLabel = 'Mile';
  const [overallStats, setOverallStats] = useState({});
  const [overallStatsLoading, setOverallStatsLoading] = useState(false);
  const [perfBreakdown, setPerfBreakdown] = useState();
  const [dailyScore, setDailyScore] = useState({});
  const [perfGroups, setPerfGroups] = useState({});
  const [locationList, setLocationList] = useState([
    { label: 'All locations', value: null },
  ]);

  const [selectedLocation, setSelectedLocation] = useState(
    queryString.parse(window.location.search)?.loc || null,
  );
  const [zendriveScore, setZendriveScore] = useState();
  const [performanceGrade, setPerformanceGrade] = useState();
  const [lastPeriodDataLoading, setLastPeriodDataLoading] = useState(false);
  const [scoreInLastPeriod, setScoreInLastPeriod] = useState({
    score: 0,
    type: score_types.greater,
    isValidDiff: false,
  });

  const [selectedPeriod, setSelectedPeriod] = useState([
    ...JSON.parse(sessionStorage.getItem(periodFilterPersistKey) || '["ALL"]'),
  ]);

  // TODO: check if tags need to be stored in local storage
  const [selectedTags, setSelectedTags] = useState([]);

  const mixpanel = useContext(MixpanelContext);
  const [loading, setLoading] = useState(false);
  const [loadingDrivers, setLoadingDrivers] = useState(false);
  const [coachingDrivers, setCoachingDrivers] = useState([]);
  const [excellentDrivers, setExcellentDrivers] = useState([]);
  const [dateWidgetData, setDateWidgetData] = useState(
    getDefaultDateRange(INTERVAL_TYPES.quarter),
  );
  const [startDateOfLastPeriod, setStartDateOfLastPeriod] = useState(
    getLastPeriodDates(dateWidgetData.startDate, dateWidgetData.intervalType)
      ?.startDate,
  );
  const { startDate, endDate, intervalType } = dateWidgetData;
  const isQuarterly = intervalType === INTERVAL_TYPES.quarter;

  const DataBreakdownString = isQuarterly ? 'Monthly' : 'Daily';

  const fetchScore = async () => {
    const queryParams = {
      apiKey: user.api_key,
      start_date: moment(startDate).format('yyyy-MM-DD'),
      interval: intervalType,
      ...(isPeriodsFilterEnabled && {
        trip_periods: selectedPeriod,
      }),
      ...(selectedTags.length > 0 && { tags: selectedTags }),
      ...(selectedLocation && { location: selectedLocation }),
    };
    const { data, isError, fault } = await fetchScoreService(queryParams);

    if (isError) {
      toast(toastTypes.ERROR, fault?.faultstring);
      return;
    }
    const {
      fm_score: fmScore,
      environmental_factors: environmentRiskFactors,
      fm_grade: grade,
      active_drivers,
      daily_distance,
      daily_score_history,
      monthly_active_drivers,
      monthly_distance,
      monthly_score_history,
    } = data;

    const scoreHistory = isQuarterly
      ? monthly_score_history
      : daily_score_history;
    const activeDrivers = isQuarterly ? monthly_active_drivers : active_drivers;
    const dailyDistance = isQuarterly ? monthly_distance : daily_distance;

    setPerformanceGrade(grade);
    setZendriveScore(fmScore);
    setOverallStats({
      active_drivers: activeDrivers,
      daily_distance: dailyDistance,
      environmentRiskFactors,
      totalDistanceInInterval: Number(
        dailyDistance?.reduce(
          (sum, { distance_miles }) => sum + distance_miles,
          0,
        ) || 0,
      ).toFixed(),
    });
    setDailyScore({ daily_score_history: scoreHistory });
    setOverallStatsLoading(false);
  };

  const fetchDrivers = async (perfGroup) => {
    const queryParams = {
      apiKey: user.api_key,
      perf_group: perfGroup,
      limit: 10,
      start_date: startDate,
      interval: intervalType,
      order_by: 'score',
      order_type: 'desc',
      source: 'dashboard',
      ...(isPeriodsFilterEnabled && {
        trip_periods: selectedPeriod,
      }),
      ...(selectedTags.length > 0 && { tags: selectedTags }),
      ...(selectedLocation && { location: selectedLocation }),
    };
    const api = `/v2/api/drivers/performance?${queryString.stringify(
      queryParams,
      {
        arrayFormat: 'comma',
      },
    )}`;
    setLoadingDrivers(true);
    try {
      const { data } = await callGetApi(api);
      const driversList = data.drivers.map((driver) => {
        const { user_id, fm_score, distance_miles, user_full_name, tags } =
          driver;
        return {
          id: user_id,
          distance: distance_miles,
          score: fm_score,
          name: user_full_name,
          tags,
        };
      });

      if (perfGroup === 'excellent') {
        setExcellentDrivers(driversList);
      } else {
        setCoachingDrivers(driversList);
      }
      setLoadingDrivers(false);
    } catch (err) {
      const { fault } = err?.response?.data || {};
      if (fault?.faultstring) {
        toast(toastTypes.ERROR, fault.faultstring);
      }
      setLoadingDrivers(false);
    }
  };

  // TODO: Migrate API call to service
  const fetchLocations = async () => {
    const params = {
      apiKey: user.api_key,
      start_date: startDate,
      // end_date: endDate,
      interval: intervalType,
    };

    const {
      data,
      isError = false,
      errorMessage = '',
    } = await fetchLocationsService({
      queryParams: params,
    });
    if (isError) {
      toast(toastTypes.ERROR, errorMessage);
      return;
    }
    if (data.length > 0) {
      setLocationList(data);
    }
  };

  // TODO: understand the usecase of this API from Abid
  async function getLastPeriodData() {
    setLastPeriodDataLoading(true);
    const queryParams = {
      apiKey: user.api_key,
      start_date: startDateOfLastPeriod,
      interval: intervalType,
      ...(isPeriodsFilterEnabled && {
        trip_periods: selectedPeriod,
      }),
      ...(selectedLocation && { location: selectedLocation }),
    };
    const { data, isError, fault } = await fetchScoreService(queryParams);
    setLastPeriodDataLoading(false);

    if (isError) {
      toast(toastTypes.ERROR, fault?.faultstring);
      return;
    }
    const { fm_score } = data;

    // TODO: can extract this functionality out of the component
    if (fm_score < 0 || Number.isNaN(fm_score) || Number.isNaN(zendriveScore)) {
      setScoreInLastPeriod({
        type: score_types.greater,
        score: zendriveScore - fm_score,
        isValidDiff: false,
      });
      return;
    }
    if (zendriveScore > fm_score) {
      setScoreInLastPeriod({
        type: score_types.greater,
        score: zendriveScore - fm_score,
        isValidDiff: true,
      });
      return;
    }
    setScoreInLastPeriod({
      type: score_types.smaller,
      score: fm_score - zendriveScore,
      isValidDiff: true,
    });
  }

  const handlePeriodChange = (period) => {
    setSelectedPeriod(period);
  };

  const handleTagChange = (tags) => {
    setSelectedTags(tags);
  };

  // get last period data API
  useEffect(() => {
    if (startDateOfLastPeriod && typeof zendriveScore !== 'undefined') {
      getLastPeriodData();
    }
  }, [startDateOfLastPeriod, zendriveScore]);

  useEffect(() => {
    mixpanel.track('View Page', {
      pageName: 'Overview Page',
    });
  }, [mixpanel]);

  // TODO: API to service
  const getDashboardStats = () => {
    setLoading(true);
    const queryParams = {
      apiKey: user.api_key,
      start_date: startDate,
      interval: intervalType,
      ...(isPeriodsFilterEnabled && {
        trip_periods: selectedPeriod,
      }),
      ...(selectedTags.length > 0 && { tags: selectedTags }),
      ...(selectedLocation && { location: selectedLocation }),
    };
    const api = `/v2/api/dashboard_stats?${queryString.stringify(queryParams, {
      arrayFormat: 'comma',
    })}`;
    callGetApi(api)
      .then((resp) => {
        const { data } = resp;
        setPerfGroups(data?.driver_count);
        setPerfBreakdown(
          isQuarterly ? data?.monthly_breakdown : data?.weekly_breakdown,
        );
        setLoading(false);
      })
      .catch((err) => {
        const { fault } = err?.response?.data || {};
        if (fault) toast(toastTypes.ERROR, fault?.faultstring);
        setLoading(false);
      });
  };

  React.useEffect(async () => {
    // get the updated start date and end date once the dates are updated
    const { startDate: sd } = getLastPeriodDates(startDate, intervalType);
    // const weeklyStartDates = getWeeklyStartDates(sd, intervalType);
    fetchLocations();
    // update the states
    setStartDateOfLastPeriod(moment(sd).format('yyyy-MM-DD'));

    setOverallStatsLoading(true);
    getDashboardStats();
    fetchScore();
    fetchDrivers('excellent');
    fetchDrivers('coaching_required');
    // getPerfBreakdowns(startDate, endDate);
  }, [startDate, endDate, selectedLocation, selectedPeriod, selectedTags]);

  // TODO: can extract this functionality out of the component
  // const distanceConverter = (v) => convertDistance(v, user?.distance_unit, true);
  // TODO: can extract this functionality out of the component

  const handleStartDateSelection = (dt, interval_type) => {
    const { start, end } = handleDateSelection(dt, interval_type);
    const state = {
      startDate: start,
      endDate: end,
      intervalType: interval_type,
    };
    setDateWidgetData(state);

    localStorage.setItem('FM_DATE_FILTER', JSON.stringify(state));
    mixpanel.track('Filter a report by Datetime', {
      ...state,
    });
    mixpanel.people.increment('# of Filters by Datetime');
  };

  const handleLocationSelection = (loc) => {
    setSelectedLocation(loc || null);
  };

  function onDownloadTrigger() {
    mixpanel.track('Request Fleet Summary Report', {
      startDate,
      endDate,
      intervalType,
      selectedLocation,
    });
    mixpanel.people.increment('# of Fleet Summary Reports Requested');
  }

  return (
    <div className="playground">
      <div className="overview_wrapper">
        {/* LOCATION AND REQUEST DRIVER REPORT WRAPPER  */}
        <div className="location_and_request_demo_wrapper p-2">
          <div className="filters_wrapper">
            <DateWidget
              onSelectDate={handleStartDateSelection}
              startDate={startDate}
              endDate={endDate}
              intervalType={intervalType}
              enableQuarterFilter
            />
            <LocationWidget
              locations={locationList}
              onSetLocation={handleLocationSelection}
              selectedLocation={selectedLocation}
            />
            <PeriodsFilter
              selectedPeriod={selectedPeriod}
              handlePeriodChange={handlePeriodChange}
            />
            <TagsFilter handleTagChange={handleTagChange} />
          </div>
          <DownloadReportTrigger
            startDate={startDate}
            endDate={endDate}
            interval={intervalType}
            onDownloadTrigger={onDownloadTrigger}
            selectedPeriod={selectedPeriod}
            selectedTags={selectedTags}
          />
        </div>
        {/* SCORE HISTORY AND PERFORMANCE */}
        <div className="py-2">
          <div className="card_wrapper overview_perf_wrapper">
            {/* PERFORMANCE BREAK DOWN CARD  */}
            <PerformanceBreakdown
              data={perfBreakdown}
              isLoading={loading}
              intervalType={intervalType}
            />
            <PerformanceGroup perfGroups={perfGroups} isLoading={loading} />
            <DriverScoreCard
              grade={performanceGrade}
              zendriveScore={zendriveScore}
              scoreInLastPeriod={scoreInLastPeriod}
              intervalType={intervalType}
              lastPeriodDataLoading={loading || lastPeriodDataLoading}
            />
          </div>
        </div>
        {/* DRIVERS LIST  */}
        <div className="py-2">
          <div className="card_wrapper risk_factor_and_driver_score_wrapper">
            {/* EXCELLENT DRIVER LIST CARD  */}
            <DriversOverview
              drivers={excellentDrivers}
              loadingDrivers={loadingDrivers}
              startDate={startDate}
              endDate={endDate}
              intervalType={intervalType}
              perfGroup="excellent"
            />
            {/* COACHING REQUIRED DRIVER LIST CARD  */}
            <DriversOverview
              drivers={coachingDrivers}
              loadingDrivers={loadingDrivers}
              startDate={startDate}
              endDate={endDate}
              intervalType={intervalType}
              perfGroup="coaching"
            />
          </div>
        </div>
        {/* DAILY ACTIVITY AND INSTALLATION  */}
        <div className="py-2">
          <div className="card_wrapper risk_factor_and_driver_score_wrapper">
            {/* DAILY ACTIVE DRIVER CARD  */}
            {/** TODO: Extract this as component */}
            <div>
              <Card
                title={
                  <div className="d-flex align-items-center">
                    <p className="driver_stats_card_header_text">
                      {DataBreakdownString} Active Drivers
                    </p>
                    <div
                      style={{
                        marginLeft: '10px',
                      }}
                    >
                      <SimpleLegend
                        color={SECONDARY_LIGHTER}
                        text="ACTIVE DRIVER COUNT"
                      />
                    </div>
                  </div>
                }
                style={{
                  margin: '10px',
                  boxShadow: BoxShadow,
                }}
              >
                <ActiveDriversChart
                  metricData={formatLineChartData(overallStats?.active_drivers)}
                  isLoading={overallStatsLoading}
                  xAxisLabelFormat={isQuarterly ? 'MMM' : 'MMM D'}
                  tooltipLabelFormat={isQuarterly ? 'MMM YYYY' : 'MMM D, YYYY'}
                />
              </Card>
            </div>

            {/* DISTANCE TRACKED CARD  */}
            {/** TODO: Extract this as component */}
            <div>
              <Card
                title={
                  <div className="d-flex align-items-center">
                    <p className="driver_stats_card_header_text">
                      {DataBreakdownString} {distanceUnitLabel}s Tracked
                    </p>
                    <div
                      style={{
                        marginLeft: 'auto',
                      }}
                    >
                      {overallStats?.totalDistanceInInterval &&
                        !overallStatsLoading &&
                        `${numbro(overallStats?.totalDistanceInInterval).format(
                          {
                            thousandSeparated: true,
                          },
                        )} miles`}
                    </div>
                  </div>
                }
                style={{
                  margin: '10px',
                  boxShadow: BoxShadow,
                }}
              >
                <DailyDistanceChart
                  metricData={formatLineChartData(
                    overallStats?.daily_distance,
                    'date',
                    'distance_miles',
                    formatDistanceNumber,
                  )}
                  isLoading={overallStatsLoading}
                  xAxisLabelFormat={isQuarterly ? 'MMM' : 'MMM D'}
                  tooltipLabelFormat={isQuarterly ? 'MMM YYYY' : 'MMM D, YYYY'}
                />
              </Card>
            </div>
          </div>
        </div>
        {/* MILES TRACKED AND PERFORMANCE GROUP  */}
        <div className="py-2">
          <div className="card_wrapper risk_factor_and_driver_score_wrapper">
            {/* SCORE HISTORY CARD  */}
            <Card
              title={
                <div className="d-flex align-items-center">
                  <p className="driver_stats_card_header_text">
                    {DataBreakdownString} Score History
                  </p>
                </div>
              }
              style={{
                margin: '10px',
                boxShadow: BoxShadow,
              }}
            >
              <div className="d-flex align-items-center justify-content-center">
                <DailyScoreChart
                  metricData={formatScoreHistoryData(
                    dailyScore?.daily_score_history,
                  )}
                  isLoading={overallStatsLoading}
                  xAxisLabelFormat={isQuarterly ? 'MMM' : 'MMM D'}
                  tooltipLabelFormat={isQuarterly ? 'MMM YYYY' : 'MMM D, YYYY'}
                />
              </div>
            </Card>

            {/* ENVIRONMENTAL RISK FACTOR CARD  */}
            <Card
              title={
                <p className="driver_stats_card_header_text">
                  Environmental Risk Factors
                </p>
              }
              style={{
                margin: '10px',
                paddingBottom: '6px',
                boxShadow: BoxShadow,
              }}
            >
              <EnvironmentalRiskFactorCard
                stats={overallStats.environmentRiskFactors}
                isLoading={overallStatsLoading}
              />
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
