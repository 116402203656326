import { Button, Typography } from 'antd';
import React, { useState } from 'react';
import ConfirmationModal from '../../components/shared/confirmationModal';
import { toast, toastTypes } from '../../utils/toast';
import { deleteTag } from './tagService';

const { Text } = Typography;

function TagDeletionModal(props) {
  const { visible, hideDeleteModal, tagToDelete, getTags } = props;
  const [loading, setLoading] = useState(false);
  const tagName = tagToDelete?.tag_name || '';
  const driverCount = tagToDelete?.driver_count || 0;
  const driverWord = driverCount > 1 ? 'drivers' : 'driver';

  const handleClose = () => {
    setLoading(false);
    hideDeleteModal();
  };

  const handleTagDeletionError = () => {
    toast(toastTypes.ERROR, `Failed to delete tag "${tagName}"`);
    setLoading(false);
    hideDeleteModal();
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await deleteTag(tagToDelete.id);
      if (response.status === 204) {
        toast(toastTypes.SUCCESS, `Tag "${tagName}" deleted successfully`);
        setLoading(false);
        hideDeleteModal();
        getTags();
      } else {
        handleTagDeletionError();
      }
    } catch (error) {
      handleTagDeletionError();
    }
  };

  return (
    <ConfirmationModal
      visible={visible}
      title="Delete Tag"
      confirmLoading={loading}
      cancelButtonProps={{ disabled: loading }}
      onCancel={handleClose}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Cancel
        </Button>,
        <Button key="submit" type="danger" onClick={handleDelete}>
          Delete
        </Button>,
      ]}
    >
      <Text>
        Are you sure you want to delete tag: <strong>{tagName}</strong>?
      </Text>
      <div />
      {driverCount > 0 && (
        <Text>
          {driverCount} {driverWord} will be untagged{' '}
        </Text>
      )}
    </ConfirmationModal>
  );
}

export default TagDeletionModal;
