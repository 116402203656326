import React from 'react';
import AppIcon from '../../assets/style/icons/app.svg';
import DisabledIcon from '../../assets/style/icons/disabled.svg';
import SdkIcon from '../../assets/style/icons/sdk.svg';

export default function fleetStatus(fleet) {
  if (!fleet) return null;
  const { is_active, fleet_name, is_fairmatic_app_enabled } = fleet;
  if (!is_active) {
    return (
      <span style={{ color: '#B95959' }}>
        <DisabledIcon
          style={{ fontSize: '22px', margin: '2px 5px -1px 0px' }}
        />
        {fleet_name}
      </span>
    );
  }

  return (
    <span>
      {is_fairmatic_app_enabled ? (
        <AppIcon style={{ fontSize: '22px', margin: '2px 5px -1px 0px' }} />
      ) : (
        <SdkIcon style={{ fontSize: '22px', margin: '2px 5px -1px 0px' }} />
      )}
      {fleet_name}
    </span>
  );
}
