import React from 'react';
import DriverStatusCards from './DriverStatusCards';

export default function AppStatusHeader() {
  return (
    <div className="page_header">
      <DriverStatusCards />
    </div>
  );
}
