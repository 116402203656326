import queryString from 'query-string';
import { callGetApi } from '../../api/axios';

const getIncidentsCount = (onSuccess, onFailure) => {
  callGetApi(
    `/v1/api/get-incidents?${queryString.stringify({
      status: 'fleetPending',
    })}`,
  )
    .then(onSuccess)
    .catch(onFailure);
};

export default getIncidentsCount;
