import React from 'react';

const EmptyHeartbeat = (
  <div
    style={{ height: '200px' }}
    className="d-flex align-items-center justify-content-center"
  >
    <h4>THE DRIVER HAS NO HEARTBEAT DATA IN THIS PERIOD</h4>
  </div>
);

export default EmptyHeartbeat;
