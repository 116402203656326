import { Spin } from 'antd';
import { navigate } from 'gatsby-link';
import PropTypes from 'prop-types';
import React from 'react';
import { AppContextProvider } from '../../contexts/AppContext';
import { isLoggedIn } from '../../utils/validateUserToken';
import Layout from './Layout';

function LayoutWrapper({ type, children }) {
  if (!isLoggedIn()) {
    navigate('/login');
    return (
      <div
        style={{ height: '100vh' }}
        className="d-flex align-items-center justify-content-center"
      >
        <Spin />
      </div>
    );
  }

  return (
    <AppContextProvider>
      <Layout type={type}>{children}</Layout>
    </AppContextProvider>
  );
}

LayoutWrapper.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
};

export default LayoutWrapper;
