import {
  callDeleteApi,
  callGetApi,
  callPatchApi,
  callPostApi,
} from '../../api/axios';

const fetchTags = async (status) => callGetApi(`/v1/api/tags?status=${status}`);

const createTag = async (tagName, driverIds) =>
  callPostApi('/v1/api/tags', {
    tag_name: tagName,
    driver_ids: driverIds,
  });

const editTag = async (tagId, tagName, driverIds) =>
  callPatchApi(`/v1/api/tags/${tagId}`, {
    tag_name: tagName,
    driver_ids: driverIds,
  });

const deleteTag = async (tagId) => callDeleteApi(`/v1/api/tags/${tagId}`);

const associatedDrivers = async (tagId) =>
  callGetApi(`/v1/api/tags/${tagId}/drivers`);

export { associatedDrivers, createTag, deleteTag, editTag, fetchTags };
