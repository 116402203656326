import { DownOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Dropdown,
  Input,
  Menu,
  Space,
  Typography,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { callPostApi } from '../../../../api/axios';
import { MAX_TAGS } from '../../../../constants';
import MixpanelContext from '../../../../services/tracking';
import { toast, toastTypes } from '../../../../utils/toast';
import { classifyTags, remapDriverTags } from '../DriversManagementUtils';
import TagsEmptyMenu from './TagsEmptyMenu';

export default function EditDriverTags({
  tags,
  selectedDriversTags,
  fetchDriversList,
  setSelectedRowKeys,
  handleModalToggle,
}) {
  const { commonTags, nonCommonTags } = classifyTags(selectedDriversTags);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedTags, setSelectedTags] = useState(commonTags);
  const [indeterminateTags, setIndeterminateTags] = useState(nonCommonTags);
  const mixpanel = useContext(MixpanelContext);
  const noTags = tags.length === 0;

  useEffect(() => {
    setSelectedTags(commonTags);
    setIndeterminateTags(nonCommonTags);
  }, [selectedDriversTags]);

  const handleMenuClick = (key) => {
    const currentIndexSelected = selectedTags.indexOf(key);
    const currentIndexIndeterminate = indeterminateTags.indexOf(key);
    const newSelectedTags = [...selectedTags];
    const newIndeterminateTags = [...indeterminateTags];

    if (currentIndexSelected !== -1) {
      // If the tag is already selected, unselect it
      newSelectedTags.splice(currentIndexSelected, 1);
    } else if (currentIndexIndeterminate !== -1) {
      // If the tag is indeterminate, make it fully selected and remove indeterminate state
      newSelectedTags.push(key);
      newIndeterminateTags.splice(currentIndexIndeterminate, 1);
    } else {
      // If the tag is not selected and not indeterminate, make it selected
      newSelectedTags.push(key);
    }

    setSelectedTags(newSelectedTags);
    setIndeterminateTags(newIndeterminateTags);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleApply = async () => {
    const drivers = remapDriverTags(
      selectedDriversTags,
      selectedTags,
      indeterminateTags,
    );
    try {
      await callPostApi('v1/api/tags/mappings', {
        data: drivers,
      });

      fetchDriversList();
      toast(
        toastTypes.SUCCESS,
        'Tags Action completed successfully for selected drivers',
      );
      setSelectedRowKeys([]);
    } catch (error) {
      toast(toastTypes.ERROR, 'Action failed due to tags error');
    }

    mixpanel.track('Bulk Assigned Tags', {
      drivers,
    });
  };

  const handleReset = () => {
    setSelectedTags(commonTags);
    setIndeterminateTags(nonCommonTags);
  };

  const menu = (
    <Menu key="edit-tags-menu">
      <Menu.Item key="tags-search">
        <Input placeholder="Search Tags" onChange={handleSearch} />
      </Menu.Item>
      {selectedTags.length >= MAX_TAGS && (
        <Menu.Item key="tags-error">
          <Typography.Text type="warning">
            Maximum tags capacity reached
          </Typography.Text>
        </Menu.Item>
      )}
      {tags
        .filter((item) => item.tag_name.includes(search))
        .map(({ id, tag_name }) => (
          <Menu.Item key={id}>
            <Checkbox
              checked={selectedTags.includes(id)}
              indeterminate={indeterminateTags.includes(id)}
              disabled={
                !selectedTags.includes(id) && selectedTags.length >= MAX_TAGS
              }
              onChange={() => handleMenuClick(id)}
            >
              {tag_name}
            </Checkbox>
          </Menu.Item>
        ))}
      <Menu.Divider />
      <Space
        style={{
          padding: '8px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button type="text" onClick={handleReset}>
          Reset
        </Button>
        <Button
          type="primary"
          onClick={() => {
            handleApply();
            setOpen(false);
          }}
        >
          Apply
        </Button>
      </Space>
    </Menu>
  );
  return (
    <Dropdown
      overlay={
        noTags ? (
          <TagsEmptyMenu
            handleModalToggle={handleModalToggle}
            setOpen={setOpen}
          />
        ) : (
          menu
        )
      }
      trigger={['click']}
      open={open}
      destroyPopupOnHide
      onOpenChange={setOpen}
    >
      <Button type="text" style={{ color: '#569D77' }}>
        <Space>
          Edit Tags
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
}
