import React from 'react';

const DeviceStatusTooltip = (
  <div className="tooltiptitles">
    The device status widget offers a visual summary of a devices readiness to
    track trips. The SDK needs to be initialized, users need to grant required
    permissions and location service should not be disabled by the user. A
    device is -
    <ul>
      <li>
        <span className="tooltiptitleLabel">Online:</span>
        Each required condition is met for the entire day
      </li>
      <li>
        <span className="tooltiptitleLabel">Offline:</span>
        At least a single condition is unmet for the entire day
      </li>
      <li>
        <span className="tooltiptitleLabel">Degraded:</span>
        Online sometimes and offline at other times of the day
      </li>
      <li>
        <span className="tooltiptitleLabel">Processing:</span>
        Device status data is yet to be uploaded
      </li>
      <li>
        <span className="tooltiptitleLabel">Unavailable:</span>
        App is not installed or device is powered off
      </li>
    </ul>
  </div>
);

export default DeviceStatusTooltip;
