import { Spin } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { SECONDARY, SECONDARY_LIGHTER } from '../../../assets/style/colors';
import LineChart from '../../../components/charts/LineChart';
import { getScoreAdjective } from '../../../components/charts/utils';
import { getLineChartAxisData } from '../utils';

export default function DailyScoreChart({
  metricData,
  isLoading,
  xAxisLabelFormat,
  tooltipLabelFormat,
}) {
  const { xAxis, yAxis } = getLineChartAxisData(metricData);

  if (isLoading) {
    return (
      <div
        style={{ minHeight: '250px' }}
        className="d-flex align-items-center justify-content-center"
      >
        <Spin />
      </div>
    );
  }

  if (yAxis?.length > 0) {
    return (
      <LineChart
        id="score-history-chart"
        tickInterval={10}
        tickLength={0}
        yAxisLabelVisibilityThreshold={70}
        yAxisMin={30}
        yAxisMax={100}
        xAxisAsDate
        xAxis={xAxis}
        yAxis={yAxis}
        chartColor={SECONDARY_LIGHTER}
        markerColor={SECONDARY}
        dynamicTooltipLabel={getScoreAdjective}
        xAxisLabelFormat={xAxisLabelFormat}
        tooltipLabelFormat={tooltipLabelFormat}
      />
    );
  }

  return (
    <div
      style={{
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        height: '217px', // TODO fix this
        alignItems: 'center',
      }}
    >
      <h4>THERE IS NO ACTIVITY IN THIS PERIOD</h4>
    </div>
  );
}

DailyScoreChart.propTypes = {
  metricData: PropTypes.object,
  isLoading: PropTypes.bool,
};
