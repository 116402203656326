import React, { useState, useContext, useEffect } from 'react';
import '../../../assets/style/settings/settings.css';
import '../../../assets/style/authStyles.css';
import BasicInfoCard from './BasicInfoCard';
import PasswordCard from './PasswordCard';
import MixpanelContext from '../../../services/tracking';

export default function Accounts() {
  const mixpanel = useContext(MixpanelContext);
  const [toggleEditBasicInfo, setToggleEditBasicInfo] = useState(false);
  const [toggleEditPassword, setToggleEditPassword] = useState(false);

  useEffect(() => {
    mixpanel.track('View Page', {
      pageName: 'Accounts Page',
    });
  }, [mixpanel]);

  return (
    <div>
      <div className="playground">
        <h4 className="page_header_text">Profile</h4>
        {/* BASIC INFO CARD  */}
        <BasicInfoCard
          setToggleEditBasicInfo={(value) => setToggleEditBasicInfo(value)}
          toggleEditBasicInfo={toggleEditBasicInfo}
        />
        {/* PASSWORD CARD  */}
        <PasswordCard
          toggleEditPassword={toggleEditPassword}
          setToggleEditPassword={(value) => setToggleEditPassword(value)}
        />
      </div>
    </div>
  );
}
