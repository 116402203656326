import { Button, Form, Input, Popover } from 'antd';
import { navigate } from 'gatsby-link';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { callPostApi } from '../../../api/axios';
import PasswordComplexityChecklist from '../../../components/ManagePassword/PasswordComplexityChecklist';
import { UPDATE_PASSWORD_RULES } from '../../../constants/formValidations';
import { toast, toastTypes } from '../../../utils/toast';

const RULES = {
  oldPassword: [
    {
      required: true,
      message: 'Please input your old password!',
    },
  ],
  ...UPDATE_PASSWORD_RULES,
};

const passwordPopover = (value) => (
  <PasswordComplexityChecklist password={value} />
);

export default function PasswordCard(props) {
  const { toggleEditPassword, setToggleEditPassword } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const passwordValue = Form.useWatch('newPassword', form);

  const cancel = () => {
    form.resetFields();
    setToggleEditPassword(false);
  };

  // function to update the password
  async function handleUpdatePassword({ oldPassword: current_password }) {
    const token = Cookies.get('Authorization');
    if (!token) {
      return navigate('/login');
    }
    setLoading(true);
    try {
      const { data } = await callPostApi(
        `/v2/api/update-password?token=${token}`,
        {
          current_password,
          password: passwordValue?.trim(),
        },
      );
      const { message } = data;
      toast(toastTypes.SUCCESS, message);
      cancel();

      Cookies.remove('Authorization');
      Cookies.remove('user');
      navigate('/login');
    } catch (err) {
      const { message } = err?.response?.data || {};
      if (message) toast(toastTypes.ERROR, message);
    } finally {
      setLoading(false);
    }
    return null;
  }
  return (
    <div className="account_info_wrapper_card">
      <div className="card_header_spacing">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="card_header_text">Password</h4>
          {!toggleEditPassword && (
            <div className="button_size">
              <Button
                type="secondary"
                onClick={() => setToggleEditPassword(true)}
                style={{ width: '100%' }}
              >
                Edit
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="card_body_spacing">
        {toggleEditPassword ? (
          <Form
            name="edit_password_form"
            initialValues={{
              remember: true,
            }}
            form={form}
            onFinish={handleUpdatePassword}
            autoComplete="off"
          >
            {/* Old Password  */}
            <div className="align-items-start name_align">
              <div className="profile_card_key">Old Password</div>
              <Form.Item name="oldPassword" rules={RULES.oldPassword}>
                <Input.Password placeholder="Old Password" type="password" />
              </Form.Item>
            </div>

            <div className="align-items-start name_align">
              <div className="profile_card_key">New Password</div>
              {/* New Password */}
              <Popover
                placement="right"
                trigger="focus"
                content={() => passwordPopover(passwordValue)}
              >
                <Form.Item
                  name="newPassword"
                  rules={RULES.newPassword}
                  validateFirst
                >
                  <Input.Password placeholder="New Password" type="password" />
                </Form.Item>
              </Popover>
            </div>
            {/* Confirm Password  */}
            <div className="align-items-start name_align">
              <div className="profile_card_key">Confirm Password</div>
              <Form.Item
                name="confirmPassword"
                dependencies={['newPassword']}
                rules={RULES.confirmPassword}
              >
                <Input.Password
                  placeholder="Confirm Password"
                  type="password"
                />
              </Form.Item>
            </div>
            {toggleEditPassword && (
              <div className="d-flex align-items-center">
                <div style={{ padding: '5px' }} className="button_size">
                  <Button
                    type="secondary"
                    onClick={cancel}
                    style={{ width: '100%' }}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                </div>
                <div style={{ padding: '5px' }} className="button_size">
                  <Button
                    type="primary"
                    style={{ width: '100%' }}
                    htmlType="submit"
                    loading={loading}
                    disabled={loading}
                  >
                    Save
                  </Button>
                </div>
              </div>
            )}
          </Form>
        ) : (
          <div className="name_align align-items-center">
            <div className="profile_card_key">Password</div>
            <div className="profile_card_value">***********</div>
          </div>
        )}
      </div>
    </div>
  );
}

PasswordCard.propTypes = {
  toggleEditPassword: PropTypes.bool.isRequired,
  setToggleEditPassword: PropTypes.func.isRequired,
};
