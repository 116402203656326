Object.defineProperty(exports, '__esModule', {
  value: true,
});
exports.bpfrpt_proptype_LegendData = exports.default = void 0;

const _react = _interopRequireDefault(require('react'));

const _styledComponents = _interopRequireDefault(require('styled-components'));

const _colors = require('../../assets/style/colors');

const _fonts = require('../../assets/style/fonts');

const _propTypes = _interopRequireDefault(require('prop-types'));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

const bpfrpt_proptype_LegendData = {
  text: _propTypes.default.string.isRequired,
  color: _propTypes.default.string.isRequired,
};
exports.bpfrpt_proptype_LegendData = bpfrpt_proptype_LegendData;

const LegendWrapper = _styledComponents.default.div.withConfig({
  displayName: 'Legend__LegendWrapper',
  componentId: 'sc-1nxew9-0',
})(['display:flex;flex-direction:row;align-items:center;margin:8px 8px;']);

const Indicator = _styledComponents.default.span.withConfig({
  displayName: 'Legend__Indicator',
  componentId: 'sc-1nxew9-1',
})(
  [
    'width:14px;display:inline-block;height:14px;background:',
    ';border-radius:2px;justify-content:center;align-items:center;',
  ],
  (props) => props.color,
);

const Text = _styledComponents.default.span.withConfig({
  displayName: 'Legend__Text',
  componentId: 'sc-1nxew9-2',
})(
  [
    'font-size:',
    'px;color:',
    ';margin-left:8px;justify-content:center;align-items:center;line-height:14px;',
  ],
  _fonts.smaller,
  _colors.BLACK,
);

const Legend = function Legend(props) {
  return _react.default.createElement(
    LegendWrapper,
    null,
    _react.default.createElement(Indicator, {
      color: props.color,
    }),
    _react.default.createElement(Text, null, props.text),
  );
};

Legend.propTypes = {
  color: _propTypes.default.string.isRequired,
  text: _propTypes.default.string.isRequired,
};
const _default = Legend;
const _default2 = _default;
exports.default = _default2;

Legend.__docgenInfo = {
  description: '',
  methods: [],
  displayName: 'Legend',
  props: {
    color: {
      required: true,
      flowType: {
        name: 'string',
      },
      description: '',
    },
    text: {
      required: true,
      flowType: {
        name: 'string',
      },
      description: '',
    },
  },
};
// # sourceMappingURL=index.js.map
