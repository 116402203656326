const DATE_FILTER_LIMITS = Object.freeze({
  DRIVER: Object.freeze({
    week: 12,
    day: 30,
    month: 6,
  }),
  COLLISION: Object.freeze({
    week: 12,
    day: 30,
    month: 6,
  }),
  DEFAULT: Object.freeze({
    week: 12,
    day: 30,
    month: 6,
  }),
});

const GUTTER_BREAK_POINTS = [
  {
    xs: 8,
    sm: 16,
    md: 24,
    lg: 12,
  },
  12,
];

const QUARTERS = [
  { id: 1, label: 'Quarter 1', months: ['Jan', 'Feb', 'Mar'] },
  { id: 2, label: 'Quarter 2', months: ['Apr', 'May', 'Jun'] },
  { id: 3, label: 'Quarter 3', months: ['Jul', 'Aug', 'Sep'] },
  { id: 4, label: 'Quarter 4', months: ['Oct', 'Nov', 'Dec'] },
];

const NOTE_MESSAGES = {
  quarter: 'You can select a quarter from last 2 quarters',
  month: 'You can select a month from last 6 months',
  week: 'You can select a week from last 12 weeks',
  day: 'You can select a day from last 30 days',
};

const generateQuartersData = (currentQuarterId, currentYear) => {
  // Calculate indices for two quarters ago, previous quarter, and next quarter
  const twoQuartersAgoIndex = (currentQuarterId - 3 + 4) % 4;
  const previousQuarterIndex = (currentQuarterId - 2 + 4) % 4;
  const nextQuarterIndex = currentQuarterId % 4;

  // Calculate years for each quarter
  const twoQuartersAgoYear =
    twoQuartersAgoIndex > currentQuarterId - 1 ? currentYear - 1 : currentYear;
  const previousQuarterYear =
    previousQuarterIndex > currentQuarterId - 1 ? currentYear - 1 : currentYear;
  const nextQuarterYear =
    nextQuarterIndex < currentQuarterId - 1 ? currentYear + 1 : currentYear;

  return [
    { ...QUARTERS[twoQuartersAgoIndex], year: twoQuartersAgoYear },
    { ...QUARTERS[previousQuarterIndex], year: previousQuarterYear },
    { ...QUARTERS[currentQuarterId - 1], year: currentYear },
    { ...QUARTERS[nextQuarterIndex], year: nextQuarterYear },
  ];
};

export {
  DATE_FILTER_LIMITS,
  GUTTER_BREAK_POINTS,
  NOTE_MESSAGES,
  QUARTERS,
  generateQuartersData,
};
