/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/function-component-definition */
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Card, Spin } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import DriveScoreGraph from '../../../components/shared/driveScoreGraph';
import DriverScoreCardTooltip from './DriverScoreCardTooltip';

function DriverScoreCard({
  grade,
  zendriveScore,
  scoreInLastPeriod,
  intervalType,
  lastPeriodDataLoading,
}) {
  const { isValidDiff, score, type } = scoreInLastPeriod;
  const score_types = {
    greater: 'greater',
    smaller: 'smaller',
  };

  const RenderDriverScoreCard = () => {
    if (zendriveScore === -1 || typeof zendriveScore !== 'number') {
      return (
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            height: '220px',
            alignItems: 'center',
          }}
        >
          <h4>THERE IS NO ACTIVITY IN THIS PERIOD</h4>
        </div>
      );
    }

    return (
      <div className="driver_stats_card_body d-flex align-items-center justify-content-center">
        <div
          className="score_graph_wrapper"
          style={{
            padding: '14px 0',
          }}
        >
          <DriveScoreGraph
            grade={grade}
            zendriveScore={zendriveScore}
            fair_score_indicator_classname="overview_fair_score_indicator"
            fair_score_indicator_value_classname="overview_fair_score_indicator_value"
            excellent_score_indicator_classname="overview_excellent_score_indicator"
            excellent_score_indicator_value_classname="overview_excellent_score_indicator_value"
            good_score_indicator_classname="overview_good_score_indicator"
            good_score_indicator_value_classname="overview_good_score_indicator_value"
          />
          <hr
            style={{
              margin: '0 80px',
              border: '1px solid #ddd',
            }}
          />
          {isValidDiff && score > 0 && (
            <div className="d-flex align-items-center justify-content-center py-2">
              <div className="px-2">
                {type === score_types.greater ? (
                  <CaretUpOutlined />
                ) : (
                  <CaretDownOutlined />
                )}
              </div>
              <div className="px-2">
                <p className="score_diff_text">
                  {scoreInLastPeriod.score} points since last {intervalType}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <Card
      title={
        <p className="driver_stats_card_header_text">
          Average Driver Score
          <DriverScoreCardTooltip />
        </p>
      }
      style={{
        margin: '10px',
        flexBasis: '25%',
        boxShadow:
          '0 6px 10px 0 rgb(47 49 104 / 12%), 0 1px 6px 0 rgb(0 0 0 / 12%)',
      }}
      bodyStyle={{ minHeight: '306px' }}
    >
      {lastPeriodDataLoading ? (
        <div
          style={{ height: '250px' }}
          className="d-flex align-items-center justify-content-center"
        >
          <Spin />
        </div>
      ) : (
        <RenderDriverScoreCard />
      )}
    </Card>
  );
}

DriverScoreCard.propTypes = {
  zendriveScore: PropTypes.number,
  lastPeriodDataLoading: PropTypes.bool,
  scoreInLastPeriod: PropTypes.object,
  intervalType: PropTypes.string,
};

export default DriverScoreCard;
