import { EVENT_RATINGS } from 'constants/event-ratings';
import PropTypes from 'prop-types';
import React from 'react';
import Event from '../../../Drivers/components/Event';

function TripDetails({ trip = {} }) {
  const {
    tripId,
    date,
    startTime,
    duration,
    ratings,
    insurancePeriod,
    trackingId,
    distance,
  } = trip;
  return (
    <div className="trip_details_wrapper">
      <div className="driver_stats_card_header">
        <p className="driver_stats_card_header_text">Trip Details</p>
      </div>
      {/* TRIP DETAILS  */}
      <div className="p-4">
        <div className="py-3 d-flex align-items-center">
          <p className="stats_key">Trip ID</p>
          <div className="ml-auto">
            <p className="stats_value">{tripId ?? 'NA'}</p>
          </div>
        </div>
        <div className="py-3 d-flex align-items-center">
          <p className="stats_key">Date & Time</p>
          <div className="ml-auto">
            <p className="stats_value">{`${date} ${startTime}`}</p>
          </div>
        </div>
        <div className="py-3 d-flex align-items-center">
          <p className="stats_key">Duration</p>
          <div className="ml-auto">
            <p className="stats_value"> {duration}</p>
          </div>
        </div>
        <div className="py-3 d-flex align-items-center">
          <p className="stats_key">Distance</p>
          <div className="ml-auto">
            <p className="stats_value">{distance}</p>
          </div>
        </div>
        <div className="py-3 d-flex align-items-center">
          <p className="stats_key">Insurance period</p>
          <div className="ml-auto">
            <p className="stats_value">{insurancePeriod}</p>
          </div>
        </div>
        <div className="py-3 d-flex align-items-center">
          <p className="stats_key">Tracking ID</p>
          <div className="ml-auto">
            <p className="stats_value">{trackingId}</p>
          </div>
        </div>
        <div className="py-3 d-flex align-items-center">
          <p className="stats_key">Focus Areas</p>
          <div className="ml-auto">
            <p className="stats_value d-flex align-items-center justify-content-end">
              {ratings?.map((rating) => {
                const { Icon, label } = EVENT_RATINGS[rating.name] || {};
                if (!Icon) return null;
                return (
                  <div key={rating.name} className="pl-2">
                    <Event label={label}>
                      <Icon />
                    </Event>
                  </div>
                );
              })}
              {ratings?.length <= 0 && <div>-</div>}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
TripDetails.propTypes = {
  drivingBehavior: PropTypes.object.isRequired,
  trip: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
};
export default TripDetails;
