import { Modal } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

export default function ConfirmationModal(props) {
  const {
    visible,
    onCancel,
    onProceed,
    title,
    primaryActionText,
    secondaryActionText,
    footer = [],
    children,
    width,
    maskClosable = true,
  } = props;
  return (
    <Modal
      open={visible}
      title={title}
      okText={primaryActionText}
      cancelText={secondaryActionText}
      onCancel={onCancel}
      onOk={onProceed}
      footer={footer}
      width={width}
      maskClosable={maskClosable}
    >
      {children}
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onProceed: PropTypes.func,
  title: PropTypes.string,
  primaryActionText: PropTypes.string,
  secondaryActionText: PropTypes.string,
  footer: PropTypes.arrayOf(PropTypes.element),
  children: PropTypes.node,
};
