import {
  CloseOutlined,
  DownloadOutlined,
  DownOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Button, Card, List, Popover, Select, Spin } from 'antd';
import Highcharts from 'highcharts';
import moment from 'moment';
import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import {
  gradeCpmPercentages,
  performanceGradeSeries,
} from 'utils/performanceGradeCharts';
import { callGetApi } from '../../api/axios';
import '../../assets/style/authStyles.css';
import { GREY_150 } from '../../assets/style/colors';
import '../../assets/style/dashboard/dashboard.css';
import '../../assets/style/dashboard/overview.css';
import '../../assets/style/settings/settings.css';
import {
  convertDistance,
  extractRgb,
  formatNumber,
  formatTrendsData,
  zip,
} from '../../components/charts/utils';
import PeriodsFilter from '../../components/widgets/periodsFilter';
import useUserDetails from '../../hooks/useUserDetails';
import MixpanelContext from '../../services/tracking';
import GradeSummary from './GradeSummary';

const { Option } = Select;

export default function Insurance() {
  const mixpanel = useContext(MixpanelContext);
  const { isPeriodsFilterEnabled, periodFilterPersistKey } = useUserDetails();
  const [trends, setTrends] = useState({});
  const [trendsPerf, setTrendsPerf] = useState(null);
  const [perfGroups, setPerfGroups] = useState(null);
  const [listReports, setListReports] = useState(null);
  const [selectedCpmDateRange, setSelectedCpmDateRange] = useState(null);
  const [selectedSummaryDateRange, setSelectedSummaryDateRange] =
    useState(null);
  const [dateRanges, setDateRanges] = useState(null);
  const [selectedPerfGroup, setSelectedPerfGroup] = useState(null);
  const [summary, setSummary] = useState(null);
  const [trendsLoading, setTrendsLoading] = useState(true);
  const [tabList, setTabList] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState(
    summary?.savings > 0 ? 'savings' : 'cpm',
  );
  const [cpmChartLoading, setCpmChartLoading] = useState(true);
  const [summaryChartLoading, setSummaryChartLoading] = useState(true);
  const [selectedReportMenu, setSelectedReportMenu] = useState({
    currentView: 'year',
    visible: false,
  });
  const [selectedPeriod, setSelectedPeriod] = useState([
    ...JSON.parse(sessionStorage.getItem(periodFilterPersistKey) || '["ALL"]'),
  ]);

  let summaryChartRef = null;
  const distanceUnit = 'miles';
  const distanceUnitLabel = 'Mile';

  const trendsTabLabels = {
    savings: { label: 'Savings', prefix: '$' },
    cpm: { label: 'Cost Per Mile', prefix: '$' },
    distance_km: { label: `${distanceUnitLabel}s` + ' Driven', prefix: '' },
    premium: { label: 'Premium', prefix: '$' },
  };

  const setChart = (xAxis, yAxis, config = {}) =>
    Highcharts.chart('time-series-container', {
      chart: {
        type: 'area',
        height: 220,
      },
      title: {
        text: null,
      },
      xAxis: {
        visible: true,
        categories: xAxis,
        labels: {
          enabled: true,
          formatter: (v) => moment(v?.value).format('MMM yyyy'),
          style: {
            color: 'rgb(18,32,56)',
            fontSize: '12px',
          },
          autoRotation: false,
          y: 40,
        },
        tickLength: 10,
      },
      yAxis: {
        gridLineColor: 'rgb(230,231,235)',
        title: {
          text: null,
        },
        labels: {
          enabled: true,
          style: {
            color: 'rgb(18,32,56)',
            fontSize: '12px',
          },
        },
        tickLength: 10,
        tickInterval: undefined,
        min: null,
        max: null,
      },
      credits: { enabled: false },
      tooltip: {
        formatter: function formatter() {
          let date = this.point.options.name;
          date = moment(date).format('MMM yyyy');

          const currentValue = this.point.options.y;
          const adjective = config?.label; // check if label and transform are not provided

          /* eslint-disable-next-line eqeqeq */

          return '\n      <div class="tooltip_date">'
            .concat(date, '</div>\n        <div class="tooltip_value">')
            .concat(config?.prefix)
            .concat(
              formatNumber(currentValue),
              '</div>\n      <div class="tooltip_label">',
            )
            .concat(adjective, '</div>\n      ');
        },
        backgroundColor: null,
        borderWidth: 0,
        shadow: false,
        useHTML: true,
        style: {
          padding: 0,
        },
      },
      legend: { enabled: false },
      plotOptions: {
        area: {
          animation: false,
          marker: {
            enabled: xAxis.length === 1,
            symbol: 'circle',
            fillColor: 'rgb(36,49,107)',
            radius: 6,
            states: {
              hover: {
                enabled: true,
              },
            },
          },
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [0, 'rgba('.concat(extractRgb('rgb(71,90,161)'), ', 0.1)')],
              [1, 'rgb(255,255,255)'],
            ],
          },
          lineColor: 'rgb(71,90,161)',
          lineWidth: 2,
        },
      },
      series: [
        {
          data: zip(xAxis, yAxis),
        },
      ],
    });

  const setCpmChart = (chartData) => {
    // trends_perf
    const cpmPerf = chartData?.data;
    return Highcharts.chart('cpm-chart', {
      chart: {
        height: 300,
      },
      title: {
        text: null,
        verticalAlign: 'top',
        floating: true,
        align: 'left',
      },
      yAxis: {
        title: {
          text: null,
        },
        tickLength: 10,
        tickInterval: undefined,
        min: 0,
        labels: {
          formatter: function formatter() {
            if (this.value === 0) {
              return ''.concat('$', '0');
            }
            if (this.value >= 0) {
              return ''.concat('$').concat(this.value);
            }
          },
        },
      },

      xAxis: {
        categories: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'End of Month'],
        accessibility: {
          rangeDescription: 'Range: 2010 to 2017',
        },
        labels: {
          style: {
            color: 'black',
            fontSize: '12px',
          },
          autoRotation: false,
        },
      },

      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'top',
      },

      tooltip: {
        formatter: function formatter() {
          const currentValue = this.point.options.y;
          const prefix = '$';
          return '\n      <div class="score">\n      '
            .concat(prefix)
            .concat(currentValue, ' ')
            .concat(
              'Cost Per Mile',
              '\n      </div>\n    <div class="qualifier">',
            )
            .concat(this.point.series.name)
            .concat('</div>\n    ');
        },
        backgroundColor: null,
        borderWidth: 0,
        shadow: false,
        useHTML: true,
        style: {
          padding: 0,
        },
      },
      plotOptions: {
        line: {
          animation: false,
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 6,
            states: {
              hover: {
                enabled: true,
              },
            },
          },
          lineWidth: 2,
        },
      },

      series: performanceGradeSeries({ data: cpmPerf }),
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                VerticalAlignValue: 'top',
              },
            },
          },
        ],
      },
      credits: { enabled: false },
    });
  };

  const setSummaryChart = (data) => {
    // perf_groups
    // var data = formatCpmPercentages(chartData)
    summaryChartRef = Highcharts.chart('summary-chart', {
      chart: {
        type: 'pie',
        margin: [0, 0, 0, 0],
        spacingTop: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        spacingRight: 0,
        slicingOffset: 0,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          size: '100%',
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
            distance: -18,
            style: {
              fontWeight: 'bold',
              fontSize: '10px',
              color: '#FFF',
            },
          },
          innerSize: '70%',
          events: {
            click: function click(e) {
              const selectedOption = data.find(
                (opt) => opt.label === e.point.name,
              );
              if (summaryChartRef && selectedOption) {
                summaryChartRef.setTitle(
                  {
                    text: ''.concat(selectedOption?.label, ' Drivers'),
                  },
                  {
                    text: 'CPM: $'.concat(selectedOption?.cpm),
                  },
                );
              }
              setSelectedPerfGroup(selectedOption);
            },
          },
          colors: data.map((e) => e.color),
          tooltip: false,
        },
      },
      title: {
        verticalAlign: 'middle',
        floating: true,
        y: -10,
        text: data[0] ? ''.concat(data[0]?.label, ' Drivers') : null,
        style: {
          fontSize: '15px',
          letterSpacing: '0.5px',
          textAlign: 'center',
        },
      },
      subtitle: {
        verticalAlign: 'middle',
        floating: true,
        text: data[0] ? 'CPM: $'.concat(data[0]?.cpm) : null,
        y: 30,
        style: {
          fontSize: '15px',
          fontWeight: 'bold',
          letterSpacing: '1.2px',
          textAlign: 'center',
          color: 'black',
        },
      },
      tooltip: {
        formatter: function formatter() {
          return false;
        },
      },
      series: [
        {
          id: 'zd-pie-id',
          data: data.map((e) => [e.label, e.value]),
        },
      ],
    });

    const lastAngle = 0;
    const moveToPoint = function (clickPoint) {
      const { points } = clickPoint.series;
      let startAngle = 0;
      for (let i = 0; i < points.length; i++) {
        const p = points[i];
        if (p === clickPoint) {
          break;
        }
        startAngle += (p.percentage / 100.0) * 360.0;
      }

      const newAngle =
        -startAngle + 90 - ((clickPoint.percentage / 100.0) * 360.0) / 2;

      // clickPoint.series.update({
      //     //startAngle: -startAngle + 180 // start at 180
      //     startAngle: newAngle // center at 90
      // });
    };
  };

  const fetchReports = () => callGetApi('/v2/api/list_reports');

  const fetchTrends = (dateRange) => {
    setTrendsLoading(true);
    let url = '/v1/api/trends?';
    if (dateRange?.startDate && dateRange?.endDate) {
      url += queryString.stringify(
        {
          start_date: dateRange?.startDate,
          end_date: dateRange?.endDate,
          ...(isPeriodsFilterEnabled && {
            trip_periods: selectedPeriod,
          }),
        },
        {
          arrayFormat: 'comma',
        },
      );
    }
    callGetApi(url)
      .then((resp) => {
        setTrends(formatTrendsData(resp?.data?.data));
        setTrendsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setTrendsLoading(false);
      });
  };

  const fetchSummary = (dateRange) => {
    let url = '/v1/api/summary?';
    if (dateRange?.startDate && dateRange?.endDate) {
      url += queryString.stringify(
        {
          start_date: dateRange?.startDate,
          end_date: dateRange?.endDate,
          ...(isPeriodsFilterEnabled && {
            trip_periods: selectedPeriod,
          }),
        },
        {
          arrayFormat: 'comma',
        },
      );
    }
    callGetApi(url)
      .then((resp) => setSummary(resp.data))
      .catch((err) => console.log(err));
  };

  const fetchTrendsPerf = (startDate, endDate) => {
    setCpmChartLoading(true);
    const queryParams = queryString.stringify(
      {
        start_date: startDate,
        end_date: endDate,
        ...(isPeriodsFilterEnabled && {
          trip_periods: selectedPeriod,
        }),
      },
      {
        arrayFormat: 'comma',
      },
    );
    callGetApi(`/v2/api/trends-perf?${queryParams}`)
      .then((resp) => {
        setCpmChartLoading(false);
        setTrendsPerf(resp.data);
      })
      .catch((err) => {
        console.log(err);
        setCpmChartLoading(false);
      });
  };

  const fetchTrendsPerfGroup = (startDate, endDate) => {
    setSummaryChartLoading(true);
    const queryParams = queryString.stringify(
      {
        start_date: startDate,
        end_date: endDate,
        ...(isPeriodsFilterEnabled && {
          trip_periods: selectedPeriod,
        }),
      },
      {
        arrayFormat: 'comma',
      },
    );

    callGetApi(`/v2/api/perf-groups?${queryParams}`)
      .then((resp) => {
        setSummaryChartLoading(false);
        const data = gradeCpmPercentages(resp?.data?.data);
        if (data && data.length > 0) {
          setPerfGroups(data);
          setSelectedPerfGroup(data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
        setSummaryChartLoading(false);
      });
  };

  async function downloadInsuranceReport(queryParams) {
    const url = `/v2/api/insurance_report?${queryString.stringify(queryParams, {
      arrayFormat: 'comma',
    })}`;
    try {
      const { data, headers } = await callGetApi(url);
      const contentType = 'text/csv';
      const contentDisposition = headers['content-disposition'];
      const filename = contentDisposition.split(';')[1].split('=')[1];
      const csvFile = new Blob([data], { type: contentType });
      const csvURL = window.URL.createObjectURL(csvFile);
      const report_link = document.createElement('a');
      report_link.href = csvURL;
      report_link.setAttribute('download', filename);
      report_link.click();
    } catch (err) {
      console.log(err);
    }
  }

  const getTrendsMetrics = (metricData) => {
    const xAxis = [];
    const yAxis = [];
    metricData?.dates?.forEach((d, idx) => {
      yAxis.push(metricData.values[idx]);
      xAxis.push(d);
    });
    return { xAxis, yAxis };
  };

  const fetchData = async () => {
    try {
      const reportsResp = await fetchReports();
      const reportsData = reportsResp.data;
      return reportsData;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const buildReportDateRanges = (reportsData) => {
    const reportDateRanges = [];
    let selectedDateRange = null;

    Object.keys(reportsData.reports).forEach((year) => {
      reportsData.reports[year].forEach((dateRange) => {
        const {
          policy_month_start,
          policy_month_end,
          monthly_reports_available,
        } = dateRange;
        const dateRangeLabel = `${moment(policy_month_start).format(
          'MMM Do yyyy',
        )} - ${moment(policy_month_end).format('MMM Do yyyy')}`;
        const dateRangeItem = {
          dateRangeLabel,
          value: dateRangeLabel,
          startDate: policy_month_start,
          endDate: policy_month_end,
        };

        if (monthly_reports_available) {
          selectedDateRange = dateRangeItem;
        }
        reportDateRanges.push(dateRangeItem);
      });
    });

    return { reportDateRanges, selectedDateRange };
  };

  const calculateTrendsDateRange = (reportDateRanges) => {
    if (reportDateRanges.length === 0) {
      return null;
    }

    const lastDate = new Date(
      reportDateRanges[reportDateRanges.length - 1].endDate,
    );
    const startDate = new Date(lastDate);
    startDate.setDate(lastDate.getDate() - 365);

    return {
      startDate: moment(startDate).format('yyyy-MM-DD'),
      endDate: moment(lastDate).format('yyyy-MM-DD'),
    };
  };

  const fetchDataAndProcess = async () => {
    const reportsData = await fetchData();

    if (!reportsData) {
      return;
    }

    const { reportDateRanges, selectedDateRange } =
      buildReportDateRanges(reportsData);
    const trendsDateRange = calculateTrendsDateRange(reportDateRanges);

    setListReports(reportsData);
    setSelectedCpmDateRange(selectedDateRange);
    setSelectedSummaryDateRange(selectedDateRange);
    setDateRanges(reportDateRanges);
    fetchSummary(selectedDateRange);
    fetchTrends(trendsDateRange);
  };

  useEffect(() => {
    fetchDataAndProcess();
  }, [selectedPeriod]);

  useEffect(() => {
    if (trends.data) {
      const metricData = trends.data[activeTabKey];
      if (metricData?.dates?.length > 0 && metricData?.values?.length > 0) {
        const { xAxis, yAxis } = getTrendsMetrics(metricData);
        setChart(xAxis, yAxis, trendsTabLabels[activeTabKey]);
      }
    }
  }, [trends]);

  useEffect(() => {
    if (summary) {
      const savingsTab = {
        key: 'savings',
        tab: renderTrendTab(summary.savings, 'Savings', '$'),
      };
      const tablist = [
        {
          key: 'cpm',
          tab: renderTrendTab(summary.cpm, 'Cost Per Mile', '$'),
        },
        {
          key: 'distance_km',
          tab: renderTrendTab(
            convertDistance(summary.distance_km, distanceUnit, true),
            `${distanceUnitLabel}s Driven`,
            '',
            distanceUnit,
          ),
        },
        {
          key: 'premium',
          tab: renderTrendTab(summary.premium, 'Premium', '$'),
        },
      ];
      if (summary?.savings > 0) {
        setActiveTabKey('savings');
        setTabList([savingsTab, ...tablist]);
      } else {
        setActiveTabKey('cpm');
        setTabList(tablist);
      }
    }
  }, [summary]);

  useEffect(() => {
    if (trendsPerf?.data) {
      setCpmChart(trendsPerf);
    }
  }, [trendsPerf]);

  useEffect(() => {
    if (perfGroups) {
      setSummaryChart(perfGroups);
    }
  }, [perfGroups]);

  useEffect(() => {
    if (selectedCpmDateRange) {
      const { startDate, endDate } = selectedCpmDateRange;
      fetchTrendsPerf(startDate, endDate);
    }
  }, [selectedCpmDateRange]);

  useEffect(() => {
    if (selectedSummaryDateRange) {
      const { startDate, endDate } = selectedSummaryDateRange;
      fetchTrendsPerfGroup(startDate, endDate);
    }
  }, [selectedSummaryDateRange]);

  useEffect(() => {
    mixpanel.track('View Page', {
      pageName: 'Insurance Page',
    });
  }, [mixpanel]);

  useEffect(() => {
    if (trends.data) {
      const metricData = trends.data[activeTabKey];
      if (metricData?.dates?.length > 0 && metricData?.values?.length > 0) {
        const { xAxis, yAxis } = getTrendsMetrics(metricData);
        setChart(xAxis, yAxis, trendsTabLabels[activeTabKey]);
      }
    }
  }, [activeTabKey]);

  // TODO: Extract this as a component
  const renderTrendTab = (v, title, prefix = '', suffix = '') => (
    <div
      style={{
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        width: tabList.length === 4 ? '330px' : '440px',
      }}
    >
      <div
        style={{
          fontSize: '10px',
          color: GREY_150,
          opacity: '0.7',
        }}
      >
        {title}
      </div>
      <div
        style={{
          fontSize: '13px',
          fontWeight: 'bold',
          opacity: '0.7',
        }}
      >
        {`${prefix}${formatNumber(v)} ${suffix}`}
      </div>
    </div>
  );
  // TODO: Extract this as a component
  const noChartData = (msg) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100% - 56px)',
        boxSizing: 'border-box',
        fontSize: '14px',
        fontWeight: '700',
      }}
    >
      {msg}
    </div>
  );
  // TODO: Extract this as a component
  const trendsChartWrapper = () => {
    const metricData = trends?.data ? trends?.data[activeTabKey] : {};
    if (trendsLoading) {
      return (
        <div
          style={{
            height: '300px',
            width: '100%',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Spin />
        </div>
      );
    }
    if (metricData?.dates?.length > 0 && metricData?.values?.length > 0) {
      return (
        <div
          style={{
            fontSize: '14px',
            textAlign: 'center',
            color: GREY_150,
          }}
        >
          The rate per {distanceUnitLabel} can fluctuate based on the average
          driver score and other environmental factors
        </div>
      );
    }
    return trends?.data
      ? noChartData('THERE IS NO ACTIVITY IN THIS PERIOD')
      : null;
  };
  // TODO: Extract this as a component
  const cpmChartWrapper = () => {
    if (cpmChartLoading) {
      return (
        <div
          style={{
            height: '300px',
            width: '100%',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Spin />
        </div>
      );
    }
    if (
      trendsPerf?.excellent?.length === 0 &&
      trendsPerf?.coaching?.length === 0 &&
      trendsPerf?.fair?.length === 0 &&
      trendsPerf?.low_distance?.length === 0
    ) {
      return noChartData('THE DATA FOR THIS PERIOD IS NOT AVAILABLE');
    }
    return null;
  };
  // TODO: Extract this as a component
  const summaryChartWrapper = () => {
    if (summaryChartLoading) {
      return (
        <div
          style={{
            height: '300px',
            width: '100%',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Spin />
        </div>
      );
    }
    if (!selectedPerfGroup) {
      return noChartData('THE DATA FOR THIS PERIOD IS NOT AVAILABLE');
    }
    return null;
  };
  const onTabChange = (key) => {
    setActiveTabKey(key);
  };

  // TODO: Extract this as a component
  const renderReportPopupHeader = () => {
    const { currentView, year, month, week } = selectedReportMenu;
    let prevView = null;
    let title = 'Policy Period';
    if (currentView === 'downloadReport') {
      prevView = 'week';
      title = week?.label;
    }
    if (currentView === 'week') {
      prevView = 'month';
      title = month?.label;
    }
    if (currentView === 'month') {
      prevView = 'year';
      title = year?.label;
    }
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          background: 'rgb(240,240,240)',
          alignItems: 'center',
          padding: '1rem',
          marginBottom: '0.5rem',
        }}
      >
        {prevView ? (
          <LeftOutlined
            style={{ cursor: 'pointer', marginRight: '1rem' }}
            onClick={() =>
              setSelectedReportMenu({
                ...selectedReportMenu,
                currentView: prevView,
              })
            }
          />
        ) : null}
        <div>{title}</div>
        <CloseOutlined
          style={{ cursor: 'pointer' }}
          onClick={() =>
            setSelectedReportMenu({
              currentView: 'year',
              visible: false,
            })
          }
        />
      </div>
    );
  };

  const getReportMenuItems = () => {
    if (selectedReportMenu?.currentView) {
      if (selectedReportMenu?.currentView === 'year') {
        if (listReports?.reports) {
          return Object.keys(listReports?.reports).map((r) => ({
            label: r,
            value: r,
            type: 'year',
          }));
        }
        return [];
      }

      if (
        selectedReportMenu?.currentView === 'month' &&
        selectedReportMenu?.year
      ) {
        return listReports?.reports[selectedReportMenu?.year?.value].map(
          (m) => ({
            label: moment(m?.policy_month_start).format('MMM yyyy'),
            value: m,
            type: 'month',
          }),
        );
      }

      if (
        selectedReportMenu?.currentView === 'week' &&
        selectedReportMenu?.month
      ) {
        const weeksData = selectedReportMenu?.month?.value?.weekly_reports.map(
          (w, idx) => ({
            label: `Week ${idx + 1}`,
            value: w,
            type: 'week',
          }),
        );
        if (selectedReportMenu?.month?.value?.monthly_reports_available) {
          weeksData.push({
            label: 'Monthly Report',
            value: selectedReportMenu?.month?.value?.monthly_report,
            type: 'week',
          });
        }
        return weeksData;
      }

      if (
        selectedReportMenu?.currentView === 'downloadReport' &&
        selectedReportMenu?.week
      ) {
        return [
          {
            label: 'Fleet Report',
            value: 'fleet_report',
            type: 'downloadReport',
          },
          {
            label: 'Driver Report',
            value: 'driver_report',
            type: 'downloadReport',
          },
        ];
      }
    }
    return [];
  };

  const handleReportSelection = (item) => {
    if (item.type === 'downloadReport') {
      const queryParams = {
        report_type: item.value,
        start_date: selectedReportMenu?.week?.value?.report_start_date,
        end_date: selectedReportMenu?.week?.value?.report_end_date,
        ...(isPeriodsFilterEnabled && {
          trip_periods: selectedPeriod,
        }),
      };
      downloadInsuranceReport(queryParams);
    } else {
      let currentView = 'month';
      if (item?.type === 'month') {
        currentView = 'week';
      } else if (item?.type === 'week') {
        currentView = 'downloadReport';
      }
      setSelectedReportMenu({
        ...selectedReportMenu,
        [item.type]: item,
        currentView,
      });
    }
  };

  const getReportsMenu = () => {
    if (listReports && listReports?.reports) {
      const years = listReports?.reports;
      const items = getReportMenuItems();
      return (
        <div>
          {renderReportPopupHeader()}
          <List
            size="large"
            dataSource={items}
            renderItem={(item) => (
              <List.Item
                className="popover-menu-item"
                key={item?.label || item}
                onClick={() => handleReportSelection(item)}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  {item?.label || item}
                  {item?.type === 'downloadReport' ? (
                    <DownloadOutlined />
                  ) : (
                    <RightOutlined />
                  )}
                </div>
              </List.Item>
            )}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <div className="playground">
      <div className="overview_wrapper">
        {/* LOCATION AND REQUEST DRIVER REPORT WRAPPER  */}
        <div className="location_and_request_demo_wrapper p-2">
          <PeriodsFilter
            selectedPeriod={selectedPeriod}
            handlePeriodChange={setSelectedPeriod}
          />
          <div
            className="filters_wrapper arrange_request_demo"
            style={{
              marginLeft: 'auto',
              marginRight: 0,
            }}
          >
            <Popover
              content={getReportsMenu()}
              placement="bottomRight"
              overlayClassName="popover-card"
              visible={selectedReportMenu?.visible}
              trigger={['click']}
              style={{ padding: 0 }}
              overlayInnerStyle={{
                padding: 0,
                width: 250,
              }}
            >
              <Button
                type="primary"
                onClick={() =>
                  setSelectedReportMenu({
                    ...selectedReportMenu,
                    visible: !selectedReportMenu.visible,
                  })
                }
              >
                INSURANCE REPORTS <DownOutlined />
              </Button>
            </Popover>
          </div>
        </div>
        <Card
          className="highchart_card"
          style={{
            margin: '10px',
            boxShadow:
              '0 6px 10px 0 rgb(47 49 104 / 12%), 0 1px 6px 0 rgb(0 0 0 / 12%)',
          }}
          tabList={tabList}
          tabProps={{ tabBarStyle: { padding: 0 } }}
          activeTabKey={activeTabKey}
          onTabChange={(key) => {
            onTabChange(key);
          }}
          bodyStyle={{ minHeight: '350px' }}
        >
          <div
            className="account_info_wrapper_card"
            id="time-series-container"
          />
          {trendsChartWrapper()}
        </Card>
        <div className="card_wrapper risk_factor_and_driver_score_wrapper">
          {/* Summary CARD  */}
          <Card
            title={
              <div
                className="d-flex align-items-center"
                style={{ flexWrap: 'wrap' }}
              >
                <p className="driver_stats_card_header_text padding_on_mobile">
                  Cost Per Mile
                </p>
                <div
                  className="padding_on_mobile"
                  style={{ marginLeft: 'auto' }}
                >
                  <Select
                    size="large"
                    style={{ width: 300 }}
                    placeholder="Select a period"
                    optionFilterProp="children"
                    value={selectedCpmDateRange?.value}
                    onChange={(value, option) =>
                      setSelectedCpmDateRange(option)
                    }
                  >
                    {dateRanges?.map((dr) => (
                      <Option {...dr}>{dr?.dateRangeLabel}</Option>
                    ))}
                  </Select>
                </div>
              </div>
            }
            style={{
              margin: '10px',
              boxShadow:
                '0 6px 10px 0 rgb(47 49 104 / 12%), 0 1px 6px 0 rgb(0 0 0 / 12%)',
            }}
            className="highchart_card"
            bodyStyle={{ minHeight: '372px' }}
          >
            {cpmChartWrapper()}
            {!cpmChartLoading && <div id="cpm-chart" />}
          </Card>
          {/* Cost Per Mile CARD  */}
          <Card
            title={
              <div
                className="d-flex align-items-center"
                style={{ flexWrap: 'wrap' }}
              >
                <p className="driver_stats_card_header_text padding_on_mobile">
                  Summary
                </p>
                <div
                  className="padding_on_mobile"
                  style={{ marginLeft: 'auto' }}
                >
                  <Select
                    size="large"
                    style={{ width: 300 }}
                    placeholder="Select a period"
                    optionFilterProp="children"
                    value={selectedSummaryDateRange?.value}
                    onChange={(value, option) =>
                      setSelectedSummaryDateRange(option)
                    }
                  >
                    {dateRanges?.map((dr) => (
                      <Option {...dr}>{dr?.dateRangeLabel}</Option>
                    ))}
                  </Select>
                </div>
              </div>
            }
            style={{
              margin: '10px',
              boxShadow:
                '0 6px 10px 0 rgb(47 49 104 / 12%), 0 1px 6px 0 rgb(0 0 0 / 12%)',
            }}
            className="highchart_card"
            bodyStyle={{ minHeight: '372px' }}
          >
            {summaryChartWrapper()}
            {!summaryChartLoading && (
              <div
                className="d-flex align-items-center"
                style={{ flexWrap: 'wrap' }}
              >
                <div className="summary_chart_body_graph" id="summary-chart" />

                <GradeSummary
                  distanceUnitLabel={distanceUnitLabel}
                  selectedPerfGroup={selectedPerfGroup}
                />
              </div>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
}
