Object.defineProperty(exports, '__esModule', {
  value: true,
});
exports.bpfrpt_proptype_LngLat =
  exports.bpfrpt_proptype_Location =
  exports.default =
    void 0;

const _propTypes = _interopRequireDefault(require('prop-types'));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

const bpfrpt_proptype_Location = {
  latitude: _propTypes.default.number.isRequired,
  longitude: _propTypes.default.number.isRequired,
  timestamp: _propTypes.default.string.isRequired,
  time_millis: _propTypes.default.number.isRequired,
};
exports.bpfrpt_proptype_Location = bpfrpt_proptype_Location;

const bpfrpt_proptype_LngLat = _propTypes.default.arrayOf(
  _propTypes.default.any,
);

exports.bpfrpt_proptype_LngLat = bpfrpt_proptype_LngLat;

const _default = function _default(path) {
  return path.map((point) => [point.longitude, point.latitude]);
};

/*
Given an array of Locations return an array of LngLats
*/
const _default2 = _default;
exports.default = _default2;

// # sourceMappingURL=convertLocationsToLatLngs.js.map
