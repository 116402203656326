import { Location, Redirect, Router } from '@reach/router';
import Accounts from 'modules/Settings/Accounts';
import AdvanceSettings from 'modules/Settings/AdvanceSettings';
import Managers from 'modules/Settings/Managers';
import ValidatorTool from 'modules/Settings/ValidatorTools';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import '../../assets/style/globalStyles.css';
import ErrorBoundary from '../../components/ErrorBoundary';
import LayoutWrapper from '../../components/Layout';
import SelectFleet from '../../components/SelectFleet';
import PrivateRoute from '../../components/privateRoute';
import favicon from '../../images/favicon.png';
import AppStatus from '../../modules/AppStatus';
import AddDriver from '../../modules/AppStatus/AddDrivers/AddDriver';
import EditDrivers from '../../modules/AppStatus/EditDrivers/EditDrivers';
import DriverStats from '../../modules/DriverDetails/DriverDetails';
import Drivers from '../../modules/Drivers/Drivers';
import DriversManagement from '../../modules/Drivers/DriversManagement';
import Incidents from '../../modules/Incidents';
import Insurance from '../../modules/Insurance';
import Overview from '../../modules/Overview';
import Support from '../../modules/Support/support';
import TagsManagement from '../../modules/TagsManagement/TagsManagement';
import Vehicles from '../../modules/Vehicles';
import SmartlookInit from '../../utils/smartlook';
import NotFound from '../404';
import ApproveNewAdmin from '../approve-new-admin';
import Helpcenter from '../helpcenter';
import TextMeTheApp from '../text-me-the-app';

export default function App() {
  // this is to fix the issue of useLayoutEffect not being supported in SSR
  if (typeof window === 'undefined') {
    React.useLayoutEffect = React.useEffect;
  }

  useEffect(() => {
    SmartlookInit();
  }, []);

  return (
    <ErrorBoundary>
      <Helmet>
        <title>Fairmatic Dashboard</title>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <Location>
        {({ location }) => (
          <Router basepath="/app" location={location}>
            <PrivateRoute path="/select-fleet" component={SelectFleet} />
            <PrivateRoute
              path="/approve-new-admin"
              component={ApproveNewAdmin}
            />
            <PrivateRoute path="text-me-the-app" component={TextMeTheApp} />
            <PrivateRoute path="/helpcenter" component={Helpcenter} />
            <LayoutWrapper path="dashboard" type="dashboard">
              <Redirect from="/" to="/app/dashboard/overview" noThrow />
              <PrivateRoute path="/overview" component={Overview} />
              <PrivateRoute path="/driver-details" component={DriverStats} />
              <PrivateRoute path="/drivers-performance" component={Drivers} />
              <PrivateRoute
                path="/drivers-management"
                component={DriversManagement}
              />
              <PrivateRoute path="/insurance" component={Insurance} />
              <PrivateRoute path="/support" component={Support} />
              <PrivateRoute path="/vehicles" component={Vehicles} />
              <PrivateRoute path="/incidents" component={Incidents} />
              <PrivateRoute path="/app-status" component={AppStatus} />
              <PrivateRoute path="/edit-drivers" component={EditDrivers} />
              <PrivateRoute path="/add-drivers" component={AddDriver} />
              <PrivateRoute
                path="/tags-management"
                component={TagsManagement}
              />
            </LayoutWrapper>
            <LayoutWrapper path="settings" type="settings">
              <Redirect from="/" to="/app/settings/accounts" noThrow />
              <PrivateRoute path="/accounts" component={Accounts} />
              <PrivateRoute path="/managers" component={Managers} />
              <PrivateRoute path="/advanced" component={AdvanceSettings} />
              <PrivateRoute path="/validator-tool" component={ValidatorTool} />
            </LayoutWrapper>
            <NotFound path="**" />
          </Router>
        )}
      </Location>
    </ErrorBoundary>
  );
}
