import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { GREY_200 } from 'assets/style/colors';
import HeartBeat from 'components/charts/HeartBeat';
import Highcharts from 'highcharts';
import highchartsGantt from 'highcharts/modules/gantt';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { formateDate } from 'utils/date';
import heartbeatTranslator from 'utils/heartbeatTranslator';
import { DeviceStatusTooltip, EmptyHeartbeat } from '.';

function DeviceStatusCard({ heartbeat, dateRange }) {
  highchartsGantt(Highcharts);
  const dtRange = { ...dateRange };
  let initialDataMissing = false;
  if (moment(dtRange.endDate).diff(moment(), 'days') > 0) {
    // To check if endDate > today, set endDate to today in that case
    dtRange.endDate = formateDate(new Date());
  }
  const heartbeatData = useMemo(
    () =>
      heartbeat?.error || heartbeat?.loading || !heartbeat
        ? []
        : heartbeatTranslator(heartbeat, dtRange),
    [dtRange.startDate, dtRange.endDate, heartbeat],
  );
  for (let i = 0; i < heartbeatData?.length; i += 1) {
    if (heartbeatData[i].name === 'Unavailable') {
      initialDataMissing = true;
      break;
    }
  }
  if (!heartbeat || heartbeatData?.length < 0) return null;
  return (
    <div className="driver_stats_and_score_wrapper">
      {/* DRIVER STATS CARD  */}
      <div className="driver_stats_wrapper_card" style={{ flexBasis: '100%' }}>
        {/* CARD HEADER  */}
        <div className="driver_stats_card_header d-flex align-items-center">
          <p className="driver_stats_card_header_text">Device Status</p>
          <div className="px-2">
            <Tooltip
              title={DeviceStatusTooltip}
              overlayClassName="tooltiptitle"
              overlayStyle={{
                minWidth: '360px',
              }}
              placement="right"
            >
              <InfoCircleOutlined
                style={{
                  marginLeft: '10px',
                  color: GREY_200,
                  fontSize: '16px',
                }}
              />
            </Tooltip>
          </div>
        </div>
        {heartbeat?.aggregates?.length === 0 ? (
          EmptyHeartbeat
        ) : (
          <HeartBeat
            id="heartbeat"
            data={heartbeatData}
            dateRange={dtRange}
            categories={[
              'Status',
              'SDK Initialization',
              'Location Permission',
              'Location Service',
              'Activity Permission',
            ]}
            collapsed={false}
            activityPermissionsDisabled={false} // fix this for iOS
            unavailableDate={initialDataMissing}
          />
        )}
      </div>
    </div>
  );
}

export default DeviceStatusCard;

DeviceStatusCard.propTypes = {
  dateRange: PropTypes.object.isRequired,
  heartbeat: PropTypes.object,
};
