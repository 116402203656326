import {
  CloudUploadOutlined,
  DownloadOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Button, Typography } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { read, utils } from 'xlsx';
import ConfirmationModal from '../../../components/shared/confirmationModal';
import MixpanelContext from '../../../services/tracking';
import { toast, toastTypes } from '../../../utils/toast';
import { deviceOwnershipMap } from '../AppStatusConstants';
import constants from './AddDriverConstants';
import {
  arrayEquals,
  fileValidationMessages,
  readableFileSize,
} from './AddDriverUtils';

const { Text, Paragraph } = Typography;

export default function UploadDrivers({
  showModal,
  dataSource,
  setShowModal,
  handleUploadDrivers,
  isTableEmpty,
}) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState('');
  const [formattedData, setFormattedData] = useState([]);
  const fileInput = useRef(null);
  const mixpanel = useContext(MixpanelContext);

  const findValueByLabel = (label) =>
    Object.keys(deviceOwnershipMap).find(
      (key) => deviceOwnershipMap[key] === label,
    );

  const formatFileData = (data) =>
    data.map((item, index) => {
      const {
        'First name': firstName = '',
        'Last name': lastName = '',
        Email: email = '',
        'Phone number': phoneNumber = null,
        'Device ownership type': deviceOwnershipType = '',
        Tags: tags = '',
      } = item;
      const key = isTableEmpty ? index : dataSource.length + index;
      const deviceOwnershipTypeValue = findValueByLabel(deviceOwnershipType);
      return {
        key,
        firstName,
        lastName,
        email,
        phoneNumber: phoneNumber ? `+1${phoneNumber}` : null,
        deviceOwnershipType: deviceOwnershipTypeValue,
        tags: tags.length > 0 ? tags.split(',').map((tag) => tag.trim()) : [],
      };
    });

  const validateFileSize = (file) => {
    const MAX_FILE_SIZE_IN_BYTES = 100 * 1024; // 100kb
    const isFileSizeValid = file.size < MAX_FILE_SIZE_IN_BYTES;
    if (!isFileSizeValid) {
      throw new Error(fileValidationMessages.size);
    }
  };

  const validateSheetNames = (workbook) => {
    const sheetNames = workbook.SheetNames;
    const isEqual = arrayEquals(['INSTRUCTIONS', 'DRIVER LIST'], sheetNames);
    if (!isEqual) {
      throw new Error(fileValidationMessages.invalid);
    }
  };

  const validateDataNotEmpty = (jsonData) => {
    const isEmpty = jsonData.length === 0;
    if (isEmpty) {
      throw new Error(fileValidationMessages.empty);
    }
  };

  const onFileLoad = (event, file) => {
    setSelectedFile(file);

    try {
      validateFileSize(file);

      const workbook = read(new Uint8Array(event.target.result));
      validateSheetNames(workbook);

      const worksheet = workbook.Sheets[workbook.SheetNames[1]];
      const jsonData = utils.sheet_to_json(worksheet);
      validateDataNotEmpty(jsonData);

      setFormattedData(formatFileData(jsonData));

      mixpanel.track('Import Drivers Modal', {
        isFileValid: true,
        fileName: file.name,
        fileSize: file.size,
      });
    } catch (error) {
      setFileError(error.message || fileValidationMessages.invalid);
      mixpanel.track('Import Drivers Modal', {
        isFileValid: false,
        error,
      });
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    try {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (event) => onFileLoad(event, file);
      reader.onerror = () => {
        mixpanel.track('Import Drivers Modal', {
          fileUploadFailed: true,
        });
        toast(toastTypes.ERROR, 'Failed to upload file');
      };
    } catch (error) {
      mixpanel.track('Import Drivers Modal', {
        fileUploadFailed: true,
      });
      toast(toastTypes.ERROR, 'Failed to upload file');
    }
  };
  const chooseFile = () => {
    fileInput.current.click();
    mixpanel.track('Import Drivers Modal', {
      chooseFile: true,
    });
  };

  const handleReplace = () => {
    fileInput.current.value = '';
    setSelectedFile(null);
    setFileError('');
    chooseFile();
    mixpanel.track('Import Drivers Modal', {
      fileReplaceClicked: true,
    });
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (fileInput.current) {
      fileInput.current.value = '';
    }
    setSelectedFile(null);
    setFileError('');
    mixpanel.track('Import Drivers Modal', {
      opened: showModal,
    });
  }, [showModal]);

  return (
    <ConfirmationModal
      visible={showModal}
      width={650}
      onCancel={handleCancel}
      maskClosable={false}
      title="Import Drivers"
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={!selectedFile || fileError}
          onClick={() => handleUploadDrivers(formattedData)}
        >
          Import
        </Button>,
      ]}
    >
      <>
        <Text>
          The file should be less than 100KB and in the format shown in
          <a href={constants.TEMPLATE_URL} rel="noreferrer" className="ml-3">
            <DownloadOutlined /> Download Template{' '}
          </a>
        </Text>

        <input
          type="file"
          accept=".xls, .xlsx"
          onChange={handleFileUpload}
          ref={fileInput}
          className="file_input"
        />
        <div
          style={{
            marginTop: 20,
          }}
        >
          {!selectedFile ? (
            <div onClick={chooseFile} className="file_upload_container">
              <Paragraph>
                <CloudUploadOutlined className="file_upload_icon" />
              </Paragraph>
              <Paragraph>Click to Upload</Paragraph>
              <Paragraph>Format supported files are .xls & .xlsx</Paragraph>
            </div>
          ) : (
            <>
              <div
                className="file_list"
                style={{
                  border: `1px solid ${fileError ? '#B95959' : '#25825B'}`,
                }}
              >
                <section>
                  <Text style={{ color: fileError ? '#B95959' : '#25825B' }}>
                    {selectedFile.name}
                  </Text>
                  <Paragraph style={{ color: '#656565' }}>
                    {readableFileSize(selectedFile.size)}
                  </Paragraph>
                </section>
                <Button onClick={handleReplace}>Replace</Button>
              </div>
              {fileError && (
                <div className="file_error">
                  <WarningOutlined className="mr-3" />
                  {fileError}
                </div>
              )}
            </>
          )}
        </div>
      </>
    </ConfirmationModal>
  );
}
