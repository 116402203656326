import { InfoCircleOutlined } from '@ant-design/icons';
import { Space, Table, Tooltip } from 'antd';
import { parseDuration } from 'components/charts/utils';
import { EVENT_RATINGS } from 'constants/event-ratings';
import { navigate } from 'gatsby-link';
import React from 'react';
import TagList from '../../TagsManagement/TagList';
import Event from './Event';

const FOCUS_AREAS_INFO =
  'Focus areas represent the events that a driver should improve on to boost their performance grade. Focus areas take multiple inputs into consideration including various driving events, duration of certain events like phone use & speeding, highway driving, night driving & several other factors.';

const DriversColumns = [
  {
    title: 'Driver Name',
    dataIndex: 'name',
    key: 'driver_name',
    sorter: true,
  },
  {
    title: 'Tags',
    dataIndex: 'tags',
    key: 'tags',
    render: (tags) => <TagList tags={tags} />,
    maxWidth: 400,
  },
  {
    title: 'Trips',
    dataIndex: 'trip_count',
    key: 'trips',
    sorter: true,
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
    key: 'duration',
    sorter: true,
    render: (duration) => parseDuration(duration * 60 * 60),
  },
  {
    title: 'Distance',
    dataIndex: 'distance',
    key: 'distance',
    sorter: true,
    render: (distance) => `${Number(distance).toFixed(1)} miles`,
  },
  {
    title: () => (
      <>
        Focus areas
        <span className="px-3">
          <Tooltip placement="bottom" title={FOCUS_AREAS_INFO}>
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      </>
    ),
    dataIndex: 'ratings',
    key: 'ratings',
    render: (ratings) => (
      <div className="d-flex">
        {ratings.length > 0
          ? ratings?.map((rating) => {
              const { Icon, label } = EVENT_RATINGS[rating.name] || {};
              if (!Icon) return null;
              return (
                <Space key={rating.name} className="px-2">
                  <Event label={label}>
                    <Icon />
                  </Event>
                </Space>
              );
            })
          : '-'}
      </div>
    ),
  },
  {
    title: 'Score',
    dataIndex: 'score',
    key: 'score',
    sorter: true,
  },
];

export default function DriversTable({
  drivers,
  loading,
  currentActivePageNumber,
  totalDrivers,
  setCurrentActivePageNumber,
  setSort,
}) {
  const onClick = (driver) => {
    navigate(
      `/app/dashboard/driver-details?driverId=${encodeURIComponent(driver.id)}`,
    );
  };
  const handleChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'sort') {
      const { order, columnKey } = sorter;
      setSort({
        key: columnKey,
        order: order === 'ascend' ? 'asc' : 'desc',
      });
    }

    if (extra.action === 'paginate') {
      setCurrentActivePageNumber(pagination.current);
    }
  };
  return (
    <Table
      size="medium"
      dataSource={drivers}
      columns={DriversColumns}
      loading={loading}
      onRow={(record) => ({
        onClick: () => onClick(record),
      })}
      onChange={handleChange}
      pagination={{
        disabled: loading,
        current: currentActivePageNumber,
        pageSize: 50,
        showSizeChanger: false,
        total: totalDrivers,
        hideOnSinglePage: true,
      }}
      scroll={{ y: 750 }}
    />
  );
}
