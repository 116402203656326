import Highcharts from 'highcharts';
import React from 'react';

function StatusPieChart({ id, chartData, background }) {
  const updateChart = () => {
    Highcharts.chart(id, {
      chart: {
        type: 'pie',
        height: 100,
        plotBackgroundColor: background,
        plotBorderWidth: null,
        plotShadow: false,
      },
      credits: {
        enabled: false,
      },
      title: false,
      tooltip: {
        outside: true,
        pointFormat: '<span class="tooltipPoint">{point.y}</span>',
        formatter() {
          return `<span>${this?.point?.name} ${Math.round(
            this.y,
          )}%</span><br/>`;
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: false,
          innerSize: '65%',
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: false,
        },
      },
      series: [
        {
          colorByPoint: true,
          animation: false,
          data: chartData,
        },
      ],
    });
  };
  React.useEffect(() => {
    updateChart();
  }, [chartData]);

  return <div id={id} style={{ width: '100%' }} />;
}

export default StatusPieChart;
