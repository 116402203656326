const PERFORMANCE_FILTERS = {
  all: {
    key: 'all',
    value: 'All',
  },
  excellent: {
    key: 'excellent',
    value: 'Excellent',
    info: 'Drivers have achieved maximum premium savings',
  },
  good: {
    key: 'good',
    value: 'Good',
    info: 'Drivers have a minimum scope of saving premium',
  },
  moderate: {
    key: 'moderate',
    value: 'Moderate',
    info: 'Drivers with reduced premium savings',
  },
  coaching_required: {
    key: 'coaching_required',
    value: 'Coaching Required',
    info: 'Drivers have a maximum scope of saving premium',
  },
};

const TOOLTIPS = {
  PERFORMANCE_GRADES: {
    excellent:
      'A driver is categorized as Excellent if his score ranges between 90-100.',
    good: 'A driver is categorized as Good if his score ranges between 80-89.',
    moderate:
      'A driver is categorized as Moderate if his score ranges between 70-79.',
    coaching:
      'A driver is categorized as Coaching Required if his score ranges between 60-69.',
  },
};

// Maximum number of tags that can be added to a driver
const MAX_TAGS = 5;

// Maximum length of a tag
const TAG_LENGTH = 32;

export { MAX_TAGS, PERFORMANCE_FILTERS, TAG_LENGTH, TOOLTIPS };
