export default function SmartlookInit() {
  if (typeof window !== `undefined`) {
    if (!window.smartlook) {
      window.smartlook = (...args) => {
        window.smartlook.api.push(args);
      };
      window.smartlook.api = [];

      const head = document.getElementsByTagName('head')[0];
      const script = document.createElement('script');
      script.async = true;
      script.type = 'text/javascript';
      script.charset = 'utf-8';
      script.src = 'https://web-sdk.smartlook.com/recorder.js';
      head.appendChild(script);
    }

    window.smartlook('init', process.env.SMARTLOOK_TOKEN, {
      region: 'eu',
    });
  }
}
