import { Empty, List, Space } from 'antd';
import noSearchResults from 'images/no_results.svg';
import React, { useState } from 'react';
import TagEditor from '../../components/tags/TagEditor';
import TagListItem from '../../components/tags/TagListItem';
import EmptyTags from './EmptyTags';
import TagDeletionModal from './TagDeletionModal';
import TagsHeader from './TagsHeader';

export default function TagsTable(props) {
  const { tags, isDeletedTagsTable, getTags, noTagsCreated } = props;

  const [tagsToRender, setTagsToRender] = useState(tags);
  const [tagToEdit, setTagToEdit] = useState(null);
  const [tagToDelete, setTagToDelete] = useState(null);
  const [createTagMode, setCreateTagMode] = useState(false);

  const toggleEditTagView = (tag) => {
    setTagToEdit(tag);
  };

  const toggleDeleteTagModal = (tag) => {
    setTagToDelete(tag);
  };

  const hideEditTagView = () => {
    setTagToEdit(null);
  };

  const hideDeleteModal = () => {
    setTagToDelete(null);
  };

  const showCreateTagView = () => {
    setCreateTagMode(true);
  };

  const cancelCreateTagFlow = () => {
    setCreateTagMode(false);
  };

  // write a function to filter tags based on search query
  const filterTags = (value) =>
    tags.filter((tag) =>
      tag.tag_name.toLowerCase().includes(value.toLowerCase()),
    );

  const handleSearch = (query) => {
    const cleanedQuery = query.trim();
    return cleanedQuery
      ? setTagsToRender(filterTags(cleanedQuery))
      : setTagsToRender(tags);
  };

  const isTagToEdit = (tag) => tagToEdit && tagToEdit.id === tag.id;

  const handleTagChange = () => {
    hideEditTagView();
    setCreateTagMode(false);
  };

  const locale = {
    emptyText: (
      <Empty
        image={noSearchResults}
        imageStyle={{ height: 72 }}
        description={<span>No results found. Try something else.</span>}
      />
    ),
  };

  if (noTagsCreated && !createTagMode) {
    return (
      <EmptyTags
        handleCreateTag={() => {
          setCreateTagMode(true);
        }}
      />
    );
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {createTagMode ? (
        <TagEditor
          handleTagChange={handleTagChange}
          onCancel={cancelCreateTagFlow}
          getTags={getTags}
        />
      ) : (
        <>
          <TagsHeader
            onSearch={handleSearch}
            onCreateTag={showCreateTagView}
            isDeletedTagsTable={isDeletedTagsTable}
          />
          <List
            style={{ width: '100%', maxHeight: '500px', overflowY: 'auto' }}
            itemLayout="horizontal"
            dataSource={tagsToRender}
            locale={locale}
            renderItem={(tag) => (
              <List.Item
                style={{
                  border: 'none',
                }}
              >
                {isTagToEdit(tag) ? (
                  <TagEditor
                    tagToEdit={tag}
                    handleTagChange={handleTagChange}
                    onCancel={hideEditTagView}
                    getTags={getTags}
                  />
                ) : (
                  <TagListItem
                    {...tag}
                    toggleEditTagModal={toggleEditTagView}
                    toggleDeleteTagModal={toggleDeleteTagModal}
                    isDeletedTag={isDeletedTagsTable}
                  />
                )}
              </List.Item>
            )}
          />
        </>
      )}
      <TagDeletionModal
        visible={tagToDelete}
        hideDeleteModal={hideDeleteModal}
        tagToDelete={tagToDelete}
        getTags={getTags}
      />
    </Space>
  );
}
