import {
  AppstoreOutlined,
  FileProtectOutlined,
  SettingOutlined,
  ToolOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import React from 'react';
import { Roles } from '../../utils/roles';
import { getUser } from '../../utils/validateUserToken';

import sideBarConstants from '../../constants/sidebarConstants';

const DRIVERS_SUBMENU = [
  {
    key: sideBarConstants.APP_STATUS,
    name: 'FM App Status',
    route: '/app/dashboard/app-status',
  },
  {
    key: sideBarConstants.DRIVERS_PERFORMANCE,
    name: 'Performance',
    route: '/app/dashboard/drivers-performance',
  },
  {
    key: sideBarConstants.DRIVERS_MANAGEMENT,
    name: 'Management',
    route: '/app/dashboard/drivers-management',
  },
];

const DASHBOARD_SIDEBAR_CONFIG = [
  {
    key: sideBarConstants.OVERVIEW,
    name: 'Overview',
    icon: <AppstoreOutlined />,
    route: '/app/dashboard/overview',
  },
  {
    key: sideBarConstants.DRIVERS_DASHBOARD,
    name: 'Drivers',
    icon: <UserOutlined />,
    submenu: DRIVERS_SUBMENU,
  },
  {
    key: sideBarConstants.INSURANCE,
    name: 'Insurance',
    icon: <FileProtectOutlined />,
    route: '/app/dashboard/insurance',
  },
  {
    key: sideBarConstants.INCIDENTS,
    name: 'Incidents',
    icon: <WarningOutlined />,
    route: '/app/dashboard/incidents',
  },
];

const SETTINGS_SIDEBAR_CONFIG = [
  {
    key: sideBarConstants.PROFILE,
    name: 'Profile',
    icon: <UserOutlined />,
    route: '/app/settings/accounts',
  },
  {
    key: sideBarConstants.VALIDATOR_TOOLS,
    name: 'Validator Tool',
    icon: <ToolOutlined />,
    route: '/app/settings/validator-tool',
  },
  {
    key: sideBarConstants.ADVANCED,
    name: 'Advanced',
    icon: <SettingOutlined />,
    route: '/app/settings/advanced',
  },
  {
    key: sideBarConstants.MANAGERS,
    name: 'Managers',
    icon: <UsergroupAddOutlined />,
    route: '/app/settings/managers',
  },
];

const isDashboardTabEnabled = (key) => {
  const user = getUser();
  const isFnolEnabled = user?.flags_enabled?.includes('fnol') || false;
  const isInsuranceTabEnabled =
    !user?.flags_enabled?.includes('hide_insurance_tab') || false;
  const appStatusEnabled = user?.flags_enabled?.includes('fm_heartbeats');
  const {
    OVERVIEW,
    DRIVERS_DASHBOARD,
    INSURANCE,
    INCIDENTS,
    APP_STATUS,
    DRIVERS_PERFORMANCE,
    DRIVERS_MANAGEMENT,
  } = sideBarConstants;
  switch (true) {
    case key === OVERVIEW:
    case key === DRIVERS_DASHBOARD:
    case key === DRIVERS_PERFORMANCE:
    case key === DRIVERS_MANAGEMENT:
      return true;
    case key === APP_STATUS:
      return appStatusEnabled;
    case key === INSURANCE:
      return isInsuranceTabEnabled;
    case key === INCIDENTS:
      return isFnolEnabled;
    default:
      return false;
  }
};

const isSettingsTabEnabled = (key) => {
  const user = getUser();
  const isSysAdmin = user?.roles?.includes(Roles.SYS_ADMIN);
  const isAdmin = user?.roles?.includes(Roles.ADMIN);
  const { PROFILE, DRIVERS, MANAGERS, ADVANCED, VALIDATOR_TOOLS } =
    sideBarConstants;
  const isFmAppEnabled = user.is_fairmatic_app_enabled;
  switch (true) {
    case key === PROFILE:
    case key === ADVANCED:
      return true;
    case key === DRIVERS:
      return isSysAdmin && isFmAppEnabled;
    case key === MANAGERS:
      return isSysAdmin || isAdmin;
    case key === VALIDATOR_TOOLS:
      return !isFmAppEnabled;
    default:
      return false;
  }
};

export {
  DASHBOARD_SIDEBAR_CONFIG,
  isDashboardTabEnabled,
  isSettingsTabEnabled,
  SETTINGS_SIDEBAR_CONFIG,
};
