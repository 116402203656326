/* eslint-disable react/no-unstable-nested-components */
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Space, Table } from 'antd';
import { EVENT_RATINGS } from 'constants/event-ratings';
import React from 'react';
import { getUser } from '../../../../utils/validateUserToken';
import Event from '../../../Drivers/components/Event';
import TripDetailsContainer from './TripDetailsContainer';

export default function NewTripsTable({
  trips,
  loading,
  driverId,
  onUpdateSortType,
  totalTrips,
  currentActivePageNumber,
  onUpdatePageNumber,
}) {
  const user = getUser();
  const showPeriodFilter = user?.is_fleet_tnc_odnc;
  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: true,
    },
    {
      title: 'Start Time',
      dataIndex: 'startTime',
      key: 'startTime',
    },
    {
      title: 'Distance',
      dataIndex: 'distance',
      key: 'distance',
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
    },
    ...(showPeriodFilter
      ? [
          {
            title: 'Policy Period',
            dataIndex: 'insurancePeriod',
            key: 'insurancePeriod',
            render: (insurancePeriod) =>
              insurancePeriod === 'NA'
                ? insurancePeriod
                : `P${insurancePeriod}` || '-',
          },
        ]
      : []),
    {
      title: 'Focus Areas',
      dataIndex: 'ratings',
      key: 'ratings',
      render: (ratings) => (
        <div className="d-flex">
          {ratings.length > 0
            ? ratings?.map((rating) => {
                const { Icon, label } = EVENT_RATINGS[rating.name] || {};
                if (!Icon) return null;
                return (
                  <Space key={rating.name} className="px-2">
                    <Event label={label}>
                      <Icon />
                    </Event>
                  </Space>
                );
              })
            : '-'}
        </div>
      ),
    },
  ];
  function ExpandIcons({ expanded, onExpand, record }) {
    const Icon = expanded ? DownOutlined : RightOutlined;
    const onClick = (e) => {
      onExpand(record, e);
    };
    return <Icon onClick={onClick} />;
  }

  const handleChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'sort') {
      onUpdateSortType(sorter.order === 'ascend' ? 'asc' : 'desc');
    }

    if (extra.action === 'paginate') {
      onUpdatePageNumber(pagination.current);
    }
  };

  return (
    <div className="trips_table_wrapper">
      <Table
        dataSource={trips}
        columns={columns}
        loading={loading}
        rowKey={(record) => record.tripId}
        onChange={handleChange}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: (record) => (
            <TripDetailsContainer
              tripId={record.zendrive_trip_id}
              user={user}
              drivingBehavior={record.drivingBehavior}
              trip={{ ...record }}
              info={record.info}
              driverId={driverId}
            />
          ),
          expandIcon: ExpandIcons,
        }}
        pagination={{
          pageSize: 50,
          showSizeChanger: false,
          total: totalTrips,
          current: currentActivePageNumber,
        }}
        scroll={{ y: 750 }}
      />
    </div>
  );
}
