import React from 'react';

const ValidatorToolInfo = () => {
  return (
    <div>
        <div className="validator_tool_sample_container">
            <table className="validator_tool_sample_table">
                <tr>
                    <th>driver_id</th>
                    <th>period</th>
                    <th>start_timestamp</th>
                    <th>end_timestamp</th>
                </tr>
                <tr>
                    <td>D100</td>
                    <td>P2</td>
                    <td>1542258221</td>
                    <td>1542261952</td>
                </tr>
                <tr>
                    <td>D101</td>
                    <td>P2</td>
                    <td>1542258222</td>
                    <td>1542261960</td>
                </tr>
                <tr>
                    <td>D102</td>
                    <td>P3</td>
                    <td>1542258223</td>
                    <td>1542261967</td>
                </tr>
            </table>
        </div>

        <p className="validator_tool_card_header_subText">
            <br/>          
            &#8226; <code>driver_id</code> (String) should refer to the unique alphanumeric Driver ID that you’ve assigned to each driver.<br/>
            &#8226; <code>period</code> (String: P1, P2, P3) should refer to the current Period that the driver is in with your application.<br/>
            &#8226; <code>start_timestamp</code> (Integer) should refer to the epoch timestamp at the moment that the driver interacted with the UI to change periods to start a new period.<br/>
            &#8226; <code>end_timestamp</code> (Integer) should refer to the epoch timestamp at the moment that the driver interacted with the UI to change periods to end the current period.<br/>
        </p>

    </div>
  );
};

export default ValidatorToolInfo;