import { Tag } from 'antd';
import React, { useState } from 'react';
import { BLACK_LIGHT, GREY_240, WHITE } from '../../assets/style/colors';
import { DriverResultLabel } from '../../utils/common';

function ExpandablePills({ drivers, sliceCount }) {
  const [showMore, setShowMore] = useState(false);
  const handleShowMore = (e) => {
    e.stopPropagation();
    setShowMore(true);
  };
  const handleShowLess = (e) => {
    e.stopPropagation();
    setShowMore(false);
  };

  return (
    <div>
      {drivers
        .slice(0, showMore ? drivers.length : sliceCount)
        .map((driver) => (
          <Tag
            key={driver.id}
            style={{
              color: BLACK_LIGHT,
              fontSize: '12px',
              fontWeight: '400',
              backgroundColor: WHITE,
              border: '1px solid lightgrey',
              padding: '4px 8px',
              borderRadius: '10px',
              marginBottom: '5px',
            }}
            color={GREY_240}
          >
            {DriverResultLabel(driver)}
          </Tag>
        ))}
      {drivers.length > sliceCount && (
        <Tag
          key="showMoreLess"
          style={{
            color: BLACK_LIGHT,
            borderRadius: '4px',
            fontSize: '11px',
            fontWeight: '400',
            cursor: 'pointer',
            padding: '4px',
          }}
          color={GREY_240}
          onClick={showMore ? handleShowLess : handleShowMore}
        >
          {showMore ? 'SHOW LESS' : `+${drivers.length - sliceCount}`}
        </Tag>
      )}
    </div>
  );
}

export default ExpandablePills;
