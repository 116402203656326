import { Tag } from 'antd';
import React, { useState } from 'react';
import './tags.scss';

function TagList({ tags = [] }) {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = (e) => {
    e.stopPropagation();
    setShowMore(!showMore);
  };

  if (tags.length === 0) return '-';

  return (
    <>
      {tags
        .sort()
        .slice(0, showMore ? tags.length : 1)
        .map(({ tag_id, tag_name }) => (
          <Tag key={tag_id} className="tags-list-item">
            {tag_name}
          </Tag>
        ))}
      {tags.length > 1 && (
        <Tag
          key="showMoreLess"
          className="tags-list-item"
          style={{
            cursor: 'pointer',
          }}
          onClick={toggleShowMore}
        >
          {showMore ? 'SHOW LESS' : `+${tags.length - 1}`}
        </Tag>
      )}
    </>
  );
}

export default TagList;
