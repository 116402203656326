import React from 'react';

export default function HardBreakSvg({
  width = '24',
  height = '24',
  className = '',
}) {
  return (
    <svg
      className={className}
      style={{ width, height }}
      focusable="false"
      viewBox="0 0 24 24"
      color="rgb(200,200,200)"
      aria-hidden="true"
      role="presentation"
    >
      <path d="M12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 Z M19.4594228,10.9586377 C18.4583224,10.8787106 17.5477583,10.4879727 16.7277637,9.78642425 C15.4977719,8.73406337 16.204121,7.44252963 15.1862473,6.80394938 C14.1683736,6.16540711 13.9911694,6.60527232 11.9796984,6.60527232 C8.11832093,6.60527232 6.98946952,5 6.12228938,5 C5.25514264,5 4.56680071,5.80832078 4.50470921,6.09411155 C4.44261771,6.37986423 4.98803582,8.35112058 6.52781822,9.64254024 C8.06760065,10.9339599 13.9602237,15.2670203 17.4474182,17.2421171 C18.7447036,15.7598534 19.7131909,12.1909296 19.4594228,10.9586377 Z M14.39291,17.8220448 L5.39954703,11.9013554 L4.80310854,13.0793486 L6.9204219,14.4732818 C6.3268512,15.87809 6.01525994,17.4284172 6.01525994,19 L7.01565999,19 C7.01565999,17.6285772 7.28166635,16.2756341 7.78933602,15.0453195 L13.7964382,19 L14.39291,17.8220448 Z" />
    </svg>
  );
}
