import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Input, Table } from 'antd';
import React, { useState } from 'react';
import { callGetApi } from '../../api/axios';
import '../../assets/style/authStyles.css';
import { GREY_150 } from '../../assets/style/colors';
import '../../assets/style/dashboard/dashboard.css';
import '../../assets/style/settings/settings.css';
import { debounce } from '../../utils/search';
import { toast, toastTypes } from '../../utils/toast';

export default function Vehicles() {
  const [loading, setLoading] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [query, setQuery] = useState(null);

  async function searchVehicle(searchQuery) {
    setLoading(true);
    try {
      const { data } = await callGetApi(
        `/v1/api/get-vehicles?query=${searchQuery}`,
      );
      setVehicles(data);
    } catch (err) {
      const { message } = err?.response?.data || {};
      if (message) toast(toastTypes.ERROR, message);
    } finally {
      setLoading(false);
    }
  }

  const getVehicles = () => {
    setLoading(true);
    callGetApi('/v1/api/get-vehicles')
      .then((resp) => {
        setVehicles(resp.data);
        setLoading(false);
      })
      .catch((err) => {
        const { message } = err?.response?.data || {};
        if (message) toast(toastTypes.ERROR, message);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getVehicles();
  }, []);

  const columns = [
    {
      title: 'Serial',
      render: (text, record, index) => index + 1,
      align: 'center',
      width: '10%',
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      align: 'left',
      width: '10%',
      sorter: (a, b) => a.year.localeCompare(b.year),
    },
    {
      title: 'Make',
      dataIndex: 'make',
      key: 'make',
      align: 'left',
      width: '25%',
      sorter: (a, b) => a.make.localeCompare(b.make),
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
      align: 'left',
      width: '25%',
      sorter: (a, b) => a.model.localeCompare(b.model),
    },
    {
      title: 'VIN',
      dataIndex: 'vin',
      key: 'vin',
      align: 'left',
      width: '30%',
      sorter: (a, b) => a.vin.localeCompare(b.vin),
    },
  ];

  const handleSearch = (searchQuery) => {
    debounce(() => {
      // this check required so that when the input is cleared
      // we do not need to call the search driver api
      if (searchQuery) {
        searchVehicle(searchQuery);
        return;
      }
      getVehicles();
    }, 500)();
  };

  React.useEffect(() => {
    handleSearch(query);
  }, [query]);

  return (
    <div className="playground">
      <div className="overview_wrapper">
        {/* LOCATION AND REQUEST DRIVER REPORT WRAPPER  */}
        <div className="location_and_request_demo_wrapper p-2">
          <div className="filters_wrapper">
            <h4 className="page_header_text" style={{ padding: 0 }}>
              Vehicles Covered
            </h4>
          </div>
          <div
            className="filters_wrapper arrange_request_demo"
            style={{
              marginLeft: 'auto',
              marginRight: 0,
            }}
          >
            <Input
              placeholder="Search by VIN, Make, Model"
              prefix={<SearchOutlined />}
              suffix={loading ? <LoadingOutlined /> : null}
              onChange={(event) => setQuery(event.target.value)}
              allowClear
              style={{ width: '300px' }}
              size="large"
            />
          </div>
        </div>
        <div>
          {vehicles.length > 0 ? (
            <div>
              {loading ? (
                <LoadingOutlined />
              ) : query && query.length > 0 ? (
                <div
                  style={{
                    color: GREY_150,
                    fontSize: 'medium',
                    margin: '10px',
                  }}
                >
                  Found {vehicles.length} vehicle records matching{' '}
                  <span
                    style={{
                      color: 'black',
                      fontWeight: '800',
                    }}
                  >
                    "{query}"
                  </span>
                </div>
              ) : (
                <div
                  style={{
                    color: GREY_150,
                    fontSize: 'medium',
                    margin: '10px',
                  }}
                >
                  Total {vehicles.length} vehicles
                </div>
              )}
              <Table
                columns={columns}
                dataSource={vehicles}
                bordered
                scroll={{ y: 500 }}
                pagination={false}
              />
            </div>
          ) : (
            <span
              style={{
                color: GREY_150,
                fontSize: 'medium',
                margin: '10px',
              }}
            >
              0 Vehicles found
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
