import { LeftOutlined } from '@ant-design/icons';
import { Select, Spin } from 'antd';
import React, { useState } from 'react';
import { callGetApi } from '../../api/axios';
import '../../assets/style/authStyles.css';
import { GREY_150 } from '../../assets/style/colors';
import '../../assets/style/dashboard/dashboard.css';
import '../../assets/style/settings/settings.css';
import { debounce } from '../../utils/search';
import { toast, toastTypes } from '../../utils/toast';
import { getUser } from '../../utils/validateUserToken';

const { Option } = Select;
export default function Support() {
  const user = getUser();
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [selectedDriverId, setSelectedDriverId] = useState();
  async function searchDriver(query) {
    setLoading(true);
    try {
      const { data } = await callGetApi(
        `/v1/api/search-drivers?query=${encodeURIComponent(query)}`,
      );
      setDrivers(data);
    } catch (err) {
      const { message } = err?.response?.data || {};
      if (message) toast(toastTypes.ERROR, message);
    } finally {
      setLoading(false);
    }
  }

  const handleSearch = (query) => {
    debounce(() => {
      // this check required so that when the input is cleared
      // we do not need to call the search driver api
      if (query) {
        searchDriver(query);
        return;
      }
      setDrivers([]);
    }, 800)();
  };

  return (
    <div className="playground">
      {selectedDriverId ? (
        <div style={{ height: '100%' }}>
          <div
            className="py-3"
            style={{
              display: 'inline-flex',
              cursor: 'pointer',
            }}
            onClick={() => {
              setSelectedDriverId('');
              setDrivers([]);
            }}
          >
            <div className="d-flex align-items-center">
              <div className="px-1">
                <LeftOutlined />
              </div>
              <div className="px-1">
                <p className="go_back_text">back</p>
              </div>
            </div>
          </div>
          <div style={{ padding: '15px', height: '100%' }}>
            <iframe
              src={`https://frontende2e.zendrive.com/dashboard/driver/${selectedDriverId}?api_key=${user.api_key}`}
              style={{ border: 'none' }}
              height="100%"
              width="100%"
              title="zendrive-support"
            />
          </div>
        </div>
      ) : (
        <>
          <h4 className="page_header_text">Support</h4>
          <div className="search_driver_input_wrapper">
            <Select
              style={{ width: '100%' }}
              filterOption={false}
              showSearch
              placeholder="Search driver..."
              size="large"
              loading={loading}
              onChange={(value) => setSelectedDriverId(value)}
              onSearch={handleSearch}
              notFoundContent={loading ? <Spin size="small" /> : null}
            >
              {loading ? (
                <Option>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Spin size="small" />
                  </div>
                </Option>
              ) : (
                drivers.map((driver) => (
                  <Option key={driver?.id} value={driver?.id}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {`${driver.first_name} ${driver?.last_name}`}
                      <span
                        style={{
                          color: GREY_150,
                        }}
                      >
                        {driver?.id}
                      </span>
                    </div>
                  </Option>
                ))
              )}
            </Select>
          </div>
        </>
      )}
    </div>
  );
}
