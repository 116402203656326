import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Collapse, Space, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { ACCENT_2 } from '../../assets/style/colors';
import AssociatedDrivers from './AssociatedDrivers';
import './tags.scss';

const { Panel } = Collapse;

export default function TagListItem(props) {
  const {
    id,
    tag_name,
    updated_at,
    updated_by,
    driver_count,
    isDeletedTag,
    getTags,
    toggleEditTagModal,
    toggleDeleteTagModal,
  } = props;

  const [activePanelKey, setActivePanelKey] = React.useState('');

  const tagActions = () =>
    isDeletedTag ? null : (
      <Space size="middle">
        <div
          onClick={() =>
            toggleEditTagModal({
              id,
              tag_name,
              updated_at,
              updated_by,
            })
          }
        >
          <EditOutlined />
        </div>
        <div
          onClick={() =>
            toggleDeleteTagModal({
              id,
              tag_name,
              updated_at,
              updated_by,
              driver_count,
              getTags,
            })
          }
        >
          <DeleteOutlined style={{ color: ACCENT_2 }} />
        </div>
      </Space>
    );

  const tagSummary = () => (
    <Space
      direction="horizontal"
      style={{ width: '100%', justifyContent: 'space-between' }}
    >
      <div>
        <Typography.Title level={5}>
          {tag_name} ({driver_count ?? 0} drivers)
        </Typography.Title>
        <Typography.Text type="secondary">
          Created on {moment(updated_at).format('MM/DD/YYYY')} by {updated_by}
        </Typography.Text>
      </div>
      <Space direction="horizontal">{tagActions()}</Space>
    </Space>
  );

  const onCollapseChange = (key) => {
    setActivePanelKey(key ?? '');
  };

  return (
    <Collapse
      bordered
      onChange={onCollapseChange}
      className="tag-list-item-collapse"
    >
      <Panel
        header={tagSummary()}
        key={id}
        showArrow={!isDeletedTag}
        collapsible={isDeletedTag ? 'disabled' : ''}
      >
        {!isDeletedTag &&
          AssociatedDrivers({
            tagId: id,
            isActive: `${activePanelKey}` === `${id}`,
          })}
      </Panel>
    </Collapse>
  );
}
