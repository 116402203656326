import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Calendar, Col, Modal, Radio, Row, Typography } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { INTERVAL_TYPES } from '../../utils/dateFilter';
import './calendar.scss';
import {
  DATE_FILTER_LIMITS,
  GUTTER_BREAK_POINTS,
  NOTE_MESSAGES,
  generateQuartersData,
} from './utils';

function disableDate(currentDate, intervalType) {
  const today = moment().startOf('day');
  const { week, day } = DATE_FILTER_LIMITS.DRIVER;
  if (intervalType === INTERVAL_TYPES.week) {
    const lastValidWeekStart = moment()
      .startOf('isoweek')
      .subtract((week - 1) * 7, 'days');
    return (
      currentDate.startOf('day').diff(lastValidWeekStart, 'days') < 0 ||
      today.startOf('day').diff(currentDate, 'days') < 1
    );
  }

  if (intervalType === INTERVAL_TYPES.day) {
    const lastValidDayStart = moment()
      .startOf('day')
      .subtract(day - 1, 'days');
    return (
      currentDate.startOf('day').diff(lastValidDayStart, 'days') < 0 ||
      today.startOf('day').diff(currentDate, 'days') < 1
    );
  }

  if (intervalType === INTERVAL_TYPES.month) {
    const lastValidMonthStart = moment().startOf('day').subtract(180, 'days');

    return (
      currentDate.startOf('day').diff(lastValidMonthStart, 'days') < 0 ||
      today.startOf('day').diff(currentDate, 'days') < 1
    );
  }
  return null;
}

export default function CalenderModal(props) {
  const {
    visible,
    onSelectDate,
    intervalType: defaultIntervalType,
    onCancel,
    onUpdateIntervalType = () => {},
    start_date,
    end_date: endDate,
    enableQuarterFilter = false,
  } = props;
  const [intervalType, setIntervalType] = useState(defaultIntervalType);
  const [startDate, setStartDate] = useState(start_date);
  const currentYear = moment().year();
  const currentQuarterId = moment().quarter();
  const previousQuarterId = currentQuarterId === 1 ? 4 : currentQuarterId - 1;
  const [selectedQuater, setSelectedQuater] = useState(currentQuarterId);

  useEffect(() => {
    if (intervalType === INTERVAL_TYPES.quarter) {
      const quarterId = Math.ceil((moment(start_date).month() + 1) / 3);
      setSelectedQuater(quarterId);
    }
  }, [start_date, intervalType]);

  const renderCalendarHeader = ({ value }) => (
    <div style={{ margin: '1rem' }} className="d-flex justify-content-center">
      <Col
        span={16}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <LeftOutlined
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const newValue = value.clone();
            newValue.subtract(1, 'months');
            setStartDate(newValue.toDate());
          }}
        />
        <strong
          style={{
            color: 'rgb(0, 204, 155)',
            fontSize: '18px',
          }}
        >
          {value.format('MMMM YYYY').toUpperCase()}
        </strong>
        <RightOutlined
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const newValue = value.clone();
            newValue.add(1, 'months');
            setStartDate(newValue.toDate());
          }}
        />
      </Col>
    </div>
  );

  const generateMonthTiles = () => {
    // generates 12 months starting from now() - 6 months
    const lastValidMonthStart = moment().startOf('month').subtract(6, 'months');
    const futureValidMonthStart = moment().startOf('month').add(5, 'months');
    const monthsList = [];
    for (
      let i = lastValidMonthStart;
      i.isBetween(lastValidMonthStart, futureValidMonthStart, undefined, '[]');
      i = i.add(1, 'month')
    ) {
      monthsList.push(i.toDate());
    }
    return monthsList;
  };

  const renderMonthTile = (month, index) => {
    const monthObj = moment(month);
    const isDisabledClassName = disableDate(monthObj, intervalType)
      ? 'disabled-'
      : '';
    const isSelectedClassName =
      defaultIntervalType === INTERVAL_TYPES.month &&
      start_date === monthObj.format('yyyy-MM-DD')
        ? 'selected-month-tile'
        : null;

    const handleClick = () => {
      if (!disableDate(monthObj, intervalType)) {
        onSelectDate(month, intervalType);
        onUpdateIntervalType(intervalType);
      }
    };

    return (
      <Col
        id={`month-tile-${index + 1}`}
        key={month}
        className={`${isDisabledClassName}month-tile ${isSelectedClassName}`}
        onClick={handleClick}
      >
        {monthObj.format('MMM yyyy')}
      </Col>
    );
  };

  const renderMonthSelection = () => {
    const months = generateMonthTiles();
    return (
      <Row gutter={GUTTER_BREAK_POINTS}>{months.map(renderMonthTile)}</Row>
    );
  };

  const renderQuarterSelection = () => {
    const formattedQuarters = generateQuartersData(
      currentQuarterId,
      currentYear,
    );
    const isSelectable = (quarterId) =>
      quarterId === currentQuarterId || quarterId === previousQuarterId;

    const tileClassNames = (quarterId) => {
      if (
        defaultIntervalType === INTERVAL_TYPES.quarter &&
        quarterId === selectedQuater
      ) {
        return 'quarter-tile-selected';
      }
      if (isSelectable(quarterId)) {
        return 'quarter-tile-selectable';
      }
      return 'quarter-tile-disabled';
    };

    const getStartDateOfQuarter = (quarter) =>
      moment(`${quarter.year}-${quarter.months[0]}-01`, 'YYYY-MMM-DD').format(
        'YYYY-MM-DD',
      );

    const handleQuarterSelection = (quarter) => {
      if (isSelectable(quarter.id)) {
        setSelectedQuater(quarter.id);
        onSelectDate(getStartDateOfQuarter(quarter), intervalType);
        onUpdateIntervalType(intervalType);
      }
    };

    return (
      <div className="quarter-selection-container">
        {formattedQuarters.map((quarter) => (
          <div
            key={quarter.id}
            className={`quarter-tile ${tileClassNames(quarter.id)}`}
            onClick={() => handleQuarterSelection(quarter)}
          >
            <Typography.Title
              style={{
                color: 'inherit',
              }}
              level={5}
            >
              {quarter.label}
            </Typography.Title>
            <Typography.Text
              style={{
                color: 'inherit',
                fontSize: '12px',
              }}
            >
              {quarter.months.map((month) => month).join(', ')} {quarter.year}
            </Typography.Text>
          </div>
        ))}
      </div>
    );
  };

  const renderNote = () => {
    const msg = NOTE_MESSAGES[intervalType] || '';

    return <Typography.Text className="note-text">{msg}</Typography.Text>;
  };

  const renderDateCell = (date) => {
    if (
      defaultIntervalType === intervalType &&
      ((defaultIntervalType === INTERVAL_TYPES.week &&
        date.isBetween(moment(start_date), moment(endDate), undefined, '[]')) ||
        (defaultIntervalType === INTERVAL_TYPES.day &&
          date.format('yyy-MM-DD') === start_date))
    ) {
      return <div className="date-cell">{date.format('DD')}</div>;
    }
    return <div>{date.format('DD')}</div>;
  };

  const renderCalendar = () => (
    <Calendar
      headerRender={renderCalendarHeader}
      dateFullCellRender={renderDateCell}
      fullscreen={false}
      mode={intervalType === INTERVAL_TYPES.month ? 'year' : 'month'}
      disabledDate={(e) => disableDate(e, intervalType)}
      onSelect={(date) => {
        onSelectDate(date, intervalType);
        onUpdateIntervalType(intervalType);
      }}
      value={moment(startDate)}
    />
  );

  const handleIntervalChange = (interval) => {
    const formattedDate = moment().format('yyyy-MM-DD');
    setStartDate(intervalType === interval ? start_date : formattedDate);
    setIntervalType(interval);
  };

  const CalendarIntervalOptions = [
    {
      value: INTERVAL_TYPES.quarter,
      label: 'Quarter',
      conditional: enableQuarterFilter,
    },
    { value: INTERVAL_TYPES.month, label: 'Month', conditional: true },
    { value: INTERVAL_TYPES.week, label: 'Week', conditional: true },
    { value: INTERVAL_TYPES.day, label: 'Day', conditional: true },
  ];

  const renderBasedOnInterval = () => {
    switch (intervalType) {
      case INTERVAL_TYPES.month:
        return renderMonthSelection();
      case INTERVAL_TYPES.quarter:
        return enableQuarterFilter && renderQuarterSelection();
      default:
        return renderCalendar();
    }
  };

  return (
    <Modal
      open={visible}
      width={400}
      footer={null}
      closable={false}
      onCancel={onCancel}
    >
      <div className="calender_size">
        <div
          style={{ width: '100%' }}
          className="d-flex justify-content-center"
        >
          <Radio.Group
            onChange={(event) => handleIntervalChange(event.target.value)}
            defaultValue={intervalType}
            buttonStyle="solid"
          >
            {CalendarIntervalOptions.map(
              ({ conditional, value, label }) =>
                conditional && (
                  <Radio.Button value={value} key={value}>
                    {label}
                  </Radio.Button>
                ),
            )}
          </Radio.Group>
        </div>
        {renderNote()}
        {renderBasedOnInterval()}
      </div>
    </Modal>
  );
}

CalenderModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onUpdateIntervalType: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  end_date: PropTypes.any.isRequired,
};
