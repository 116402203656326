import { Select, Typography } from 'antd';
import React, { useContext, useMemo, useState } from 'react';
import useUserDetails from '../../hooks/useUserDetails';
import MixpanelContext from '../../services/tracking';

const selectOptions = [
  {
    label: 'All',
    value: 'ALL',
  },
  {
    label: 'P1',
    value: 1,
  },
  {
    label: 'P2',
    value: 2,
  },
  {
    label: 'P3',
    value: 3,
  },
];

export default function PeriodsFilter({ selectedPeriod, handlePeriodChange }) {
  const mixpanel = useContext(MixpanelContext);
  const { isPeriodsFilterEnabled, periodFilterPersistKey } = useUserDetails();

  if (!isPeriodsFilterEnabled) {
    return null;
  }

  const [selectedPeriods, setSelectedPeriods] = useState(selectedPeriod);

  const handleSelectionChange = (value) => {
    const diff = value.filter((period) => !selectedPeriods.includes(period));
    let applied = [];
    if (value.length === 0) {
      applied = [selectOptions[0].value];
    } else if (diff.includes(selectOptions[0].value)) {
      applied = diff;
    } else {
      applied = value.filter((tag) => tag !== selectOptions[0].value);
    }
    setSelectedPeriods(applied);
    handlePeriodChange(applied);
    sessionStorage.setItem(periodFilterPersistKey, JSON.stringify(applied));

    mixpanel.people.increment('# of Filters by Periods');
    mixpanel.track('Filtered by Periods', {
      applied,
    });
  };

  const tagRender = (tagRenderProps) => {
    const { label, value } = tagRenderProps;
    const allSelections = selectedPeriods;

    const isLastSelection = useMemo(() => {
      const lastSelection = allSelections[allSelections.length - 1];
      return value === lastSelection;
    }, [label, allSelections]);

    const isFirstSelection = useMemo(() => {
      const firstTag = allSelections[0];
      return value === firstTag;
    }, [label, allSelections]);

    return (
      <Typography.Text
        style={{
          color: '#569D77',
        }}
      >
        {isFirstSelection && 'Periods - '}
        {label}
        {!isLastSelection && ', '}
      </Typography.Text>
    );
  };

  return (
    <Select
      className="select-filter mr-3"
      showSearch={false}
      showArrow
      size="large"
      mode="multiple"
      placeholder="Periods - All"
      options={selectOptions}
      onChange={handleSelectionChange}
      value={selectedPeriods}
      defaultActiveFirstOption
      tagRender={tagRender}
    />
  );
}
