const sideBarConstants = {
  PROFILE: 'Profile',
  MANAGERS: 'Managers',
  DRIVERS: 'Drivers',
  VALIDATOR_TOOLS: 'Validator Tool',
  OVERVIEW: 'Overview',
  DRIVERS_DASHBOARD: 'Drivers',
  INSURANCE: 'Insurance',
  SUPPORT: 'Support',
  VEHICLES: 'Vehicles',
  INCIDENTS: 'Incidents',
  ADVANCED: 'Advanced',
  APP_STATUS: 'FM App Status',
  DRIVERS_PERFORMANCE: 'Drivers Performance',
  DRIVERS_MANAGEMENT: 'Drivers Management',
};

export default sideBarConstants;
