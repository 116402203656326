import { PERFORMANCE_GRADE_COLORS } from 'assets/style/colors';

function performanceGradeBreakdown({ data }) {
  const excellent = data?.excellent;
  const good = data?.good;
  const moderate = data?.moderate;
  const coaching = data?.coaching;

  return [
    {
      name: 'EXCELLENT',
      data: excellent,
      color: PERFORMANCE_GRADE_COLORS.excellent,
      fillOpacity: 0.2,
    },
    {
      name: 'GOOD',
      data: good,
      color: PERFORMANCE_GRADE_COLORS.good,
      fillOpacity: 0.5,
    },
    {
      name: 'MODERATE',
      data: moderate,
      color: PERFORMANCE_GRADE_COLORS.moderate,
      fillOpacity: 0.3,
    },
    {
      name: 'COACHING REQUIRED',
      data: coaching,
      color: PERFORMANCE_GRADE_COLORS.coaching,
      fillOpacity: 0.3,
    },
  ];
}
function performanceGradeSeries({ data }) {
  const excellent = data?.excellent?.map((e) => e.cpm);
  const good = data?.good?.map((e) => e.cpm);
  const moderate = data?.moderate?.map((e) => e.cpm);
  const coaching = data?.coaching_required?.map((e) => e.cpm);

  return [
    {
      name: 'EXCELLENT',
      data: excellent,
      color: PERFORMANCE_GRADE_COLORS.excellent,
      fillOpacity: 0.2,
    },
    {
      name: 'GOOD',
      data: good,
      color: PERFORMANCE_GRADE_COLORS.good,
      fillOpacity: 0.5,
    },
    {
      name: 'MODERATE',
      data: moderate,
      color: PERFORMANCE_GRADE_COLORS.moderate,
      fillOpacity: 0.3,
    },
    {
      name: 'COACHING REQUIRED',
      data: coaching,
      color: PERFORMANCE_GRADE_COLORS.coaching,
      fillOpacity: 0.3,
    },
  ];
}

const gradeCpmPercentages = (perfGroups) => {
  let cpmPercentages = [];
  if (perfGroups) {
    const coaching = perfGroups?.coaching_required;
    const excellent = perfGroups?.excellent;
    const good = perfGroups?.good;
    const moderate = perfGroups?.moderate;
    cpmPercentages = [
      {
        label: 'Coaching Required',
        value: coaching?.percent_cost,
        cpm: coaching?.cpm,
        color: PERFORMANCE_GRADE_COLORS.coaching,
        ...coaching,
      },
      {
        label: 'Moderate',
        value: moderate?.percent_cost,
        cpm: moderate?.cpm,
        color: PERFORMANCE_GRADE_COLORS.moderate,
        ...moderate,
      },
      {
        label: 'Good',
        value: good?.percent_cost,
        cpm: good?.cpm,
        color: PERFORMANCE_GRADE_COLORS.good,
        ...good,
      },
      {
        label: 'Excellent',
        value: excellent?.percent_cost,
        cpm: excellent?.cpm,
        color: PERFORMANCE_GRADE_COLORS.excellent,
        ...excellent,
      },
    ];
  }
  return cpmPercentages;
};

export {
  gradeCpmPercentages,
  performanceGradeBreakdown,
  performanceGradeSeries,
};
