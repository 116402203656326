import React from 'react';

function GradeSummary({ selectedPerfGroup, distanceUnitLabel }) {
  if (selectedPerfGroup) {
    return (
      <div className="summary_chart_body_graph_legends">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '1rem',
          }}
        >
          <div style={{ fontWeight: 700 }}>Cost per mile</div>
          <div>${selectedPerfGroup?.cpm}</div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '1rem',
          }}
        >
          <div style={{ fontWeight: 700 }}>% of fleet</div>
          <div>{selectedPerfGroup?.percent_count}%</div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '1rem',
          }}
        >
          <div style={{ fontWeight: 700 }}>Number of drivers</div>
          <div>{selectedPerfGroup?.num_drivers}</div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '1rem',
          }}
        >
          <div style={{ fontWeight: 700 }}>Average Score</div>
          <div>{selectedPerfGroup?.fm_score}</div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '1rem',
          }}
        >
          <div style={{ fontWeight: 700 }}>% of total {distanceUnitLabel}s</div>
          <div>{selectedPerfGroup?.percent_distance}%</div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '1rem',
          }}
        >
          <div style={{ fontWeight: 700 }}>% of total cost</div>
          <div>{selectedPerfGroup?.percent_cost}%</div>
        </div>
      </div>
    );
  }

  return <div style={{ width: '50%' }} />;
}

export default GradeSummary;
