import { callGetApi } from 'api/axios';
import moment from 'moment';
import queryString from 'query-string';
import { apiDateToShortDate } from 'utils/date';
import { getUser } from 'utils/validateUserToken';

function formatStats(data) {
  const {
    event_counts = {},
    info: { attributes, tags } = {},
    daily_score_history = [],
  } = data;

  const getDate = (date) =>
    date ? apiDateToShortDate(moment.utc(date).local().toDate(), true) : null;

  const info = {
    deviceOwnershipType: attributes?.device_ownership_type,
    deviceType: attributes?.device_type,
    deviceVersion: attributes?.device_version,
    fullName: attributes?.full_name,
    appId: data.application_id,
    totalTrips: data.count_trips,
    daysDriven: data.days_driven,
    milesDriven: data.distance_miles,
    userId: data.user_id,
    environmentRiskFactors: data.environmental_factors,
    firstDateOfTravel: getDate(data?.info?.first_trip_date),
    lastDateOfTravel: getDate(data?.info?.last_trip_date),
    tags,
  };

  return {
    data: {
      score: { score: data.fm_score, grade: data.fm_grade },
      info,
      eventDistribution: {
        hard_brake: event_counts.brakes || 0,
        overspeeding: event_counts.speeding || 0,
        phone_use: event_counts.phone_use || 0,
        rapid_acceleration: event_counts.accels || 0,
      },
      scoreHistory: daily_score_history,
      focus_areas: data.focus_areas,
    },
  };
}

function handleApiError(err) {
  const { fault } = err?.response?.data || {};
  return {
    isError: true,
    errorMessage: fault?.faultstring,
  };
}

async function getDriverStats({
  startDate,
  driverId,
  intervalType,
  trip_periods,
  location,
}) {
  try {
    const user = getUser();
    const qs = {
      apiKey: user.api_key,
      interval: intervalType,
      order_by: 'trips',
      start_date: startDate,
      location,
      trip_periods,
    };

    const response = await callGetApi(
      `/v2/api/driver/${encodeURIComponent(
        driverId,
      )}/score?${queryString.stringify(qs, {
        arrayFormat: 'comma',
      })}`,
    );

    return formatStats(response.data);
  } catch (err) {
    return handleApiError(err);
  }
}

async function getDriverStatsService(...args) {
  const driverStats = await getDriverStats(...args);

  return {
    isError: driverStats?.isError,
    errorMessage: 'Something went wrong.',
    data: {
      ...driverStats?.data,
      info: {
        ...driverStats?.data?.info,
      },
    },
  };
}
export default getDriverStatsService;
