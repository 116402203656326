import {
  DeleteOutlined,
  DownloadOutlined,
  RedoOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Input } from 'antd';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { callPostApi } from '../../api/axios';
import AppStatusContext from '../../contexts/AppStatusContext';
import MixpanelContext from '../../services/tracking';
import { Roles } from '../../utils/roles';
import { toast, toastTypes } from '../../utils/toast';
import { getUser } from '../../utils/validateUserToken';
import DeleteDrivers from './BulkActions/DeleteDrivers';
import EmailView from './BulkActions/EmailView';
import ResendInvites from './BulkActions/ResendInvites';

export default function DriverBulkActions() {
  const {
    searchTerm,
    handleSearch,
    driversList,
    selectedStatus,
    selectedRowKeys,
    fetchStatus,
    fetchDriversList,
    setSelectedRowKeys,
    isAllRowsSelected,
    fetchDriversBlobForCsv,
  } = useContext(AppStatusContext);
  const mixpanel = useContext(MixpanelContext);
  const user = getUser();
  const isSysAdmin = user?.roles?.includes(Roles.SYS_ADMIN);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const { total_count } = driversList;
  const [action, setAction] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const isRowsSelected = selectedRowKeys.length > 0;
  const numberOfDrivers = isAllRowsSelected
    ? total_count
    : selectedRowKeys.length;
  const IconStyles = {
    fontSize: '16px',
    marginBottom: '3px',
  };

  const handleAction = async (config) => {
    setIsLoading(true);
    try {
      await callPostApi('/v1/api/task/create', {
        task_type: config.task_type,
        context: isAllRowsSelected
          ? {
              category: params.selectedStatus,
              issues: params.issues,
              search_term: params.searchTerm,
            }
          : {
              driver_ids: selectedRowKeys,
            },
      });
      toast(toastTypes.SUCCESS, config.success);
    } catch (err) {
      toast(toastTypes.ERROR, config.failure);
    } finally {
      setIsLoading(false);
      setShowModal(false);
      setSelectedRowKeys([]);
      fetchStatus();
      fetchDriversList();
    }
  };

  const handleEmailView = async () => {
    setIsLoading(true);
    try {
      await callPostApi('/v1/api/devicestatus/email-fleet-managers', {
        prepared_url: window.location.href,
      });
      toast(toastTypes.SUCCESS, 'Email Sent');
    } catch (err) {
      toast(toastTypes.ERROR, 'Failed to send the email');
    } finally {
      setIsLoading(false);
      setShowModal(false);
      setSelectedRowKeys([]);
    }
  };

  const handleOnClick = (act) => {
    if (isRowsSelected || act === 'email_view') {
      setAction(act);
      setShowModal(!showModal);

      mixpanel.track('App Status Bulk Action', {
        action: act,
      });
    }
  };

  const generateFileName = () => {
    const dateFormat = moment().format('MM/DD/YY, HH:MM A');
    const fleetname = user?.company_name || '';
    let status = selectedStatus;
    switch (selectedStatus) {
      case 'all':
        status = 'AllDrivers';
        break;
      case 'healthy':
        status = 'AllSetDrivers';
        break;
      case 'unhealthy':
        status = 'ActionRequiredDrivers';
        break;
      case 'deleted':
        status = 'DeletedDrivers';
        break;
      default:
        status = '';
        break;
    }
    return `Driver_status_${fleetname}_${status}_${dateFormat}.csv`;
  };

  const fetchCsvData = async () => {
    fetchDriversBlobForCsv()
      .then((data) => {
        const contentType = 'text/csv';
        const csvFile = new Blob([data], { type: contentType });
        const csvURL = window.URL.createObjectURL(csvFile);
        const report_link = document.createElement('a');
        report_link.href = csvURL;
        report_link.setAttribute('download', generateFileName());
        report_link.click();
        toast(toastTypes.SUCCESS, 'Downloaded the current view');
      })
      .catch(() => {
        toast(toastTypes.ERROR, 'Failed to Download the current view');
      });

    mixpanel.track('App Status Bulk Action', {
      action: 'Download View',
    });
  };

  return (
    <div className="table_actions_wrapper">
      {action === 'delete_drivers' && (
        <DeleteDrivers
          handleAction={handleAction}
          numberOfDrivers={numberOfDrivers}
          isLoading={isLoading}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
      {action === 'resend_invites' && (
        <ResendInvites
          handleAction={handleAction}
          numberOfDrivers={numberOfDrivers}
          isLoading={isLoading}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
      {action === 'email_view' && (
        <EmailView
          handleEmailView={handleEmailView}
          isLoading={isLoading}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
      <Input
        placeholder="Search by driver id, name, email, phone number"
        prefix={<SearchOutlined />}
        defaultValue={searchTerm}
        onChange={(event) => handleSearch(event.target.value)}
        allowClear
        style={{ width: '30%' }}
        size="large"
      />
      {selectedStatus !== 'deleted' ? (
        <div className="bulk_actions actions_active">
          {isRowsSelected && (
            <div
              className="action"
              onClick={() => handleOnClick('resend_invites')}
            >
              <RedoOutlined rotate="270" style={IconStyles} />
              Resend Invites
            </div>
          )}
          {isSysAdmin && isRowsSelected && (
            <div
              className="action"
              onClick={() => handleOnClick('delete_drivers')}
            >
              <DeleteOutlined style={IconStyles} />
              Delete
            </div>
          )}

          <div
            className="action"
            style={{ color: 'green', cursor: 'pointer' }}
            onClick={() => fetchCsvData()}
          >
            <DownloadOutlined style={IconStyles} />
            {` Download`}
          </div>
        </div>
      ) : null}
    </div>
  );
}
