import React, { useContext, useEffect, useMemo, useState } from 'react';
import { callGetApi, callPostApi } from '../../api/axios';
import AppStatusContext from '../../contexts/AppStatusContext';
import MixpanelContext from '../../services/tracking';
import { debounce } from '../../utils/search';
import { fetchTags } from '../TagsManagement/tagService';

import DriverTableSelectAll from '../Drivers/DriversManagement/DriverTableSelectAll';
import './appStatus.scss';
import AppStatusHeader from './AppStatusHeader';
import DriverBulkActions from './DriverBulkActions';
import DriverTable from './DriverTable';
import DriverTableFooter from './DriverTableFooter';

export default function AppStatus() {
  const mixpanel = useContext(MixpanelContext);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const [allTags, setAllTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDriversLoading, setIsDriversLoading] = useState(false);
  const [status, setStatus] = useState({});
  const [driversList, setDriversList] = useState({});
  const [showAddDriver, setShowAddDriver] = useState(false);
  const [issues, setIssues] = useState(
    params?.issues?.length > 0 ? params?.issues?.split(',') : [],
  );
  const [selectedTags, setSelectedTags] = useState(
    params?.tags?.length > 0 ? params?.tags?.split(',') : [],
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(
    params.selectedStatus || 'all',
  );
  const [searchTerm, setSearchTerm] = useState(params.searchTerm || '');
  const [activity, setActivity] = useState(params.activity || '');
  const hasFiltersApplied =
    !!issues || !!searchTerm || !!activity || !!selectedTags;

  const fetchStatus = async () => {
    setLoading(true);
    try {
      const { data } = await callGetApi('/v1/api/devicestatus/overview');
      setStatus(data);
    } finally {
      setLoading(false);
    }
  };

  const fetchDriversList = async (isLoadMore = false) => {
    setIsDriversLoading(true);
    try {
      const { data } = await callPostApi('/v1/api/devicestatus/list', {
        category: selectedStatus,
        limit: 25,
        offset: isLoadMore ? driversList?.result?.length : 0,
        issues,
        search_term: searchTerm,
        activity,
        tags: selectedTags ?? [],
      });

      setDriversList((current) => ({
        result: isLoadMore
          ? [...current.result, ...data.result]
          : [...data.result],
        total_count: data.total_count,
      }));
    } catch (err) {
      setDriversList({ result: [] });
    } finally {
      setIsDriversLoading(false);
    }
  };

  const fetchDriversBlobForCsv = async () => {
    const { data } = await callPostApi('/v1/api/devicestatus/download-view', {
      category: selectedStatus,
      issues,
      search_term: searchTerm,
    });
    return data;
  };

  async function getTags() {
    const { data } = await fetchTags('active');
    setAllTags(data);
  }

  useEffect(() => {
    getTags();
    fetchStatus();
  }, []);

  useEffect(() => {
    mixpanel.track('View Page', {
      pageName: 'Fairmatic App Status Page',
    });
  }, [mixpanel]);

  const handleSearch = (term) => {
    debounce(() => {
      setSearchTerm(term.trim());
    }, 800)();
  };

  const handlePagination = () => {
    fetchDriversList(true);
    mixpanel.track('App Status Pagination', {
      loadMore: true,
    });
  };

  const toggleAddDriver = () => {
    setShowAddDriver(!showAddDriver);
  };

  const handleStatusSelection = (currentStatus) => {
    setDriversList({
      status: [],
    });
    setIssues([]);
    setSearchTerm('');
    setSelectedRowKeys([]);
    setSelectedStatus(currentStatus);
    setSelectedTags([]);
  };

  const updateUrl = (param, value) => {
    const searchParams = new URLSearchParams(window.location.search);
    if (value === undefined) {
      searchParams.delete(param);
    } else {
      searchParams.set(param, value);
    }
    const newRelativePathQuery = `${
      window.location.pathname
    }?${searchParams.toString()}`;
    window.history.pushState(null, '', newRelativePathQuery);
  };

  const handleFilterChange = (filters) => {
    setIssues(filters.issues);
    setActivity(filters.activity);
    setSelectedTags(filters.tags);
  };

  useEffect(() => {
    updateUrl('searchTerm', searchTerm || '');
    updateUrl('selectedStatus', selectedStatus || 'all');
    updateUrl('issues', issues || '');
    updateUrl('activity', activity || '');
    updateUrl('tags', selectedTags || '');
    setSelectedStatus(selectedStatus);
    setSearchTerm(searchTerm);
    fetchDriversList();
    mixpanel.track('App Status State Change', {
      searchTerm,
      selectedStatus,
      issues,
    });
  }, [searchTerm, selectedStatus, issues, activity, selectedTags]);

  const ContextValues = useMemo(() => ({
    allTags,
    status,
    loading,
    selectedStatus,
    driversList,
    searchTerm,
    selectedRowKeys,
    setSelectedRowKeys,
    toggleAddDriver,
    handleSearch,
    handlePagination,
    handleFilterChange,
    handleStatusSelection,
    fetchDriversBlobForCsv,
    isDriversLoading,
    fetchStatus,
    fetchDriversList,
    hasFiltersApplied,
  }));

  return (
    <div className="playground">
      <AppStatusContext.Provider value={ContextValues}>
        <AppStatusHeader />
        <div style={{ height: '100vh' }}>
          <div className="driver_table_wrapper">
            <DriverBulkActions />
            <DriverTableSelectAll />
            <div className="status_table_wrapper">
              <DriverTable />
              <DriverTableFooter />
            </div>
          </div>
        </div>
      </AppStatusContext.Provider>
    </div>
  );
}
