import { getUser } from 'utils/validateUserToken';

export default function useUserDetails() {
  const user = getUser();
  const isPeriodsFilterEnabled = user?.is_fleet_tnc_odnc || false;
  const periodFilterPersistKey = `${user?.company_name} Periods` || 'Periods';

  return {
    user,
    isPeriodsFilterEnabled,
    periodFilterPersistKey,
  };
}
