import { ArrowRightOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import MixpanelContext from '../../../services/tracking';
import { Roles } from '../../../utils/roles';
import { getUser } from '../../../utils/validateUserToken';
import ApiKeyInfoCard from './ApiKeyInfoCard';

export default function Advanced() {
  const user = getUser();
  const mixpanel = useContext(MixpanelContext);
  const [toggleSidebarOnMobile, setToggleSidebarOnMobile] = useState(false);

  useEffect(() => {
    mixpanel.track('View Page', {
      pageName: 'Advanced Settings Page',
    });
  }, [mixpanel]);

  return (
    <div className="playground">
      <div className="ham_burgur">
        <div className="han_burgur_icon">
          <ArrowRightOutlined
            onClick={() => setToggleSidebarOnMobile(!toggleSidebarOnMobile)}
          />
        </div>
      </div>
      {/* LABEL  */}
      <div
        className="d-flex align-items-center"
        style={{ paddingBottom: '10px' }}
      >
        <h4 className="page_header_text">Advanced Settings</h4>
      </div>
      {/* API KEY INFO CARD  */}
      {!user?.roles?.includes(Roles.CUSTOMER_SUPPORT) && <ApiKeyInfoCard />}
    </div>
  );
}
