import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { navigate } from 'gatsby-link';
import React from 'react';

export default function EditDriversHeader({
  handleDiscard,
  handlePublish,
  hasChanges = false,
}) {
  return (
    <div className="page_header">
      <div className="page_header_div" style={{ fontSize: 20 }}>
        <ArrowLeftOutlined
          className="back_arrow"
          color="black"
          onClick={() => navigate(-1)}
        />
        Edit Drivers
        <div className="edit_driver_info_text">
          You can only edit a driver&apos;s First Name, Last Name and Device
          Ownership
        </div>
      </div>
      <span>
        <Button
          className="mr-3 br-5 discard_button"
          onClick={handleDiscard}
          disabled={!hasChanges}
        >
          Discard Changes
        </Button>
        <Button
          className="br-5"
          onClick={handlePublish}
          type="primary"
          size="medium"
          key="submit"
          htmlType="submit"
          disabled={!hasChanges}
        >
          Review & Publish
        </Button>
      </span>
    </div>
  );
}
