import { Select, Spin } from 'antd';
import React, { useMemo, useState } from 'react';
import { debounce } from '../../utils/search';

function DebounceSelect({ fetchOptions, debounceTimeout = 500, ...props }) {
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(false);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      setDrivers([]);
      setLoading(true);
      fetchOptions(value).then((newOptions) => {
        setDrivers(newOptions);
        setLoading(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      {...props}
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      loading={loading}
      notFoundContent={loading ? <Spin size="small" /> : 'No Drivers Found'}
      options={drivers}
    />
  );
}

export default DebounceSelect;
