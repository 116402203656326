import React from 'react';

export default function UseMobileSvg({
  width = '24',
  height = '24',
  className = '',
}) {
  return (
    <svg
      className={className}
      style={{ width, height }}
      focusable="false"
      viewBox="0 0 24 24"
      color="rgb(18,32,56)"
      aria-hidden="true"
      role="presentation"
    >
      <path d="M12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 Z M15.6033962,4.5 L8.39660377,4.5 C7.90075472,4.5 7.5,4.89500801 7.5,5.38619189 L7.5,18.6138081 C7.5,19.1015571 7.90075472,19.5 8.39660377,19.5 L15.6033962,19.5 C16.0992453,19.5 16.5,19.104992 16.5,18.6138081 L16.5,5.38275704 C16.5,4.89500801 16.0992453,4.5 15.6033962,4.5 Z M12,18.75 C11.625974,18.75 11.325,18.4155844 11.325,18 C11.325,17.5844156 11.625974,17.25 12,17.25 C12.374026,17.25 12.675,17.5844156 12.675,18 C12.675,18.4155844 12.374026,18.75 12,18.75 Z M15.746777,16.5 L8.25,16.5 L8.25,6 L15.75,6 L15.75,16.5 L15.746777,16.5 Z" />
    </svg>
  );
}
