import React from 'react';

export default function OverSpeedSvg({
  width = '24',
  height = '24',
  className = '',
}) {
  return (
    <svg
      className={className}
      style={{ width, height }}
      focusable="false"
      viewBox="0 0 24 24"
      color="rgb(18,32,56)"
      aria-hidden="true"
      role="presentation"
    >
      <path d="M3.63349437e-05,11.9999999 C0.00412876494,13.6894081 0.353824211,15.3285307 1,16.7699998 L3.95,16.7699998 C3.45,14.0199998 5,12.4699998 7.35,12.4699998 C9.7,12.4699998 11.65,14.0199998 12.4,16.9199998 L13.9,16.9199998 C13.9,16.9199998 13.15,12.3199998 11.6,9.71999979 C6.95,7.06999979 5.45,6.01999979 1.8,5.71999979 C1.55,6.11999979 1.35,6.51999979 1.15,6.91999979 C2.1,7.06999979 2.95,7.71999979 3.4,8.61999979 L4.35,10.5199998 L0.1,10.4699998 C0.0538208107,10.9317917 0.00764162147,11.3935836 0.000853604294,11.8553755 C0.0783362877,5.29459836 5.42085829,0 12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 Z M10.830355,19.262465 C12.0019166,18.0909034 11.780311,15.969821 10.335385,14.524895 C8.89045898,13.079969 6.76937664,12.8583634 5.597815,14.029925 C4.42625336,15.2014866 4.64785898,17.322569 6.092785,18.767495 C7.53771102,20.212421 9.65879336,20.4340266 10.830355,19.262465 Z M17.9,5.6 L6.85,5.6 C7.55,5.85 8.2,6.15 8.95,6.55 L18.65,6.55 L17.9,5.6 Z M14.25,10.2 C14.4,10.5 14.55,10.85 14.65,11.15 L18.95,11.15 L18.25,10.2 L14.25,10.2 Z M20.65,7.9 L11.35,7.9 C11.85,8.2 12.4,8.5 13,8.85 L21.35,8.85 L20.65,7.9 Z" />
    </svg>
  );
}
