import { CloudUploadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import { validateToken } from '../../../api/axios';
import { toast, toastTypes } from '../../../utils/toast';
import ValidatorToolInfo from './ValidatorToolInfo';
import {
  defaultTripDetails,
  driverArgs,
  headers,
  resultsMap,
} from './constants';

const text = (value) => <span>{value}</span>;

export default function ValidatorToolCard() {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [csvData, setCsvData] = useState([]);

  const updateEntries = (results, result, reason) => {
    const { drivers } = results[result];
    if (drivers.length > 0) {
      return drivers.map((driverId) => ({
        ...defaultTripDetails,
        driver_id: driverId ?? '',
        missmatch_reason: reason,
      }));
    }

    return [];
  };

  async function handleDropFile(event) {
    event.preventDefault();
    if (event.type === 'drop') {
      const files = event.dataTransfer.files;
      if (files.length > 1) {
        toast(toastTypes.ERROR, "Drop only one file at a time");
      }
      if (files.length == 1) {
        handleUploadFile(files[0]);
      }
    }
  }

  async function handleUploadFile(file) {
    const token = validateToken();

    // generating the form data
    const fileDate = new FormData();
    fileDate.append('file', file);

    // making api call to get the results
    setLoading(true);
    try {
      const { data } = await axios({
        method: 'post',
        url: `/v1/api/validate_trips`,
        data: fileDate,
        headers: { Authorization: `Bearer ${token}` },
      });
      const { results } = data;
      if (results) {
        setUserData(results);
        // use this array for values of csv file
        const entries = [];

        Object.keys(results).forEach((result) => {
          // check if the value is for
          // missing drivers,
          // missing alias drivers,
          // automatic trip drivers if yes than just push driver id and reason
          driverArgs.forEach(([res, reason]) => {
            if (result === res) {
              entries.push(...updateEntries(results, res, reason));
            }
          });

          const { trips = [] } =
            result !== 'missing_drivers' ? results[result] : {};

          // if trips array is present and has length > 0
          if (trips && trips.length > 0) {
            trips.forEach((trip) => {
              const {
                driver_id,
                zd_start_timestamp,
                zd_end_timestamp,
                start_timestamp,
                end_timestamp,
                match_percent,
                zd_period,
                period,
              } = { ...defaultTripDetails, ...trip };

              entries.push({
                driver_id,
                zd_start_timestamp,
                zd_end_timestamp,
                start_timestamp,
                end_timestamp,
                match_percent,
                zd_period,
                period,
                missmatch_reason: resultsMap[result].label,
              });
            });
          }
        });
        setCsvData(entries);
      }
      toast(toastTypes.ERROR, data.message);
    } catch (err) {
      const { message } = err?.response?.data || {};
      if (message) toast(toastTypes.ERROR, message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="validator_tool_wrapper_card">
      <div className="validator_tool_card_header">
        <h4 className="validator_tool_card_header_text">
          Fairmatic SDK Integration Validation Tool
        </h4>
      </div>
      <div className="validator_tool_card_body">
        {userData ? (
          <div>
            {Object.keys(userData).map((result) =>
              resultsMap[result] ? (
                <div className="d-flex" key={result}>
                  <div className="d-flex align-items-center">
                    <p className="validator_tool_card_header_subText">
                      {resultsMap[result].label}
                    </p>
                    <div className="px-1">
                      <Tooltip
                        placement="right"
                        title={text(resultsMap[result].tooltip_text)}
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </div>
                  </div>
                  <p
                    className="validator_tool_card_header_subText"
                    style={{ marginLeft: 'auto' }}
                  >
                    {userData[result].count}
                    {userData[result].percent > 0
                      ? ` (${userData[result].percent}%)`
                      : ''}
                  </p>
                </div>
              ) : null,
            )}
            <div className="p-3 d-flex align-items-center">
              <div className="px-2">
                <Button type="primary">
                  <CSVLink
                    data={csvData}
                    headers={headers}
                    filename="detailed_fairmatic_report.csv"
                  >
                    Download Detailed Report
                  </CSVLink>
                </Button>
              </div>
              <div className="px-2">
                <Button type="primary" onClick={() => setUserData(null)}>
                  start over
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div class="validator_tool_csv_upload_area"
              onDragOver={handleDropFile}
              onDrop={handleDropFile}
            >
              <p>
                Drag and drop your CSV file here or click to upload. 
                Ensure that the file is within 1MB file size and only contains trips within a 1 week date range.
              </p>

              <input 
                type="file" 
                accept=".csv"
                style={{display: 'none'}}
              />

              <Button
                type="primary"
                icon={<CloudUploadOutlined />}
                loading={loading}
                disabled={loading}
              >
                <label htmlFor="upload-file">
                  &nbsp; Upload File
                  <input
                    type="file"
                    style={{
                      visibility: 'hidden',
                      display: 'none',
                    }}
                    id="upload-file"
                    name="upload-file"
                    accept=".csv"
                    onChange={(event) =>
                      handleUploadFile(event.target.files[0])
                    }
                  />
                </label>
              </Button>
            </div>

            <p className="validator_tool_card_header_subText">
              Use the structure given below to generate the report in a 
              {' '}
              <a
                href="https://fairamtic-public-documentation.s3.us-west-2.amazonaws.com/sample-validation-input.csv"
                download
              >
                .CSV file format
              </a>
              . 
              For more details, follow this{' '}
              <a href="https://docs.fairmatic.com/validation/guide">
                guide
              </a>
              .
              <br/>
              <br/>
            </p>

            <ValidatorToolInfo />          
            
          </div>
        )}
      </div>
    </div>
  );
}
