import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { GREY_200 } from 'assets/style/colors';
import React from 'react';

const TooltipText = function () {
  return (
    <span>
      The average driver score is an aggregate of the risk factors that are
      within a driver’s control.
      <br />
      <br /> It is calculated using a weighted average for a given time period
      across all drivers of the fleet. The score can range between 60 to 100. A
      higher score indicates safer driving performance.
    </span>
  );
};
const DriverScoreCardTooltip = function ({ data }) {
  return (
    <Tooltip title={TooltipText}>
      <InfoCircleOutlined
        style={{
          marginLeft: '10px',
          color: GREY_200,
          fontSize: '16px',
        }}
      />
    </Tooltip>
  );
};

export default DriverScoreCardTooltip;
