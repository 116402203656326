import { Select, Typography } from 'antd';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { fetchTags } from '../../modules/TagsManagement/tagService';
import MixpanelContext from '../../services/tracking';

function TagsFilter(props) {
  const { handleTagChange } = props;
  const mixpanel = useContext(MixpanelContext);

  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const tagsWithAllAndNone = [
    { id: '', tag_name: 'All' },
    { id: -1, tag_name: 'None' },
    ...tags,
  ];

  const selectOptions = tagsWithAllAndNone.map((tag) => ({
    label: tag.tag_name,
    value: tag.id,
  }));

  useEffect(async () => {
    setIsLoading(true);
    const { data } = await fetchTags('active');
    setTags(data);
    setIsLoading(false);
  }, []);

  const [selectedTags, setSelectedTags] = useState([selectOptions[0].value]);

  const handleSelectionChange = (value) => {
    // check if there is any item other than 0 then remove 0 from current selection
    const diff = value.filter((tag) => !selectedTags.includes(tag));
    let applied = [];
    if (value.length === 0) {
      applied = selectOptions[0].value;
    } else if (diff.includes(selectOptions[0].value)) {
      applied = diff;
    } else {
      applied = value.filter((tag) => tag !== selectOptions[0].value);
    }
    setSelectedTags(applied);
    handleTagChange(applied);
    mixpanel.track('Tags Filter Applied');
  };

  const tagRender = (tagRenderProps) => {
    const { label, value } = tagRenderProps;
    const allTags = selectedTags || [''];

    const isLastTag = useMemo(() => {
      const lastTag = allTags[allTags.length - 1];
      return value === lastTag;
    }, [label, allTags]);

    const isFirstTag = useMemo(() => {
      const firstTag = allTags[0];
      return value === firstTag;
    }, [label, allTags]);

    return (
      <Typography.Text
        style={{
          color: '#569D77',
        }}
      >
        {isFirstTag && 'Tags - '}
        {label}
        {!isLastTag && ', '}
      </Typography.Text>
    );
  };

  return (
    <Select
      showSearch
      showArrow
      size="large"
      className="select-filter mr-3"
      mode="multiple"
      loading={isLoading}
      placeholder="Tags - All"
      options={selectOptions}
      onChange={handleSelectionChange}
      value={selectedTags}
      defaultActiveFirstOption
      tagRender={tagRender}
      filterOption={(input, option) =>
        (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
      }
    />
  );
}

export default TagsFilter;
