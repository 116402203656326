import React from 'react';

export default function DriverTableSelectAll(props) {
  const { selectedRowKeys = [], setSelectedRowKeys } = props;

  const pluralize = (count, noun, suffix = 's') =>
    `${count} ${noun}${count !== 1 ? suffix : ''}`;

  const handleClearAll = () => {
    setSelectedRowKeys([]);
  };

  return (
    <div>
      {selectedRowKeys.length > 0 && (
        <div className="selected_status">
          <span className="selected_text">
            {`${pluralize(selectedRowKeys.length, 'driver')} selected`}
          </span>
          <span className="selected_link" onClick={handleClearAll}>
            Clear Selection
          </span>
        </div>
      )}
    </div>
  );
}
