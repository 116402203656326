import { callGetApi } from 'api/axios';
import queryString from 'query-string';
import { getUser } from 'utils/validateUserToken';

const fetchLocationsService = async ({ queryParams }) =>
  new Promise(async (resolve) => {
    const user = getUser();
    try {
      queryParams = {
        ...queryParams,
        apiKey: user.api_key,
      };
      const { data } = await callGetApi(
        `/v2/api/locations?${queryString.stringify(queryParams)}`,
      );
      let locs = data?.locations || [];
      try {
        let allIndex = -1;
        let noneIndex = -1;
        locs = locs
          ?.sort((a, b) => a.localeCompare(b))
          .map((key, index) => {
            const obj = {
              label: key,
              value: key,
            };
            if (key === 'ALL') {
              obj.value = null;
              allIndex = index;
              obj.label = 'All locations';
            }
            if (key === 'US-None') {
              noneIndex = index;
              obj.label = 'US-Unknown';
            }

            return obj;
          });
        if (allIndex > 0) {
          const el = locs.splice(allIndex, 1);
          locs = [...el, ...locs];
        }
        if (noneIndex > -1) {
          const el = locs.splice(noneIndex, 1);
          locs = [...locs, ...el];
        }
      } catch (e) {
        resolve({
          data: locs,
        });
      }
      resolve({
        data: locs,
      });
    } catch (err) {
      const { fault } = err?.response?.data || {};
      resolve({
        isError: true,
        errorMessage: fault?.faultstring,
      });
    }
  });
export { fetchLocationsService };
