import { Button, Card, Input, Space, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { callGetApi } from '../../api/axios';
import { MAX_TAGS, TAG_LENGTH } from '../../constants';
import DebounceSelect from '../../modules/TagsManagement/SelectDrivers';
import {
  associatedDrivers,
  createTag,
  editTag,
} from '../../modules/TagsManagement/tagService';
import MixpanelContext from '../../services/tracking';
import { DriverResultLabel } from '../../utils/common';
import { toast, toastTypes } from '../../utils/toast';

const { Text, Title, Paragraph } = Typography;

export default function TagEditor(props) {
  const boxStyle = {
    width: '100%',
    border: '1px solid #f0f0f0',
    borderRadius: '4px',
  };

  const inputStlye = {
    width: '50%',
    marginBottom: 8,
  };

  const { tagToEdit, handleTagChange, onCancel, getTags } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [tagName, setTagName] = useState(tagToEdit ? tagToEdit.tag_name : '');
  const [drivers, setDrivers] = useState([]);
  const mixpanel = useContext(MixpanelContext);

  useEffect(async () => {
    if (tagToEdit) {
      const { data } = await associatedDrivers(tagToEdit.id);
      setDrivers(
        data.drivers.map((driver) => ({
          label: driver.alias,
          value: driver.id,
        })),
      );
    }
  }, [tagToEdit]);

  const validateTagName = (name) => {
    setTagName(name);

    // if tag name is empty, set error
    if (!name) {
      setErrorMessage('Tag name is required');
      return;
    }

    // if tag name is longer than 32 characters, set error
    if (name.length > TAG_LENGTH) {
      setErrorMessage(`Tag name should be less than ${TAG_LENGTH} characters`);
      return;
    }

    if (name.includes(' ')) {
      setErrorMessage('Tag name should not contain spaces');
      return;
    }

    // if tag contains special characters other than underscore, hyphen, space, ampersand and @, set error
    if (!/^[a-zA-Z0-9_\-&@ ]+$/.test(name)) {
      setErrorMessage(
        'Tag name can only contain letters, numbers, -, _, & and @',
      );
      return;
    }

    setErrorMessage('');
  };

  const handleOk = async () => {
    setLoading(true);
    try {
      const driverIds = drivers.map((driver) => driver.value);
      const { data } = tagToEdit
        ? await editTag(tagToEdit.id, tagName, driverIds)
        : await createTag(tagName, driverIds);

      const tag = tagToEdit ? { ...tagToEdit, name: tagName } : data;
      const successMessage = tagToEdit
        ? 'Tag updated successfully'
        : 'Tag created successfully';
      toast(toastTypes.SUCCESS, successMessage);
      handleTagChange(tag);
      setTagName('');
      getTags();

      mixpanel.track('Tag Created/Updated', {
        tagName,
        driverIds,
      });
    } catch (error) {
      setErrorMessage(`Tag name ${tagName} already exists`);
    } finally {
      setLoading(false);
    }
  };

  async function fetchDrivers(query) {
    const response = await callGetApi(
      `/v2/api/drivers?query=${encodeURIComponent(query)}`,
    );
    const fetchedDrivers = response.data.drivers;

    return fetchedDrivers.length > 0
      ? fetchedDrivers.map((driver) => ({
          label: DriverResultLabel(driver),
          value: driver.id,
          disabled: driver.tags?.length >= MAX_TAGS,
        }))
      : [];
  }

  return (
    <Card direction="vertical" style={boxStyle}>
      <Title level={5}>Name your tag*</Title>
      <Paragraph type="secondary">
        The tag name can describe Branch Location, Phone OS, Car Ownership,
        Employee Department, Driver Type etc.
      </Paragraph>
      <Input
        placeholder="Tag Name"
        value={tagName}
        style={inputStlye}
        onChange={(e) => {
          validateTagName(e.target.value);
        }}
        status={errorMessage ? 'error' : ''}
      />
      {errorMessage && (
        <div>
          <Text type="danger">{errorMessage}</Text>
        </div>
      )}

      <Title level={5} style={{ marginTop: '32px' }}>
        Add Drivers
      </Title>
      <Paragraph type="secondary">
        Selected drivers will get added with this tag
      </Paragraph>
      <DebounceSelect
        mode="multiple"
        value={drivers}
        placeholder="Add drivers"
        fetchOptions={fetchDrivers}
        onChange={setDrivers}
        style={inputStlye}
      />
      <Space
        direction="horizontal"
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '32px',
        }}
      >
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          disabled={errorMessage || !tagName}
          type="primary"
          onClick={handleOk}
          loading={loading}
        >
          Save
        </Button>
      </Space>
    </Card>
  );
}
