import { Select } from 'antd';
import React, { useContext } from 'react';
import MixpanelContext from '../../services/tracking';

export default function LocationWidget({
  locations = [],
  selectedLocation = null,
  onSetLocation,
}) {
  const mixpanel = useContext(MixpanelContext);
  const { Option } = Select;
  const handleLocationSelection = (value) => {
    onSetLocation(value);
    mixpanel.people.increment('# of Filters by Location');
    mixpanel.track('Filter a report by location', {
      value,
    });
  };

  return (
    <Select
      size="large"
      placeholder="Location"
      className="mr-3 location_filter"
      defaultValue={selectedLocation === 'null' ? null : selectedLocation}
      optionLabelProp="label"
      onChange={handleLocationSelection}
      getPopupContainer={(trigger) => trigger.parentElement}
    >
      {locations.map((location) => (
        <Option
          key={location.label}
          value={location.value}
          label={`Location - ${location.label}`}
        >
          {location.label}
        </Option>
      ))}
    </Select>
  );
}
