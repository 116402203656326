import { DownOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import MixpanelContext from '../../services/tracking';
import CalendarModal from '../modals/calendar';

const getSelectedDate = (startDate, endDate, intervalType) => {
  if (intervalType === 'quarter') {
    return `Quarter ${moment(startDate).quarter()} ${moment(startDate).format(
      'YYYY',
    )}`;
  }
  if (intervalType === 'month') {
    return moment(startDate).format('MMM yyyy');
  }
  if (intervalType === 'week') {
    return `${moment(startDate).format('DD MMM')} - ${moment(endDate).format(
      'DD MMM',
    )}`;
  }
  return moment(startDate).format('DD MMM');
};

export default function DateWidget(props) {
  const {
    startDate,
    endDate,
    intervalType,
    onSelectDate,
    onUpdateIntervalType,
    enableQuarterFilter = false,
  } = props;
  const [toggleCalenderModal, setToggleCalenderModal] = useState(false);
  const mixpanel = useContext(MixpanelContext);

  return (
    <>
      <Button
        id="date_widget"
        className="date_wrapper"
        onClick={() => {
          setToggleCalenderModal(true);
          mixpanel.track('Date widget clicked');
        }}
        size="large"
      >
        <span>Date - {getSelectedDate(startDate, endDate, intervalType)}</span>

        <DownOutlined className="date_selector_icon" />
      </Button>
      {toggleCalenderModal && (
        <CalendarModal
          start_date={startDate}
          visible={toggleCalenderModal}
          intervalType={intervalType}
          onCancel={() => setToggleCalenderModal(false)}
          onSelectDate={(date, inputIntervalType) => {
            onSelectDate(date, inputIntervalType);
            setToggleCalenderModal(false);
            mixpanel.track('Date widget date selected', {
              date: moment(date).format('DD MMM YYYY'),
              intervalType: inputIntervalType,
            });
          }}
          onUpdateIntervalType={onUpdateIntervalType}
          end_date={endDate}
          enableQuarterFilter={enableQuarterFilter}
        />
      )}
    </>
  );
}
DateWidget.propTypes = {
  startDate: PropTypes.string.isRequired, // Need to confirm further
  endDate: PropTypes.string.isRequired, // Need to confirm further
  intervalType: PropTypes.string.isRequired,
  onSelectDate: PropTypes.func.isRequired,
  onUpdateIntervalType: PropTypes.func,
};
