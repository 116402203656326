import {
  ArrowLeftOutlined,
  ImportOutlined,
  PlusOutlined,
  RightCircleOutlined,
} from '@ant-design/icons';
import { Button, Card, Form, Space, Table, Typography } from 'antd';
import { navigate } from 'gatsby-link';
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { VList, scrollTo } from 'virtuallist-antd';
import { callPostApi } from '../../../api/axios';
import MixpanelContext from '../../../services/tracking';
import { toast, toastTypes } from '../../../utils/toast';
import { getUser } from '../../../utils/validateUserToken';
import { fetchTags } from '../../TagsManagement/tagService';
import './AddDriver.scss';
import AddDriverConfirmation from './AddDriverConfirmation';
import AddDriverContext from './AddDriverContext';
import { checkForInvalidRows, getColumns } from './AddDriverUtils';
import DiscardAllChanges from './DiscardAllChanges';
import EditableCell from './EditableCell';
import UploadDrivers from './UploadDrivers';

export default function AddDriver() {
  const mixpanel = useContext(MixpanelContext);
  const user = getUser();
  const companyName = user?.company_name;
  const localData = JSON.parse(localStorage.getItem('fm_add_drivers')) || [];
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState(
    localData[companyName] ? [...localData[companyName]] : [],
  );
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const isTableEmpty = JSON.stringify(dataSource) === '[{"key":0}]';
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const tableRef = useRef(null);
  const [tableHeight, setTableHeight] = useState(400);
  const [allTags, setAllTags] = useState([]);

  const invalidRows = useMemo(
    () => checkForInvalidRows(dataSource),
    [dataSource],
  );

  const VComponents = useMemo(
    () =>
      VList({
        height: 600,
        resetTopWhenDataChange: false,
      }),
    [],
  );

  useLayoutEffect(() => {
    const node = tableRef.current;
    const { top } = node.getBoundingClientRect();
    setTableHeight(window.innerHeight - top - 100);
  }, [tableRef, dataSource]);

  useEffect(() => {
    mixpanel.track('View Page', {
      pageName: 'Add Drivers Page',
    });
  }, [mixpanel]);

  useEffect(async () => {
    const { data } = await fetchTags('active');
    setAllTags(data);
  }, []);

  const validateFields = useCallback(async () => {
    try {
      await form.validateFields();
      return true;
    } catch (errors) {
      return false;
    }
  }, [form]);

  const handleDelete = (key) => {
    setDataSource((current) =>
      current
        .filter((item) => item.key !== key)
        .map((item, index) => ({
          ...item,
          key: index,
        })),
    );
    validateFields();
  };

  const handleAdd = () => {
    const last = dataSource.slice(-1)[0];
    setDataSource([
      ...dataSource,
      {
        key: last ? last.key + 1 : 0,
      },
    ]);

    if (dataSource.length > 0) {
      setTimeout(() => {
        scrollTo({
          row: last ? last.key + 1 : 0,
        });
      }, 100);
    }
  };

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  useEffect(() => {
    localStorage.setItem(
      'fm_add_drivers',
      JSON.stringify({
        ...localData,
        [companyName]: dataSource,
      }),
    );
    form.setFieldsValue(dataSource);
    if (dataSource.length === 0) {
      handleAdd();
    }
  }, [dataSource]);

  const defaultColumns = getColumns(dataSource, handleDelete).map((col) =>
    col.editable
      ? {
          ...col,
          onCell: (record) => ({
            ...col,
            record,
            allTags: allTags?.map((tag) => ({
              label: tag.tag_name,
              value: tag.tag_name,
            })),
            handleSave,
          }),
        }
      : col,
  );

  const handleToastMessages = (numberOfDuplicateDrivers) => {
    const { SUCCESS, ERROR } = toastTypes;
    const numberOfDrivers = dataSource.length;
    const uniqueDrivers = numberOfDrivers - numberOfDuplicateDrivers;
    const pluralize = (value) => `${value} ${value > 1 ? 'drivers' : 'driver'}`;

    if (numberOfDuplicateDrivers === 0) {
      setDataSource([]);
      toast(SUCCESS, `${pluralize(numberOfDrivers)} added successfully`);
    }

    if (
      numberOfDuplicateDrivers > 0 &&
      numberOfDuplicateDrivers < numberOfDrivers
    ) {
      toast(SUCCESS, `${pluralize(uniqueDrivers)} added successfully`);
    }

    if (numberOfDuplicateDrivers > 0) {
      toast(ERROR, `Failed to add ${pluralize(numberOfDuplicateDrivers)}`);
    }
  };

  const handleAddDrivers = async () => {
    setIsLoading(true);
    try {
      const response = await callPostApi('/v1/api/task/create', {
        task_type: 'add_drivers',
        add_driver_data: dataSource.map((item) => ({
          first_name: item?.firstName?.trim(),
          last_name: item?.lastName?.trim(),
          email: item?.email?.trim() || null,
          phone_number: item?.phoneNumber?.trim() || null,
          device_ownership_type: item?.deviceOwnershipType?.trim() || null,
          tags: item?.tags || [],
        })),
      });

      const duplicateDrivers = response?.data?.context?.duplicate_drivers;
      const numberOfDuplicateDrivers = duplicateDrivers?.length || 0;

      handleToastMessages(numberOfDuplicateDrivers);

      const updatedData = duplicateDrivers
        ? duplicateDrivers.map((item, index) => ({
            key: index,
            firstName: item.first_name,
            lastName: item.last_name,
            email: item.email,
            phoneNumber: item.phone_number,
            duplicates: item.duplicate_fields,
            isTouched: [],
          }))
        : [];

      setDataSource([...updatedData]);
      validateFields();
    } catch (err) {
      if (err?.response?.data) {
        const { message } = err?.response?.data || {};
        if (message) toast(toastTypes.ERROR, message);
      } else {
        toast(toastTypes.ERROR, 'Failed to add drivers');
      }
    } finally {
      setIsLoading(false);
      setShowModal(false);
    }
  };

  const hanldeSubmit = async () => {
    const isFormValid = await validateFields();
    if (isFormValid && invalidRows.length === 0) {
      setShowModal(true);
    }
    if (invalidRows.length > 0) {
      toast(
        toastTypes.ERROR,
        `Please fix the errors in ${invalidRows.length} rows`,
      );
    }
  };

  const handleUploadDrivers = (data) => {
    setShowUploadModal(false);
    toast(toastTypes.SUCCESS, `${data.length} Drivers imported successfully`);
    const formData = isTableEmpty ? [...data] : [...dataSource, ...data];
    setDataSource(formData);
    validateFields();
    mixpanel.track('Import Drivers Modal', {
      driversImported: true,
    });
  };

  const showErrors = () => {
    scrollTo({
      row: invalidRows.length > 0 ? invalidRows[0].key : null,
    });
    setTimeout(() => {
      formRef.current.validateFields();
    }, 100);
  };

  return (
    <div className="playground add_driver">
      <div className="page_header">
        <div className="page_header_div" style={{ fontSize: 20 }}>
          <span
            style={{ cursor: 'pointer', marginRight: '16px' }}
            onClick={() => navigate(-1)}
          >
            <ArrowLeftOutlined color="black" />
          </span>
          Add Drivers to Invite
        </div>
        <div>
          <Button
            disabled={isTableEmpty}
            onClick={() => setShowDiscardModal(true)}
          >
            Discard Changes
          </Button>
          <Button
            onClick={hanldeSubmit}
            type="primary"
            disabled={dataSource.length === 0}
            form="fm-add-driver-form"
            key="submit"
            htmlType="submit"
            className="ml-3"
          >
            Invite Drivers
          </Button>
        </div>
      </div>

      <AddDriverConfirmation
        isLoading={isLoading}
        numberOfDrivers={dataSource.length}
        showModal={showModal}
        setShowModal={setShowModal}
        handleAddDrivers={handleAddDrivers}
      />

      <DiscardAllChanges
        showModal={showDiscardModal}
        setShowModal={setShowDiscardModal}
        handleAction={() => {
          setDataSource([]);
          setShowDiscardModal(false);
          formRef?.current?.resetFields();
          formRef.current.setFieldsValue([]);
        }}
      />

      <UploadDrivers
        dataSource={dataSource}
        showModal={showUploadModal}
        setShowModal={setShowUploadModal}
        isTableEmpty={isTableEmpty}
        setIsLoading={setIsLoading}
        handleUploadDrivers={handleUploadDrivers}
      />
      <Card>
        <Space
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 10,
          }}
        >
          <span>
            <Button
              onClick={handleAdd}
              style={{ color: '#569d77' }}
              type="text"
            >
              <PlusOutlined color="primary" /> Add Driver
            </Button>
            <Button
              type="text"
              className="import_drivers"
              onClick={() => setShowUploadModal(true)}
            >
              <ImportOutlined rotate={270} />
              Import Drivers
            </Button>
          </span>

          {invalidRows.length > 0 && !isTableEmpty && (
            <Typography.Text type="danger" strong>
              Errors in {invalidRows.length} rows{' '}
              <RightCircleOutlined onClick={showErrors} />
            </Typography.Text>
          )}
        </Space>
        <Form
          ref={formRef}
          form={form}
          name="fm-add-driver-form"
          autoComplete="off"
          component={false}
          initialValues={[...dataSource]}
        >
          <AddDriverContext.Provider value={form}>
            <Table
              ref={tableRef}
              className="add_drivers_table"
              components={{
                ...VComponents,
                body: {
                  ...VComponents.body,
                  cell: EditableCell,
                },
              }}
              loading={isLoading}
              rowKey={(record) => record.key}
              rowClassName={() => 'editable-row'}
              bordered
              dataSource={dataSource.map((item) => item)}
              columns={defaultColumns}
              pagination={false}
              scroll={{ y: tableHeight, x: '100%' }}
            />
          </AddDriverContext.Provider>
        </Form>
      </Card>
    </div>
  );
}
