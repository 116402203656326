import { Card, Spin } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {
  formatPerfBreakdownChartData,
  setPerfBreakdownChart,
} from '../../utils';
import PerformanceBreakdownTooltip from './PerformanceBreakdownTooltip';
import './performanceBreakdown.scss';

function PerformanceBreakdown({ data, isLoading, intervalType }) {
  const isEmpty = data?.length === 0;
  const isMonthly = intervalType === 'quarter' ? 'monthly' : 'weekly';
  const xAxisLabelFormat = intervalType === 'quarter' ? 'MMM' : 'MMM D';
  const tooltipLabelFormat =
    intervalType === 'quarter' ? 'MMM YYYY' : 'MMM D, yyyy';

  React.useEffect(() => {
    if (!isEmpty && !isLoading && data) {
      setPerfBreakdownChart(
        formatPerfBreakdownChartData(data),
        xAxisLabelFormat,
        tooltipLabelFormat,
      );
    }
  }, [data, isLoading]);

  return (
    <Card
      title={
        <div className="d-flex align-items-center">
          <p className="driver_stats_card_header_text">
            Performance Breakdown ({isMonthly})
          </p>
          <div className="px-2">
            <PerformanceBreakdownTooltip />
          </div>
        </div>
      }
      style={{
        margin: '10px',
        flexBasis: '50%',
        boxShadow:
          '0 6px 10px 0 rgb(47 49 104 / 12%), 0 1px 6px 0 rgb(0 0 0 / 12%)',
      }}
      bodyStyle={{
        minHeight: '306px',
      }}
    >
      {isLoading && (
        <div
          style={{ height: '200px' }}
          className="d-flex align-items-center justify-content-center"
        >
          <Spin />
        </div>
      )}
      {!isLoading && isEmpty && (
        <div className="performanceBreakdown__empty">
          <h4>THERE IS NO ACTIVITY IN THIS PERIOD</h4>
        </div>
      )}
      {!isLoading && !isEmpty && (
        <div id="pf-breakdown-chart" style={{ padding: '15px' }} />
      )}
    </Card>
  );
}

PerformanceBreakdown.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default PerformanceBreakdown;
