import { LoadingOutlined } from '@ant-design/icons';
import { Button, Spin, Typography } from 'antd';
import React from 'react';
import ConfirmationModal from '../../../components/shared/confirmationModal';

const { Text } = Typography;

export default function EditDriversDiscardModal({
  isLoading = false,
  showModal,
  setShowModal,
  handleDiscard,
}) {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <ConfirmationModal
      visible={showModal}
      onCancel={() => {
        setShowModal(false);
      }}
      title="Are you sure you want to discard all changes?"
      footer={[
        <Button
          key="submit"
          type="primary"
          disabled={isLoading}
          onClick={handleDiscard}
        >
          Discard Changes
        </Button>,
      ]}
    >
      {isLoading ? (
        <div className="modal_loading">
          <Text>Adding Drivers...</Text>
          <Spin indicator={antIcon} />
        </div>
      ) : (
        <>If you choose to discard, all the changes will be reset to default.</>
      )}
    </ConfirmationModal>
  );
}
