import { ArrowLeftOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Space, Spin, Tabs, Tooltip, Typography } from 'antd';

import React, { useState } from 'react';
import TagsTable from './TagsTable';
import { fetchTags } from './tagService';

const TooltipText =
  'Manage your tags here: create, edit, or delete them. You can also assign up to 5 tags to each driver.';

const loadingTagsView = (
  <div
    style={{ minHeight: '500px' }}
    className="d-flex align-items-center justify-content-center"
  >
    <Spin />
  </div>
);

function TagsManagement(props) {
  const { visible, handleClose } = props;
  const [loading, setLoading] = useState(false);
  const [activeTags, setActiveTags] = useState([]);
  const [deletedTags, setDeletedTags] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState('1');
  const [refetchDeletedTags, setRefetchDeletedTags] = useState(true);
  const noTagsCreated = activeTags.length === 0 && deletedTags.length === 0;

  async function getTags(status = 'active') {
    try {
      setLoading(true);
      const { data } = await fetchTags(status);
      if (status === 'active') {
        setActiveTags(data);
      } else {
        setDeletedTags(data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // TODO: handle error
    }
  }

  const items = [
    {
      key: '1',
      label: `Active Tags (${activeTags.length})`,
      children: (
        <TagsTable
          tags={activeTags}
          getTags={getTags}
          noTagsCreated={noTagsCreated}
        />
      ),
    },
    {
      key: '2',
      label: 'Deleted Tags',
      children: <TagsTable isDeletedTagsTable tags={deletedTags} />,
    },
  ];

  React.useEffect(() => {
    getTags('active');
  }, []);

  const modalHeader = (
    <Space
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Button
        type="text"
        onClick={() => {
          handleClose();
          setActiveTabKey('1');
        }}
      >
        <ArrowLeftOutlined />
      </Button>
      <Typography
        style={{
          fontSize: '18px',
        }}
      >
        Tags Management
      </Typography>
      <Tooltip title={TooltipText}>
        <InfoCircleOutlined
          style={{
            marginLeft: '5px',
            marginTop: '5px',
            fontSize: '16px',
            color: 'grey',
          }}
        />
      </Tooltip>
    </Space>
  );

  const onTabChange = (key) => {
    setActiveTabKey(key);
    if (key === '1' && activeTags.length === 0) getTags('active');
    else if (refetchDeletedTags) {
      getTags('deleted');
      setRefetchDeletedTags(false);
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={handleClose}
      width={1000}
      footer={null}
      closable={false}
      maskClosable={false}
    >
      <Space direction="vertical" style={{ width: '100%', height: '100%' }}>
        {modalHeader}
        {loading ? (
          loadingTagsView
        ) : (
          <Space
            direction="vertical"
            style={{ width: '100%', height: '100%', padding: '0px 20px' }}
          >
            <Tabs
              activeKey={activeTabKey}
              items={items}
              onChange={onTabChange}
            />
          </Space>
        )}
      </Space>
    </Modal>
  );
}

export default TagsManagement;
