import {
  DeleteOutlined,
  RedoOutlined,
  SearchOutlined,
  TagsOutlined,
} from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { callGetApi, callPostApi } from '../../../api/axios';
import { debounce } from '../../../utils/search';
import { toast, toastTypes } from '../../../utils/toast';
import { fetchTags } from '../../TagsManagement/tagService';
import TagsManagement from '../../TagsManagement/TagsManagement';
import DeleteDrivers from './BulkActions/DeleteDrivers';
import EditDriverTags from './BulkActions/EditDriverTags';
import ResendInvites from './BulkActions/ResendInvites';
import {
  deviceOwnershipFilters,
  DriversManagementColumns,
  statusFilters,
} from './DriversManagementUtils';
import DriverTableSelectAll from './DriverTableSelectAll';

export default function DriversManagementTable({ isSysAdmin, isFmAppEnabled }) {
  const [currentActivePageNumber, setCurrentActivePageNumber] =
    React.useState(1);
  const [driversList, setDriversList] = useState([]);
  const [isDriversLoading, setIsDriversLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [toggleModal, setToggleModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [actionLoading, setActionLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [selectedDriversTags, setSelectedDriversTags] = useState([]);
  const [filters, setFilters] = useState({
    status: ['joined', 'invited'],
  });
  const [totalDrivers, setTotalDrivers] = useState(0);

  const fetchDriversList = async () => {
    setIsDriversLoading(true);
    const queryParams = {
      limit: 25,
      page: currentActivePageNumber,
      query: searchTerm,
    };

    if (filters.tags?.length > 0) {
      queryParams.tags = filters.tags;
    }

    if (filters.status) {
      queryParams.status = filters.status;
    }
    if (filters.device_ownership_type) {
      queryParams.device_ownership_type = filters.device_ownership_type;
    }

    try {
      const { data } = await callGetApi(
        `/v2/api/drivers?${queryString.stringify(queryParams, {
          arrayFormat: 'comma',
        })}`,
      );

      setDriversList(data.drivers);
      setTotalDrivers(data.total_count);
    } catch (err) {
      setDriversList([]);
    } finally {
      setIsDriversLoading(false);
    }
  };

  const handleAction = async (config) => {
    setActionLoading(true);
    try {
      await callPostApi('/v1/api/task/create', {
        task_type: config.task_type,
        context: {
          driver_ids: selectedRowKeys,
        },
      });
      toast(toastTypes.SUCCESS, config.success);
    } catch (err) {
      toast(toastTypes.ERROR, config.failure);
    } finally {
      setActionLoading(false);
      setToggleModal(false);
      setSelectedRowKeys([]);
      fetchDriversList();
    }
  };

  useEffect(async () => {
    const { data } = await fetchTags('active');
    setTags(data);
  }, []);

  useEffect(() => {
    fetchDriversList();
  }, [currentActivePageNumber, searchTerm, filters]);

  const handleSearch = (term) => {
    debounce(() => {
      setSearchTerm(term.trim());
    }, 800)();
  };

  const handleModalToggle = (value) => {
    setModalType(value);
    setToggleModal((prev) => !prev);
  };

  const handleChange = (pagination, filterValues, sorter, extra) => {
    if (extra.action === 'paginate') {
      setCurrentActivePageNumber(pagination.current);
    } else {
      setCurrentActivePageNumber(1);
    }

    setFilters(filterValues);
  };

  const updateSelectedDriversTags = (newSelectedRowKeys) => {
    const currentDriverIds = new Set(
      selectedDriversTags.map((driver) => driver.driver_id),
    );

    // Filter and map new drivers that are selected and not already in the previous state
    const newDrivers = driversList
      .filter(
        (driver) =>
          newSelectedRowKeys.includes(driver.id) &&
          !currentDriverIds.has(driver.id),
      )
      .map((driver) => ({
        driver_id: driver.id,
        tags: driver.tags,
      }));

    // Filter the previous drivers to include only those that are still selected
    const updatedDrivers = selectedDriversTags.filter((driver) =>
      newSelectedRowKeys.includes(driver.driver_id),
    );

    // Combine the filtered previous drivers and the new unique drivers into one array
    return [...updatedDrivers, ...newDrivers];
  };

  const onRowChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedDriversTags(updateSelectedDriversTags(newSelectedRowKeys));
  };

  const formattedColumns = DriversManagementColumns.map((column) => {
    if (column.dataIndex === 'tags') {
      const tagsWithNone = [
        {
          id: -1,
          tag_name: 'None',
        },
        ...tags,
      ];
      return {
        ...column,
        filters:
          tags.length > 0
            ? tagsWithNone.map((tag) => ({
                text: tag.tag_name,
                value: tag.id,
              }))
            : null,
      };
    }
    if (column.dataIndex === 'status') {
      return {
        ...column,
        filters: statusFilters,
        defaultFilteredValue: ['joined', 'invited'],
      };
    }

    if (column.dataIndex === 'device_ownership_type') {
      return {
        ...column,
        filters: deviceOwnershipFilters,
      };
    }
    return column;
  }).filter((column) =>
    column.dataIndex === 'status' ||
    column.dataIndex === 'device_ownership_type'
      ? isFmAppEnabled
      : true,
  );

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys,
    onChange: onRowChange,
    getCheckboxProps: (record) => ({
      disabled: record.status === 'deleted',
    }),
  };

  return (
    <div className="driver_table_wrapper">
      <Space direction="horizontal" className="table_actions_wrapper">
        <Input
          placeholder="Search by driver id, name, email, phone number"
          prefix={<SearchOutlined />}
          defaultValue={searchTerm}
          onChange={(event) => handleSearch(event.target.value)}
          allowClear
          size="large"
          style={{ width: 300 }}
        />

        {selectedRowKeys.length > 0 ? (
          <Space>
            <EditDriverTags
              tags={tags}
              selectedDriversTags={selectedDriversTags}
              fetchDriversList={fetchDriversList}
              setSelectedRowKeys={setSelectedRowKeys}
              handleModalToggle={handleModalToggle}
            />

            {isFmAppEnabled && (
              <Button
                type="text"
                icon={<RedoOutlined />}
                style={{ color: '#569D77' }}
                onClick={() => {
                  handleModalToggle('resendInvites');
                }}
              >
                Resend Invites
              </Button>
            )}

            {isSysAdmin && isFmAppEnabled && (
              <Button
                type="text"
                icon={<DeleteOutlined />}
                style={{ color: '#B95959' }}
                onClick={() => {
                  handleModalToggle('deleteDrivers');
                }}
              >
                Delete Drivers
              </Button>
            )}
          </Space>
        ) : (
          <Button
            type="text"
            icon={<TagsOutlined />}
            onClick={() => {
              handleModalToggle('tags');
            }}
          >
            Manage Tags
          </Button>
        )}
      </Space>
      <DriverTableSelectAll
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
      />

      <div className="status_table_wrapper">
        <Table
          dataSource={driversList}
          columns={formattedColumns}
          loading={isDriversLoading}
          onChange={handleChange}
          rowKey={(record) => record.id}
          rowSelection={rowSelection}
          pagination={{
            disabled: isDriversLoading,
            current: currentActivePageNumber,
            pageSize: 25,
            showSizeChanger: false,
            total: totalDrivers,
            hideOnSinglePage: true,
          }}
          scroll={{ y: 500, x: 'max-content' }}
          rowClassName={(record) =>
            record.status === 'deleted' ? 'table-row-disable' : ''
          }
        />
      </div>

      <ResendInvites
        numberOfDrivers={selectedRowKeys.length}
        isLoading={actionLoading}
        handleAction={handleAction}
        showModal={toggleModal && modalType === 'resendInvites'}
        setShowModal={() => setToggleModal(false)}
      />

      <DeleteDrivers
        numberOfDrivers={selectedRowKeys.length}
        isLoading={actionLoading}
        handleAction={handleAction}
        showModal={toggleModal && modalType === 'deleteDrivers'}
        setShowModal={() => setToggleModal(false)}
      />

      <TagsManagement
        visible={toggleModal && modalType === 'tags'}
        handleClose={() => {
          setToggleModal(false);
          setModalType(null);
        }}
      />
    </div>
  );
}
