import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { callGetApi } from 'api/axios';
import queryString from 'query-string';
import React, { useContext } from 'react';
import { toast, toastTypes } from 'utils/toast';
import MixpanelContext from '../../../services/tracking';

function DownloadReportTrigger({
  startDate,
  endDate,
  interval,
  onDownloadTrigger,
  selectedPeriod,
  selectedTags,
}) {
  const mixpanel = useContext(MixpanelContext);

  // TODO: loading should be on download report button only. Also, can exctract the api call
  const makeReportRequest = () => {
    onDownloadTrigger();
    const query_params = {
      start_date: startDate,
      report_type: 'fleet',
      interval,
      trip_periods: selectedPeriod,
      ...(selectedTags.length > 0 && { tags: selectedTags }),
    };
    const api = `/v2/api/report?${queryString.stringify(query_params, {
      arrayFormat: 'comma',
    })}`;
    callGetApi(api)
      .then(({ data, headers }) => {
        const contentType = 'text/csv';
        const contentDisposition = headers['content-disposition'];
        const filename = contentDisposition.split(';')[1].split('=')[1];
        const csvFile = new Blob([data], { type: contentType });
        const csvURL = window.URL.createObjectURL(csvFile);
        const report_link = document.createElement('a');
        report_link.href = csvURL;
        report_link.setAttribute('download', filename);
        report_link.click();
        toast(toastTypes.SUCCESS, 'Successfully downloaded the report');
      })
      .catch((err) => {
        const { fault } = err?.response?.data || {};
        if (fault) toast(toastTypes.ERROR, fault?.faultstring);
      });
  };

  return (
    <Button
      size="large"
      style={{ marginLeft: 'auto', color: '#57ab85' }}
      onClick={makeReportRequest}
    >
      <DownloadOutlined /> Fleet Summary Report
    </Button>
  );
}

export default DownloadReportTrigger;
