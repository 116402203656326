import { InfoCircleOutlined } from '@ant-design/icons';
import { Divider, Space } from 'antd';
import React from 'react';
import { getUser } from '../../../utils/validateUserToken';
import KeysInfoItem from './KeysInfoItem';

export default function ApiKeyInfoCard() {
  const user = getUser();
  const FM_SDK_KEY = user?.fm_sdk_key;
  const FM_API_KEY = user?.fm_api_key;
  const SDK_KEY = user?.sdk_key;
  const API_KEY = user?.api_key;

  return (
    <div className="account_info_wrapper_card">
      <div className="card_header_spacing">
        <h4 className="card_header_text">Integration Keys</h4>
        <p className="card_header_subText">
          Use these keys for initializing the SDK and accessing the Analytics
          API for your production ready application.
        </p>
      </div>
      <div className="card_body_spacing">
        <KeysInfoItem itemKey={FM_SDK_KEY} title="Fairmatic SDK" />
        <Space className="sdk_info">
          <InfoCircleOutlined style={{ color: '#56C79D' }} />
          <div className="pl-3">
            We recommend to use Fairmatic SDK which helps us in serving you a
            better experience. If you use a fleet software e.g. Onfleet, you can
            continue to use Zendrive SDK.
          </div>
        </Space>
        <KeysInfoItem itemKey={FM_API_KEY} title="Fairmatic API" />
        <Divider />
        <KeysInfoItem itemKey={SDK_KEY} title="Zendrive SDK" />
        <KeysInfoItem itemKey={API_KEY} title="Zendrive Analytics API" />
      </div>
    </div>
  );
}
