import { LoadingOutlined } from '@ant-design/icons';
import { Button, Spin, Typography } from 'antd';
import React from 'react';
import ConfirmationModal from '../../../components/shared/confirmationModal';

const { Text } = Typography;

export default function AddDriverConfirmation({
  isLoading,
  numberOfDrivers,
  showModal,
  setShowModal,
  handleAddDrivers,
}) {
  const text = numberOfDrivers > 1 ? 'drivers' : 'driver';
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <ConfirmationModal
      visible={showModal}
      onCancel={() => {
        setShowModal(false);
      }}
      title={`Add ${numberOfDrivers} ${text} to your fleet?`}
      footer={[
        <Button
          key="cancel"
          disabled={isLoading}
          onClick={() => {
            setShowModal(false);
          }}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={isLoading}
          onClick={handleAddDrivers}
        >
          Invite
        </Button>,
      ]}
    >
      {isLoading ? (
        <div className="modal_loading">
          <Text>Adding Drivers...</Text>
          <Spin indicator={antIcon} />
        </div>
      ) : (
        <>
          An sms & email will be sent to the drivers requesting to accept our
          invite & join the fleet.
        </>
      )}
    </ConfirmationModal>
  );
}
