import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

function FocusLabelTooltipText(value) {
  return <span>{value}</span>;
}

const Event = function Event({ children, label }) {
  return (
    <Tooltip placement="bottom" title={FocusLabelTooltipText(label)}>
      <div
        className="d-flex align-items-center"
        style={{ width: '24px', height: '24px' }}
      >
        {children}
      </div>
    </Tooltip>
  );
};

Event.propTypes = {
  children: PropTypes.object,
  label: PropTypes.string,
};
Event.defaultProps = {
  children: null,
  label: null,
};
export default Event;
