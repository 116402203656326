import { Button, Form, Select, Spin } from 'antd';
import { navigate } from 'gatsby-link';
import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react';
import { callGetApi } from '../../api/axios';
import { GREY_150 } from '../../assets/style/colors';
import MixpanelContext from '../../services/tracking';
import { roles_enum as ROLES_ENUM, Roles } from '../../utils/roles';
import { debounce } from '../../utils/search';
import AuthLayout from '../auth/authLayout';
import fleetStatus from '../shared/fleetStatus';

const { Option } = Select;

export default function SelectFleet() {
  const mixpanel = useContext(MixpanelContext);
  const [selectedFleet, setSelectedFleet] = useState('');
  const [selectedUserRole, setSelectedUserRole] = useState('sys_admin');
  const fleetsData = [];
  const [fleets, setFleets] = useState(fleetsData);
  const [loading, setLoading] = useState(false);
  const [overallLoading, setOverAllLoading] = useState(false);
  const fetchFleets = (query) => {
    setOverAllLoading(true);
    const apiUrl = query
      ? `/v1/api/get-fleets?query=${query}`
      : '/v1/api/get-fleets';
    callGetApi(apiUrl)
      .then((resp) => {
        setFleets(resp.data);
        setOverAllLoading(false);
      })
      .catch(() => {
        setOverAllLoading(false);
      });
  };

  useEffect(() => {
    mixpanel.track('View Page', {
      name: 'Select Fleet Page',
    });
  }, [mixpanel]);

  const fetchShadowToken = (applicationId) => {
    setLoading(true);
    callGetApi(
      `/v1/api/get_shadow_token?application_id=${applicationId}&role=${selectedUserRole}`,
    )
      .then((resp) => {
        const cookieExpiryTime = new Date();
        cookieExpiryTime.setTime(cookieExpiryTime.getTime() + 24 * 3600 * 1000);
        // expires in 24 hours
        Cookies.set('Authorization', resp.data, {
          expires: cookieExpiryTime,
        });
        const base64Url = resp.data.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split('')
            .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
            .join(''),
        );
        Cookies.set('User', jsonPayload, {
          expires: cookieExpiryTime,
        });
        setLoading(false);
        const user = JSON.parse(jsonPayload);

        const { company_name, application_id, first_name, last_name, email } =
          user;
        mixpanel.set_group('partnerName', [company_name]);
        mixpanel.get_group('partnerName', company_name).set_once({
          partnerName: company_name,
          partnerId: application_id,
        });

        mixpanel.people.set({
          $email: email,
          $name: `${first_name} ${last_name}`,
          partnerName: company_name,
          partnerId: application_id,
        });

        mixpanel.register({
          partnerName: company_name,
          partnerId: application_id,
        });

        if (user?.roles.includes(Roles.CUSTOMER_SUPPORT)) {
          navigate('/app/dashboard/support');
          return;
        }
        navigate('/app/dashboard');
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleSearch = (query) => {
    debounce(() => {
      fetchFleets(query);
    }, 800)();
  };

  return (
    <AuthLayout>
      <div
        className="content_height d-flex align-items-center justify-content-center"
        style={{ height: '100vh' }}
      >
        <div className="auth_card">
          <h3 className="header_text">Select Fleet</h3>
          <Form
            name="select_fleet_form"
            initialValues={{
              remember: true,
            }}
            onFinish={() => fetchShadowToken(selectedFleet)}
            autoComplete="off"
          >
            {/* SELECT FLEET DROPDOWN  */}
            <Form.Item
              name="fleet"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please select a fleet!',
                },
              ]}
            >
              <Select
                filterOption={false}
                showSearch
                placeholder="Start typing..."
                size="large"
                autoFocus
                loading={loading}
                onChange={setSelectedFleet}
                onSearch={handleSearch}
                notFoundContent={loading ? <Spin size="small" /> : null}
              >
                {overallLoading ? (
                  <Option>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Spin size="small" />
                    </div>
                  </Option>
                ) : (
                  fleets.map((fleet) => (
                    <Option
                      key={fleet?.application_id}
                      value={fleet?.zendrive_application_id}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        {fleetStatus(fleet)}
                        <span
                          style={{
                            color: GREY_150,
                          }}
                        >
                          {fleet?.subaccount_id}
                        </span>
                      </div>
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>
            {/* SELECT ROLE DROPDOWN  */}
            <Form.Item
              initialValue={selectedUserRole}
              name="role"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please select a Role!',
                },
              ]}
            >
              <Select
                placeholder="Please select a role"
                value={selectedUserRole}
                size="large"
                onChange={setSelectedUserRole}
              >
                {Object.values(ROLES_ENUM).map((role) => (
                  <Option key={role.key} value={role.key}>
                    {role.value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <div style={{ margin: '0 auto', width: '150px' }}>
              <Form.Item>
                <Button
                  disabled={!selectedFleet || overallLoading || loading}
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={loading}
                  style={{
                    width: '100%',
                  }}
                  onClick={() => {
                    const fleetDetails = fleets.filter(
                      (fleet) =>
                        fleet.zendrive_application_id === selectedFleet,
                    )[0];
                    localStorage.setItem('fleet_name', selectedFleet);
                    localStorage.setItem('role', selectedUserRole);
                    localStorage.setItem(
                      'fleet_details',
                      JSON.stringify(fleetDetails || []),
                    );

                    mixpanel.track('Fleet Selected', {
                      fleetName: selectedFleet,
                      role: selectedUserRole,
                      partnerId: selectedFleet,
                    });
                  }}
                >
                  Select
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </AuthLayout>
  );
}
