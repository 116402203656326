/* eslint-disable no-param-reassign */
/* eslint-disable no-async-promise-executor */
import { callGetApi } from 'api/axios';
import queryString from 'query-string';
import { getUser } from 'utils/validateUserToken';

const getStatsService = async function get({ queryParams }) {
  return new Promise(async (resolve) => {
    const user = getUser();

    queryParams = {
      ...queryParams,
      apiKey: user.api_key,
    };
    try {
      const { data } = await callGetApi(
        `/v2/api/dashboard_stats?${queryString.stringify(queryParams, {
          arrayFormat: 'comma',
        })}`,
      );
      resolve({ data: data.driver_count });
    } catch (err) {
      const { fault } = err?.response?.data || {};
      resolve({
        isError: true,
        errorMessage: fault?.faultstring,
      });
    }
  });
};

function getDriversListService({ queryParams }) {
  return new Promise(async (resolve) => {
    try {
      const { data } = await callGetApi(
        `/v2/api/perf_drivers?${queryString.stringify(queryParams, {
          arrayFormat: 'comma',
        })}`,
      );
      resolve({
        data,
      });
    } catch (err) {
      resolve({
        isError: true,
        errorMessage: err?.response?.data?.fault?.faultstring,
      });
    }
  });
}

export { getDriversListService, getStatsService };
