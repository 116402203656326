import { LoadingOutlined } from '@ant-design/icons';
import { Button, Spin, Typography } from 'antd';
import React from 'react';
import ConfirmationModal from '../../../../components/shared/confirmationModal';

const { Text } = Typography;

export default function ResendInvites({
  numberOfDrivers,
  handleAction,
  isLoading,
  showModal,
  setShowModal,
}) {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const handleResendInvites = () => {
    handleAction({
      task_type: 'resend_invites',
      success: 'Invites resent',
      failure: 'Failed to resend invites',
    });
  };

  return (
    <ConfirmationModal
      visible={showModal}
      onCancel={() => {
        setShowModal(false);
      }}
      title="Resend driver invites"
      footer={[
        <Button
          key="cancel"
          disabled={isLoading}
          onClick={() => {
            setShowModal(false);
          }}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={isLoading}
          onClick={handleResendInvites}
        >
          Resend Invite
        </Button>,
      ]}
    >
      {isLoading ? (
        <div className="modal_loading">
          <Text>Resending Invites...</Text>
          <div>
            <Spin indicator={antIcon} />
          </div>
        </div>
      ) : (
        <>
          Are you sure you want to resend invite to {numberOfDrivers} selected
          {numberOfDrivers > 1 ? ' drivers' : ' driver'}?
        </>
      )}
    </ConfirmationModal>
  );
}
