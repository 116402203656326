import { Spin } from 'antd';
import React from 'react';
import DeviceStatusTooltip from './DeviceStatusTooltip';
import DriverInfoCard from './DriverInfoCard';
import EmptyHeartbeat from './EmptyHeartbeat';
import EmptyState from './EmptyState';
import EnvironmentalRiskFactorCard from './EnvironmentalRiskFactorCard';
import EventsDistribution from './EventsDistribution';
import TripsTable from './TripsTable';

const loadingSpinned = (
  <div
    style={{ height: '70vh' }}
    className="d-flex align-items-center justify-content-center"
  >
    <Spin />
  </div>
);
const loadingSpinned2 = (
  <div
    style={{ height: '250px' }}
    className="d-flex align-items-center justify-content-center"
  >
    <Spin />
  </div>
);
export {
  DeviceStatusTooltip,
  DriverInfoCard,
  EmptyHeartbeat,
  EmptyState,
  EnvironmentalRiskFactorCard,
  EventsDistribution,
  TripsTable,
  loadingSpinned,
  loadingSpinned2,
};
