import React from 'react';

export default function PerformanceGroupsTooltip() {
  return (
    <p>
      This reflects the breakdown of the total number of drivers in each
      performance grade.
      <br />
      <br />
      <u>Excellent:</u> These drivers have a score ranging between 90-100 and
      have achieved maximum premium savings. <br /> <br />
      <u>Good:</u> These drivers have a score ranging between 80-89. This group
      has achieved a smaller percentage of premium savings compared to those in
      the Excellent category. <br /> <br />
      <u>Moderate:</u> These drivers have a score ranging between 70-79. This
      group is not achieving any premium savings. Coaching is recommended.
      <br /> <br />
      <u>Coaching Required:</u> These drivers have a score ranging between
      60-69. Coaching is required. Improving this group&apos;s performance would
      maximally impact premium savings potential.
    </p>
  );
}
