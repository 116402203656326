import { CloseCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { MAX_TAGS, TAG_LENGTH } from '../../../constants';

export const getColumns = (dataSource, handleDelete) => [
  {
    title: 'No',
    dataIndex: 'key',
    width: '60px',
    render: (text, record) => dataSource.indexOf(record) + 1,
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
    editable: true,
    hint: 'First Name',
    width: '25%',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    editable: true,
    hint: 'Last Name',
    width: '25%',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    editable: true,
    hint: 'Email',
    width: '25%',
  },
  {
    title: 'Phone Number',
    dataIndex: 'phoneNumber',
    editable: true,
    hint: 'Phone Number',
    width: '25%',
  },
  {
    title: 'Device Ownership',
    dataIndex: 'deviceOwnershipType',
    editable: true,
    hint: 'Device Ownership Type',
    width: '25%',
  },
  {
    title: 'Tags',
    dataIndex: 'tags',
    editable: true,
    hint: 'Tags',
    width: '25%',
  },
  {
    title: '',
    dataIndex: 'close',
    width: '5%',
    render: (_, record) => (
      <CloseCircleOutlined onClick={() => handleDelete(record.key)} />
    ),
  },
];

export const vaidationRules = {
  firstName: [
    {
      required: true,
      whitespace: true,
      message: 'Please enter first name',
    },
    {
      max: 20,
      message: 'Name cannot be more than 20 characters',
    },
    {
      type: 'string',
      message: 'Please enter a valid Name!',
    },
  ],
  lastName: [
    {
      required: true,
      whitespace: true,
      message: 'Please enter last name',
    },
    {
      max: 20,
      message: 'Name cannot be more than 20 characters',
    },
    {
      type: 'string',
      message: 'Please enter a valid name',
    },
  ],
  email: [
    {
      type: 'email',
      message: 'Please enter a valid email',
    },
    {
      whitespace: true,
      message: 'Please enter an email',
    },
  ],
  phoneNumber: [
    {
      min: 10,
      pattern:
        /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
      message: 'Please enter a valid phone number',
    },
    {
      message: 'Please enter a phone number',
    },
  ],
  deviceOwnershipType: [
    {
      required: true,
      message: 'Please select device ownership',
    },
  ],
  tags: [
    {
      required: false,
    },
  ],
};

export const fileValidationMessages = {
  empty: 'Empty File',
  invalid: 'Upload the correct file as per the prescribed template',
  size: 'Max file size allowed to be uploaded is 100KB',
};

export const validateTags = (tags) => {
  const maxTags = tags.length > 0 && tags.length <= MAX_TAGS;
  const tagLength = tags.every((tag) => tag.length < TAG_LENGTH);
  const hasSpace = tags.every((tag) => !tag.includes(' '));
  const hasSpecialChars = tags.every((tag) => /^[a-zA-Z0-9_\-&@ ]+$/.test(tag));

  if (!maxTags) {
    return Promise.reject(
      new Error(`Max number of tags allowed is {MAX_TAGS}`),
    );
  }

  if (!tagLength) {
    return Promise.reject(
      new Error(`Tag name should be less than ${TAG_LENGTH} characters`),
    );
  }

  if (!hasSpace) {
    return Promise.reject(new Error('Tag name should not contain spaces'));
  }

  if (!hasSpecialChars) {
    return Promise.reject(
      new Error('Tag name can only contain letters, numbers, -, _, & and @'),
    );
  }

  return Promise.resolve();
};

export const arrayEquals = (a, b) =>
  Array.isArray(a) &&
  Array.isArray(b) &&
  a.length === b.length &&
  a.every((val, index) => val === b[index]);

export const readableFileSize = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const checkForInvalidRows = (dataSource) =>
  dataSource.filter((obj) => {
    const { firstName, lastName, email, phoneNumber, tags = [] } = obj;
    const isFirstNameValid =
      typeof firstName === 'string' && firstName.trim().length > 0;
    const isLastNameValid =
      typeof lastName === 'string' && lastName.trim().length > 0;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmailValid = typeof email === 'string' && emailRegex.test(email);
    const isPhoneNumberValid = phoneNumber && phoneNumber.length >= 10;
    const isValidTags = tags && tags.length <= MAX_TAGS;

    return !(
      isFirstNameValid &&
      isLastNameValid &&
      isValidTags &&
      (isEmailValid || isPhoneNumberValid)
    );
  });
