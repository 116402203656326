import { Badge } from 'antd';
import React from 'react';

export default function DriverTableActivity({ isActive = false }) {
  const activeColor = isActive ? '#569D77' : '#A8A8A8';
  return (
    <div
      style={{
        color: activeColor,
        fontSize: 12,
        minWidth: 100,
      }}
    >
      <Badge dot color={activeColor} style={{ margin: '0 4px' }} />
      {isActive ? 'Active' : 'Inactive'}
    </div>
  );
}
