import { Button } from 'antd';
import React from 'react';
import ConfirmationModal from '../../../components/shared/confirmationModal';

export default function DiscardAllChanges({
  handleAction,
  showModal,
  setShowModal,
}) {
  return (
    <ConfirmationModal
      visible={showModal}
      onCancel={() => {
        setShowModal(false);
      }}
      title="Are you sure you want to discard changes?"
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            setShowModal(false);
          }}
        >
          Cancel
        </Button>,
        <Button key="submit" type="danger" onClick={handleAction}>
          Discard Changes
        </Button>,
      ]}
    >
      If you discard changes, data entered will be lost
    </ConfirmationModal>
  );
}
