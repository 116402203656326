import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDomServer from 'react-dom/server';
import StyledComponents from 'styled-components';
import { GREY_117, WHITE } from '../../assets/style/colors';
import Legend from './Legend';
import { formatNumber } from './utils';

function HeartBeat(props) {
  const xAxisLabel = {
    formatter: function formatter() {
      return moment(this.value).format('MMM D').toUpperCase();
    },
  };

  const tooltip = (
    label,
    transform,
    asDate = false,
    asDateRange = false,
    prefix = '',
    roundDecimalDigits = undefined,
  ) => ({
    formatter: function formatter() {
      let date = this.point.options.name;

      if (asDate || asDateRange) {
        date = moment(this.point.options.name).format('MMM D, yyyy');
      }

      const currentValue = this.point.options.y;
      let adjective = label || ''; // check if label and transform are not provided

      if (label === undefined && transform === undefined) {
        throw new Error(
          'One of dynamicTooltipLabel and toolTipLabel must be provided',
        );
      }

      if (transform) {
        adjective = transform(currentValue);
      }

      return '\n      <div class="tooltip_date">'
        .concat(date, '</div>\n        <div class="tooltip_value">')
        .concat(prefix)
        .concat(
          formatNumber(currentValue, roundDecimalDigits),
          '</div>\n      <div class="tooltip_label">',
        )
        .concat(adjective, '</div>\n      ');
    },
    backgroundColor: null,
    borderWidth: 0,
    shadow: false,
    useHTML: true,
    style: {
      padding: 0,
    },
  });

  const LegendsWrapper = StyledComponents.div.withConfig({
    displayName: 'Heartbeat__LegendsWrapper',
    componentId: 'sc-1avolxg-1',
  })([
    'width:100%;display:flex;justify-content:center;align-items-center;margin-left:16px;& > *{margin-Right:40px;}',
  ]);

  const { dateRange } = props;
  const startDate = new Date(dateRange.startDate).getTime();
  let endDate = new Date(dateRange.endDate).getTime();
  const daysDifference = Math.floor(
    (endDate - startDate) / 1000 / 60 / 60 / 24,
  );
  let titleText = '';
  if (daysDifference < 15) {
    titleText = '';
  } else if (daysDifference > 75) {
    titleText = 'Each date indicates the start <br/>of a week';
  } else if (daysDifference < 75) {
    titleText = 'Each number indicates the day <br/>of the month';
  }

  let maxValue = 4;
  if (props.collapsed) {
    maxValue = 0;
  } else if (props.activityPermissionsDisabled) {
    maxValue = 3;
  }
  let factor = 1;

  if (daysDifference > 90) {
    factor = 0;
    endDate = new Date(endDate - 50 * 1000 * 60 * 60 * 24).getTime();
  } else if (daysDifference > 31) {
    factor = 7;
    endDate = new Date(endDate - 25 * 1000 * 60 * 60 * 24).getTime();
  } else {
    factor = 1;
    endDate = new Date(endDate + 1 * 1000 * 60 * 60 * 24).getTime();
  }

  const config = {
    chart: {
      type: 'gantt',
      spacingLeft: 1,
      events: {
        redraw: true,
      },
    },
    title: {
      text: titleText,
      align: 'left',
      y: 16,
      x: 16,
      floating: true,
      style: {
        fontSize: '13px',
        color: '#363636',
        textAlign: 'right',
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        type: 'gantt',
      },
    },
    yAxis: {
      type: 'category',
      categories: props.categories,
      className: 'axesoverride',
      min: 0,
      max: maxValue,
      labels: {
        formatter: function formatter() {
          let domStr = '';

          switch (this.value) {
            case 'Status':
              domStr = ReactDomServer.renderToString(
                <div className="labelTooltipWrapper">
                  <span className="boldLable">
                    {this.value}
                    <Tooltip title="Whether the user was online, degraded, or offline. This value is the same as the most degraded status from the other four categories.">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>,
              );
              break;

            case 'SDK Initialization':
              domStr = ReactDomServer.renderToString(
                <div className="labelTooltipWrapper">
                  <span className="boldLable">
                    {this.value}
                    <Tooltip
                      placement="right"
                      title="Reports whether the SDK was initialized correctly."
                    >
                      <InfoCircleOutlined
                        style={{
                          color: GREY_117,
                          size: '12px',
                        }}
                      />
                    </Tooltip>
                  </span>
                </div>,
              );
              break;

            case 'Location Permission':
              domStr = ReactDomServer.renderToString(
                <div className="labelTooltipWrapper">
                  <span className="boldLable">
                    {this.value}
                    <Tooltip
                      placement="right"
                      title="Reports whether Always Location permission was granted to the app."
                    >
                      <InfoCircleOutlined
                        style={{
                          color: GREY_117,
                          size: '12px',
                        }}
                      />
                    </Tooltip>
                  </span>
                </div>,
              );
              break;

            case 'Activity Permission':
              domStr = ReactDomServer.renderToString(
                <div className="labelTooltipWrapper">
                  <span className="boldLable">
                    {this.value}
                    <Tooltip
                      placement="right"
                      title="Reports whether the Activity Recognition permission was granted to the app."
                    >
                      <InfoCircleOutlined
                        style={{
                          color: GREY_117,
                          size: 'sm',
                        }}
                      />
                    </Tooltip>
                  </span>
                </div>,
              );
              break;

            case 'Location Service':
              domStr = ReactDomServer.renderToString(
                <div className="labelTooltipWrapper">
                  <span className="boldLable">
                    {this.value}
                    <Tooltip
                      placement="right"
                      title="Reports whether location service was on and providing GPS updates to the app."
                    >
                      <InfoCircleOutlined
                        style={{
                          color: GREY_117,
                          size: '12px',
                        }}
                      />
                    </Tooltip>
                  </span>
                </div>,
              );
              break;

            default:
              break;
          }

          return domStr;
        },
        useHTML: true,
      },
    },
    xAxis:
      factor === 0 || props.unavailableDate
        ? [
            {
              currentDateIndicator: false,
              alternateGridColor: '#F7F7F7',
              min: startDate,
              max: endDate,
              scrollbar: {
                enabled: true,
                liveRedraw: false,
              },
              dateTimeLabelFormats: {
                week: '%d %b',
                day: {
                  list: ['%d %b', '%d %b', '%d'],
                },
              },
            },
          ]
        : [
            {
              currentDateIndicator: false,
              alternateGridColor: '#F7F7F7',
              tickInterval: factor * 24 * 3600 * 1000,
              scrollbar: {
                enabled: false,
              },
              dateTimeLabelFormats: {
                week: '%d %b',
                day: {
                  list: ['%d %b', '%d %b', '%d'],
                },
              },
            },
          ],
    tooltip: {
      xDateFormat: '%a %b %d',
      backgroundColor: 'rgba(18, 32, 56, 0.8)',
      textAlign: 'center',
      borderColor: 'none',
      shape: 'square',
      style: {
        color: WHITE,
        fontSize: '12px',
      },
    },
    rangeSelector: {
      enabled: false,
    },
    series: props.data,
  };

  React.useEffect(() => {
    // Highcharts.ganttChart(props.id, config)
  }, []);

  return (
    <div id={props.id} style={{ width: '100%' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={config}
        constructorType="ganttChart"
      />
      <LegendsWrapper>
        {props.data &&
          props.data?.map((permission) => (
            <Legend
              key={`gantt_chart_legend_${permission.name}`}
              text={permission.name}
              color={permission.color}
            />
          ))}
      </LegendsWrapper>
    </div>
  );
}

HeartBeat.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          color: PropTypes.string.isRequired,
          start: function start() {
            return (
              typeof Number === 'function'
                ? PropTypes.instanceOf(Number).isRequired
                : PropTypes.any.isRequired
            ).apply(this, arguments);
          },
          end: function end() {
            return (
              typeof Number === 'function'
                ? PropTypes.instanceOf(Number).isRequired
                : PropTypes.any.isRequired
            ).apply(this, arguments);
          },
          y: PropTypes.number.isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  ),
  categories: PropTypes.arrayOf(PropTypes.any).isRequired,
  collapsed: PropTypes.bool,
  activityPermissionsDisabled: PropTypes.bool,
  dateRange: PropTypes.shape({
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
  }).isRequired,
  unavailableDate: PropTypes.bool,
};

// Same approach for defaultProps too
HeartBeat.defaultProps = {
  collapsed: true,
  activityPermissionsDisabled: false,
  unavailableDate: false,
  data: [],
};

export default HeartBeat;
