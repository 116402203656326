const resultsMap = {
  no_overlap_trip: {
    label: 'Non-overlapping Trips',
    tooltip_text:
      "The timestamp interval reported does not overlap at all with Zendrive's recorded timestamps",
  },
  missing_drivers: '',
  matched_trip: {
    label: 'Matched Trips',
    tooltip_text: 'Trips that meet all matching criteria',
  },
  matched_trip_but_not_insurance_period: {
    label: 'Mismatching Periods',
    tooltip_text:
      'Customer reported Period does not match the Zendrives recorded Period',
  },
  insufficient_overlap_trip: {
    label: 'Partially overlapping Trips',
    tooltip_text:
      'The time duration overlap percentage does not meet the minimum threshold required to count a trip as a match',
  },
  invalid_data: {
    label: 'Invalid Trips',
    tooltip_text: 'Start Timestamp >= End Timestamp',
  },
  ignored_short_trips: '',
  automatic_trip_drivers: {
    label: 'Automatic Trip Drivers',
    tooltip_text: 'Number of Drivers with automatic trips',
  },
  missing_alias_drivers: {
    label: 'Missing Alias Drivers',
    tooltip_text: 'Number of Drivers with missing aliases',
  },
};

const headers = [
  { label: 'Driver ID', key: 'driver_id' },
  { label: 'SDK Start Timestamp', key: 'zd_start_timestamp' },
  { label: 'SDK End Timestamp', key: 'zd_end_timestamp' },
  { label: 'Customer Start Timestamp', key: 'start_timestamp' },
  { label: 'Customer End Timestamp', key: 'end_timestamp' },
  { label: 'Match Percentage', key: 'match_percent' },
  { label: 'SDK Period', key: 'zd_period' },
  { label: 'Customer Period', key: 'period' },
  { label: 'Mismatch reason', key: 'missmatch_reason' },
];

const driverArgs = [
  ['missing_drivers', 'Missing Drivers'],
  ['automatic_trip_drivers', 'Automatic Trip Drivers'],
  ['missing_alias_drivers', 'Missing Alias Drivers'],
];

const defaultTripDetails = {
  driver_id: '',
  zd_start_timestamp: '',
  zd_end_timestamp: '',
  start_timestamp: '',
  end_timestamp: '',
  match_percent: '',
  zd_period: '',
  period: '',
};

export { defaultTripDetails, driverArgs, headers, resultsMap };
