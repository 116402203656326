Object.defineProperty(exports, '__esModule', {
  value: true,
});
exports.getEventIcon = getEventIcon;
exports.filterEvents = filterEvents;
exports.bpfrpt_proptype_DrivingEvent =
  exports.default =
  exports.generateSpeedingEventPathLayer =
  exports.generateTripPathLayer =
  exports.generateMarker =
  exports.generatePopupOptions =
    void 0;

const _react = _interopRequireDefault(require('react'));

const _mapboxGl = _interopRequireDefault(require('mapbox-gl'));

require('mapbox-gl/dist/mapbox-gl.css');

const _colors = require('../../assets/style/colors');

const { extractRgb } = _interopRequireDefault(require('../charts/utils'));

const _convertLocationsToLatLngs = _interopRequireWildcard(
  require('../../utils/convertLocationsToLatLngs'),
);

const _acceleration = _interopRequireDefault(
  require('./icons/svgs/acceleration.svg'),
);

const _hardBrake = _interopRequireDefault(
  require('./icons/svgs/hard-brake.svg'),
);

const _phoneUse = _interopRequireDefault(require('./icons/svgs/phone-use.svg'));

const _speeding = _interopRequireDefault(require('./icons/svgs/speeding.svg'));

const _hardTurn = _interopRequireDefault(require('./icons/svgs/hard-turn.svg'));

const _end2x = _interopRequireDefault(require('./icons/pngs/end@2x.png'));

const _collision = _interopRequireDefault(
  require('./icons/svgs/collision.svg'),
);

const _hover = _interopRequireDefault(require('./icons/pngs/hover.png'));

const { Wrapper } = _interopRequireDefault(require('./Wrapper'));

const _propTypes = _interopRequireDefault(require('prop-types'));

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }
  const newObj = {};
  if (obj != null) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const desc =
          Object.defineProperty && Object.getOwnPropertyDescriptor
            ? Object.getOwnPropertyDescriptor(obj, key)
            : {};
        if (desc.get || desc.set) {
          Object.defineProperty(newObj, key, desc);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
  }
  newObj.default = obj;
  return newObj;
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

function _typeof(obj) {
  if (typeof Symbol === 'function' && typeof Symbol.iterator === 'symbol') {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj &&
        typeof Symbol === 'function' &&
        obj.constructor === Symbol &&
        obj !== Symbol.prototype
        ? 'symbol'
        : typeof obj;
    };
  }
  return _typeof(obj);
}

function _slicedToArray(arr, i) {
  return (
    _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest()
  );
}

function _nonIterableRest() {
  throw new TypeError('Invalid attempt to destructure non-iterable instance');
}

function _iterableToArrayLimit(arr, i) {
  const _arr = [];
  let _n = true;
  let _d = false;
  let _e;
  try {
    for (
      var _i = arr[Symbol.iterator](), _s;
      !(_n = (_s = _i.next()).done);
      _n = true
    ) {
      _arr.push(_s.value);
      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i.return != null) _i.return();
    } finally {
      if (_d) throw _e;
    }
  }
  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _defineProperties(target, props) {
  for (let i = 0; i < props.length; i++) {
    const descriptor = props[i];
    descriptor.enumerable = descriptor.enumerable || false;
    descriptor.configurable = true;
    if ('value' in descriptor) descriptor.writable = true;
    Object.defineProperty(target, descriptor.key, descriptor);
  }
}

function _createClass(Constructor, protoProps, staticProps) {
  if (protoProps) _defineProperties(Constructor.prototype, protoProps);
  if (staticProps) _defineProperties(Constructor, staticProps);
  return Constructor;
}

function _possibleConstructorReturn(self, call) {
  if (call && (_typeof(call) === 'object' || typeof call === 'function')) {
    return call;
  }
  return _assertThisInitialized(self);
}

function _getPrototypeOf(o) {
  _getPrototypeOf = Object.setPrototypeOf
    ? Object.getPrototypeOf
    : function _getPrototypeOf(o) {
        return o.__proto__ || Object.getPrototypeOf(o);
      };
  return _getPrototypeOf(o);
}

function _assertThisInitialized(self) {
  if (self === void 0) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called",
    );
  }
  return self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError('Super expression must either be null or a function');
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: { value: subClass, writable: true, configurable: true },
  });
  if (superClass) _setPrototypeOf(subClass, superClass);
}

function _setPrototypeOf(o, p) {
  _setPrototypeOf =
    Object.setPrototypeOf ||
    function _setPrototypeOf(o, p) {
      o.__proto__ = p;
      return o;
    };
  return _setPrototypeOf(o, p);
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value,
      enumerable: true,
      configurable: true,
      writable: true,
    });
  } else {
    obj[key] = value;
  }
  return obj;
}

// TODO: Add this token to the node environment
_mapboxGl.default.accessToken =
  'pk.eyJ1IjoibWF5dXItamFkaGF2IiwiYSI6ImNreDc0bmExdTFubHczMG8xYWN3NnVidXAifQ.s3duqTSQeEicFZ7OFTlnPg';
const bpfrpt_proptype_DrivingEvent = {
  latitude: _propTypes.default.number.isRequired,
  longitude: _propTypes.default.number.isRequired,
  eventType: _propTypes.default.string.isRequired,
  data: _propTypes.default.string.isRequired,
  description: _propTypes.default.string,
};
exports.bpfrpt_proptype_DrivingEvent = bpfrpt_proptype_DrivingEvent;

const generatePopupOptions = function generatePopupOptions() {
  const markerHeight = 30;
  const markerRadius = 10;
  const linearOffset = 25;
  const popupOffsets = {
    top: [0, 0],
    'top-left': [0, 0],
    'top-right': [0, 0],
    bottom: [0, -(markerHeight + 15)],
    'bottom-left': [
      linearOffset,
      (markerHeight - markerRadius + linearOffset) * -1,
    ],
    'bottom-right': [
      -linearOffset,
      (markerHeight - markerRadius + linearOffset) * -1,
    ],
    left: [markerRadius, (markerHeight - markerRadius) * -1],
    right: [-markerRadius, (markerHeight - markerRadius) * -1],
  };
  return popupOffsets;
};

exports.generatePopupOptions = generatePopupOptions;

const generateMarker = function generateMarker(event) {
  return new _mapboxGl.default.Marker()
    .setLngLat([event.longitude, event.latitude])
    .setPopup();
};

exports.generateMarker = generateMarker;

const generateTripPathLayer = function generateTripPathLayer(
  coordinates,
  color,
  thickness,
  type,
) {
  if (!coordinates) {
    return null;
  }

  return {
    id: 'zendrive-'.concat(type, '-path'),
    type: 'line',
    source: {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: (0, _convertLocationsToLatLngs.default)(coordinates),
        },
      },
    },
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': color,
      'line-width': thickness,
    },
  };
};

exports.generateTripPathLayer = generateTripPathLayer;

const generateSpeedingEventPathLayer = function generateSpeedingEventPathLayer(
  speedingEventCoordinates,
  pathId,
) {
  if (!speedingEventCoordinates) {
    return null;
  }

  return {
    id: pathId,
    type: 'line',
    source: {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: (0, _convertLocationsToLatLngs.default)(
            speedingEventCoordinates,
          ),
        },
      },
    },
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': 'rgb('.concat(extractRgb(_colors.ACCENT_2), ')'),
      'line-width': 4,
    },
  };
};

exports.generateSpeedingEventPathLayer = generateSpeedingEventPathLayer;

function getEventIcon(eventType) {
  const eventIconMap = {
    'HARD BRAKING': _hardBrake.default,
    'PHONE USE': _phoneUse.default,
    SPEEDING: _speeding.default,
    ACCELERATION: _acceleration.default,
    'HARD TURN': _hardTurn.default,
    COLLISION: _collision.default,
    'PHONE SCREEN INTERACTION': _phoneUse.default,
  };
  return eventIconMap[eventType] || '';
} // create a DOM element for the marker

function createElement(className, icon, height, width) {
  const el = document.createElement('div');
  el.className = className;
  el.style.backgroundImage = 'url('.concat(icon, ')');
  el.style.width = ''.concat(height, 'px');
  el.style.height = ''.concat(width, 'px');
  el.style.backgroundSize = 'contain';
  el.style.backgroundRepeat = 'no-repeat';
  return el;
}

function removeSelectedPointMarker(selectedPointMarker) {
  if (selectedPointMarker) selectedPointMarker.remove();
} // eslint-disable-next-line class-methods-use-this

function filterEvents(markers) {
  return markers.filter((eventMarker) => eventMarker.eventType);
}

function addSelectedPointMarker(coordinates) {
  const marker = createElement('selected-marker', _hover.default, 20, 20);
  return new _mapboxGl.default.Marker(marker).setLngLat(coordinates);
}

function clearPathAndEventMarkers(context) {
  // clear all existing markers and path
  context.map.getStyle().layers.forEach((layer) => {
    // remove the trip path and speeding event path layers which have a zendrive- prefix
    if (layer.id.indexOf('zendrive-') !== -1) {
      context.map.removeLayer(layer.id);
      context.map.removeSource(layer.id);
    }
  }); // clear existing markers

  if (context.allMarkers) {
    context.allMarkers.forEach((marker) => {
      marker.remove();
    });
  } // clear existing popups

  if (context.allPopups) {
    context.allPopups.forEach((popup) => {
      popup.remove();
    });
  }
}

const Map =
  /* #__PURE__ */
  (function (_React$Component) {
    _inherits(Map, _React$Component);

    function Map() {
      let _getPrototypeOf2;

      let _this;

      _classCallCheck(this, Map);

      for (
        var _len = arguments.length, args = new Array(_len), _key = 0;
        _key < _len;
        _key++
      ) {
        args[_key] = arguments[_key];
      }

      _this = _possibleConstructorReturn(
        this,
        (_getPrototypeOf2 = _getPrototypeOf(Map)).call.apply(
          _getPrototypeOf2,
          [this].concat(args),
        ),
      );

      _defineProperty(
        _assertThisInitialized(_this),
        'addPathAndEvents',
        function () {
          const fitBounds =
            arguments.length > 0 && arguments[0] !== undefined
              ? arguments[0]
              : false;
          clearPathAndEventMarkers(_assertThisInitialized(_this));
          const _this$props = _this.props;
          const { primaryTrail } = _this$props;
          const { secondaryTrail } = _this$props;
          const tripPathLatLngs = (0, _convertLocationsToLatLngs.default)(
            primaryTrail.coordinates,
          ); // init reference for all markers and popups, to remove later

          _this.allMarkers = [];
          _this.allPopups = []; // Add trip path to the map as a layer

          if (primaryTrail.coordinates.length > 0) {
            _this.map.addLayer(
              generateTripPathLayer(
                primaryTrail.coordinates,
                primaryTrail.color,
                primaryTrail.thickness,
                'primary',
              ),
            );
          }

          if (secondaryTrail && secondaryTrail.coordinates.length > 0) {
            _this.map.addLayer(
              generateTripPathLayer(
                secondaryTrail.coordinates,
                secondaryTrail.color,
                secondaryTrail.thickness,
                'secondary',
              ),
            );
          } // add end icon with the last pair of coordinates

          if (tripPathLatLngs.length > 0) {
            const endElement = createElement('marker', _end2x.default, 20, 20);

            _this.allMarkers.push(
              new _mapboxGl.default.Marker(endElement)
                .setLngLat(tripPathLatLngs[tripPathLatLngs.length - 1])
                .addTo(_this.map),
            );

            const popup = new _mapboxGl.default.Popup({
              offset: {
                bottom: [0, -15],
              },
              closeButton: false,
              closeOnClick: false,
            });
            endElement.addEventListener('mouseenter', () => {
              // Change the cursor style as a UI indicator.
              _this.map.getCanvas().style.cursor = 'pointer'; // Populate the popup and set its coordinates
              // based on the feature found.

              popup
                .setLngLat(tripPathLatLngs[tripPathLatLngs.length - 1])
                .setHTML('<h1 class="zd-mapbox-title">TRIP END</h1>')
                .addTo(_this.map);
            }); // remove popup on hover (mouseleave)

            endElement.addEventListener('mouseleave', () => {
              _this.map.getCanvas().style.cursor = '';
              popup.remove();
            });
          }

          const filteredEvents = filterEvents(_this.props.eventMarkers); // Mapbox data for Event Markers

          if (_this.props.eventMarkers.length > 0) {
            const geojson = {
              type: 'FeatureCollection',
              features: filteredEvents.map((eventMarker) => {
                let description = '\n          <h1 class="zd-mapbox-title">'
                  .concat(
                    eventMarker.eventType.toUpperCase(),
                    '</h1>\n          <span class="zd-mapbox-data">',
                  )
                  .concat(
                    eventMarker.data,
                    '</span>\n          <span class="zd-mapbox-description">',
                  )
                  .concat(eventMarker.description, '</span>\n          ');

                if (eventMarker.eventType.toUpperCase() === 'SPEEDING') {
                  description = '\n            <h1 class="zd-mapbox-title">'
                    .concat(
                      eventMarker.eventType.toUpperCase(),
                      '</h1>\n            <span class="zd-mapbox-speed">Driver: <span class="zd-mapbox-max-speed">',
                    )
                    .concat(
                      eventMarker.maxSpeed,
                      '</span></span>\n            <span class="zd-mapbox-speed">Limit: <span class="zd-mapbox-posted-speed">',
                    )
                    .concat(eventMarker.postedSpeed, '</span>\n            ')
                    .concat(
                      ' <span class="zd-mapbox-speed">Duration: <span class="zd-mapbox-max-seed">',
                      eventMarker.duration,
                      '</span></span>\n            ',
                    )
                    .concat(
                      '<span class="zd-mapbox-description">',
                      eventMarker.data,
                      '</span>\n            ',
                    );
                }

                return {
                  type: 'Feature',
                  properties: {
                    iconSize: [28, 38],
                    description,
                  },
                  geometry: {
                    type: 'Point',
                    coordinates: [eventMarker.longitude, eventMarker.latitude],
                  },
                };
              }),
            }; // add event markers to map

            geojson.features.forEach((marker, i) => {
              const eventmarker = filteredEvents[i];
              // Change this condition to SPEEDING, to enable overspeed trail
              if (eventmarker.eventType === 'SPEEDING_REMOVED') {
                // add the speeding path layer to the map
                // as the trip path timestamps and the event timestamps don't exactly match, add buffer of 10 seconds before the
                // event stand and 10 seconds after the event end, for increase the matching probability
                const tripPathForSpeedingEvent =
                  primaryTrail.coordinates.filter(
                    (pathItem) =>
                      +pathItem.time_millis + 10000 >=
                        eventmarker.startTimeMillis &&
                      +pathItem.time_millis - 10000 <=
                        eventmarker.endTimeMillis,
                  ); // every speeding path layer needs to have an unique id
                const speedingPathId = 'zendrive-speeding-path-'.concat(
                  tripPathForSpeedingEvent[0].latitude,
                );

                _this.map.addLayer(
                  generateSpeedingEventPathLayer(
                    tripPathForSpeedingEvent,
                    speedingPathId,
                  ),
                ); // add popup to this speeding segment
                // Change the cursor to a pointer when the mouse is over the places layer.
                // Create a popup, but don't add it to the map yet.

                const _popup = new _mapboxGl.default.Popup({
                  closeButton: false,
                  closeOnClick: false,
                });

                _this.allPopups.push(_popup); // find a mid-point of the speed segment to add the tooltip
                // adding the tooltip to the start or end makes the position look odd

                const tooltipLatLng =
                  tripPathForSpeedingEvent[
                    Math.round(tripPathForSpeedingEvent.length / 2)
                  ];

                _this.map.on('mouseenter', speedingPathId, () => {
                  _this.map.getCanvas().style.cursor = 'pointer';

                  _popup
                    .setLngLat([
                      tooltipLatLng.longitude,
                      tooltipLatLng.latitude,
                    ])
                    .setHTML(marker.properties.description)
                    .addTo(_this.map);
                }); // Change it back to a pointer when it leaves.

                _this.map.on('mouseleave', speedingPathId, () => {
                  _this.map.getCanvas().style.cursor = '';

                  _popup.remove();
                });
              } else {
                const icon = getEventIcon(filteredEvents[i].eventType); // create a DOM element for the marker

                const markerElement = createElement(
                  'marker',
                  icon,
                  marker.properties.iconSize[0],
                  marker.properties.iconSize[1],
                ); // Create a popup, but don't add it to the map yet.

                const _popup2 = new _mapboxGl.default.Popup({
                  offset: generatePopupOptions(),
                  closeButton: false,
                  closeOnClick: false,
                });

                _this.allPopups.push(_popup2); // add popup on hover (mouseenter)

                markerElement.addEventListener('mouseenter', () => {
                  // Change the cursor style as a UI indicator.
                  _this.map.getCanvas().style.cursor = 'pointer'; // Populate the popup and set its coordinates
                  // based on the feature found.

                  _popup2
                    .setLngLat(marker.geometry.coordinates)
                    .setHTML(marker.properties.description)
                    .addTo(_this.map);
                });

                if (
                  _this.props.onCollisonEventClick &&
                  eventmarker.eventType === 'COLLISION'
                ) {
                  markerElement.addEventListener('click', () => {
                    const _marker$geometry$coor = _slicedToArray(
                      marker.geometry.coordinates,
                      2,
                    );
                    const longitude = _marker$geometry$coor[0];
                    const latitude = _marker$geometry$coor[1]; // $FlowFixMe (onCollisonEventClick will not be undefined here due to prior check)

                    _this.props.onCollisonEventClick({
                      longitude,
                      latitude,
                    });
                  });
                } // add popup on hover (mouseleave)

                markerElement.addEventListener('mouseleave', () => {
                  _this.map.getCanvas().style.cursor = '';

                  _popup2.remove();
                }); // add marker to map

                _this.allMarkers.push(
                  new _mapboxGl.default.Marker(markerElement, {
                    offset: [0, -20],
                  })
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(_this.map),
                );
              }
            });
          } // fit map to bounds

          if (tripPathLatLngs.length > 0 && fitBounds) {
            const bounds = new _mapboxGl.default.LngLatBounds();
            tripPathLatLngs.forEach((coordinates) => {
              bounds.extend(coordinates);
            });

            _this.map.fitBounds(bounds, {
              padding: 100,
            });
          }
        },
      );

      _defineProperty(_assertThisInitialized(_this), 'mapContainer', void 0);

      _defineProperty(_assertThisInitialized(_this), 'map', void 0);

      _defineProperty(_assertThisInitialized(_this), 'allMarkers', void 0);

      _defineProperty(_assertThisInitialized(_this), 'allPopups', void 0);

      _defineProperty(
        _assertThisInitialized(_this),
        'selectedPointMarker',
        void 0,
      );

      return _this;
    }

    _createClass(Map, [
      {
        key: 'componentDidMount',
        value: function componentDidMount() {
          const _this2 = this;

          this.map = new _mapboxGl.default.Map({
            container: this.mapContainer,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [this.props.centerLon, this.props.centerLat],
            zoom: this.props.zoom,
          }); // Add zoom and rotation controls to the map.

          this.map.addControl(new _mapboxGl.default.NavigationControl());
          this.map.on('style.load', () => {
            _this2.addPathAndEvents(true);

            if (_this2.props.selectedPoint) {
              const _this2$props$selected = _this2.props.selectedPoint;
              const { longitude } = _this2$props$selected;
              const { latitude } = _this2$props$selected;
              _this2.selectedPointMarker = addSelectedPointMarker([
                longitude,
                latitude,
              ]).addTo(_this2.map);
            }
          });
        },
      },
      {
        key: 'componentDidUpdate',
        value: function componentDidUpdate(prevProps) {
          if (this.map.isStyleLoaded()) {
            if (
              this.props.primaryTrail !== prevProps.primaryTrail ||
              this.props.secondaryTrail !== prevProps.secondaryTrail
            ) {
              this.addPathAndEvents();
            }

            if (
              this.props.selectedPoint &&
              this.props.selectedPoint !== prevProps.selectedPoint
            ) {
              const _this$props$selectedP = this.props.selectedPoint;
              const { longitude } = _this$props$selectedP;
              const { latitude } = _this$props$selectedP;
              removeSelectedPointMarker(this.selectedPointMarker);
              this.selectedPointMarker = addSelectedPointMarker([
                longitude,
                latitude,
              ]).addTo(this.map);
            }
          }
        },
      },
      {
        key: 'render',
        value: function render() {
          const _this3 = this;

          const mapStyle = {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            overflow: 'hidden',
            minHeight: '500px',
          };
          /* eslint-disable no-return-assign */

          return _react.default.createElement(
            Wrapper,
            null,
            _react.default.createElement('div', {
              ref: function ref(el) {
                return (_this3.mapContainer = el);
              },
              style: mapStyle,
            }),
          );
          /* eslint-enable no-return-assign */
        },
      },
      {
        key: '__reactstandin__regenerateByEval',
        // @ts-ignore
        value: function __reactstandin__regenerateByEval(key, code) {
          // @ts-ignore
          this[key] = eval(code);
        },
      },
    ]);

    return Map;
  })(_react.default.Component);

_defineProperty(Map, 'map', null);

_defineProperty(Map, 'allMarkers', []);

_defineProperty(Map, 'selectedPointMarker', null);

_defineProperty(Map, 'allPopups', []);

_defineProperty(Map, 'propTypes', {
  centerLat: _propTypes.default.number.isRequired,
  centerLon: _propTypes.default.number.isRequired,
  zoom: _propTypes.default.number.isRequired,
  eventMarkers: _propTypes.default.arrayOf(
    _propTypes.default.shape({
      latitude: _propTypes.default.number.isRequired,
      longitude: _propTypes.default.number.isRequired,
      eventType: _propTypes.default.string.isRequired,
      data: _propTypes.default.string.isRequired,
      description: _propTypes.default.string,
    }).isRequired,
  ).isRequired,
  primaryTrail: _propTypes.default.shape({
    coordinates: _propTypes.default.arrayOf(function () {
      return (
        typeof _convertLocationsToLatLngs.bpfrpt_proptype_Location ===
        'function'
          ? _convertLocationsToLatLngs.bpfrpt_proptype_Location.isRequired
            ? _convertLocationsToLatLngs.bpfrpt_proptype_Location.isRequired
            : _convertLocationsToLatLngs.bpfrpt_proptype_Location
          : _propTypes.default.shape(
              _convertLocationsToLatLngs.bpfrpt_proptype_Location,
            ).isRequired
      ).apply(this, arguments);
    }).isRequired,
    color: _propTypes.default.string.isRequired,
    thickness: _propTypes.default.number.isRequired,
  }).isRequired,
  secondaryTrail: _propTypes.default.shape({
    coordinates: _propTypes.default.arrayOf(function () {
      return (
        typeof _convertLocationsToLatLngs.bpfrpt_proptype_Location ===
        'function'
          ? _convertLocationsToLatLngs.bpfrpt_proptype_Location.isRequired
            ? _convertLocationsToLatLngs.bpfrpt_proptype_Location.isRequired
            : _convertLocationsToLatLngs.bpfrpt_proptype_Location
          : _propTypes.default.shape(
              _convertLocationsToLatLngs.bpfrpt_proptype_Location,
            ).isRequired
      ).apply(this, arguments);
    }).isRequired,
    color: _propTypes.default.string.isRequired,
    thickness: _propTypes.default.number.isRequired,
  }),
  onCollisonEventClick: _propTypes.default.func,
  selectedPoint: _propTypes.default.shape({
    latitude: _propTypes.default.number.isRequired,
    longitude: _propTypes.default.number.isRequired,
  }),
});

const _default = Map;
const _default2 = _default;
exports.default = _default2;
Map.__docgenInfo = {
  description: '',
  methods: [
    {
      name: 'addPathAndEvents',
      docblock: null,
      modifiers: [],
      params: [
        {
          name: 'fitBounds',
          type: null,
        },
      ],
      returns: null,
    },
  ],
  displayName: 'Map',
  props: {
    centerLat: {
      required: true,
      flowType: {
        name: 'number',
      },
      description: '',
    },
    centerLon: {
      required: true,
      flowType: {
        name: 'number',
      },
      description: '',
    },
    zoom: {
      required: true,
      flowType: {
        name: 'number',
      },
      description: '',
    },
    eventMarkers: {
      required: true,
      flowType: {
        name: 'Array',
        elements: [
          {
            name: 'signature',
            type: 'object',
            raw: '{\n  latitude: number,\n  longitude: number,\n  eventType: string,\n  data: string,\n  description?: string\n}',
            signature: {
              properties: [
                {
                  key: 'latitude',
                  value: {
                    name: 'number',
                    required: true,
                  },
                },
                {
                  key: 'longitude',
                  value: {
                    name: 'number',
                    required: true,
                  },
                },
                {
                  key: 'eventType',
                  value: {
                    name: 'string',
                    required: true,
                  },
                },
                {
                  key: 'data',
                  value: {
                    name: 'string',
                    required: true,
                  },
                },
                {
                  key: 'description',
                  value: {
                    name: 'string',
                    required: false,
                  },
                },
              ],
            },
          },
        ],
        raw: 'Array<DrivingEvent>',
      },
      description: '',
    },
    primaryTrail: {
      required: true,
      flowType: {
        name: 'signature',
        type: 'object',
        raw: '{ coordinates: Array<Location>, color: string, thickness: number }',
        signature: {
          properties: [
            {
              key: 'coordinates',
              value: {
                name: 'Array',
                elements: [
                  {
                    name: 'Location',
                  },
                ],
                raw: 'Array<Location>',
                required: true,
              },
            },
            {
              key: 'color',
              value: {
                name: 'string',
                required: true,
              },
            },
            {
              key: 'thickness',
              value: {
                name: 'number',
                required: true,
              },
            },
          ],
        },
      },
      description: '',
    },
    secondaryTrail: {
      required: false,
      flowType: {
        name: 'signature',
        type: 'object',
        raw: '{ coordinates: Array<Location>, color: string, thickness: number }',
        signature: {
          properties: [
            {
              key: 'coordinates',
              value: {
                name: 'Array',
                elements: [
                  {
                    name: 'Location',
                  },
                ],
                raw: 'Array<Location>',
                required: true,
              },
            },
            {
              key: 'color',
              value: {
                name: 'string',
                required: true,
              },
            },
            {
              key: 'thickness',
              value: {
                name: 'number',
                required: true,
              },
            },
          ],
        },
      },
      description: '',
    },
    onCollisonEventClick: {
      required: false,
      flowType: {
        name: 'signature',
        type: 'function',
        raw: '({ longitude: number, latitude: number }) => void',
        signature: {
          arguments: [
            {
              name: '',
              type: {
                name: 'signature',
                type: 'object',
                raw: '{ longitude: number, latitude: number }',
                signature: {
                  properties: [
                    {
                      key: 'longitude',
                      value: {
                        name: 'number',
                        required: true,
                      },
                    },
                    {
                      key: 'latitude',
                      value: {
                        name: 'number',
                        required: true,
                      },
                    },
                  ],
                },
              },
            },
          ],
          return: {
            name: 'void',
          },
        },
      },
      description: '',
    },
    selectedPoint: {
      required: false,
      flowType: {
        name: 'signature',
        type: 'object',
        raw: '{ latitude: number, longitude: number }',
        signature: {
          properties: [
            {
              key: 'latitude',
              value: {
                name: 'number',
                required: true,
              },
            },
            {
              key: 'longitude',
              value: {
                name: 'number',
                required: true,
              },
            },
          ],
        },
      },
      description: '',
    },
  },
};
// # sourceMappingURL=index.js.map
