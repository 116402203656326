import Highcharts from 'highcharts';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { BLACK, GREY_230, WHITE } from '../../assets/style/colors';
import { calculateChartAxisStep, extractRgb, formatNumber, zip } from './utils';

function LineChart({ id, xAxisLabelFormat = 'MMM D', ...props }) {
  const xAxisLabel = {
    formatter: function formatter() {
      return moment(this.value).format(xAxisLabelFormat).toUpperCase();
    },
  };

  const yAxisLabel = (
    yAxisMinimum,
    yAxisLabelVisibilityThreshold = 0,
    prefix = '',
    yAxisLabelFormatter = undefined,
  ) => ({
    formatter: function formatter() {
      if (this.value === yAxisMinimum) {
        return 0;
      }
      if (this.value >= yAxisLabelVisibilityThreshold) {
        const formattedValue = yAxisLabelFormatter(this.value);
        return ''.concat(prefix).concat(formattedValue);
      }

      return null;
    },
  });

  const tooltip = (
    label,
    transform,
    asDate = false,
    asDateRange = false,
    prefix = '',
    roundDecimalDigits = undefined,
    tooltipLabelFormat = 'MMM D, YYYY',
  ) => ({
    formatter: function formatter() {
      if (label === undefined && transform === undefined) {
        throw new Error(
          'One of dynamicTooltipLabel and toolTipLabel must be provided',
        );
      }

      let date = this.point.options.name;

      if (asDate || asDateRange) {
        date = moment(this.point.options.name).format(tooltipLabelFormat);
      }

      const currentValue = this.point.options.y;
      let adjective = label || ''; // check if label and transform are not provided

      if (transform) {
        adjective = transform(currentValue);
      }

      return '\n      <div class="tooltip_date">'
        .concat(date, '</div>\n        <div class="tooltip_value">')
        .concat(prefix)
        .concat(
          formatNumber(currentValue, roundDecimalDigits),
          '</div>\n      <div class="tooltip_label">',
        )
        .concat(adjective, '</div>\n      ');
    },
    backgroundColor: null,
    borderWidth: 0,
    shadow: false,
    useHTML: true,
    style: {
      padding: 0,
    },
  });

  const getChartConfig = () => {
    const { height, visibleXAxis, xAxis, withXAxisLabels, withYAxisLabels } =
      props;

    return {
      chart: {
        type: 'area',
        height,
      },
      title: {
        text: null,
      },
      xAxis: {
        visible: visibleXAxis,
        categories: xAxis,
        labels: {
          enabled: withXAxisLabels,
          formatter:
            props.xAxisAsDate || props.xAxisAsDateRange
              ? xAxisLabel.formatter
              : false,
          style: {
            color: BLACK,
            fontSize: '12px',
          },
          autoRotation: false,
          step: calculateChartAxisStep(
            props.xAxis.length,
            props.xAxisLabelCount,
          ),
          y: 40,
        },
        tickLength: 10,
      },
      yAxis: {
        gridLineColor: props.transparentGridLines ? 'transparent' : GREY_230,
        title: {
          text: null,
        },
        labels: {
          enabled: withYAxisLabels,
          formatter: yAxisLabel(
            props.yAxisMin,
            props.yAxisLabelVisibilityThreshold,
            props.yAxisPrefix,
            props.yAxisLabelFormatter,
          ).formatter,
          style: {
            color: BLACK,
            fontSize: '12px',
          },
        },
        tickLength: props.tickLength,
        tickInterval: props.tickInterval,
        min: props.yAxisMin,
        max: props.yAxisMax,
      },
      tooltip: tooltip(
        props.tooltipLabel,
        props.dynamicTooltipLabel,
        props.xAxisAsDate,
        props.xAxisAsDateRange,
        props.yAxisPrefix,
        props.roundDecimalDigits,
        props.tooltipLabelFormat,
      ),
      legend: {
        enabled: false,
      },
      plotOptions: {
        area: {
          animation: false,
          marker: {
            enabled: props.xAxis.length === 1,
            symbol: 'circle',
            fillColor: props.markerColor,
            radius: 6,
            states: {
              hover: {
                enabled: true,
              },
            },
          },
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [0, 'rgba('.concat(extractRgb(props.chartColor), ', 0.1)')],
              [1, WHITE],
            ],
          },
          lineColor: props.chartColor,
          lineWidth: 2,
        },
      },
      series: [
        {
          data: zip(props.xAxis, props.yAxis),
        },
      ],
      credits: {
        enabled: false,
      },
    };
  };

  React.useEffect(() => {
    Highcharts.chart(id, getChartConfig());
  }, [id, props]);

  return <div id={id} style={{ width: '100%' }} />;
}

LineChart.propTypes = {
  id: PropTypes.string.isRequired,
  xAxis: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  yAxis: PropTypes.arrayOf(PropTypes.number, PropTypes.oneOf([null])),
  xAxisLabelCount: PropTypes.number,
  yAxisLabelCount: PropTypes.number,
  tooltipLabel: PropTypes.string,
  dynamicTooltipLabel: PropTypes.func,
  chartColor: PropTypes.string.isRequired,
  markerColor: PropTypes.string.isRequired,
  transparentGridLines: PropTypes.bool,
  withXAxisLabels: PropTypes.bool,
  withYAxisLabels: PropTypes.bool,
  visibleXAxis: PropTypes.bool,
  height: PropTypes.number,
  xAxisAsDate: PropTypes.bool,
  xAxisAsDateRange: PropTypes.bool,
  yAxisMin: PropTypes.number,
  yAxisMax: PropTypes.number,
  tickLength: PropTypes.number,
  tickInterval: PropTypes.number,
  yAxisLabelVisibilityThreshold: PropTypes.number,
  yAxisPrefix: PropTypes.string,
  roundDecimalDigits: PropTypes.number,
  yAxisLabelFormatter: PropTypes.func,
};

// Same approach for defaultProps too
LineChart.defaultProps = {
  xAxisLabelCount: 4,
  transparentGridLines: false,
  withXAxisLabels: true,
  withYAxisLabels: true,
  visibleXAxis: true,
  height: 220,
  xAxisAsDate: false,
  xAxisAsDateRange: false,
  yAxisMin: null,
  yAxisMax: null,
  tickLength: 10,
  tickInterval: undefined,
  yAxisLabelVisibilityThreshold: 0,
  yAxisPrefix: '',
  roundDecimalDigits: 1,
  yAxisLabelFormatter: function yAxisLabelFormatter(value) {
    return value;
  },
};

export default LineChart;
