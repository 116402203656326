import { Typography } from 'antd';
import Link from 'gatsby-link';
import React from 'react';
import TagList from '../../TagsManagement/TagList';

const deviceOwnershipMap = {
  private: 'Private Device (Personal)',
  business_dedicated: 'Business Device (Dedicated)',
  business_shared: 'Business Device (Shared)',
};

const statusFilters = [
  {
    text: 'JOINED',
    value: 'joined',
  },
  {
    text: 'INVITED',
    value: 'invited',
  },
  {
    text: 'DELETED',
    value: 'deleted',
  },
];

const deviceOwnershipFilters = [
  {
    text: 'Private Device (Personal)',
    value: 'private',
  },
  {
    text: 'Business Device (Dedicated)',
    value: 'business_dedicated',
  },
  {
    text: 'Business Device (Shared)',
    value: 'business_shared',
  },
  {
    text: 'Unknown',
    value: '-1',
  },
];

function DriverName({ value, record }) {
  if (!value && !record.id) return '-';

  const driverDetailsLink = `/app/dashboard/driver-details?driverId=${record.id}`;
  return (
    <Link to={driverDetailsLink}>
      <Typography.Text
        ellipsis
        style={{
          color: 'inherit',
        }}
      >
        {value || record.id || '-'}
      </Typography.Text>
    </Link>
  );
}

const DriversManagementColumns = [
  {
    title: 'Driver Name',
    dataIndex: 'alias',
    key: 'alias',
    width: 100,
    render: (value, record) => <DriverName value={value} record={record} />,
  },
  {
    title: 'Tags',
    dataIndex: 'tags',
    key: 'tags',
    filterSearch: true,
    width: 100,
    render: (tags) => <TagList tags={tags} />,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: 100,
    render: (value) => value || '-',
  },
  {
    title: 'Phone Number',
    dataIndex: 'phone_number',
    key: 'phone_number',
    width: 100,
    render: (value) => value || '-',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (value) => (value ? value?.toUpperCase() : '-'),
  },
  {
    title: 'Device Ownership',
    dataIndex: 'device_ownership_type',
    key: 'device_ownership_type',
    render: (value) => (value ? deviceOwnershipMap[value] : '-'),
  },
];

function classifyTags(drivers) {
  const tagMap = {};

  drivers.forEach((driver) => {
    driver.tags.forEach((tag) => {
      if (tagMap[tag.tag_id]) {
        tagMap[tag.tag_id].count += 1;
      } else {
        tagMap[tag.tag_id] = {
          tag_name: tag.tag_name,
          count: 1,
        };
      }
    });
  });

  // Filter the tags based on occurrence in each driver's tag list
  const commonTags = [];
  const nonCommonTags = [];
  const totalDrivers = drivers.length;

  Object.keys(tagMap).forEach((tag) => {
    if (tagMap[tag].count === totalDrivers) {
      commonTags.push(+tag);
    } else {
      nonCommonTags.push(+tag);
    }
  });

  return {
    commonTags,
    nonCommonTags,
  };
}

function remapDriverTags(drivers, selectedTags, indeterminateTags) {
  const selectedSet = new Set(selectedTags);
  const indeterminateSet = new Set(indeterminateTags);

  // Drivers array with updated tags
  const updatedDrivers = drivers.map((driver) => {
    const updatedTags = driver.tags
      .filter(
        ({ tag_id }) => selectedSet.has(tag_id) || indeterminateSet.has(tag_id),
      )
      .map(({ tag_id }) => tag_id);

    const combinedTagIds = [...selectedTags, ...updatedTags];

    // Return updated driver
    return {
      driver_id: driver.driver_id,
      tag_ids: Array.from(new Set(combinedTagIds)),
    };
  });

  return updatedDrivers;
}

export {
  classifyTags,
  deviceOwnershipFilters,
  DriversManagementColumns,
  remapDriverTags,
  statusFilters,
};
