import { CopyOutlined } from '@ant-design/icons';
import { message } from 'antd';
import React from 'react';

export default function KeysInfoItem({ itemKey, title }) {
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    message.info('Key is copied to clipboard');
  };
  return (
    <div className="d-flex align-items-center my-3">
      <div className="profile_card_key">{title}</div>
      <div className="disabled_input">
        <div className="profile_card_value">{itemKey || 'NA'}</div>
        {itemKey && (
          <CopyOutlined
            className="copy_icon"
            onClick={() => handleCopy(itemKey)}
          />
        )}
      </div>
    </div>
  );
}
