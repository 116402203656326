import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { PERFORMANCE_FILTERS } from 'constants';
import React from 'react';
import { PERFORMANCE_GRADE_COLORS } from '../../assets/style/colors';
import '../../assets/style/dashboard/drivers.css';
import './driverScoreGraphstyle.scss';

const colours = {
  coaching: PERFORMANCE_GRADE_COLORS.coaching,
  moderate: PERFORMANCE_GRADE_COLORS.moderate,
  good: PERFORMANCE_GRADE_COLORS.good,
  excellent: PERFORMANCE_GRADE_COLORS.excellent,
};

export default function DriveScoreGraph(props) {
  const {
    zendriveScore,
    grade,
    fair_score_indicator_classname,
    fair_score_indicator_value_classname,
    excellent_score_indicator_classname,
    excellent_score_indicator_value_classname,
    good_score_indicator_classname = 'trip_good_score_indicator',
    good_score_indicator_value_classname = 'trip_good_score_indicator_value',
  } = props;
  const radius = 100;
  const maskStrokeWidth = 16;
  const indicatorStrokeWidth = 14;
  const circumference = Math.round(2 * Math.PI * radius);
  const semiCircumference = Math.round(circumference / 2);
  // patchs are used to differentiate in which range the driver is falling
  const coaching_patch = (semiCircumference * 100) / 100;
  const moderate_patch = (semiCircumference * 75) / 100;
  const good_patch = (semiCircumference * 50) / 100;
  const excellent_patch = (semiCircumference * 25) / 100;

  return (
    <div style={{ position: 'relative', width: '280px', margin: 'auto' }}>
      {/* SCORE GRAPH  */}
      <svg id="meter">
        {/* Outline (Grey) */}
        {/* <circle
            id="outline_curves"
            r={radius}
            cx="50%"
            cy="5%"
            stroke="#f6f6f6"
            strokeWidth={maskStrokeWidth}
            strokeDasharray={`0, ${semiCircumference}, ${circumference}`}
            strokeDashoffset={semiCircumference}
            fill="none"
          /> */}

        {/*  Riskey range */}
        <circle
          id="coaching"
          r={radius}
          cx="50%"
          cy="5%"
          stroke={grade === 'coaching_required' ? colours.coaching : '#f6f6f6'}
          strokeWidth={
            grade === 'coaching_required'
              ? indicatorStrokeWidth
              : maskStrokeWidth
          }
          strokeDasharray={`${coaching_patch}, ${circumference}`}
          fill="none"
        />
        <circle
          id="moderate"
          r={radius}
          cx="50%"
          cy="5%"
          stroke={grade === 'moderate' ? colours.moderate : '#f6f6f6'}
          strokeWidth={
            grade === 'moderate' ? indicatorStrokeWidth : maskStrokeWidth
          }
          strokeDasharray={`${moderate_patch}, ${circumference}`}
          fill="none"
        />
        {/*  Fair */}
        <circle
          id="good"
          r={radius}
          cx="50%"
          cy="5%"
          stroke={grade === 'good' ? colours.good : '#f6f6f6'}
          strokeWidth={
            grade === 'good' ? indicatorStrokeWidth : maskStrokeWidth
          }
          strokeDasharray={`${good_patch}, ${circumference}`}
          fill="none"
        />

        {/* Excellent */}
        <circle
          id="excellent"
          r={radius}
          cx="50%"
          cy="5%"
          stroke={grade === 'excellent' ? colours.excellent : '#f6f6f6'}
          strokeWidth={
            grade === 'excellent' ? indicatorStrokeWidth : maskStrokeWidth
          }
          strokeDasharray={`${excellent_patch}, ${circumference}`}
          fill="none"
        />

        {/* Mask */}
        <circle
          id="mask"
          r={radius}
          cx="50%"
          cy="5%"
          stroke="#f6f6f6"
          strokeWidth={maskStrokeWidth}
          strokeDasharray={`${semiCircumference}, ${circumference}`}
          strokeDashoffset={semiCircumference}
          fill="none"
        />
      </svg>
      <div className="score_graph_value_placement">
        <div className="d-flex justify-content-center">
          <div className="tl-center">
            <p className="score_value">{zendriveScore}</p>
            <p className="score_label">
              {PERFORMANCE_FILTERS[grade] ? (
                <>
                  {PERFORMANCE_FILTERS[grade]?.value}
                  <Tooltip
                    placement="bottom"
                    title={PERFORMANCE_FILTERS[grade]?.info}
                  >
                    <InfoCircleOutlined className="ml-2" />
                  </Tooltip>
                </>
              ) : (
                grade
              )}
            </p>
          </div>
        </div>
      </div>
      {/* POINTS INDICATORS  */}
      <p className="drive-score-graph__min">60</p>
      <div className={fair_score_indicator_classname} />
      <p className={fair_score_indicator_value_classname}>70</p>
      <div className={good_score_indicator_classname} />
      <p className={good_score_indicator_value_classname}>80</p>
      <div className={excellent_score_indicator_classname} />
      <p className={excellent_score_indicator_value_classname}>90</p>
      <p className="drive-score-graph__max">100</p>
    </div>
  );
}
