import { LoadingOutlined } from '@ant-design/icons';
import { Button, Spin, Typography } from 'antd';
import React from 'react';
import ConfirmationModal from '../../../components/shared/confirmationModal';

const { Text } = Typography;

export default function EmailView({
  showModal,
  setShowModal,
  handleEmailView,
  isLoading,
}) {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <ConfirmationModal
      visible={showModal}
      onCancel={() => {
        setShowModal(false);
      }}
      title="Email view to fleet managers"
      footer={[
        <Button
          key="cancel"
          disabled={isLoading}
          onClick={() => {
            setShowModal(false);
          }}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={isLoading}
          onClick={handleEmailView}
        >
          Email View
        </Button>,
      ]}
    >
      {isLoading ? (
        <div className="modal_loading">
          <Text>Sending Email...</Text>
          <Spin indicator={antIcon} />
        </div>
      ) : (
        <div>
          You are about to email this view (as a URL to the dashboard) to all
          the fleet managers and admins in this organisation
          <div style={{ marginTop: '10px' }}> Do you want to continue?</div>
        </div>
      )}
    </ConfirmationModal>
  );
}
