import { Button, Form, Input, Modal, Select } from 'antd';
import React, { useState } from 'react';
import { callPostApi } from '../../../api/axios';
import { roles_enum as ROLES_ENUM } from '../../../utils/roles';
import { toast, toastTypes } from '../../../utils/toast';
import { getUser } from '../../../utils/validateUserToken';

const { Option } = Select;
const RULES = {
  firstName: [
    {
      required: true,
      message: 'Please enter the first name',
    },
    {
      max: 20,
      message: 'Name cannot be more than 20 characters',
    },
    {
      type: 'string',
      message: 'Please enter a valid name',
    },
  ],
  lastName: [
    {
      required: true,
      message: 'Please enter the last name',
    },
    {
      max: 20,
      message: 'Name cannot be more than 20 characters',
    },
    {
      type: 'string',
      message: 'Please enter a valid name',
    },
  ],
  email: [
    {
      required: true,
      message: 'Please enter the email',
    },
    {
      type: 'email',
      message: 'Please enter a valid email',
    },
  ],
  role: [
    {
      required: true,
      message: 'Please select a role',
    },
  ],
};

export default function AddManagerModal(props) {
  const user = getUser();
  const { visible, title, footer, onCancel, onProceed, userInfo } = props;
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState(userInfo?.name.split(' ')[0]);
  const [lastName, setLastName] = useState(userInfo?.name.split(' ')[1]);
  const [userEmail, setUserEmail] = useState(userInfo?.email);
  const [userRole, setUserRole] = useState(userInfo?.role ?? null);
  function onAddMember(data) {
    const { first_name, last_name, email, role } = data;
    const name = `${first_name} ${last_name}`;
    onProceed({
      key: userInfo?.key ? userInfo.key : Math.floor(Math.random() * 100),
      name,
      email,
      role: Object.values(ROLES_ENUM).find(({ key }) => key === role).value,
    });
  }
  async function handleUpdateMember() {
    const userRoleKey = Object.values(ROLES_ENUM).find(
      ({ value }) => value === userRole,
    ).key;
    if (userInfo) {
      // call api to update a member
      setLoading(true);
      try {
        const { data } = await callPostApi('/v1/api/update-member', {
          first_name: firstName?.trim(),
          last_name: lastName?.trim(),
          email: userEmail?.trim()?.toLowerCase(),
          role: userRoleKey,
        });
        onAddMember(data);
      } catch (err) {
        const { message } = err?.response?.data || {};
        if (message) toast(toastTypes.ERROR, message);
      } finally {
        setLoading(false);
      }
      return;
    }
    // call api to Add a member
    setLoading(true);
    try {
      const { data } = await callPostApi('/v1/api/add-member', {
        first_name: firstName?.trim(),
        last_name: lastName?.trim(),
        email: userEmail?.trim()?.toLowerCase(),
        role: userRoleKey,
      });
      toast(toastTypes.SUCCESS, 'User Added to the fleet');
      onAddMember(data);
    } catch (err) {
      const { message } = err?.response?.data || {};
      if (message) toast(toastTypes.ERROR, message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal visible={visible} title={title} footer={footer} onCancel={onCancel}>
      <Form
        name="add_member_form"
        initialValues={{
          remember: true,
        }}
        onFinish={handleUpdateMember}
        autoComplete="off"
      >
        {/* First name input  */}
        <div className="align-items-start name_align">
          <div className="profile_card_key">First Name</div>
          <div style={{ width: '100%' }}>
            <Form.Item
              initialValue={firstName}
              style={{ paddingRight: '5px' }}
              name="first_name"
              rules={RULES.firstName}
            >
              <Input
                name="first_name"
                placeholder="First Name"
                type="text"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
              />
            </Form.Item>
          </div>
        </div>

        {/* Last name input  */}
        <div className="align-items-start name_align">
          <div className="profile_card_key">Last Name</div>
          <div style={{ width: '100%' }}>
            <Form.Item
              initialValue={lastName}
              style={{ paddingRight: '5px' }}
              name="last_name"
              rules={RULES.lastName}
            >
              <Input
                name="last_name"
                placeholder="Last Name"
                type="text"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
              />
            </Form.Item>
          </div>
        </div>

        {/* Email Input  */}
        <div className="align-items-start name_align">
          <div className="profile_card_key">Email</div>
          <div style={{ width: '100%' }}>
            <Form.Item
              initialValue={userEmail}
              style={{ paddingRight: '5px' }}
              name="email"
              rules={RULES.email}
            >
              <Input
                disabled={userInfo}
                name="email"
                placeholder="Email"
                type="email"
                value={userEmail}
                onChange={(event) => setUserEmail(event.target.value)}
              />
            </Form.Item>
          </div>
        </div>

        {/* SELECT ROLE  */}
        <div className="align-items-start name_align">
          <div className="profile_card_key">Role</div>
          <div style={{ width: '100%' }}>
            <Form.Item
              initialValue={userRole}
              name="role"
              hasFeedback
              rules={RULES.role}
            >
              <Select
                disabled={user.email === userInfo?.email}
                placeholder="Please select a role"
                value={userRole}
                onChange={(role) => {
                  setUserRole(role);
                }}
              >
                {Object.values(ROLES_ENUM)
                  .filter((role) => role.key !== 'sys_admin')
                  .map((role) => (
                    <Option key={role.key} value={role.value}>
                      {role.value}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
        </div>

        {/* ACTIONS  */}
        <div className="d-flex align-items-center">
          <div style={{ padding: '5px' }} className="button_size">
            <Button
              type="secondary"
              onClick={() => onCancel()}
              style={{ width: '100%' }}
              disabled={loading}
            >
              Cancel
            </Button>
          </div>
          <div style={{ padding: '5px' }} className="button_size">
            <Button
              type="primary"
              style={{ width: '100%' }}
              htmlType="submit"
              loading={loading}
              disabled={loading}
            >
              Save
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
}
