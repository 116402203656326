import { LoadingOutlined } from '@ant-design/icons';
import { Button, Spin, Typography } from 'antd';
import React from 'react';
import ConfirmationModal from '../../../components/shared/confirmationModal';

const { Text } = Typography;

export default function DeleteDrivers({
  numberOfDrivers,
  isLoading,
  handleAction,
  showModal,
  setShowModal,
}) {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const handleDeleteDrivers = () => {
    const text = numberOfDrivers.length > 1 ? 'Drivers' : 'Driver';
    handleAction({
      task_type: 'delete_drivers',
      success: `${numberOfDrivers} ${text} Deleted`,
      failure: `Failed to Delete the ${text}`,
    });
  };
  return (
    <ConfirmationModal
      visible={showModal}
      onCancel={() => {
        setShowModal(false);
      }}
      title={`Delete ${numberOfDrivers} drivers from your fleet?`}
      footer={[
        <Button
          key="cancel"
          disabled={isLoading}
          onClick={() => {
            setShowModal(false);
          }}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="danger"
          disabled={isLoading}
          onClick={handleDeleteDrivers}
        >
          Delete
        </Button>,
      ]}
    >
      {isLoading ? (
        <div className="modal_loading">
          <Text>Deleting Drivers...</Text>
          <Spin indicator={antIcon} />
        </div>
      ) : (
        <>
          Once deleted, this action cannot be reverted. Are you sure you want to
          go ahead and delete {numberOfDrivers} selected drivers?
        </>
      )}
    </ConfirmationModal>
  );
}
