import { CloseOutlined } from '@ant-design/icons';
import { Badge, Menu, Space, Typography } from 'antd';
import { navigate } from 'gatsby-link';
import PropTypes from 'prop-types';
import React from 'react';
import '../../assets/style/dashboard/dashboardSidebar.css';
import getIncidentsCount from '../../components/dashboard/utils';
import sideBarConstants from '../../constants/sidebarConstants';
import { useGlobalState } from '../../contexts/AppContext';
import { Roles } from '../../utils/roles';
import { getUser } from '../../utils/validateUserToken';
import './Sidebar.scss';
import SidebarFooter from './SidebarFooter';
import SidebarHeader from './SidebarHeader';
import {
  DASHBOARD_SIDEBAR_CONFIG,
  SETTINGS_SIDEBAR_CONFIG,
  isDashboardTabEnabled,
  isSettingsTabEnabled,
} from './utils';

function MenuItemGroupTitle({ icon, name }) {
  return (
    <Space
      style={{
        color: '#98999D',
        marginLeft: 10,
      }}
    >
      {icon}
      <Typography.Text
        style={{
          color: 'inherit',
        }}
      >
        {name}
      </Typography.Text>
    </Space>
  );
}

export default function Sidebar({
  type,
  activeKey,
  handleSideBarClick,
  onCloseSidebar = () => {},
}) {
  const { state, dispatch } = useGlobalState();
  const user = getUser();
  const isSysAdmin = user?.roles?.includes(Roles.SYS_ADMIN);
  const HasUnreadIncidents = !isSysAdmin && state.unreadIncidents > 0;

  const ActiveStyle = {
    background: '#fff',
    color: 'rgb(65, 65, 65)',
  };

  const CURRENT_CONFIG =
    type === 'dashboard' ? DASHBOARD_SIDEBAR_CONFIG : SETTINGS_SIDEBAR_CONFIG;

  function CurrentTab(key) {
    return type === 'dashboard'
      ? isDashboardTabEnabled(key)
      : isSettingsTabEnabled(key);
  }

  const onIncidentCountSuccess = (resp) => {
    dispatch({ type: 'SET_UNREAD_INCIDENTS', payload: resp?.data?.total });
  };

  const onIncidentCountFailure = (err) => {
    const { fault } = err?.response?.data || {};
    console.log(fault?.faultstring);
  };

  React.useEffect(() => {
    if (!isSysAdmin) {
      getIncidentsCount(onIncidentCountSuccess, onIncidentCountFailure);
    }
  }, []);

  const renderMenu = (item) => {
    if (CurrentTab(item.key)) {
      return item.submenu ? (
        <Menu.ItemGroup key={item.key} title={MenuItemGroupTitle(item)}>
          {item.submenu.map(
            (subItem) =>
              CurrentTab(subItem.key) && (
                <Menu.Item
                  style={activeKey !== subItem.key ? ActiveStyle : null}
                  key={subItem.key}
                  onClick={() => {
                    navigate(subItem.route);
                    handleSideBarClick(subItem);
                  }}
                >
                  {subItem.name}
                </Menu.Item>
              ),
          )}
        </Menu.ItemGroup>
      ) : (
        <Menu.Item
          style={activeKey !== item.key ? ActiveStyle : null}
          key={item.key}
          icon={item.icon}
          onClick={() => {
            navigate(item.route);
            handleSideBarClick(item);
          }}
        >
          {item.name}
          {item.key === sideBarConstants.INCIDENTS && HasUnreadIncidents && (
            <Badge dot style={{ marginTop: '5px', marginLeft: '5px' }} />
          )}
        </Menu.Item>
      );
    }

    return null;
  };

  return (
    <div className="sidebar_background" style={{ paddingTop: 0 }}>
      <div className="close_icon">
        <CloseOutlined onClick={onCloseSidebar} />
      </div>
      <SidebarHeader />
      <div className="dashboard_sidebar_body">
        <div className="menu_wrapper">
          <Menu
            defaultSelectedKeys={[activeKey]}
            selectedKeys={[activeKey]}
            mode="inline"
            theme="dark"
            style={{ borderRight: 0, background: 'transparent' }}
          >
            {CURRENT_CONFIG.map((item) => renderMenu(item))}
          </Menu>
        </div>
      </div>
      <SidebarFooter />
    </div>
  );
}

Sidebar.propTypes = {
  type: PropTypes.string,
  activeKey: PropTypes.string,
  handleSideBarClick: PropTypes.func,
  onCloseSidebar: PropTypes.func,
};
