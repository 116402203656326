import { InfoCircleOutlined } from '@ant-design/icons';
import { Tag, Tooltip, Typography } from 'antd';
import Link from 'gatsby-link';
import moment from 'moment';
import React from 'react';
import TagList from '../TagsManagement/TagList';
import { appStatusDocsURL } from './AppStatusConstants';
import DriverTableActivity from './DriverTableActivity';
import DriverTableIssues from './DriverTableIssues';

function getSearchParams() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(urlSearchParams.entries());
}

function fetchIssuesToResolve() {
  const params = getSearchParams();
  return params?.issues ? params?.issues?.split(',') : [];
}

function fetchActivity() {
  const params = getSearchParams();
  return params?.activity ? [params?.activity] : [];
}

function fetchTags() {
  const params = getSearchParams();
  return params?.tags ? params?.tags?.split(',') : [];
}

function RowWrapper({ children }) {
  return (
    <Typography.Text
      ellipsis
      style={{
        maxWidth: 200,
        color: 'inherit',
      }}
    >
      {children}
    </Typography.Text>
  );
}

function DriverName({ value, record }) {
  if (!value) return '-';

  const driverDetailsLink = `/app/dashboard/driver-details?driverId=${record.driver_id}`;
  return (
    <Link to={driverDetailsLink} state={{ timelineview: true }}>
      <RowWrapper>{value}</RowWrapper>
    </Link>
  );
}

function DeviceCount({ count, record }) {
  const { device_name } = record;
  return (
    <Tooltip title={`Last seen device: ${device_name}`} placement="right">
      <Tag color="default">{count}</Tag>
    </Tooltip>
  );
}

const TableColumns = [
  {
    title: 'Name',
    dataIndex: 'driver_name',
    render: (value, record) => <DriverName value={value} record={record} />,
  },
  {
    title: 'Tags',
    dataIndex: 'tags',
    filterSearch: true,
    width: 100,
    render: (tags) => <TagList tags={tags} />,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    render: (value) => <RowWrapper>{value || '-'}</RowWrapper>,
  },
  {
    title: 'Phone Number',
    dataIndex: 'phone_number',
    width: 100,
    render: (value) => <RowWrapper>{value || '-'}</RowWrapper>,
  },
  {
    title: () => (
      <span>
        Activity{' '}
        <Tooltip title="Driver activity in the last 3 days">
          <InfoCircleOutlined color="#7C7C7C" />
        </Tooltip>
      </span>
    ),
    dataIndex: 'is_active',
    filters: [
      { text: 'Active', value: 'active' },
      { text: 'Inactive', value: 'inactive' },
    ],
    filterMultiple: false,
    render: (is_active) => <DriverTableActivity isActive={is_active} />,
  },
  {
    title: 'Issues to Resolve',
    dataIndex: 'issues',
    filterSearch: true,
    render: (tags) => <DriverTableIssues tags={tags} />,
  },

  {
    title: 'Device Last Seen',
    dataIndex: 'last_seen',
    width: 100,
    render: (time) => {
      const utcTime = moment.utc(time).toDate();
      return (
        <RowWrapper>
          {time ? moment(utcTime).format('MM/DD/YY, HH:mm') : '-'}
        </RowWrapper>
      );
    },
  },
  {
    title: 'Device Count',
    dataIndex: 'count_devices',
    width: 100,
    render: (count, record) => (
      <RowWrapper>
        <DeviceCount count={count} record={record} />
      </RowWrapper>
    ),
  },
  {
    title: 'Last Updated',
    dataIndex: 'last_updated',
    render: (time) => {
      const utcTime = moment.utc(time).toDate();
      return (
        <RowWrapper>
          {time ? moment(utcTime).local().format('MM/DD/YY') : '-'}
        </RowWrapper>
      );
    },
  },
];

function getIssuesTitle(IssuesLength) {
  const Title = (
    <div>
      To fix the issue(s), drivers should open their app and follow the
      instructions. <br /> Make sure they are on the latest app version.{' '}
      <a
        style={{ color: '#7ADAB2' }}
        target="_blank"
        rel="noreferrer"
        href={appStatusDocsURL}
      >
        Learn more
      </a>
    </div>
  );
  const IssuesTitle =
    IssuesLength > 0
      ? `Issues to Resolve (${IssuesLength}) `
      : 'Issues to Resolve ';
  return (
    <>
      {IssuesTitle}
      <Tooltip title={Title}>
        <InfoCircleOutlined color="#7C7C7C" />
      </Tooltip>
    </>
  );
}

function updateIssuesColumn(column, errorCodes) {
  const newColumn = { ...column };
  const IssuesToResolve = fetchIssuesToResolve();
  const IssuesLength = IssuesToResolve.length;

  // updating the filters
  newColumn.filters = errorCodes.map((error) => ({
    text: error.display_name,
    value: error.code,
  }));
  newColumn.filters.sort((a, b) => a.text > b.text);
  newColumn.filteredValue = IssuesToResolve;
  newColumn.title = getIssuesTitle(IssuesLength);

  return newColumn;
}

function updateActivityColumn(column) {
  const newColumn = { ...column };
  const activity = fetchActivity();
  newColumn.filteredValue = activity;
  return newColumn;
}

function updateTagsColumn(column, tags) {
  return {
    ...column,
    filters: tags.map((tag) => ({
      text: tag.tag_name,
      value: tag.id,
    })),
    filteredValue: fetchTags(),
  };
}

function formatTableColumns(errorCodes = [], tags = []) {
  return TableColumns?.map((column) => {
    if (column.dataIndex === 'issues') {
      return updateIssuesColumn(column, errorCodes);
    }
    if (column.dataIndex === 'is_active') {
      return updateActivityColumn(column);
    }
    if (column.dataIndex === 'tags') {
      return updateTagsColumn(column, [
        {
          id: -1,
          tag_name: 'None',
        },
        ...tags,
      ]);
    }
    return column;
  });
}

function formatFilteredKeys(filters, errorCodes) {
  if (!errorCodes || !filters) return [];
  const filterKeys = errorCodes
    .filter((error) => filters.includes(error.code))
    .map((error) => error.code);
  return filterKeys;
}

const isAlphabetic = (value) => /^[^@0-9]+$/.test(value);

export { formatFilteredKeys, formatTableColumns, isAlphabetic, TableColumns };
