import { Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { associatedDrivers } from '../../modules/TagsManagement/tagService';
import ExpandablePills from './ExpandablePills';

export default function AssociatedDrivers(props) {
  const { tagId, isActive } = props;
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    // do not fetch drivers until expanded
    if (!isActive) {
      return;
    }

    setLoading(true);
    const { data } = await associatedDrivers(tagId);
    setDrivers(data.drivers);
    setLoading(false);
  }, [tagId, isActive]);

  return (
    <Space direction="vertical" style={{ width: '100%', marginLeft: '24px' }}>
      {loading ? (
        <Typography.Text type="secondary">Loading drivers...</Typography.Text>
      ) : (
        <Space direction="vertical">
          {drivers && drivers.length > 0 ? (
            <>
              <Typography.Text type="secondary">Drivers</Typography.Text>
              <ExpandablePills drivers={drivers} sliceCount={3} />
            </>
          ) : (
            <Typography.Text type="secondary">No drivers</Typography.Text>
          )}
        </Space>
      )}
    </Space>
  );
}
