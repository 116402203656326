import { Button, Result } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { getUser } from '../utils/validateUserToken';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: '',
      errorInfo: '',
    };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      const subject = `${getUser()?.company_name} - UI error report`;
      const body = `${this.state.error}%0D%0A${JSON.stringify(
        this.state.errorInfo,
      )}`;

      return (
        <>
          <Text>
            Sorry, something went wrong. Please contact{' '}
            <a
              href={`mailto:support@fairmatic.com?subject=${subject}&body=${body}`}
            >
              support@fairmatic.com
            </a>{' '}
            if the problem persists.
          </Text>
          <Result
            status="warning"
            extra={
              <Button href="/" type="secondary">
                Refresh
              </Button>
            }
          />
        </>
      );
    }
    return this.props.children;
  }
}

export const Text = styled.p`
  margin-top: 5rem;
  margin-bottom: -1rem;
  font-size: 24px;
  font-family: 'Helvetica Neue';
  letter-spacing: 0.5px;
  text-align: center;
  font-weight: 400;
`;
