import { Form } from 'antd';
import React, { useEffect } from 'react';
import { EditableContext } from './EditDriversUtils';

export default function EditDriversRow({
  record,
  editedDriversList,
  initialValues,
  resetForm,
  resetRow,
  ...props
}) {
  const [form] = Form.useForm();
  const { children } = props;

  useEffect(async () => {
    if (resetForm) {
      await form.resetFields([
        'first_name',
        'last_name',
        'device_ownership_type',
      ]);
    }
  }, [resetForm]);

  useEffect(async () => {
    if (resetRow && record.driver_id === resetRow) {
      await form.resetFields([
        'first_name',
        'last_name',
        'device_ownership_type',
      ]);
    }
  }, [resetRow]);

  useEffect(() => {
    if (editedDriversList?.length > 0) {
      const editedDriver = editedDriversList.find(
        (driver) => driver.driver_id === record.driver_id,
      );
      if (editedDriver) {
        form.setFieldsValue({
          first_name: editedDriver.first_name,
          last_name: editedDriver.last_name,
          device_ownership: editedDriver.device_ownership_type,
        });
      }
    }
  }, []);

  return (
    <Form form={form} initialValues={initialValues} component={false}>
      <EditableContext.Provider value={form}>
        <tr>{children}</tr>
      </EditableContext.Provider>
    </Form>
  );
}
