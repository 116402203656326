import { PlusOutlined, TagOutlined } from '@ant-design/icons';
import { Button, Empty, Space } from 'antd';
import React from 'react';

export default function EmptyTags({ handleCreateTag }) {
  return (
    <Space className="empty-view-container">
      <Empty
        image={<TagOutlined rotate={270} style={{ fontSize: '40px' }} />}
        imageStyle={{ height: 48 }}
        description={
          <span>There are no tags created yet, create your first tag.</span>
        }
      >
        <Button
          type="primary"
          style={{
            borderRadius: '8px',
            cursor: 'pointer',
          }}
          icon={<PlusOutlined />}
          onClick={handleCreateTag}
        >
          Create Tag
        </Button>
      </Empty>
    </Space>
  );
}
