import { Button, Form, Input } from 'antd';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { callPostApi } from '../../../api/axios';
import { toast, toastTypes } from '../../../utils/toast';
import { getUser } from '../../../utils/validateUserToken';

const RULES = {
  firstName: [
    {
      required: true,
      message: 'Please enter the first name',
    },
    {
      max: 20,
      message: 'Name cannot be more than 20 characters',
    },
    {
      type: 'string',
      message: 'Please enter a valid name',
    },
  ],
  lastName: [
    {
      required: true,
      message: 'Please enter the Last name',
    },
    {
      max: 20,
      message: 'Name cannot be more than 20 characters',
    },
    {
      type: 'string',
      message: 'Please enter a valid Name!',
    },
  ],
};
export default function BasicInfoCard(props) {
  const user = getUser();
  const { toggleEditBasicInfo, setToggleEditBasicInfo } = props;
  const [firstName, setFirstName] = useState(user?.first_name);
  const [lastName, setLastName] = useState(user?.last_name);
  const [tempUserDetails, setTempUserDetails] = useState({
    firstName: user?.first_name,
    lastName: user?.last_name,
  });
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  // function to update the changes in basic info
  async function handleUpdateBasicInfo() {
    setLoading(true);
    // call apis with the entered values
    try {
      const { data } = await callPostApi('/v1/api/update-member', {
        email: user?.email,
        first_name: tempUserDetails.firstName,
        last_name: tempUserDetails.lastName,
      });
      setFirstName(tempUserDetails.firstName);
      setLastName(tempUserDetails.lastName);
      const { first_name, last_name } = data;
      Cookies.set(
        'User',
        JSON.stringify({
          ...user,
          first_name,
          last_name,
        }),
      );
      setToggleEditBasicInfo(false);
    } catch (err) {
      const { fault } = err?.response?.data || {};
      if (fault) toast(toastTypes.ERROR, fault);
    } finally {
      setLoading(false);
    }
  }
  return (
    <div className="account_info_wrapper_card">
      <div className="card_header_spacing">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="card_header_text">Basic Information</h4>
          {!toggleEditBasicInfo && (
            <div className="button_size">
              <Button
                type="secondary"
                onClick={() => setToggleEditBasicInfo(true)}
                style={{ width: '100%' }}
              >
                Edit
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="card_body_spacing">
        <Form
          form={form}
          name="edit_basic_info_form"
          initialValues={{
            remember: true,
          }}
          onFinish={handleUpdateBasicInfo}
          autoComplete="off"
        >
          <div className="align-items-start name_align">
            <div className="profile_card_key">Full Name</div>
            {toggleEditBasicInfo ? (
              <div className="name_align">
                {/* First name input  */}
                <Form.Item
                  initialValue={firstName}
                  style={{ paddingRight: '5px' }}
                  name="first_name"
                  rules={RULES.firstName}
                >
                  <Input
                    name="first_name"
                    placeholder="First Name"
                    type="text"
                    value={tempUserDetails.firstName}
                    onChange={(event) =>
                      setTempUserDetails((state) => ({
                        ...state,
                        firstName: event.target.value,
                      }))
                    }
                  />
                </Form.Item>
                {/* Last name input  */}
                <Form.Item
                  initialValue={lastName}
                  style={{ paddingRight: '5px' }}
                  name="last_name"
                  rules={RULES.lastName}
                >
                  <Input
                    name="last_name"
                    placeholder="Last Name"
                    type="text"
                    value={tempUserDetails.lastName}
                    onChange={(event) =>
                      setTempUserDetails((state) => ({
                        ...state,
                        lastName: event.target.value,
                      }))
                    }
                  />
                </Form.Item>
              </div>
            ) : (
              <div className="profile_card_value">
                {firstName} {lastName}
              </div>
            )}
          </div>
          <div className="align-items-start name_align">
            <div className="profile_card_key">Email</div>
            <div className="profile_card_value">{user?.email}</div>
          </div>
          {toggleEditBasicInfo && (
            <div className="d-flex align-items-center">
              <div style={{ padding: '5px' }} className="button_size">
                <Button
                  disabled={loading}
                  type="secondary"
                  onClick={() => {
                    form.resetFields();
                    setTempUserDetails({
                      firstName: user?.first_name,
                      lastName: user?.last_name,
                    });
                    setToggleEditBasicInfo(false);
                  }}
                  style={{ width: '100%' }}
                >
                  Cancel
                </Button>
              </div>
              <div style={{ padding: '5px' }} className="button_size">
                <Button
                  disabled={loading || !firstName || !lastName}
                  type="primary"
                  style={{ width: '100%' }}
                  htmlType="submit"
                  loading={loading}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </Form>
      </div>
    </div>
  );
}

BasicInfoCard.propTypes = {
  toggleEditBasicInfo: PropTypes.bool.isRequired,
  setToggleEditBasicInfo: PropTypes.func.isRequired,
};
