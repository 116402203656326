import queryString from 'query-string';
import { callGetApi } from '../../../api/axios';

const fetchScoreService = (queryParams) =>
  new Promise(async (resolve) => {
    try {
      const { data } = await callGetApi(
        `/v2/api/score?${queryString.stringify(queryParams, {
          arrayFormat: 'comma',
        })}`,
      );
      resolve({
        data,
      });
    } catch (err) {
      const { fault } = err?.response?.data || {};
      resolve({
        fault,
        isError: true,
      });
    }
  });

const fetchScoreServiceV1 = (queryParams) =>
  new Promise(async (resolve) => {
    try {
      const { data } = await callGetApi(
        `/v1/api/score?${queryString.stringify(queryParams, {
          arrayFormat: 'comma',
        })}`,
      );
      resolve({
        data,
      });
    } catch (err) {
      const { fault } = err?.response?.data || {};
      resolve({
        fault,
        isError: true,
      });
    }
  });

export { fetchScoreService, fetchScoreServiceV1 };
