// object for event ratings
import HardBreakSvg from 'images/hardbreak';
import OverSpeedSvg from 'images/overspeed';
import RapidAccelrationSvg from 'images/rapidaccelration';
import UseMobileSvg from 'images/usemobile';

export const event_ratings = {
  hard_brake_rating: {
    key: 'hard_brake_rating',
    value: 'Hard Braking',
  },
  hard_turn_rating: {
    key: 'hard_turn_rating',
    value: 'Hard Turn',
  },
  rapid_acceleration_rating: {
    key: 'rapid_acceleration_rating',
    value: 'Acceleration',
  },
  phone_use_rating: {
    key: 'phone_use_rating',
    value: 'Phone Use',
  },
  overspeeding_rating: {
    key: 'overspeeding_rating',
    value: 'Speeding',
  },
};

export const EVENT_RATINGS = {
  acceleration: {
    key: 'acceleration',
    label: 'Rapid Acceleration',
    Icon: RapidAccelrationSvg,
  },
  hard_brake: {
    key: 'hard_brake',
    label: 'Hard Braking',
    Icon: HardBreakSvg,
  },
  phone_use: {
    key: 'phone_use',
    label: 'Phone Use',
    Icon: UseMobileSvg,
  },
  overspeeding: {
    key: 'overspeeding',
    label: 'Over Speeding',
    Icon: OverSpeedSvg,
  },
};
