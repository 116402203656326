import { navigate } from 'gatsby-link';
import queryString from 'query-string';
import React from 'react';
import { roles_enum } from '../utils/roles';
import { getUser, isLoggedIn } from '../utils/validateUserToken';

function PrivateRoute({ component: Component, location, ...rest }) {
  const user = getUser();
  const { pathname } = location;

  // If user is not logged in, redirect to login page
  if (!isLoggedIn() && pathname !== '/login') {
    navigate(`/login?${queryString.stringify({ next: pathname })}`);
    return null;
  }

  // [TODO]: refactor this later
  // user roles can be an array or a string
  const defaultUserRole = Array.isArray(user.roles)
    ? user.roles[0]
    : user.roles;

  const userRole = roles_enum[defaultUserRole];

  if (userRole.accesibleRoutes.includes(pathname)) {
    return <Component {...rest} />;
  }

  navigate(userRole.defaultRoute);
  return null;
}
export default PrivateRoute;
