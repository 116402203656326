const ORDER_BY = {
  zendrive_score: {
    value: 'Score',
    key: 'score',
  },
  distance_km: {
    value: 'Distance',
    key: 'distance',
  },
  trip_count: {
    value: 'Trips',
    key: 'trips',
  },
  duration_seconds: {
    value: 'Duration',
    key: 'duration',
  },
  driver_name: {
    value: 'Driver name',
    key: 'driver_name',
  },
};

export { ORDER_BY };
