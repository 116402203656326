import { InfoCircleOutlined, LikeOutlined } from '@ant-design/icons';
import { Space, Tooltip, Typography } from 'antd';
import { EVENT_RATINGS } from 'constants/event-ratings';
import React from 'react';
import './focusArea.scss';

export default function FocusAreas({ focusAreas = [] }) {
  const isFocusAreaApplicable = focusAreas.some((area) => area.active);

  const formatValue = (count = 0, duration = 0) => {
    if (count > 0) {
      return `${count} ${count > 1 ? `counts` : `count`}`;
    }
    if (duration > 0) {
      const minutes = Math.floor(duration / 60);
      const seconds = Math.floor(duration - minutes * 60);
      return minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`;
    }
    return 0;
  };

  return (
    <div>
      <Space className="focus_areas_header" align="start" wrap>
        <div
          style={{
            fontSize: '14px',
            color: '#999',
            margin: 0,
          }}
        >
          Focus areas
          <Tooltip title="Focus areas represent the events that a driver should improve on to boost their performance grade. Focus areas take multiple inputs into consideration including various driving events, duration of certain events like phone use & speeding, highway driving, night driving & several other factors. ">
            <InfoCircleOutlined
              style={{
                marginLeft: '5px',
              }}
            />
          </Tooltip>
        </div>

        {!isFocusAreaApplicable && (
          <div className="no_focus_areas">
            <LikeOutlined />
            <span className="ml-2">No focus areas at the moment.</span>
          </div>
        )}
      </Space>

      <div className="focusAreas">
        {Object.keys(EVENT_RATINGS).map((key, index) => {
          const { Icon, label } = EVENT_RATINGS[key];
          const { active, count, duration } = focusAreas.find(
            (area) => area.name === key,
          );
          return (
            <Space
              align="center"
              className={`focusAreas__icon ${index > 1 && 'second_row'} ${
                active ? 'active' : ''
              }`}
              key={key}
            >
              <Space align="center">
                <Icon width="3em" height="3em" />
                <div className="focus_area_item">
                  <Typography.Text
                    className={`focus_area_label ${active ? 'active' : ''}`}
                  >
                    {label}
                  </Typography.Text>
                  <Typography.Text className="focus_area_value">
                    {formatValue(count, duration)}
                  </Typography.Text>
                </div>
              </Space>
            </Space>
          );
        })}
      </div>
    </div>
  );
}
