import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { callGetApi } from '../../../api/axios';
import '../../../assets/style/settings/settings.css';
import MixpanelContext from '../../../services/tracking';
import { roles_enum } from '../../../utils/roles';
import { toast, toastTypes } from '../../../utils/toast';
import AddManagerModal from './AddManagerModal';
import ManagerListCard from './ManagerListCard';

export default function Managers() {
  const mixpanel = useContext(MixpanelContext);
  const [toggleSidebarOnMobile, setToggleSidebarOnMobile] = useState(false);
  const [toggleAddManager, setToggleAddManager] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfManagers, setListOfManagers] = useState([]);
  function handleAddManager(manager) {
    setListOfManagers([...listOfManagers, manager]);
    setToggleAddManager(false);
  }
  useEffect(() => {
    async function getAllMembers() {
      setLoading(true);
      try {
        const { data } = await callGetApi('/v1/api/get-all-members');
        const members = data.map((user) => ({
          key: `${user.first_name}${user.email}`,
          name: `${user.first_name} ${user.last_name}`,
          emailInsights: user.weekly_report_status ? 'On' : 'Off',
          downloadAbleReports: user.downloadable_reports ? 'On' : 'Off',
          role: Object.values(roles_enum).find(
            (user_role) => user_role.key === user.role,
          )?.value,
          email: user.email,
        }));
        setListOfManagers(members);
      } catch (err) {
        const { message } = err?.response?.data || {};
        if (message) toast(toastTypes.ERROR, message);
      } finally {
        setLoading(false);
      }
    }
    getAllMembers();
  }, []);

  useEffect(() => {
    mixpanel.track('View Page', {
      pageName: 'Managers Page',
    });
  }, [mixpanel]);

  const loadingSpinned = (
    <div
      style={{ height: '60vh' }}
      className="d-flex align-items-center justify-content-center"
    >
      <Spin />
    </div>
  );

  return (
    <div>
      <div className="playground">
        <div className="ham_burgur">
          <div className="han_burgur_icon">
            <ArrowRightOutlined
              onClick={() => setToggleSidebarOnMobile(!toggleSidebarOnMobile)}
            />
          </div>
        </div>
        {loading ? (
          loadingSpinned
        ) : (
          <>
            {/* LABEL AND ADD MEMBER BUTTON  */}
            <div
              className="d-flex align-items-center"
              style={{ paddingBottom: '10px' }}
            >
              <h4 className="page_header_text">Team Management</h4>
              {!toggleAddManager && (
                <div className="ml-auto px-3">
                  <div className="button_size">
                    <Button
                      size="large"
                      type="primary"
                      onClick={() => setToggleAddManager(true)}
                      style={{ width: '100%' }}
                    >
                      Add
                    </Button>
                  </div>
                </div>
              )}
            </div>
            {/* LIST OF MEMBERS IN TABLE  */}
            <ManagerListCard
              listOfManagers={listOfManagers}
              updateListOfManagers={(value) => {
                setListOfManagers(
                  listOfManagers.map((manager) => {
                    if (manager.key === value.key) {
                      return {
                        ...manager,
                        name: value.name,
                        email: value.email,
                        role: value.role,
                        downloadAbleReports: value.downloadAbleReports,
                        emailInsights: value.emailInsights,
                      };
                    }
                    return manager;
                  }),
                );
              }}
              deleteAManager={(value) => {
                setListOfManagers(
                  listOfManagers.filter(
                    (manager) => manager.email !== value.email,
                  ),
                );
              }}
            />
          </>
        )}
      </div>
      {/* ADD MANAGER MODAL  */}
      {toggleAddManager && (
        <AddManagerModal
          visible={toggleAddManager}
          onCancel={() => setToggleAddManager(false)}
          onProceed={(value) => handleAddManager(value)}
          title="Add Team Member"
          footer={null}
        />
      )}
    </div>
  );
}
