import { UndoOutlined } from '@ant-design/icons';
import { Form, Input, Popover, Select } from 'antd';
import React, { useCallback, useContext } from 'react';
import { debounce } from '../../../utils/search';
import {
  INVALID_NAME_MESSAGE,
  deviceOwnershipSelectOptions,
} from '../AppStatusConstants';
import { isAlphabetic } from '../DriverTableUtils';
import { EditableContext, validationRules } from './EditDriversUtils';

function EditDriversCell({
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  handleDeleteRow,
  showClose,
}) {
  const form = useContext(EditableContext);
  const nameValue = Form.useWatch(dataIndex, form);
  const isDirty = editable && nameValue?.trim() !== record[dataIndex]?.trim();
  const fieldError = form.getFieldError(dataIndex);
  const inputBgColor = isDirty ? '#FFFAD0' : '#fff';

  const handleClose = useCallback(
    () => handleDeleteRow(record?.driver_id),
    [handleDeleteRow, record?.driver_id],
  );

  const save = useCallback(async () => {
    try {
      const values = await form.validateFields();
      const newValues = { ...record, ...values };
      form.setFieldsValue(newValues);
      handleSave(newValues);
    } catch (errInfo) {
      const { values } = errInfo;
      const hasError = Object.keys(values).some(
        (key) => values[key] !== record[key],
      );
      handleSave({ ...record, ...values }, hasError);
    }
  }, [form, handleSave, record, dataIndex]);

  const debouncedSave = useCallback(debounce(save, 200), [save]);

  const popoverContent =
    fieldError.length > 0 ? (
      <div className="edit_driver_popover">{fieldError[0]}</div>
    ) : null;

  const validateName = (firstName, lastName) => {
    const isFirstNameValid = isAlphabetic(firstName);
    const isLastNameValid = isAlphabetic(lastName);

    if (!isFirstNameValid && dataIndex === 'first_name') {
      return Promise.reject(new Error(INVALID_NAME_MESSAGE));
    }

    if (!isLastNameValid && dataIndex === 'last_name') {
      return Promise.reject(new Error(INVALID_NAME_MESSAGE));
    }

    return Promise.resolve();
  };

  const checkValue = async () => {
    const {
      first_name: firstName,
      last_name: lastName,
      device_ownership_type: deviceOwnershipType,
    } = form.getFieldsValue();

    const isOwnershipValid =
      deviceOwnershipType && deviceOwnershipType !== 'business_shared';

    if (isOwnershipValid) {
      return validateName(firstName, lastName);
    }

    return Promise.resolve();
  };

  if (editable) {
    return (
      <td className="input_with_popover">
        <Popover
          className="edit_driver_popover"
          content={popoverContent}
          placement="bottomLeft"
          autoAdjustOverflow
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
        >
          <Form.Item
            help=""
            name={dataIndex}
            rules={[
              ...validationRules[dataIndex],
              {
                validator: checkValue,
              },
            ]}
            preserve={false}
            onChange={debouncedSave}
            shouldUpdate={(prevValues, curValues) =>
              prevValues[dataIndex] !== curValues[dataIndex]
            }
          >
            {dataIndex === 'device_ownership_type' ? (
              <Select
                style={{ width: 250 }}
                options={deviceOwnershipSelectOptions}
                placeholder="Device Ownership"
                onChange={save}
                title=""
              />
            ) : (
              <Input style={{ backgroundColor: inputBgColor }} title="" />
            )}
          </Form.Item>
        </Popover>
      </td>
    );
  }

  if (dataIndex === 'undo' && showClose) {
    return (
      <td style={{ textAlign: 'center' }}>
        <UndoOutlined rotate="90" onClick={handleClose} />
      </td>
    );
  }

  return <td>{children}</td>;
}

export default EditDriversCell;
