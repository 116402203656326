import { Select, Space } from 'antd';
import { PERFORMANCE_FILTERS } from 'constants';
import React from 'react';

export default function PerformanceFilters({
  prefGroup = 'all',
  setPrefGroup,
  driversStats,
  loading,
}) {
  const getSumOfAllValues = () =>
    Object.values(driversStats).reduce((sum, val) => sum + val, 0);

  return (
    <Select
      size="large"
      loading={loading}
      className="mr-3 performance_filter"
      optionLabelProp="label"
      placeholder="Performance"
      defaultValue={prefGroup}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      onChange={setPrefGroup}
      getPopupContainer={(trigger) => trigger.parentElement}
    >
      {Object.keys(PERFORMANCE_FILTERS).map((filter) => {
        const { key, value } = PERFORMANCE_FILTERS[filter];
        const stats = key === 'all' ? getSumOfAllValues() : driversStats[key];
        return (
          <Select.Option
            key={key}
            value={key}
            label={`Performance - ${value} (${stats || 0})`}
          >
            <Space>{`${value} (${stats || 0})`}</Space>
          </Select.Option>
        );
      })}
    </Select>
  );
}
