import React from 'react';
import { getUser } from '../../utils/validateUserToken';

export default function SidebarHeader() {
  const user = getUser();
  const { first_name, last_name, company_name } = user;

  return (
    <div className="dashboard_sidebar_header">
      <div>
        <p className="dashboard_sidebar_header_account_name">
          {`${first_name} ${last_name}`}
        </p>
        <p className="dashboard_sidebar_header_company_name">{company_name}</p>
      </div>
      <div className="account_initials">
        <p className="account_initial_text">
          {first_name ? first_name[0]?.toUpperCase() : 'N'}
          {last_name ? last_name[0]?.toUpperCase() : 'A'}
        </p>
      </div>
    </div>
  );
}
