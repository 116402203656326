import { Spin } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { SECONDARY, SECONDARY_LIGHTER } from '../../../assets/style/colors';
import LineChart from '../../../components/charts/LineChart';
import { getLineChartAxisData } from '../utils';

export default function ActiveDriversChart({
  metricData,
  isLoading,
  xAxisLabelFormat,
  tooltipLabelFormat,
}) {
  const { xAxis, yAxis } = getLineChartAxisData(metricData);
  if (isLoading) {
    return (
      <div
        style={{ minHeight: '250px' }}
        className="d-flex align-items-center justify-content-center"
      >
        <Spin />
      </div>
    );
  }
  if (yAxis?.length > 0) {
    return (
      <LineChart
        id="active-driver-chart"
        xAxisAsDate
        xAxis={xAxis}
        yAxis={yAxis}
        chartColor={SECONDARY_LIGHTER}
        markerColor={SECONDARY}
        tooltipLabel=""
        xAxisLabelFormat={xAxisLabelFormat}
        tooltipLabelFormat={tooltipLabelFormat}
      />
    );
  }
  return (
    <div
      style={{
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        height: '260px',
        alignItems: 'center',
      }}
    >
      <h4>NO ACTIVE DRIVERS IN THIS TIME PERIOD</h4>
    </div>
  );
}

ActiveDriversChart.propTypes = {
  metricData: PropTypes.object,
  isLoading: PropTypes.bool,
};
