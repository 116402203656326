const EVENT_DISTRIBUTIONS = {
  hard_brake: {
    key: 'hard_brake',
    value: 'Hard Braking',
    color: '#EF8784',
  },
  hard_turn: {
    key: 'hard_turn',
    value: 'Hard Turn',
    color: '#F4B53F',
  },
  rapid_acceleration: {
    key: 'rapid_acceleration',
    value: 'Acceleration',
    color: '#70DAAD',
  },
  phone_use: {
    key: 'phone_use',
    value: 'Phone Use',
    color: '#273267',
  },
  overspeeding: {
    key: 'overspeeding',
    value: 'Speeding',
    color: '#E6412D',
  },
};

const SCORE_TYPES = {
  greater: 'greater',
  smaller: 'smaller',
};
export { EVENT_DISTRIBUTIONS, SCORE_TYPES };
