import { Table } from 'antd';
import { navigate } from 'gatsby-link';
import React from 'react';
import TagList from '../../../modules/TagsManagement/TagList';

export default function DriversTable(props) {
  const { isLoading, drivers } = props;

  const columns = [
    {
      title: 'Driver',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => text || record.id,
      width: 100,
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      render: (tags) => <TagList tags={tags} />,
      width: 100,
    },
    {
      title: 'Distance',
      dataIndex: 'distance',
      key: 'distance',
      render: (distance) => `${Number(distance).toFixed(1)} miles`,
      width: 50,
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      width: 50,
    },
  ];

  return (
    <Table
      dataSource={drivers}
      columns={columns}
      loading={isLoading}
      pagination={false}
      scroll={{ y: 200, x: 'max-content' }}
      tableLayout="auto"
      rowClassName={() => 'driver_table_row'}
      rowKey={(record) => record.id}
      onRow={(record) => ({
        onClick: () =>
          navigate(`/app/dashboard/driver-details?driverId=${record.id}`),
      })}
    />
  );
}
