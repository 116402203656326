import { parseDuration } from 'components/charts/utils';
import moment from 'moment';
import { apiDateToShortDate, isoTimeToSimplifiedTime } from 'utils/date';
import { formatDistance } from '../../../utils/common';
import { EVENT_DISTRIBUTIONS, SCORE_TYPES } from '../constants';

const oneDay = 1000 * 60 * 60 * 24;

const heartbeatTooltipStyles = `
    .tooltip {
      position: relative;
      display: inline-block;
      width: 18px;
      height: 18px;
      border-radius: 9px;
      margin-left: 8px;
    }

    .tooltiptitle {
      width: 340px;
      white-space: break-spaces;
      background-color: black;
      color: #fff;
      text-align: left;
      padding: 8px 8px;
      border-radius: 6px;
      position: absolute;
      font-size: 13px;
    }

    .tooltip:hover .tooltiptitle {
      visibility: visible;
    }

    .labelTooltipWrapper{
      display: flex;
      width: 200px;
    }

    .boldLable{
      font-size: 14px;
      font-weight: bold;
      color: #363636;
      flex: 0.8;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .normalLable{
      font-size: 13px;
      color: #363636;
      flex: 0.8;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .tooltiptitleLabel{
      font-weight: bold:
      font-size: 13px;
    }
    `;

// get axix of line chart
const getLineChartAxisData = (metricData) => {
  const xAxis = [];
  const yAxis = [];
  metricData?.dates?.forEach((d, idx) => {
    yAxis.push(metricData.values[idx]);
    xAxis.push(d);
  });

  return { xAxis, yAxis };
};
// format line chart data
const formatScoreHistoryData = (metricData, xAxisKey = 'date') => {
  const dates = [];
  const values = [];
  if (metricData.length) {
    metricData.forEach((e) => {
      dates.push(e[xAxisKey]);
      values.push(+e?.fm_score);
    });
  }
  return { dates, values };
};

const getDifferenceInDays = (start, end) => {
  // Calculating the time difference between two dates
  const diffInTime = end.getTime() - start.getTime();
  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay);

  return diffInDays;
};

const formatTrips = (tripList) =>
  tripList?.map((trip) => {
    const localStartTime = moment.utc(trip?.start_datetime).local().toDate();
    return {
      // ...trip,
      zendrive_trip_id: trip.zendrive_trip_id,
      tripId: trip.trip_id,
      date: apiDateToShortDate(localStartTime, true),
      startTime: isoTimeToSimplifiedTime(localStartTime),
      distance: formatDistance(trip.distance_miles),
      duration: parseDuration(Number(trip?.drive_hrs ?? 0) * 60 * 60),
      score: trip?.fm_score,
      grade: trip?.fm_grade,
      insurancePeriod: trip?.period || 'NA',
      trackingId: trip?.tracking_id || 'NA',
      ratings: trip?.focus_areas?.filter((area) => area.active),
      // handle missing Vehicle ID & Type in Demo Dashboard API response
      vehicleId: trip?.info?.vehicle_id || 'NA',
      vehicleType: trip?.info?.vehicle_type || 'NA',
      userMode: trip?.info?.user_mode || 'NA',
    };
  });

function processLastPeriodData({ zendriveScore, data }) {
  const { fm_score } = data;
  let obj = null;
  if (
    fm_score < 0 ||
    typeof fm_score === 'undefined' ||
    Number.isNaN(fm_score) ||
    Number.isNaN(zendriveScore)
  ) {
    obj = {
      type: SCORE_TYPES.greater,
      score: zendriveScore - fm_score,
      isValidDiff: false,
    };
  }
  if (zendriveScore > fm_score) {
    obj = {
      type: SCORE_TYPES.greater,
      score: zendriveScore - fm_score,
      isValidDiff: true,
    };
  }
  if (obj === null) {
    obj = {
      type: SCORE_TYPES.smaller,
      score: fm_score - zendriveScore,
      isValidDiff: true,
    };
  }
  return obj;
}
function getEventDistributionChartData({ eventDistribution }) {
  return Object.keys(eventDistribution).map((rating) => {
    if (eventDistribution[rating] > 0) {
      return {
        label: `${eventDistribution[rating]}`,
        value: eventDistribution[rating],
        color: EVENT_DISTRIBUTIONS[rating].color,
        key: rating,
        hasData: true,
      };
    }
    return {
      label: '',
      value: 0,
      color: '',
      key: '',
      hasData: false,
    };
  });
}

export {
  formatScoreHistoryData,
  formatTrips,
  getDifferenceInDays,
  getEventDistributionChartData,
  getLineChartAxisData,
  heartbeatTooltipStyles,
  processLastPeriodData,
};
