// PRIMARY
export const PRIMARY = 'rgb(0,204,155)';
export const PRIMARY_DARK = 'rgb(0,171,131)';
export const PRIMARY_LIGHT = 'rgb(56,222,170)';
export const PRIMARY_LIGHTER = 'rgb(132,237,202)'; // SECONDARY
export const SECONDARY = 'rgb(36,49,107)';
export const SECONDARY_DARK = 'rgb(21,29,69)';
export const SECONDARY_LIGHT = 'rgb(51,67,135)';
export const SECONDARY_LIGHTER = 'rgb(71,90,161)'; // WHITE
export const WHITE = 'rgb(255,255,255)';
export const ACCENT_1 = 'rgb(255,228,27)';
export const ACCENT_1_LIGHT = 'rgb(255,241,117)';
export const ACCENT_1_LIGHTER = 'rgb(255,247,156)'; // ACCENT 2
export const ACCENT_2 = 'rgb(250,42,23)';
export const ACCENT_2_LIGHT = 'rgb(255,77,77)';
export const ACCENT_2_LIGHTER = 'rgb(255,128,128)'; // ACCENT 3
export const ACCENT_3 = 'rgb(255,179,2)';
export const ACCENT_3_LIGHT = 'rgb(255,207,94)';
export const ACCENT_3_LIGHTER = 'rgb(255, 224, 138)'; // GREY
export const GREY_50 = 'rgb(50,55,65)';
export const GREY_100 = 'rgb(100,102,110)';
export const GREY_150 = 'rgb(150,151,155)';
export const GREY_200 = 'rgb(200,202,205)';
export const GREY_215 = 'rgb(215,218,220)';
export const GREY_230 = 'rgb(230,231,235)';
export const GREY_235 = 'rgb(235,237,240)';
export const GREY_240 = 'rgb(240,241,243)';
export const GREY_248 = 'rgb(248,249,250)';
export const GREY_117 = 'rgb(117,117,117)'; // BLACK
export const BLACK = 'rgb(18,32,56)';
export const BLACK_LIGHT = 'rgb(53,61,73)';
export const PERFORMANCE_GRADE_COLORS = {
  excellent: PRIMARY,
  good: ACCENT_1,
  moderate: '#FF9F00',
  coaching: ACCENT_2,
};
