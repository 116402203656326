/* eslint-disable no-use-before-define */
// disabled the rule just for code readability across too many useEffects
import {
  CaretDownOutlined,
  CaretUpOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { Alert, Card, Col, Row, Tooltip } from 'antd';
import EnvironmentalRiskFactorCard from 'components/EnvironmentalRiskFactorCard';
import FocusAreas from 'components/FocusAreas/FocusAreas';
import moment from 'moment';
import queryString from 'query-string';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { fetchLocationsService } from 'services';
import { callGetApi } from '../../api/axios';
import { SECONDARY, SECONDARY_LIGHTER } from '../../assets/style/colors';
import LineChart from '../../components/charts/LineChart';
import { getScoreAdjective } from '../../components/charts/utils';
import DriveScoreGraph from '../../components/shared/driveScoreGraph';
import DateWidget from '../../components/widgets/dateWidget';
import LocationWidget from '../../components/widgets/locationWidget';
import PeriodsFilter from '../../components/widgets/periodsFilter';
import { SORT_TYPES } from '../../constants/sort-types';
import { useGlobalState } from '../../contexts/AppContext';
import useUserDetails from '../../hooks/useUserDetails';
import MixpanelContext from '../../services/tracking';
import {
  getDefaultDateRange,
  getLastPeriodDates,
  handleDateSelection,
} from '../../utils/dateFilter';
import { toast, toastTypes } from '../../utils/toast';
import {
  DriverInfoCard,
  EmptyState,
  TripsTable,
  loadingSpinned,
  loadingSpinned2,
} from './components';
import DeviceStatusCard from './components/DeviceStatusCard';
import DownloadReportTrigger from './components/DownloadReportTrigger';
import { SCORE_TYPES } from './constants';
import getDriverStatsService from './services';
import {
  formatScoreHistoryData,
  formatTrips,
  getLineChartAxisData,
  heartbeatTooltipStyles,
  processLastPeriodData,
} from './utils';

export default function DriverStats() {
  const { user, isPeriodsFilterEnabled, periodFilterPersistKey } =
    useUserDetails();
  const { dispatch } = useGlobalState();
  const mixpanel = useContext(MixpanelContext);
  const timelineRef = useRef(null);
  // One day in milliseconds

  // STATES
  const driverId = queryString.parse(window.location.search)?.driverId;
  // ;window.history?.state?.driver_stat?.id;
  const [performanceGrade, setPerformanceGrade] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tripsLoading, setTripsLoading] = useState(false);
  const [lastPeriodDataoading, setLastPeriodDataLoading] = useState(false);
  const [driverStats, setDriverStats] = useState(null);
  const [zendriveScore, setZendriveScore] = useState(null);
  const [sortType, setSortType] = useState(SORT_TYPES.desc);
  const [trips, setTrips] = useState([]);
  const [totalTrips, setTotalTrips] = useState(0);
  const [currentActivePageNumber, setCurrentActivePageNumber] = useState(1);
  const [nextTripOffset, setNextTripOffset] = useState(0);
  const [scoreInLastPeriod, setScoreInLastPeriod] = useState({
    score: 0,
    type: SCORE_TYPES.greater,
    isValidDiff: false,
  });
  const [dailyScoreHistory, setDailyScoreHistory] = useState([]);
  const [heartbeat, setHeartbeat] = useState(null);
  const [dateWidgetData, setDateWidgetData] = useState(getDefaultDateRange);
  const { startDate, endDate, intervalType } = dateWidgetData;
  const tempLastPeriodData = getLastPeriodDates(startDate, intervalType);
  const [datesOfLastPeriod, setDatesOfLastPeriod] = useState([
    moment(tempLastPeriodData.startDate).format('yyyy-MM-DD'),
    moment(tempLastPeriodData.endDate).format('yyyy-MM-DD'),
  ]);
  const [focusAreas, setFocusAreas] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState([
    ...JSON.parse(sessionStorage.getItem(periodFilterPersistKey) || '["ALL"]'),
  ]);
  const [locationList, setLocationList] = useState([
    { label: 'All locations', value: null },
  ]);
  const [selectedLocation, setSelectedLocation] = useState(
    queryString.parse(window.location.search)?.loc || null,
  );

  async function fetchLocations() {
    const params = {
      apiKey: user.api_key,
      start_date: startDate,
      interval: intervalType,
    };

    const {
      data,
      isError = false,
      errorMessage = '',
    } = await fetchLocationsService({
      queryParams: params,
    });
    if (isError) {
      toast(toastTypes.ERROR, errorMessage);
      return;
    }
    if (data.length > 0) {
      setLocationList(data);
    }
  }

  useEffect(() => {
    fetchLocations();
  }, []);

  // get score api
  useEffect(() => {
    if (driverId) {
      getDriverStats();
      getHeartBeatAggs();
    }
  }, [startDate, endDate, driverId, selectedPeriod, selectedLocation]);
  // get Trips api
  useEffect(() => {
    getTripsData();
  }, [
    startDate,
    endDate,
    sortType,
    nextTripOffset,
    driverId,
    selectedLocation,
    selectedPeriod,
  ]);
  // get last period data API
  useEffect(() => {
    if (datesOfLastPeriod[0] && datesOfLastPeriod[1] && zendriveScore) {
      getLastPeriodData();
    }
  }, [datesOfLastPeriod, zendriveScore]);

  useEffect(() => {
    const firstName = driverStats?.attributes?.first_name;
    const lastName = driverStats?.attributes?.last_name;
    const driverFullName =
      firstName && lastName
        ? `${firstName} ${lastName}`
        : driverStats?.fullName || driverStats?.userId;
    dispatch({
      type: 'SET_HEADER_BREADCRUMBS',
      payload: [
        { text: 'Drivers', uri: '/app/dashboard/drivers-performance' },
        { text: driverFullName },
      ],
    });

    return () => {
      dispatch({ type: 'SET_HEADER_BREADCRUMBS', payload: [] });
    };
  }, [driverStats]);

  useEffect(() => {
    mixpanel.track('View Page', {
      pageName: 'Driver Details Page',
    });
  }, [mixpanel]);

  const handleLocationSelection = (loc) => {
    setSelectedLocation(loc);
  };

  const getHeartBeatAggs = async () => {
    try {
      const queryParams = {
        apiKey: user.api_key,
        start_date: startDate,
        user_id: driverId,
        source: 'dashboard',
        interval_type: intervalType,
      };

      const { data } = await callGetApi(
        `/v1/api/heartbeat_aggregates?${queryString.stringify(queryParams, {
          arrayFormat: 'comma',
        })}`,
      );
      setHeartbeat(data);
    } catch (err) {
      const { fault } = err?.response?.data || {};
      if (fault) toast(toastTypes.ERROR, fault?.faultstring);
    }
  };

  async function getDriverStats() {
    setLoading(true);
    const {
      data,
      isError = false,
      errorMessage,
    } = await getDriverStatsService({
      startDate,
      endDate,
      driverId,
      intervalType,
      trip_periods: selectedPeriod,
      ...(selectedLocation && { location: selectedLocation }),
    });
    setLoading(false);
    if (isError) {
      toast(toastTypes.ERROR, errorMessage);
      return;
    }
    const { score, info } = data;
    setZendriveScore(score.score);
    setPerformanceGrade(score.grade);
    setDailyScoreHistory(data.scoreHistory);
    setDriverStats(info);
    setFocusAreas(data.focus_areas);

    mixpanel.track('View Page', {
      pageName: 'Driver Details Page',
      ...info,
      ...score,
      ...data.eventDistribution,
      ...data.focus_areas,
    });
  }

  async function getTripsData() {
    setTripsLoading(true);
    try {
      const queryParams = {
        apiKey: user.api_key,
        interval: intervalType,
        limit: 50,
        offset: nextTripOffset,
        start_date: startDate,
        order_by: 'start_time',
        order_type: sortType,
        ...(isPeriodsFilterEnabled && {
          trip_periods: selectedPeriod,
        }),
        ...(selectedLocation && { location: selectedLocation }),
      };

      if (selectedLocation && selectedLocation !== 'null') {
        queryParams.location = selectedLocation;
      }

      const { data } = await callGetApi(
        `/v2/api/driver/${encodeURIComponent(
          driverId,
        )}/trips?${queryString.stringify(queryParams, {
          arrayFormat: 'comma',
        })}`,
      );
      setTotalTrips(data?.count || 0);
      setTrips(formatTrips(data.trips));
    } catch (err) {
      const { fault } = err?.response?.data || {};
      if (fault?.faultstring) {
        toast(toastTypes.ERROR, fault.faultstring);
      }
    } finally {
      setTripsLoading(false);
    }
  }

  async function getLastPeriodData() {
    setLastPeriodDataLoading(true);
    try {
      const queryParams = {
        apiKey: user.api_key,
        interval: intervalType,
        start_date: datesOfLastPeriod[0],
        user_id: driverId,
        source: 'dashboard',
        fields: 'driving_behavior',
        ...(isPeriodsFilterEnabled && {
          trip_periods: selectedPeriod,
        }),
        ...(selectedLocation && { location: selectedLocation }),
      };

      const { data } = await callGetApi(
        `/v2/api/driver/${encodeURIComponent(
          driverId,
        )}/score?${queryString.stringify(queryParams, {
          arrayFormat: 'comma',
        })}`,
      );

      setScoreInLastPeriod(processLastPeriodData({ data, zendriveScore }));
    } catch (err) {
      const { fault } = err?.response?.data || {};
      if (fault) toast(toastTypes.ERROR, fault?.faultstring);
    } finally {
      setLastPeriodDataLoading(false);
    }
  }

  // daily score history line chart
  const dailyScoreChartWrapper = (id, metricData) => {
    const { xAxis, yAxis } = getLineChartAxisData(metricData);
    if (yAxis?.length > 0) {
      return (
        <LineChart
          id={id}
          tickInterval={10}
          tickLength={0}
          yAxisLabelVisibilityThreshold={70}
          yAxisMin={30}
          yAxisMax={100}
          xAxisAsDate
          xAxis={xAxis}
          yAxis={yAxis}
          chartColor={SECONDARY_LIGHTER}
          markerColor={SECONDARY}
          dynamicTooltipLabel={getScoreAdjective}
        />
      );
    }
    return EmptyState;
  };

  // loading spinner

  const updateLastPeriod = (range) => {
    const start = new Date(range[0]);
    // const end = new Date(range[1]);
    // get the difference in days
    // const diffInDays = getDifferenceInDays(start, end);
    // get the updated start date and end date once the dates are updated
    const { startDate } = getLastPeriodDates(start, intervalType);
    setDatesOfLastPeriod([
      moment(startDate).format('yyyy-MM-DD'),
      // moment(endDateFromXDays).format('yyyy-MM-DD'),
    ]);
  };

  useEffect(() => {
    if (
      !loading &&
      !tripsLoading &&
      timelineRef.current &&
      window?.history?.state?.timelineview
    ) {
      timelineRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [loading, tripsLoading]);

  const handleStartDateSelection = (dt, intType) => {
    // intType refers to interval type
    const { start, end } = handleDateSelection(dt, intType);
    const state = {
      startDate: start,
      endDate: end,
      intervalType: intType,
    };
    setDateWidgetData(state);
    updateLastPeriod([start, end]);
    localStorage.setItem('FM_DATE_FILTER', JSON.stringify(state));

    mixpanel.track('Filter a report by Datetime', {
      ...state,
    });
    mixpanel.people.increment('# of Filters by Datetime');
  };

  function onDownloadTrigger() {
    mixpanel.people.increment('# of Driver Specific Report Requested');
    mixpanel.track('Request Specific Driver Report', {
      startDate,
      endDate,
      driverId,
      intervalType,
      performanceGrade,
      zendriveScore,
      ...driverStats,
    });
  }

  if (loading) {
    return <div className="playground">{loadingSpinned}</div>;
  }

  return (
    <div>
      <style>{heartbeatTooltipStyles}</style>

      <div className="playground">
        <div className="p-3 d-flex align-items-center">
          <DateWidget
            onSelectDate={(date, interval) => {
              handleStartDateSelection(date, interval);
            }}
            startDate={startDate}
            endDate={endDate}
            intervalType={intervalType}
          />
          <LocationWidget
            locations={locationList}
            onSetLocation={handleLocationSelection}
            selectedLocation={selectedLocation}
          />
          <PeriodsFilter
            selectedPeriod={selectedPeriod}
            handlePeriodChange={setSelectedPeriod}
          />
          <DownloadReportTrigger
            startDate={startDate}
            endDate={endDate}
            driverId={driverId}
            onDownloadTrigger={onDownloadTrigger}
            selectedPeriod={selectedPeriod}
          />
        </div>

        {/* STATS AND SCORE GRAPH  */}
        <div className="driver_stats_and_score_wrapper">
          {/* DRIVER STATS CARD  */}
          <div
            className="driver_stats_wrapper_card stats_wrapper"
            style={{
              flexBasis: '35%',
            }}
          >
            {/* CARD HEADER  */}
            <div className="driver_stats_card_header">
              <p className="driver_stats_card_header_text">Driver</p>
            </div>
            {/* CARD BODY  */}
            <div
              className="driver_stats_card_body"
              style={{
                maxHeight: '250px',
                overflow: 'auto',
              }}
            >
              {driverId && driverStats && (
                <DriverInfoCard id={driverId} driverStats={driverStats} />
              )}
            </div>
          </div>
          {/* DRIVER SCORE CARD  */}
          <div
            className="driver_stats_wrapper_card stats_wrapper"
            style={{
              flexBasis: '65%',
            }}
          >
            {/* CARD HEADER  */}
            <div className="driver_stats_card_header">
              <p className="driver_stats_card_header_text">
                Driver performance
              </p>
            </div>
            {/* CARD BODY  */}
            <div className="driver_stats_card_body ">
              {lastPeriodDataoading && loadingSpinned2}
              {!lastPeriodDataoading &&
                (zendriveScore === -1 || typeof zendriveScore !== 'number') &&
                EmptyState}
              {!lastPeriodDataoading &&
                zendriveScore !== -1 &&
                typeof zendriveScore === 'number' && (
                  <Row justify="start">
                    <Col span={12}>
                      <h6
                        className="card-sub-heading"
                        style={{
                          fontSize: '14px',
                          color: '#999',
                          textAlign: 'left',
                          margin: 0,
                        }}
                      >
                        Score
                        <Tooltip title="The average driver score is an aggregate of the risk factors that are within a driver’s control. It is calculated using a weighted average for a given time period across all drivers of the fleet. The score can range between 60 to 100. A higher score indicates safer driving performance.">
                          <InfoCircleOutlined
                            style={{
                              marginLeft: '5px',
                            }}
                          />
                        </Tooltip>
                      </h6>
                      <div className="score_graph_wrapper">
                        <DriveScoreGraph
                          zendriveScore={zendriveScore}
                          grade={performanceGrade}
                          fair_score_indicator_classname="driver_fair_score_indicator"
                          fair_score_indicator_value_classname="driver_fair_score_indicator_value"
                          excellent_score_indicator_classname="driver_excellent_score_indicator"
                          excellent_score_indicator_value_classname="driver_excellent_score_indicator_value"
                          good_score_indicator_classname="driver_good_score_indicator"
                          good_score_indicator_value_classname="driver_good_score_indicator_value"
                        />
                        <hr
                          style={{
                            margin: '0 80px',
                            border: '1px solid #ddd',
                          }}
                        />
                        {scoreInLastPeriod.isValidDiff && (
                          <div className="d-flex align-items-center justify-content-center py-2">
                            <div className="px-2">
                              {scoreInLastPeriod.type ===
                              SCORE_TYPES.greater ? (
                                <CaretUpOutlined />
                              ) : (
                                <CaretDownOutlined />
                              )}
                            </div>
                            <div className="px-2">
                              <p className="score_diff_text">
                                {scoreInLastPeriod.score} points since last{' '}
                                {intervalType}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col span={12}>
                      {focusAreas?.length > 0 && (
                        <FocusAreas focusAreas={focusAreas} />
                      )}
                    </Col>
                  </Row>
                )}
            </div>
          </div>
        </div>

        {/* DAILY SCORE LINE CHART AND EVENT RATING  */}
        <div className="driver_stats_and_score_wrapper">
          <Card
            title={
              <p className="driver_stats_card_header_text">
                Environmental Risk Factors
              </p>
            }
            className="card_box_shadow"
            style={{
              margin: '10px',
              paddingBottom: '6px',
              flexBasis: '35%',
            }}
          >
            <EnvironmentalRiskFactorCard
              stats={driverStats?.environmentRiskFactors}
            />
          </Card>
          {/* DRIVER STATS CARD  */}
          <div
            className="driver_stats_wrapper_card line_chart_wrapper"
            style={{
              flexBasis: '65%',
            }}
          >
            {/* CARD HEADER  */}
            <div className="driver_stats_card_header">
              <p className="driver_stats_card_header_text">
                Daily Score History
              </p>
            </div>
            {/* CARD BODY  */}
            <div className="driver_stats_card_body">
              {/* LINE CHART  */}
              {dailyScoreChartWrapper(
                'score-history-chart',
                formatScoreHistoryData(dailyScoreHistory),
              )}
            </div>
          </div>
          {/* EVENT RATING CARD  */}
        </div>

        <div ref={timelineRef}>
          <DeviceStatusCard
            heartbeat={heartbeat}
            dateRange={{ startDate, endDate }}
          />
        </div>

        {/* TRIPS CARD  */}
        <div className="trips_card_wrapper">
          {/* DRIVER STATS CARD  */}
          <div className="driver_stats_wrapper_card">
            {/* CARD HEADER  */}
            <Alert
              message="Data is displayed as per browser time zone & trips can take up to
              2 hours from trip completion to be displayed here"
              type="info"
              showIcon
              className="drivers_list_info_alert"
            />
            <div className="driver_stats_card_header">
              <p className="driver_stats_card_header_text">Trips</p>
            </div>
            <TripsTable
              trips={trips}
              loading={tripsLoading}
              driverId={driverId}
              sortType={sortType}
              onUpdateSortType={(value) => setSortType(value)}
              totalTrips={totalTrips}
              currentActivePageNumber={currentActivePageNumber}
              onUpdatePageNumber={(pageNumber) => {
                setCurrentActivePageNumber(pageNumber);
                setNextTripOffset(50 * (pageNumber - 1));
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
