import { ACCENT_1, ACCENT_2, PRIMARY } from '../../assets/style/colors';
import {
  isoTimeDifferenceText,
  isoTimeToSimplifiedTime,
  isoTimeToUTCMilliSeconds,
} from '../../utils/date';

const _nonIterableRest = () => {
  throw new TypeError('Invalid attempt to destructure non-iterable instance');
};

const _iterableToArrayLimit = (arr, i) => {
  const _arr = [];
  let _n = true;
  let _d = false;
  let _e;
  try {
    for (
      var _i = arr[Symbol.iterator](), _s;
      !(_n = (_s = _i.next()).done);
      _n = true
    ) {
      _arr.push(_s.value);
      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i.return != null) _i.return();
    } finally {
      if (_d) throw _e;
    }
  }
  return _arr;
};

const _arrayWithHoles = (arr) => {
  if (Array.isArray(arr)) return arr;
};

const _slicedToArray = (arr, i) =>
  _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();

export const formatNumber = (numericValue, remainderDigits = null) => {
  const formatRegex = /\B(?=(\d{3})+(?!\d))/g; // adds comma after every 3rd character as long as its followed by another number (from right to left)

  if (numericValue % 1 !== 0) {
    const remainerLength = numericValue.toString().split('.')[1].length;
    remainderDigits =
      !remainderDigits || remainerLength < remainderDigits
        ? remainerLength
        : remainderDigits;
    const roundedVal = numericValue.toFixed(remainderDigits);

    const _roundedVal$split = roundedVal.split('.');
    const _roundedVal$split2 = _slicedToArray(_roundedVal$split, 2);
    const nonDecimal = _roundedVal$split2[0];
    const decimal = _roundedVal$split2[1];

    return ''.concat(nonDecimal.replace(formatRegex, ','), '.').concat(decimal);
  }

  return numericValue.toString().replace(formatRegex, ',');
};

export const convertDistance = (
  distance,
  unit,
  numeric = false,
  precision = 1,
) => {
  if (!distance || !unit) {
    return 0;
  }
  let adjustedDistance = distance; // handle miles

  if (unit.toLowerCase() === 'miles') {
    adjustedDistance *= 0.62137119;
  }

  const calcDistance = adjustedDistance.toFixed(precision);

  if (numeric) {
    return +calcDistance;
  }

  if (unit.toLowerCase() === 'miles') {
    return +calcDistance > 1
      ? ''.concat(+calcDistance, ' miles')
      : ''.concat(calcDistance, ' mile');
  }

  return ''.concat(calcDistance, ' km');
};

export const formatTrendsData = (trendsData) => {
  let computedTrends = {
    data: {
      savings: {
        dates: [],
        values: [],
      },
      costPerMile: {
        dates: [],
        values: [],
      },
      milesDriven: {
        dates: [],
        values: [],
      },
      premium: {
        dates: [],
        values: [],
      },
    },
    isLoaded: true,
  };
  if (trendsData.length) {
    const dates = [];
    const savings = [];
    const costPerMile = [];
    const milesDriven = [];
    const premium = [];
    trendsData.forEach((e) => {
      dates.push(e.start_date);
      savings.push(+e.savings);
      costPerMile.push(e.cpm);
      milesDriven.push(convertDistance(e.distance_km, 'miles', true));
      premium.push(e.premium);
    });
    computedTrends = {
      data: {
        savings: { dates, values: savings },
        cpm: { dates, values: costPerMile },
        distance_km: { dates, values: milesDriven },
        premium: { dates, values: premium },
      },
      isLoaded: true,
    };
  }
  return computedTrends;
};

export const formatCpmPercentages = (perfGroups) => {
  let cpmPercentages = [];
  if (perfGroups) {
    const coaching = perfGroups?.coaching;
    const excellent = perfGroups?.excellent;
    const good = perfGroups?.good;
    const moderate = perfGroups?.moderate;
    cpmPercentages = [
      {
        label: 'Coaching Required',
        value: coaching?.percent_cost,
        cpm: coaching?.cpm,
        color: ACCENT_2,
        ...coaching,
      },
      {
        label: 'Moderate',
        value: moderate?.percent_cost,
        cpm: moderate?.cpm,
        color: ACCENT_2,
        ...moderate,
      },
      {
        label: 'Good',
        value: good?.percent_cost,
        cpm: good?.cpm,
        color: ACCENT_1,
        ...good,
      },
      {
        label: 'Excellent',
        value: excellent?.percent_cost,
        cpm: excellent?.cpm,
        color: PRIMARY,
        ...excellent,
      },
    ];
  }
  return cpmPercentages;
};

export const calculateChartAxisStep = (axisLength, expectedLabelCount) => {
  // calculates the step count needed to get the desired count of axis labels
  if (expectedLabelCount) {
    const stepCount = parseInt(axisLength / expectedLabelCount, 10);
    return stepCount === 0 ? 1 : stepCount;
  }

  return null;
};

export const extractRgb = (color) =>
  // extracts value in-between rgb color code e.g.(rgb(255,255,255) -> 255,255,255)
  color.match(/rgb\((.+)\)/)[1];
export const zip = (arr, ...args) =>
  arr.map((value, idx) => [value, ...args.map((arr) => arr[idx])]);

export const getScoreAdjective = (score) => {
  if (score > 89) {
    return 'Excellent';
  }
  if (score > 71) {
    return 'Fair';
  }
  return 'Coaching Required';
};

export const secondsToHoursAndMinutes = (d) => {
  d = Number(d);
  const hours = Math.floor(d / 3600);
  const minutes = Math.floor((d % 3600) / 60);
  const seconds = Math.floor((d % 3600) % 60);

  const hDisplay = hours > 0 ? `${hours}h` : '';
  const mDisplay = minutes > 0 ? `${minutes}m` : `${seconds}s`;
  return `${hDisplay} ${mDisplay}`;
};

const formatSpeedMPH = (speed, unit, precision = 1) => {
  speed = speed.toFixed(precision);
  if (unit.toLowerCase() === 'miles') {
    return ''.concat(speed, ' mph');
  }
  return ''.concat(speed, ' kmph');
};

export const convertApiEventToMapEvent = (
  apiEvent,
  distanceUnit,
  convertTimeToLocalTimeZone = true,
) => {
  const eventNames = {
    PHONE_USE: 'PHONE USE',
    HARD_BRAKE: 'HARD BRAKING',
    OVERSPEEDING: 'SPEEDING',
    RAPID_ACCELERATION: 'ACCELERATION',
    COLLISION: 'COLLISION',
    HARD_TURN: 'HARD TURN',
    PHONE_SCREEN_INTERACTION: 'PHONE SCREEN INTERACTION',
  };
  const isPhoneUseEvent =
    apiEvent.event_type_name === 'PHONE_USE' ||
    apiEvent.event_type_name === 'PHONE_SCREEN_INTERACTION';
  const isSpeedingEvent = apiEvent.event_type_name === 'OVERSPEEDING';
  const eventDict = {
    latitude: apiEvent.latitude_start,
    longitude: apiEvent.longitude_start,
    eventType: eventNames[apiEvent.event_type_name],
    data: isPhoneUseEvent
      ? isoTimeDifferenceText(apiEvent.start_time, apiEvent.end_time)
      : isoTimeToSimplifiedTime(
          apiEvent.start_time,
          convertTimeToLocalTimeZone,
        ),
    description: isPhoneUseEvent ? 'Duration' : '',
    duration: isoTimeDifferenceText(apiEvent.start_time, apiEvent.end_time),
  }; // add additional keys for OVERSPEEDING event

  if (
    isSpeedingEvent &&
    apiEvent.max_driver_speed_mph &&
    apiEvent.posted_speed_limit_mph &&
    apiEvent.start_time &&
    apiEvent.end_time
  ) {
    eventDict.maxSpeed = ''.concat(
      formatSpeedMPH(+apiEvent.max_driver_speed_mph, distanceUnit),
    );
    eventDict.postedSpeed = ''.concat(
      formatSpeedMPH(+apiEvent.posted_speed_limit_mph, distanceUnit, 0),
    );
    eventDict.startTimeMillis = isoTimeToUTCMilliSeconds(apiEvent.start_time);
    eventDict.endTimeMillis = isoTimeToUTCMilliSeconds(apiEvent.end_time);
  }

  return eventDict;
};

export const defaultLowDistanceKm = 80;

export const getEnvironmentalRiskScores = (info) => {
  const highwayDrivenDistance = info?.distance_km * info?.highway_ratio;
  const nightDrivingDuration =
    info?.duration_seconds * info?.night_driving_fraction;
  const environmentRiskFactors = {
    highwayDriving: info?.highway_ratio,
    nightDriving: info?.night_driving_fraction,
    highwayDrivenDistance,
    nightDrivingDuration,
  };
  return environmentRiskFactors;
};

const padTimeElements = (timeUnit) => String(timeUnit).padStart(2, '0');

export const formatNightDrivingDuration = (duration) => {
  if (Number.isNaN(Number(duration))) return 'N/A';
  if (duration) {
    let acc = duration;
    let minutes = 0;
    let hours = 0;
    if (acc >= 3600) {
      hours = Math.floor(acc / 3600);
      acc %= 3600;
    }
    if (acc >= 60) {
      minutes = Math.floor(acc / 60);
      acc %= 60;
    }
    hours = padTimeElements(hours);
    minutes = padTimeElements(minutes);
    return `${hours}:${minutes} Hrs:mins`;
  }
  return '00:00 Hr:min';
};

export const formatToPercent = (ratio) => {
  if (Number.isNaN(Number(ratio))) {
    return 'N/A';
  }

  if (ratio >= 0) {
    return `${Math.round(ratio * 100)} %`;
  }

  // handle case where ratio is -1
  return '0 %';
};

export const parseDuration = (seconds) => {
  // 3600 seconds in an hour
  const hours = Math.floor(seconds / 3600);
  const remainingMinutes = Math.round((seconds % 3600) / 60);

  if (hours && !remainingMinutes) {
    return ''.concat(hours, 'hr');
  }

  return hours < 1
    ? ''.concat(remainingMinutes, 'm')
    : ''.concat(hours, 'h ').concat(remainingMinutes, 'm');
};
