const _styledComponents = _interopRequireDefault(require('styled-components'));

const _colors = require('../../assets/style/colors');

const _fonts = require('../../assets/style/fonts');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

export const Wrapper = _styledComponents.default.div.withConfig({
  displayName: 'Wrapper',
  componentId: 'vtjm8p-0',
})(
  [
    'display:block;padding:0;position:relative;height:100%;width:100%;& .mapboxgl-popup{display:flex;justify-content:center;padding:0;width:145px;color:',
    ';opacity:0.8;padding:8px;font-size:10px !important;transform:scale(0);transition:opacity 150ms cubic-bezier(0.4,0,0.2,1) 0ms,transform 150ms cubic-bezier(0.4,0,0.2,1) 0ms;min-height:0;line-height:1.33;font-weight:normal;border-radius:2px;letter-spacing:0.5px;background-color:rgb(18,32,56);}& .mapboxgl-popup-content{background:inherit;padding:0;}& .mapboxgl-popup-tip{display:none;}& .marker{cursor:pointer;}& .zd-mapbox-title{margin:0;font-size:',
    'px;line-height:1.33;text-align:center;color:',
    ';font-weight:normal;}& .zd-mapbox-data{font-size:',
    'px;font-weight:bold;text-align:center;color:',
    ';display:block;margin-top:4px;}& .zd-mapbox-description{font-size:',
    'px;line-height:1.33;text-align:center;color:',
    ';display:block;margin-top:4px;}& .zd-mapbox-speed{font-size:',
    'px;line-height:1.33;text-align:center;color:',
    ';display:block;margin-top:4px;}& .zd-mapbox-max-speed{font-weight:bold;color:',
    ';}& .zd-mapbox-posted-speed{font-weight:bold;}',
  ],
  _colors.WHITE,
  _fonts.small,
  _colors.WHITE,
  _fonts.large,
  _colors.WHITE,
  _fonts.small,
  _colors.GREY_150,
  _fonts.large,
  _colors.WHITE,
  _colors.ACCENT_2,
);

// # sourceMappingURL=Wrapper.js.map
