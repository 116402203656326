Object.defineProperty(exports, '__esModule', {
  value: true,
});
exports.bpfrpt_proptype_LegendData = exports.default = void 0;

const _react = _interopRequireDefault(require('react'));

const _styledComponents = _interopRequireDefault(require('styled-components'));

const _colors = require('../../assets/style/colors');

const _fonts = require('../../assets/style/fonts');

const _propTypes = _interopRequireDefault(require('prop-types'));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

const bpfrpt_proptype_LegendData = {
  text: _propTypes.default.string.isRequired,
  color: _propTypes.default.string.isRequired,
};
exports.bpfrpt_proptype_LegendData = bpfrpt_proptype_LegendData;

const Indicator = _styledComponents.default.span.withConfig({
  displayName: 'Legend__Indicator',
  componentId: 'sc-1lfgmys-0',
})(
  [
    'width:12px;display:inline-block;height:2px;background:',
    ';margin:3px 8px;border-radius:2px;',
  ],
  (props) => props.color,
);

const Text = _styledComponents.default.span.withConfig({
  displayName: 'Legend__Text',
  componentId: 'sc-1lfgmys-1',
})(['font-size:', 'px;color:', ';'], _fonts.smaller, _colors.BLACK);

const Legend = function Legend(props) {
  return _react.default.createElement(
    'div',
    null,
    _react.default.createElement(Indicator, {
      color: props.color,
    }),
    _react.default.createElement(Text, null, props.text),
  );
};

Legend.propTypes = {
  color: _propTypes.default.string.isRequired,
  text: _propTypes.default.string.isRequired,
};
const _default = Legend;
const _default2 = _default;
exports.default = _default2;

Legend.__docgenInfo = {
  description: '',
  methods: [],
  displayName: 'Legend',
  props: {
    color: {
      required: true,
      flowType: {
        name: 'string',
      },
      description: '',
    },
    text: {
      required: true,
      flowType: {
        name: 'string',
      },
      description: '',
    },
  },
};
// # sourceMappingURL=index.js.map
