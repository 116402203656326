import PropTypes from 'prop-types';
import React from 'react';
import { BLACK } from '../../../../assets/style/colors';
import MapBox from '../../../../components/Map';
import {
  convertApiEventToMapEvent,
  extractRgb,
} from '../../../../components/charts/utils';

function MapWrapper({ mapData, mapLoading }) {
  function findMarkers() {
    return mapData?.events?.map((apiMarker) =>
      convertApiEventToMapEvent(apiMarker, 'miles'),
    );
  }
  return (
    <MapBox
      centerLat={
        mapData?.simple_path?.length > 0
          ? mapData?.simple_path[0]?.latitude
          : -1
      }
      centerLon={
        mapData?.simple_path?.length > 0
          ? mapData?.simple_path[0]?.longitude
          : -1
      }
      zoom={12}
      primaryTrail={{
        coordinates: mapData?.simple_path,
        color: 'rgba('.concat(extractRgb(BLACK), ',.80)'),
        thickness: 4,
      }}
      eventMarkers={findMarkers()}
      loading={mapLoading}
    />
  );
}
MapWrapper.propTypes = {
  mapData: PropTypes.object.isRequired,
  mapLoading: PropTypes.bool.isRequired,
};
export default MapWrapper;
