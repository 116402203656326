import { Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { callPostApi } from '../../../api/axios';
import MixpanelContext from '../../../services/tracking';
import { debounce } from '../../../utils/search';
import { toast, toastTypes } from '../../../utils/toast';
import { getUser } from '../../../utils/validateUserToken';
import './EditDrivers.scss';
import EditDriversCell from './EditDriversCell';
import EditDriversConfirmationModal from './EditDriversConfirmationModal';
import EditDriversDiscardModal from './EditDriversDiscardModal';
import EditDriversFooter from './EditDriversFooter';
import EditDriversHeader from './EditDriversHeader';
import EditDriversRow from './EditDriversRow';
import EditDriversTableHeader from './EditDriversTableHeader';
import { checkForSameFields, columns } from './EditDriversUtils';

const DEFAULT_CATEGORY = 'all';
const DEFAULT_LIMIT = 25;

export default function EditDrivers() {
  const tableRef = React.createRef();
  const user = getUser();
  const companyName = user?.company_name;
  const mixpanel = useContext(MixpanelContext);
  const localData = JSON.parse(localStorage.getItem('fm_edit_drivers')) || [];
  const [searchTerm, setSearchTerm] = useState('');
  const [driversList, setDriversList] = useState({ result: [], total: 0 });
  const [tableHeight, setTableHeight] = useState(600);
  const [editedDriversList, setEditedDriversList] = useState(
    localData[companyName] ? [...localData[companyName]] : [],
  );
  const [isDriversLoading, setIsDriversLoading] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [resetRow, setResetRow] = useState(false);

  useEffect(() => {
    const node = tableRef.current;
    const resizeObserver = new ResizeObserver(() => {
      const { top } = node.getBoundingClientRect();
      setTableHeight(window.innerHeight - top - 150);
    });
    resizeObserver.observe(node);

    return () => {
      resizeObserver.disconnect();
    };
  }, [tableRef]);

  useEffect(() => {
    mixpanel.track('View Page', {
      pageName: 'Edit Drivers Page',
    });
  }, [mixpanel]);

  async function fetchDriversList(isLoadMore = false) {
    setIsDriversLoading(true);
    try {
      const { data } = await callPostApi('/v1/api/devicestatus/list', {
        category: DEFAULT_CATEGORY,
        limit: DEFAULT_LIMIT,
        offset: isLoadMore ? driversList?.result?.length : 0,
        search_term: searchTerm,
      });

      setDriversList((current) => ({
        result: isLoadMore
          ? [...current.result, ...data.result]
          : [...data.result],
        total_count: data.total_count,
      }));
    } catch (err) {
      setDriversList({ result: [] });
    } finally {
      setIsDriversLoading(false);
    }
  }

  const handleSearch = (term) => {
    debounce(() => {
      setSearchTerm(term.trim());
    }, 800)();
  };

  const handleDiscard = () => {
    setResetForm(true);
    setEditedDriversList([]);
    setShowDiscardModal(false);

    mixpanel.track('Discarded changes', {
      pageName: 'Edit Drivers Page',
    });
  };

  useEffect(() => {
    fetchDriversList();
  }, [searchTerm]);

  const handlePublish = async () => {
    setIsModalLoading(true);
    try {
      await callPostApi('/v1/api/task/create', {
        task_type: 'update_drivers',
        update_driver_data: editedDriversList.map((driver) => ({
          driver_id: driver?.driver_id,
          first_name: driver?.first_name.trim(),
          last_name: driver?.last_name.trim(),
          device_ownership_type: driver?.device_ownership_type.trim(),
        })),
      });
      toast(toastTypes.SUCCESS, 'Drivers updated successfully');
      setEditedDriversList([]);
    } catch (err) {
      toast(toastTypes.ERROR, 'Error updating drivers');
    } finally {
      setIsModalLoading(false);
      setShowPublishModal(false);
      fetchDriversList();
    }

    mixpanel.track('Published changes', {
      pageName: 'Edit Drivers Page',
    });
  };

  const handleSave = (row, hasError = false) => {
    const filterdEditedDrivers = editedDriversList.filter(
      (driver) => driver.driver_id !== row.driver_id,
    );

    const editedList = checkForSameFields(driversList.result, row)
      ? filterdEditedDrivers
      : [...filterdEditedDrivers, { ...row, hasError }];

    setEditedDriversList(editedList);
    setResetRow(false);
    setResetForm(false);
  };

  useEffect(() => {
    localStorage.setItem(
      'fm_edit_drivers',
      JSON.stringify({
        ...localData,
        [companyName]: editedDriversList,
      }),
    );
  }, [editedDriversList]);

  function handlePagination() {
    fetchDriversList(true);
    mixpanel.track('Edit Drivers Pagination', {
      loadMore: true,
      pageName: 'Edit Drivers Page',
    });
  }

  const handleDeleteRow = (id) => {
    setEditedDriversList(
      editedDriversList.filter((driver) => driver.driver_id !== id),
    );
    setResetRow(id);
  };

  const togglePublishModal = () => {
    setShowPublishModal(true);
    mixpanel.track('Publish Modal Shown', {
      pageName: 'Edit Drivers Page',
    });
  };

  const toggleDiscardModal = () => {
    setShowDiscardModal(true);
    mixpanel.track('Discard Modal Shown', {
      pageName: 'Edit Drivers Page',
    });
  };

  const getColumns = columns.map((col) => ({
    ...col,
    onCell: (record) => ({
      record,
      editable: col.editable,
      dataIndex: col.dataIndex,
      handleSave,
      handleDeleteRow,
      showClose: editedDriversList.some(
        (driver) => driver.driver_id === record.driver_id,
      ),
    }),
  }));

  const components = {
    body: {
      row: EditDriversRow,
      cell: EditDriversCell,
    },
  };

  return (
    <div className="playground">
      <EditDriversHeader
        handleDiscard={toggleDiscardModal}
        handlePublish={togglePublishModal}
        hasChanges={editedDriversList.length > 0}
      />
      <EditDriversConfirmationModal
        showModal={showPublishModal}
        setShowModal={setShowPublishModal}
        handlePublish={handlePublish}
        modalData={editedDriversList}
        handleDelete={handleDeleteRow}
        isModalLoading={isModalLoading}
      />
      <EditDriversDiscardModal
        showModal={showDiscardModal}
        setShowModal={setShowDiscardModal}
        handleDiscard={handleDiscard}
      />
      <div className="edit_driver_table">
        <EditDriversTableHeader
          searchTerm={searchTerm}
          handleSearch={handleSearch}
          editedDriversList={editedDriversList}
        />
        <Table
          ref={tableRef}
          rowKey={(record) => record.driver_id}
          bordered
          components={components}
          loading={isDriversLoading}
          dataSource={driversList.result.map((driver_id) => driver_id)}
          columns={getColumns}
          size="medium"
          pagination={false}
          className="add_drivers_table"
          scroll={{ y: tableHeight, x: 'max-content' }}
          onRow={(record, index) => ({
            record,
            editedDriversList,
            initialValues: driversList.result[index],
            resetForm,
            resetRow,
          })}
        />
        <EditDriversFooter
          handlePagination={handlePagination}
          driversList={driversList}
          isDriversLoading={isDriversLoading}
        />
      </div>
    </div>
  );
}
