import { Spin } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { SECONDARY, SECONDARY_LIGHTER } from '../../../assets/style/colors';
import LineChart from '../../../components/charts/LineChart';
import { formatNumber } from '../../../components/charts/utils';
import { getLineChartAxisData } from '../utils';

export default function DailyDistanceChart({
  metricData,
  isLoading,
  xAxisLabelFormat,
  tooltipLabelFormat,
}) {
  const { xAxis, yAxis } = getLineChartAxisData(metricData);

  if (isLoading) {
    return (
      <div
        style={{ minHeight: '250px' }}
        className="d-flex align-items-center justify-content-center"
      >
        <Spin />
      </div>
    );
  }
  if (yAxis?.length > 0) {
    return (
      <div style={{ display: 'flex' }}>
        <LineChart
          id="daily-distance-chart"
          xAxisAsDate
          chartColor={SECONDARY_LIGHTER}
          markerColor={SECONDARY}
          tooltipLabel=""
          xAxis={xAxis}
          yAxis={yAxis}
          yAxisLabelFormatter={formatNumber}
          xAxisLabelFormat={xAxisLabelFormat}
          tooltipLabelFormat={tooltipLabelFormat}
        />
      </div>
    );
  }
  return (
    <div
      style={{
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        height: '260px',
        alignItems: 'center',
      }}
    >
      <h4>NO MILES TRACKED IN THIS TIME PERIOD</h4>
    </div>
  );
}

DailyDistanceChart.propTypes = {
  metricData: PropTypes.object,
  isLoading: PropTypes.bool,
};
