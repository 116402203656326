import { InfoCircleOutlined } from '@ant-design/icons';
import { Card, Spin, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { GREY_200 } from '../../../assets/style/colors';
import PerformanceGroupsTooltip from '../../../components/PerformanceGroupsTooltip';

const BoxShadow =
  '0 6px 10px 0 rgb(47 49 104 / 12%), 0 1px 6px 0 rgb(0 0 0 / 12%)';

function RenderPerfGroup(label, value) {
  return (
    <div
      key={label}
      style={{
        fontSize: '13px',
        width: '100%',
        padding: '12px 24px',
        display: 'flex',
        textAlign: 'left',
        alignItems: 'center',
        justifyContent: 'space-between',
        textDecoration: 'none',
        cursor: 'pointer',
      }}
    >
      <div
        style={{
          fontWeight: 'bold',
          color: 'rgb(18, 32, 56)',
          paddingLeft: '0px',
          flex: '1 0 auto',
          minWidth: '0',
        }}
      >
        <span>{label}</span>
      </div>
      <div
        style={{
          flex: '0 1 auto',
          fontWeight: 'normal',
          color: 'rgb(18, 32, 56)',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {value}
      </div>
    </div>
  );
}

export default function PerformanceGroup({ perfGroups, isLoading }) {
  const { excellent, good, moderate, coaching_required } = perfGroups;

  const PerfGroupData = [
    {
      label: 'Excellent',
      value: excellent,
    },
    {
      label: 'Good',
      value: good,
    },
    {
      label: 'Moderate',
      value: moderate,
    },
    {
      label: 'Coaching Required',
      value: coaching_required,
    },
  ];

  return (
    <Card
      title={
        <div className="d-flex align-items-center">
          <p className="driver_stats_card_header_text">
            Driver Performance Grades
            <Tooltip title={<PerformanceGroupsTooltip />}>
              <InfoCircleOutlined
                style={{
                  marginLeft: '10px',
                  color: GREY_200,
                  fontSize: '16px',
                }}
              />
            </Tooltip>
          </p>
        </div>
      }
      style={{
        margin: '10px',
        flexBasis: '24%',
        boxShadow: BoxShadow,
      }}
      bodyStyle={{
        padding: '50px 0px',
        minHeight: '306px',
      }}
      className="performance_group_card"
    >
      {isLoading ? (
        <div
          style={{ minHeight: '200px' }}
          className="d-flex align-items-center justify-content-center"
        >
          <Spin />
        </div>
      ) : (
        PerfGroupData.map((item) => RenderPerfGroup(item.label, item.value))
      )}
    </Card>
  );
}

PerformanceGroup.propTypes = {
  perfGroups: PropTypes.object,
  isLoading: PropTypes.bool,
};
