import { LoadingOutlined } from '@ant-design/icons';
import { Button, Card, Spin, Table, Typography } from 'antd';
import React from 'react';
import ConfirmationModal from '../../../components/shared/confirmationModal';
import { modalTableColumns } from './EditDriversUtils';

const { Text } = Typography;

export default function EditDriversConfirmationModal({
  isLoading,
  showModal,
  setShowModal,
  handlePublish,
  modalData,
  handleDelete,
  isModalLoading,
}) {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const hasError = modalData.some((driver) => driver.hasError);
  const pluralizeText = modalData.length > 1 ? 'drivers' : 'driver';

  return (
    <ConfirmationModal
      visible={showModal}
      onCancel={() => {
        setShowModal(false);
      }}
      width="60%"
      title="Edit Drivers"
      footer={[
        <Button
          key="cancel"
          disabled={isLoading}
          onClick={() => {
            setShowModal(false);
          }}
        >
          Keep Editing
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={isLoading || modalData.length === 0 || hasError}
          onClick={handlePublish}
        >
          Publish Changes
        </Button>,
      ]}
    >
      {isLoading ? (
        <div className="modal_loading">
          <Text>Editing Drivers...</Text>
          <Spin indicator={antIcon} />
        </div>
      ) : (
        <div>
          <p>
            Are you sure you want to edit {modalData.length} {pluralizeText} in
            your fleet as this action cannot be reverted?
          </p>
          <Card style={{ height: '50vh', overflowY: 'auto' }}>
            <Table
              pagination={false}
              dataSource={modalData}
              columns={modalTableColumns(handleDelete)}
              loading={isModalLoading}
            />
          </Card>
        </div>
      )}
    </ConfirmationModal>
  );
}
