import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import { GREY_200 } from '../../../../assets/style/colors';

function TooltipText() {
  return (
    <span>
      This chart offers a consolidated view of your fleet’s driving performance.
      Each data point on the chart reflects the breakdown of the total number of
      drivers in each performance grade.
      <br />
      <br />
      Note: Data for the current week is populated once the week is over.
    </span>
  );
}

function PerformanceBreakdownTooltip() {
  return (
    <Tooltip title={TooltipText}>
      <InfoCircleOutlined
        style={{
          marginLeft: '10px',
          color: GREY_200,
          fontSize: '16px',
        }}
      />
    </Tooltip>
  );
}
export default PerformanceBreakdownTooltip;
