import React from 'react';

export default function SidebarFooter() {
  return (
    <div className="dashboard_sidebar_footer">
      <a
        href="http://fairmatic.com/privacy-policy"
        target="_blank"
        rel="noreferrer"
        style={{ color: '#3664A0', fontSize: '11px' }}
      >
        Privacy Policy
      </a>
      <div>
        <p className="footer_text">©2023 Fairmatic Insurance</p>
        <p className="footer_text">All rights reserved</p>
      </div>
    </div>
  );
}
