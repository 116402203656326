import { InfoCircleOutlined } from '@ant-design/icons';
import { Card, Tooltip, Typography } from 'antd';
import React from 'react';
import { GREY_200 } from '../../../assets/style/colors';
import DriversTable from '../../../components/dashboard/drivers/driversTable';

const BoxShadow =
  '0 6px 10px 0 rgb(47 49 104 / 12%), 0 1px 6px 0 rgb(0 0 0 / 12%)';

const EXCELLENT_DRIVERS_CONSTANTS = {
  TooltipMessage:
    'This is a list of your fleet’s top performing drivers. On average, fleets with the best Cost Per Mile have most of their drivers scoring 90 to 100. ',
  TitleMessage: 'Excellent drivers',
  EmptyMessage: 'NO EXCELLENT DRIVERS IN THIS TIME PERIOD',
};

const COACHING_REQUIRED_DRIVERS_CONSTANTS = {
  TooltipMessage:
    'This is a list of your fleet’s riskiest drivers. Coaching Required drivers have the most severe impact on a fleet’s average driver score and Cost Per Mile.  ',
  TitleMessage: 'Coaching Required',
  EmptyMessage: 'NO COACHING REQUIRED DRIVERS IN THIS TIME PERIOD',
};

export default function DriversOverview({
  drivers,
  loadingDrivers,
  startDate,
  endDate,
  intervalType,
  perfGroup,
}) {
  const { TooltipMessage, TitleMessage, EmptyMessage } =
    perfGroup === 'excellent'
      ? EXCELLENT_DRIVERS_CONSTANTS
      : COACHING_REQUIRED_DRIVERS_CONSTANTS;

  return (
    <Card
      title={
        <div className="d-flex align-items-center">
          <p className="driver_stats_card_header_text">{TitleMessage}</p>
          <Tooltip title={TooltipMessage}>
            <InfoCircleOutlined
              style={{
                marginLeft: '10px',
                color: GREY_200,
                fontSize: '16px',
              }}
            />
          </Tooltip>
        </div>
      }
      style={{
        margin: '10px',
        boxShadow: BoxShadow,
      }}
      bodyStyle={{ padding: '0' }}
    >
      <div
        style={{
          height: '300px',
          padding: '10px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {drivers.length === 0 ? (
          <Typography.Text
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {EmptyMessage}
          </Typography.Text>
        ) : (
          <DriversTable
            isLoading={loadingDrivers}
            drivers={drivers}
            startDate={startDate}
            endDate={endDate}
            intervalType={intervalType}
            ratingsAvailable={false}
            perfGroup={perfGroup}
            noDataMessage={EmptyMessage}
          />
        )}
      </div>
    </Card>
  );
}
