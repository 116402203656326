import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import React from 'react';
import { deviceOwnershipMap } from '../AppStatusConstants';

export const EditableContext = React.createContext(null);

const renderTitle = (title) => (
  <>
    {title}
    <Tooltip title={`You cannot edit ${title}`}>
      <InfoCircleOutlined className="ml-2" color="#7C7C7C" />
    </Tooltip>
  </>
);

const textEllipse = (text) => (
  <Typography.Text
    ellipsis
    style={{
      maxWidth: 200,
    }}
  >
    {text || '--'}
  </Typography.Text>
);

const errorTooltip = (isOwnershipPrivate) => (
  <Tooltip
    placement="top"
    title={
      <ul className="tooltip-error">
        <li>name cannot be empty</li>
        {isOwnershipPrivate && <li>name cannot have @ or numbers</li>}
      </ul>
    }
  >
    <ExclamationCircleOutlined className="ml-3" style={{ color: '#BD4747' }} />
  </Tooltip>
);

export const validationRules = {
  first_name: [
    {
      required: true,
      whitespace: true,
      message: 'Please enter first name',
    },
    {
      max: 20,
      message: 'Name cannot be more than 20 characters',
    },
    {
      type: 'string',
      message: 'Please enter a valid Name!',
    },
  ],
  last_name: [
    {
      required: true,
      whitespace: true,
      message: 'Please enter last name',
    },
    {
      max: 20,
      message: 'Name cannot be more than 20 characters',
    },
    {
      type: 'string',
      message: 'Please enter a valid name',
    },
  ],
  device_ownership_type: [
    {
      required: true,
      message: 'Please select device ownership',
    },
  ],
};

export const columns = [
  {
    title: 'First Name*',
    dataIndex: 'first_name',
    key: 'firstName',
    editable: true,
  },
  {
    title: 'Last Name*',
    dataIndex: 'last_name',
    key: 'lastName',
    editable: true,
  },
  {
    title: () => renderTitle('Email'),
    dataIndex: 'email',
    key: 'email',
    render: textEllipse,
  },
  {
    title: () => renderTitle('Phone Number'),
    dataIndex: 'phone_number',
    key: 'phoneNumber',
    width: 200,
    render: textEllipse,
  },
  {
    title: 'Device Ownership*',
    dataIndex: 'device_ownership_type',
    key: 'device_ownership_type',
    editable: true,
    width: 150,
  },
  {
    title: 'Undo',
    dataIndex: 'undo',
    key: 'undo',
    width: 75,
  },
];

export const modalTableColumns = (handleDelete) => [
  {
    title: 'First Name',
    dataIndex: 'first_name',
    key: 'firstName',
  },
  {
    title: 'Last Name',
    dataIndex: 'last_name',
    key: 'lastName',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    render: textEllipse,
  },
  {
    title: 'Phone Number',
    dataIndex: 'phone_number',
    key: 'phoneNumber',
  },
  {
    title: 'Device Ownership',
    dataIndex: 'device_ownership_type',
    key: 'device_ownership_type',
    render: (text) => textEllipse(deviceOwnershipMap[text]),
  },
  {
    title: 'Undo',
    dataIndex: 'undo',
    key: 'undo',
    render: (_, record) => (
      <td className="d-flex justify-content-center">
        <UndoOutlined
          rotate="90"
          onClick={() => handleDelete(record.driver_id)}
          class="mr-3"
        />
        {record.hasError &&
          errorTooltip(record.device_ownership_type === 'private')}
      </td>
    ),
  },
];

export const checkForSameFields = (
  driversList,
  { driver_id, first_name, last_name, device_ownership_type },
) => {
  const existingDriver = driversList.find(
    (driver) => driver.driver_id === driver_id,
  );

  if (!existingDriver) return false;

  const compareFields = (field) =>
    existingDriver[field]?.trim() === field?.trim();

  return (
    compareFields(first_name) &&
    compareFields(last_name) &&
    compareFields(device_ownership_type)
  );
};
