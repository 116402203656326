import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import React, { useState } from 'react';

export default function TagsHeader({
  onSearch,
  onCreateTag,
  isDeletedTagsTable,
}) {
  const [search_term, setSearchTerm] = useState('');

  const handleSearch = (value) => {
    setSearchTerm(value);
    onSearch(value);
  };

  return (
    <Space
      direction="horizontal"
      width="100%"
      className="table_actions_wrapper"
    >
      <Input
        value={search_term}
        onChange={(e) => handleSearch(e.target.value)}
        prefix={<SearchOutlined />}
        placeholder="Search by tag name"
        allowClear
        size="large"
        width="30%"
      />

      {!isDeletedTagsTable && (
        <Button
          icon={<PlusOutlined />}
          size="large"
          style={{
            marginLeft: '8px',
            border: '1px solid #569D77',
            color: '#569D77',
            borderRadius: '6px',
            padding: '8px',
          }}
          onClick={onCreateTag}
        >
          Create Tag
        </Button>
      )}
    </Space>
  );
}
