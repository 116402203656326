import React from 'react';

const EmptyState = (
  <div
    style={{ height: '200px' }}
    className="d-flex align-items-center justify-content-center"
  >
    <h4>The driver has no activity in this time period</h4>
  </div>
);

export default EmptyState;
