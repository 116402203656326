function formatDistance(input, unit = 'miles') {
  let value = Number(input);
  if (Number.isNaN(value)) return 'NA';
  if (value === 0) {
    return '0 miles';
  }
  if (Math.trunc(value) === 0) {
    return '< 1 mile';
  }
  value = Number(value).toFixed(1);
  return `${value} miles`;
}

function formatDistanceNumber(input) {
  if (input === null) {
    return null;
  }

  const value = Number(input);
  if (Number.isNaN(value) || value === 0) {
    return 0;
  }

  return value.toFixed(1);
}

function formatSelectedPeriodList(selectedList, reverse = false) {
  return selectedList.map((item) => {
    if (reverse) {
      return item === 'ALL' ? 'All' : `P${item}`;
    }
    return item === 'All' ? 'ALL' : item.replace('P', '');
  });
}

function DriverResultLabel(driver) {
  const { id, first_name, last_name, email, phone_number } = driver;

  // if first_name and last_name are not present, return driver id as label
  if (!first_name && !last_name) {
    return id;
  }

  // if email and phone_number are not present, return first_name and last_name as label
  if (!email && !phone_number) {
    return `${first_name} ${last_name}`;
  }

  return `${first_name} ${last_name} (${email || phone_number})`;
}

export {
  DriverResultLabel,
  formatDistance,
  formatDistanceNumber,
  formatSelectedPeriodList,
};
