import { Card } from 'antd';
import React, { useContext } from 'react';
import AppStatusContext from '../../contexts/AppStatusContext';
import StatusPieChart from './StatusPieChart';

export default function DriverStatusCards() {
  const { status, selectedStatus, handleStatusSelection } =
    useContext(AppStatusContext);
  const { all = 0, unhealthy = 0, healthy = 0 } = status;

  const ChartData = [
    {
      name: 'All Set',
      y: (100 * healthy) / all,
      color: '#66A684',
    },
    {
      name: 'Action Pending',
      y: (100 * unhealthy) / all,
      color: '#FA2A18',
    },
  ];

  const handleSelectedStatus = (value) => {
    if (selectedStatus !== value) {
      handleStatusSelection(value);
    }
  };

  return (
    <div className="driver_status_wrapper">
      <Card
        bordered
        style={{
          border: selectedStatus === 'all' && '1px solid #389F74',
          borderRadius: '8px',
        }}
        className="status_card"
        onClick={() => handleSelectedStatus('all')}
      >
        <div className="status_card_all_drivers">
          <div className="status_text">
            <div className="dark_green_tag">All Drivers</div>
            <div className="total">{all}</div>
            <div className="description">Drivers</div>
          </div>
          {status.all > 0 && (
            <div className="pie_chart">
              <StatusPieChart
                id="active_status_chart"
                chartData={ChartData}
                backgroundColor={selectedStatus === 'all' ? '#fff' : '#F8F8FA'}
              />
            </div>
          )}
        </div>
      </Card>
      <Card
        style={{
          border: selectedStatus === 'unhealthy' && '1px solid #389F74',
          borderRadius: '8px',
        }}
        className="status_card"
        onClick={() => handleSelectedStatus('unhealthy')}
      >
        <div className="red_tag">Action Required</div>
        <div className="total">{unhealthy}</div>
        <div className="description">Drivers With Unresolved Issues</div>
      </Card>
      <Card
        style={{
          border: selectedStatus === 'healthy' && '1px solid #389F74',
          borderRadius: '8px',
        }}
        className="status_card"
        onClick={() => handleSelectedStatus('healthy')}
      >
        <div className="green_tag">All Set</div>
        <div className="total">{healthy}</div>
        <div className="description">Drivers With No Pending Issues</div>
      </Card>
    </div>
  );
}
