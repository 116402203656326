import { WarningOutlined } from '@ant-design/icons';
import { Alert, Button } from 'antd';
import { navigate } from 'gatsby-link';
import PropTypes from 'prop-types';
import React from 'react';
import fleetStatus from './fleetStatus';

export default function WarningBanner({ allowCta, companyName, userRole }) {
  const fleet = JSON.parse(localStorage.getItem('fleet_details'));
  const warningMessage = fleet ? (
    <>
      <WarningOutlined className="mr-1" />
      {'Caution! You are impersonating as '} {userRole}
      {' for insured '}
      {fleetStatus(fleet) || companyName}
      {/* Will enable this when we can fix this functionality.
       Right now, it works only for sys-admin
        as the new role fetched is different on role selection, */}
      {allowCta && (
        <Button
          type="primary"
          className="br-5 ml-2"
          onClick={() => navigate('/app/select-fleet')}
        >
          Change Fleet
        </Button>
      )}
    </>
  ) : null;
  return (
    <Alert
      style={{ textAlign: 'center', height: '60px' }}
      banner
      message={warningMessage}
      type="warning"
      showIcon={false}
    />
  );
}

WarningBanner.propTypes = {
  allowCta: PropTypes.bool,
  companyName: PropTypes.string,
  userRole: PropTypes.string,
};
