import { ArrowRightOutlined } from '@ant-design/icons';
import { navigate } from 'gatsby-link';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import '../../assets/style/app.css';
import { useGlobalState } from '../../contexts/AppContext';
import Sidebar from '../../modules/Sidebar';
import {
  DASHBOARD_SIDEBAR_CONFIG,
  SETTINGS_SIDEBAR_CONFIG,
} from '../../modules/Sidebar/utils';
import { Roles } from '../../utils/roles';
import { getUser, isLoggedIn } from '../../utils/validateUserToken';
import Header from '../shared/header';
import WarningBanner from '../shared/WarningBanner';

function Layout({ type, children }) {
  const { state, dispatch } = useGlobalState();
  const user = getUser();
  const userRole = isLoggedIn() && user?.roles[0];
  const allowCta = userRole === Roles.SYS_ADMIN;
  const isShadowUser = user?.purpose === 'shadow';

  if (allowCta && user?.purpose === 'login') {
    navigate('/app/select-fleet');
  }

  const currentConfig =
    type === 'dashboard' ? DASHBOARD_SIDEBAR_CONFIG : SETTINGS_SIDEBAR_CONFIG;

  const toggleSideBar = () => {
    dispatch({ type: 'TOGGLE_SIDEBAR' });
  };

  const handleSideBarClick = (item) => {
    dispatch({ type: 'SET_ACTIVE_ITEM', payload: item });
  };

  useEffect(() => {
    const { pathname } = window.location;
    currentConfig
      .flatMap((item) => (item.submenu ? item.submenu : [item]))
      .filter((item) => {
        if (item.submenu) {
          return item.submenu.some((subItem) => subItem.route === pathname);
        }

        return item.route === pathname || item.subroutes?.includes(pathname);
      })

      .map((item) => dispatch({ type: 'SET_ACTIVE_ITEM', payload: item }));
  }, [window.location.pathname]);

  return (
    <div className="app_wrapper">
      {isShadowUser && (
        <WarningBanner
          allowCta={allowCta}
          companyName={user.company_name}
          userRole={userRole}
        />
      )}
      <Header type={type} />
      <div
        className={
          isShadowUser ? 'app_playground_with_banner' : 'app_playground'
        }
      >
        <div
          className={state.toggleSidebarOnMobile ? 'sidebar_slider' : 'sidebar'}
        >
          <Sidebar
            type={type}
            activeKey={state?.activeItem?.key}
            handleSideBarClick={handleSideBarClick}
            onCloseSidebar={toggleSideBar}
          />
        </div>
        <div className="playground_wrapper">
          <div className="ham_burgur">
            <div className="han_burgur_icon">
              <ArrowRightOutlined onClick={toggleSideBar} />
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}

Layout.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
};

export default Layout;
